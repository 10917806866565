import * as yup from 'yup';
import {
  dateSchemaRequired,
  dropdownRequired,
  nullableNumberSchema,
  stringSchemaRequired,
  numberSchemaRequired,
} from './common';
import { gibbTrueOption } from 'utils/formValues/evaluation';
import { Option } from 'common/components/DetailControls';

/** Evaluation schema requires:
 *  - All fields except 'Gibb Rate' if gibb is false
 */
export const evaluationSchema = yup.object({
  evaluationDate: dateSchemaRequired,
  gibb: dropdownRequired,
  gibbRate: nullableNumberSchema.when('gibb', {
    is: (gibb: Option) => gibb?.id === gibbTrueOption.id,
    then: numberSchemaRequired,
  }),
  market: stringSchemaRequired,
  color: numberSchemaRequired,
  drop: numberSchemaRequired,
  priority: numberSchemaRequired,
  condition: numberSchemaRequired,
});
