import { LoginButton } from 'common/styles/button';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import splashImage from '../../../assets/img/citrus-splash.jpg';
import mockups from '../../../assets/img/mobile_mockups.png';
import { ReactComponent as Logo } from '../../../assets/img/porterville_logo.svg';
import Header from '../Header';
import { darkOrange, white } from 'common/styles/colors';
import { tablet, mobile } from '../../styles/breakpoints';

const ContentContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(180deg, #112457 0%, #10523a 100%);
  color: ${white};

  .sign-in-btn-mobile {
    display: none;
  }

  @media (max-width: 1240px) {
    .sign-in-btn-desktop {
      display: none;
    }

    .sign-in-btn-mobile {
      display: block;
      margin-bottom: 140px;
    }
  }

  @media (max-width: ${mobile}) {
    .sign-in-btn-mobile {
      margin: 206px 0 80px;
      font-size: 18px;
      width: 100%;
      max-width: 280px;
    }
  }
`;

const TopImage = styled.div`
  background-image: url(${splashImage});
  background-repeat: no-repeat;
  background-size: cover;
  height: 330px;

  @media (max-width: ${tablet}) {
    height: 15vh;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -60px;
`;

const FloatingLogo = styled(Logo)`
  max-width: 240px;
  width: 100%;

  @media (max-width: ${mobile}) {
    max-width: 188px;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 580px;
  height: 100%;
  margin-top: -95px;

  @media (max-width: 1240px) {
    flex-direction: column;
    max-height: unset;
    margin-top: 0;
  }
`;

const SignInPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 1240px) {
    align-items: center;
    margin-top: 20px;
  }
`;

const MainHeader = styled(Header)`
  font-family: KanitBold;
  font-size: 96px;
  line-height: 78.5%;
  text-align: right;
  white-space: nowrap;

  @media (max-width: ${mobile}) {
    font-size: 60px;
  }
`;

const AppTitle = styled(Header)`
  font-family: KanitSemiBold;
  font-size: 60px;
  line-height: 104.5%;
  letter-spacing: -0.015em;
  padding-top: 5px;
  padding-bottom: 20px;
  white-space: nowrap;

  @media (max-width: ${mobile}) {
    font-family: KanitBold;
    font-size: 22px;
    letter-spacing: 0.0075em;
  }
`;

const SignInButton = styled(LoginButton)`
  z-index: 100;

  &:hover {
    background-color: ${darkOrange};
  }
`;

const GraphicsPane = styled.img`
  margin: 0 -113px 0 -100px;
  max-width: 865px;
  width: 100%;

  @media (max-width: 1240px) {
    align-items: center;
    margin: -70px 0 -50px 0;
  }

  @media (max-width: ${mobile}) {
    display: none;
  }
`;

const Splash: FC = () => {
  const historyProvider = useHistory();

  return (
    <ContentContainer>
      <TopImage />
      <LogoContainer>
        <FloatingLogo title='Company Logo' />
      </LogoContainer>
      <ActionContainer>
        <SignInPane>
          <MainHeader>
            Porterville
            <br />
            Citrus, Inc.
          </MainHeader>
          <AppTitle variant='h3'>{Constants.appName}</AppTitle>
          <SignInButton
            className='sign-in-btn-desktop'
            onClick={() => historyProvider.push('auth/login')}
          >
            SIGN IN
          </SignInButton>
        </SignInPane>
        <GraphicsPane src={mockups} alt='app image mockup' />
        <SignInButton
          className='sign-in-btn-mobile'
          onClick={() => historyProvider.push('auth/login')}
        >
          SIGN IN
        </SignInButton>
      </ActionContainer>
    </ContentContainer>
  );
};

export default Splash;
