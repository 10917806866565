import { mobile } from 'common/styles/breakpoints';
import {
  darkNavy,
  darkOrange,
  grey,
  lighterNavy,
  tableBodyGrey,
  white,
} from 'common/styles/colors';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const containerWidth = '40px';

export const BaseRow = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${mobile}) {
    width: fit-content;
  }
`;

export const TableHeader = styled(BaseRow)`
  height: 48px;
  margin: 15px 0 18px 0;
`;

export const TableRow = styled(BaseRow)`
  height: 40px;
  margin-bottom: 8px;
`;

export const TableFooter = styled(BaseRow)`
  height: 38px;
  align-items: center;
  margin-top: 5px;
  border-bottom: 1px solid ${grey};
`;

export const NewRowFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  div {
    font-family: KanitRegular;
    color: ${lighterNavy};
  }

  .dropdown-container {
    display: flex;
    align-items: center;
  }
`;

export const InfoColumnContainer = styled.div`
  display: flex;
  width: 21%;
  max-width: 130px;
  min-width: 94px;
  margin-right: 2.5%;

  @media (max-width: ${mobile}) {
    min-width: 105px;
  }
`;

export const PHCode = styled(InfoColumnContainer)`
  justify-content: center;
  font-family: KanitRegular;
  font-size: 14px;
  color: ${white};
  align-items: center;
  background: ${darkOrange};
  height: 100%;
  border-radius: 6px;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
  height: 100%;
  border-radius: 6px;

  :last-child {
    margin-right: 0;
  }
`;

export const ComboContainer = styled(InfoContainer)`
  font-family: KanitLight;
  font-size: 14px;
  width: 33%;
  color: ${white};
  background: ${lighterNavy};
`;

export const FooterText = styled(InfoColumnContainer)`
  justify-content: flex-end;
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${lighterNavy};
`;

export const DateColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 5px;
  height: 100%;
  width: 6.5%;

  &.day-total {
    justify-content: center;

    span {
      text-align: center;
      width: 100%;
      min-width: ${containerWidth};
      font-family: KanitSemiBold;
      font-size: 14px;
      color: ${tableBodyGrey};
    }
  }
`;

export const PackDay = styled.span`
  display: flex;
  width: 100%;
  min-width: ${containerWidth};
  justify-content: center;
  font-family: KanitLight;
  font-size: 16px;
  background: ${lighterNavy};
  color: ${white};
  border-radius: 6px 6px 0 0;
`;

export const PackDate = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: KanitRegular;
  height: 36px;
  width: 100%;
  min-width: ${containerWidth};
  font-size: 14px;
  color: ${darkNavy};
  background: ${white};
  border: 1px solid ${lighterNavy};
  border-radius: 0 0 6px 6px;
`;

export const RemoveRowBtnContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 24px;
  margin-right: 5px;
`;

export const TotalsColumnContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 28%;
  min-width: 100px;
`;

export const TotalsHeader = styled.span`
  text-align: center;
  align-items: flex-end;
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${lighterNavy};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TotalsRow = styled.span`
  text-align: center;
  font-family: KanitLight;
  font-size: 14px;
  color: ${tableBodyGrey};
`;

export const TotalsFooter = styled.span`
  text-align: center;
  font-family: KanitSemiBold;
  font-size: 14px;
  color: ${tableBodyGrey};
`;

export const BinReqContainer = styled.input`
  height: 100%;
  width: 100%;
  font-family: KanitLight;
  font-size: 14px;
  min-width: ${containerWidth};
  text-align: center;
  border-radius: 6px;
  border: 1px solid ${grey};

  &.positive-bins {
    background-color: ${darkOrange};
    color: ${white};
  }

  &.show-pointer {
    cursor: pointer;
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;
