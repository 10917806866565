import { FormButtonHeader } from 'common/components/FormHeader/FormButtonHeader';
import {
  LocationButton,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import { lighterBlueShade, lightGreyText, orange } from 'common/styles/colors';
import { FC, useMemo, useState } from 'react';
import {
  HeaderSection,
  HeaderRow,
  HarvestIcon,
  NavyInfoContainer,
  HeaderInfoLabel,
  NavyInfoValue,
  NestedContainer,
  PickDetailInput,
} from './styles';
import TreeHarvestIcon from 'assets/img/tree-harvest.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { formatPickDetailDate } from 'features/pick-schedule-views/utils/pickDetailUtils';
import { Pool, ScheduledPick } from 'common/models';
import { ScheduledPickButtons } from './ScheduledPickButtons';
import { WizardNumericInput } from 'common/components/WizardControls/WizardNumericInput';
import usePickScheduleActions from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { editScheduledPickSchema } from 'utils/schemas/scheduledPickSchema';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import { selectBaseStyling } from '../WizardComponents/wizardStyles';
import { useRbac } from 'features/rbac';

export const ScheduledPickForm: FC<{
  pickData: ScheduledPick;
  pools: Pool[];
}> = ({ pickData, pools }) => {
  const { userHasPermission } = useRbac();
  const [isEditable, setIsEditable] = useState(false);
  const { tryEditScheduledPick: editScheduledPick } = usePickScheduleActions();
  const mapParams = validateCoordinateInfo(
    pickData.block.latitude,
    pickData.block.longitude,
    pickData.block.blockId,
  );

  const poolOptions: WizardDropdownOption<Pool>[] = useMemo(
    () =>
      pools?.map(pool => ({
        label: pool.poolId,
        value: pool,
      })),
    [pools],
  );

  const methods = useForm<{ bins: number; pool: WizardDropdownOption<Pool> }>({
    defaultValues: {
      bins: pickData.bins,
      pool: { label: pickData.pool.poolId, value: pickData.pool },
    },
    resolver: yupResolver(editScheduledPickSchema),
    mode: 'all',
  });
  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
  } = methods;

  const pickDayAsDate = formatPickDetailDate(
    pickData?.schedule?.pickDay || undefined,
  );

  const tryEditScheduledPick = async ({
    bins,
    pool,
  }: {
    bins: number;
    pool: WizardDropdownOption<Pool>;
  }) => {
    const payload = {
      id: pickData.id,
      schedId: pickData.schedule.id,
      bins,
      poolId: pool.value.id,
    };

    const success = await editScheduledPick(payload);

    if (success) {
      setIsEditable(false);
      reset();
    }
  };

  return (
    <FormProvider {...methods}>
      <HeaderSection
        $backgroundColor={isEditable ? lighterBlueShade : lightGreyText}
        onSubmit={e => {
          // This logic prevents the `DetailForm` wrapper from submitting the form
          e.preventDefault();
          if (isEditable && isDirty) {
            handleSubmit(tryEditScheduledPick)(e);
          } else {
            reset();
          }
        }}
      >
        <HeaderRow>
          <div style={{ display: 'flex', width: '100%' }}>
            <HarvestIcon src={TreeHarvestIcon} alt='harvest icon' />
            <NavyInfoContainer>Pick Detail</NavyInfoContainer>
          </div>
          {userHasPermission('pick:edit-schedule-details') && (
            <FormButtonHeader
              isEditable={isEditable}
              handleCancelClick={() => setIsEditable(false)}
              handleEditClick={() => {
                if (!isDirty) {
                  setIsEditable(!isEditable);
                }
              }}
            />
          )}
        </HeaderRow>
        <HeaderRow>
          <NavyInfoContainer>
            {pickData.block.grower.growerId}
          </NavyInfoContainer>
          <NavyInfoContainer>
            {pickData.block.blockId}
            <LocationButton
              iconColor={lightGreyText}
              backgroundColor={orange}
              mapParams={mapParams}
            />
          </NavyInfoContainer>
        </HeaderRow>
        <HeaderRow>
          <HeaderInfoLabel>
            Pick Day:
            <NavyInfoValue>{pickDayAsDate}</NavyInfoValue>
          </HeaderInfoLabel>
        </HeaderRow>
        <HeaderRow>
          <NestedContainer>
            <HeaderInfoLabel>Bins:</HeaderInfoLabel>
            {isEditable ? (
              <PickDetailInput>
                <WizardNumericInput name='bins' />
              </PickDetailInput>
            ) : (
              <NavyInfoValue>{pickData.bins}</NavyInfoValue>
            )}
          </NestedContainer>
          <NestedContainer>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id=''>Packhouse Code</Tooltip>}
            >
              <NavyInfoValue>{pickData.packHouse?.code}</NavyInfoValue>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id=''>Variety Code</Tooltip>}
            >
              <NavyInfoValue>
                {pickData.block.variety?.varietyCode}
              </NavyInfoValue>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={<Tooltip id=''>Size</Tooltip>}
            >
              <NavyInfoValue>{pickData.size?.value}</NavyInfoValue>
            </OverlayTrigger>
          </NestedContainer>
        </HeaderRow>
        <HeaderRow>
          {isEditable && (
            <NestedContainer>
              <HeaderInfoLabel>Pool:</HeaderInfoLabel>
              <PickDetailInput>
                <WizardDropdown
                  formControl={control}
                  styles={selectBaseStyling}
                  name='pool'
                  options={poolOptions}
                />
              </PickDetailInput>
            </NestedContainer>
          )}
        </HeaderRow>
        <ScheduledPickButtons
          isAssigned={!!pickData.pickAssignment}
          isPicked={!!pickData.pickRecord}
          isEdit={isEditable}
        />
      </HeaderSection>
    </FormProvider>
  );
};
