import { useMemo } from 'react';
import { Column } from 'react-table';
import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import {
  VarietalSumTableItem,
  toFormattedNumber,
} from './useVarietalSumTableData';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';

export const useVarietalSumTableDataMobile =
  (): Column<VarietalSumTableItem>[] => {
    return useMemo(
      () => [
        {
          accessor: 'varietyInfo',
          Header: 'VAR',
          Cell: ({ value: variety }) => (
            <DataTableText>{variety.code}</DataTableText>
          ),
        },
        {
          accessor: 'district',
          Header: 'District',
          Cell: ({ value }) => (
            <DataTableText>
              {value ? value.toLocaleString() : '-'}
            </DataTableText>
          ),
        },
        {
          accessor: 'originalEstimate',
          Header: 'Orig.',
          Cell: ({ value }) => (
            <DataTableText>{toFormattedNumber(value)}</DataTableText>
          ),
        },
        {
          accessor: 'revisedEstimate',
          Header: 'Rev.',
          Cell: ({ value }) => (
            <DataTableText>{toFormattedNumber(value)}</DataTableText>
          ),
        },
        {
          accessor: 'binsRemaining',
          Header: 'To Go',
          Cell: ({ value }) => (
            <DataTableText>{toFormattedNumber(value)}</DataTableText>
          ),
        },
        {
          id: 'Arrow',
          Header: '',
          Cell: () => <ForwardArrow />,
        },
      ],
      [],
    );
  };
