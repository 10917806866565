import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/redux';
import { GrowerWithBlocks } from 'common/api/dto/get-grower.dto';
import { useUpdateGrowerMutation } from 'common/api/growerApi';
import { handleError } from 'common/api/handleError';
import {
  DetailHeading,
  DetailSubHeading,
  DetailText,
} from 'common/components/DetailControls';
import DetailDatePicker from 'common/components/DetailControls/DetailDatePicker';
import {
  DetailForm,
  DetailFormContext,
} from 'common/components/DetailControls/DetailForm';
import { RichDetailTableContainer } from 'common/components/DetailControls/RichDetailTableContainer';
import FormPrompt from 'common/components/FormPrompt';
import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import {
  BaseTableItem,
  CustomRenderer,
  GenericTable,
  TableHeader,
} from 'common/components/GenericTable';
import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import useWindowSize from 'common/hooks/useWindowSize';
import { GrowerStatus } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';
import { BlockStatus } from 'common/models/growerBlock';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import {
  blueShade,
  brightOrange,
  lightGreyBackground,
  lightGreyText,
} from 'common/styles/colors';
import { FormPadding, StyledFormWrapper, Title } from 'common/styles/form';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { useRbac } from 'features/rbac';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import {
  growerAllFieldsSchema,
  growerCertFieldsSchema,
} from 'utils/schemas/growerSchema';
import { DetailsHeader } from './DetailsHeader';
import { ForwardArrow, InactiveErrorIcon } from './GrowerIcons';
import { StatusIcon } from './StatusIcon';

export type GrowerFormValues = {
  parentGrowerName: string | null;
  managerName: string | null;
  grpNumber: number | null;
  permitNumber: number | null;
  ggnNumber: number | null;
  certExp: Date | null;
  gapStatus: string | null;
  gapCertNotes: string | null;
  managerCell: string | null;
  notes: string | null;
  secondGrower: string | null;
};

type GrowerBlockItem = BaseTableItem & {
  blockId: string;
  name: string;
  acres: number | null;
  variety?: string;
  status?: BlockStatus;
  action: string;
};

const LargeFormWrapper = styled(StyledFormWrapper)`
  max-width: 1168px;
  width: 100%;

  @media (max-width: ${mobile}) {
    width: 100vw;
  }
`;

const CertField = styled(Col)`
  .row {
    background: ${props =>
      props.$editable ? `${blueShade}` : `${lightGreyBackground}`};
  }
`;

const CertifiedField: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const context = useContext(DetailFormContext);
  return <CertField $editable={context.editable}>{children}</CertField>;
};

const StyledBlocksTable = styled(RichDetailTableContainer)`
  thead > tr,
  tbody > tr {
    th:first-child,
    td:first-child {
      width: 3%;
      text-align: center;
    }
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }
`;

const MobileBlockTableWrapper = styled.div`
  background-color: ${lightGreyText};
  padding: 0;

  // counteract the DetailForm padding
  margin: 0 -${FormPadding};
  margin-bottom: -${FormPadding};

  @media (max-width: ${mobile}) {
    margin: 0;
  }
`;

const NeedsReviewWarningBar = styled.div`
  width: ${LargeFormWrapper.width};
  height: 27px;
  margin-left: -${FormPadding};
  margin-right: -${FormPadding};
  background-color: ${brightOrange};
  color: ${lightGreyText};
  text-align: center;

  @media (max-width: ${mobile}) {
    width: 100vw;
    margin: 0;
  }
`;

type GrowerBlockHeader = TableHeader<GrowerBlockItem>;
type GrowerBlockRenderer = CustomRenderer<GrowerBlockItem>;

const blockHeaders: GrowerBlockHeader[] = [
  { key: 'status', label: 'STA' },
  { key: 'blockId', label: 'Block ID' },
  { key: 'name', label: 'Name' },
  { key: 'variety', label: 'Variety' },
  { key: 'acres', label: 'Acres' },
  { key: 'action', label: '' },
];

const mobileBlockHeaders: GrowerBlockHeader[] = [
  { key: 'status', label: 'STA' },
  { key: 'blockId', label: 'Block ID' },
  { key: 'name', label: 'Name' },
  { key: 'variety', label: 'Variety' },
  { key: 'action', label: '' },
];

const GrowerDetailForm: React.FC<{ grower: GrowerWithBlocks }> = ({
  grower,
}): JSX.Element => {
  const { userHasPermission } = useRbac();
  const canEditCertInfo: boolean = useMemo<boolean>(
    () => userHasPermission('grower:edit-cert-info'),
    [userHasPermission],
  );
  const canEditAll: boolean = useMemo<boolean>(
    () => userHasPermission('grower:edit-all'),
    [userHasPermission],
  );
  const [updateGrower] = useUpdateGrowerMutation();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const cellRenderers: GrowerBlockRenderer[] = [
    {
      key: 'status',
      headerRenderer: () => (
        <WithTooltip
          title='Block Status Icon'
          tooltipText={`Block Status${` (${selectedSeason?.year})` || ''}`}
        >
          <InactiveErrorIcon />
        </WithTooltip>
      ),
      renderer: block => <StatusIcon status={block.status} type='block' />,
    },
    {
      key: 'action',
      renderer: () => <ForwardArrow />,
    },
  ];

  const methods = useForm<GrowerFormValues>({
    defaultValues: {
      parentGrowerName: grower.parentGrowerName || null,
      managerName: grower.managerName || null,
      grpNumber: grower.grpNumber || null,
      permitNumber: grower.permitNumber || null,
      ggnNumber: grower.ggnNumber || null,
      certExp: CmsDate.parse(grower.certExp)?.toDate(),
      gapStatus: grower.gapStatus || null,
      gapCertNotes: grower.gapCertNotes || null,
      managerCell: grower.managerCell || null,
      notes: grower.notes || null,
      secondGrower: grower.secondGrower || null,
    },
    resolver: yupResolver(
      canEditAll ? growerAllFieldsSchema : growerCertFieldsSchema,
    ),
    mode: 'all',
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid, errors },
    reset,
    getValues,
    trigger,
  } = methods;

  const history = useHistory();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  // Add variable and  reused it for multiple fields in the return
  const isEditable = canEditAll || canEditCertInfo;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ref: certRef, ...certExpProps } = register('certExp');

  const trySubmit = useCallback(
    async (evt: React.FormEvent) => {
      let isSuccessful: boolean;

      const saveGrower = async (data: GrowerFormValues) => {
        const growerDto = {
          ...data,
          certExp: CmsDate.fromDateOnly(data.certExp)?.toISO() ?? null,
        };
        await updateGrower({
          growerId: grower.id,
          seasonId: selectedSeason?.id as number,
          ...growerDto,
        }).unwrap();
      };

      if (isDirty && isValid) {
        try {
          await handleSubmit(saveGrower)(evt);
          notifier.showSuccessMessage('Grower updated.');
          isSuccessful = true;
        } catch (error) {
          handleError(error, Constants.errorMessages.SAVE_FAILED);
          isSuccessful = false;
        }
      } else if (!isValid) {
        trigger();
        notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
        isSuccessful = false;
      } else {
        isSuccessful = true;
      }

      return isSuccessful;
    },
    [
      isDirty,
      isValid,
      updateGrower,
      grower,
      selectedSeason,
      handleSubmit,
      trigger,
    ],
  );

  const blocks: GrowerBlockItem[] = useMemo(
    () =>
      grower.growerBlocks.map(block => ({
        id: block.id,
        blockId: block.blockId,
        name: block.blockName,
        status: block.seasonData?.status,
        variety: block.variety?.varietyName,
        acres: block.acres,
        action: 'edit',
      })),
    [grower],
  );

  // This effect clears the dirty flag.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  return (
    <FormProvider {...methods}>
      {!isMobile && <Title>Grower Detail</Title>}
      <DetailForm
        submitAction={trySubmit}
        CustomWrapper={LargeFormWrapper}
        largeLabels
        reset={reset}
        closeHandler={() => history.push(Constants.routes.GROWERS_LIST)}
        canEdit={
          !!grower.seasonData?.status &&
          (userHasPermission('grower:edit-all') ||
            userHasPermission('grower:edit-cert-info') ||
            userHasPermission('grower:edit-manager-info'))
        }
      >
        <DetailsHeader
          status={grower.seasonData?.status}
          type='grower'
          inactiveReason={grower.seasonData?.inactiveReason}
        />

        <DetailHeading>{grower.name}</DetailHeading>
        {grower.seasonData?.status === GrowerStatus.New && (
          <NeedsReviewWarningBar>NEW - COMPLETE CMS INFO</NeedsReviewWarningBar>
        )}
        <StyledStack>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='Grower ID'
                editable={false}
                text={grower.growerId}
              />
            </Col>
            <Col>
              <DetailText
                label='SK #'
                editable={false}
                text={grower.skNumber}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='Parent Grower'
                editable={canEditAll}
                name='parentGrowerName'
                validation={errors.parentGrowerName?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='Address'
                editable={false}
                text={grower.address.addressLine1}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='Manager'
                editable={
                  canEditAll || userHasPermission('grower:edit-manager-info')
                }
                name='managerName'
                validation={errors.managerName?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='Address line 2'
                editable={false}
                text={grower.address.addressLine2}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='Manager Cell'
                editable={
                  canEditAll || userHasPermission('grower:edit-manager-info')
                }
                name='managerCell'
                validation={errors.managerCell?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='City'
                editable={false}
                text={grower.address.city}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='GRP #'
                editable={canEditAll}
                name='grpNumber'
                validation={errors.grpNumber?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='State'
                editable={false}
                text={grower.address.state}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <Col>
              <DetailText
                label='Permit #'
                editable={canEditAll}
                name='permitNumber'
                validation={errors.permitNumber?.message}
              />
            </Col>
            <Col>
              <DetailText
                label='Zip'
                editable={false}
                text={grower.address.zipCode}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <CertifiedField>
              <DetailText
                label='GGN #'
                editable={isEditable}
                name='ggnNumber'
                validation={errors.ggnNumber?.message}
              />
            </CertifiedField>
            <Col>
              <DetailText label='Phone' editable={false} text={grower.phone} />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <CertifiedField>
              <DetailDatePicker
                label='Cert. Expiration'
                control={control}
                editable={isEditable}
                {...certExpProps}
              />
            </CertifiedField>
            <Col>
              <DetailText label='Email' editable={false} text={grower.email} />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <CertifiedField>
              <DetailText
                label='GAP Status'
                editable={isEditable}
                name='gapStatus'
                validation={errors.gapStatus?.message}
              />
            </CertifiedField>
            <Col>
              <DetailText
                label='2nd Grower'
                editable={canEditAll}
                name='secondGrower'
                validation={errors.secondGrower?.message}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <CertifiedField>
              <DetailText
                label='GAP Notes'
                name='gapCertNotes'
                editable={isEditable}
                validation={errors.gapCertNotes?.message}
              />
            </CertifiedField>
            <Col>
              <DetailText
                label='Grower Notes'
                name='notes'
                editable={canEditAll}
                validation={errors.notes?.message}
              />
            </Col>
          </ResponsiveRow>
          <ResponsiveRow>
            <CertifiedField>
              <DetailText
                label='GAP Updated At'
                editable={false}
                text={CmsDate.parse(grower.gapUpdatedAt)?.toString() || ''}
              />
            </CertifiedField>
            <Col />
          </ResponsiveRow>
        </StyledStack>
        <MobileBlockTableWrapper>
          <DetailSubHeading>Blocks</DetailSubHeading>
          <StyledBlocksTable>
            <GenericTable
              headers={isMobile ? mobileBlockHeaders : blockHeaders}
              showHeaders={blocks && blocks.length > 0}
              items={blocks}
              customRenderers={cellRenderers}
              emptyMessage='No blocks found.'
              clickHandler={blk =>
                history.push(Constants.routes.GROWER_BLOCK_DETAILS + blk.id)
              }
            />
          </StyledBlocksTable>
        </MobileBlockTableWrapper>
        <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
      </DetailForm>
    </FormProvider>
  );
};

export default GrowerDetailForm;
