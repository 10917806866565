import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BlockEvalView } from './pages/BlockEvalView';
import { EvalEstMainView } from './pages/EvalEstMainView';

export const EvalEstimatesRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={EvalEstMainView}
        requiredRoles={[RoleType.Admin, RoleType.Analyst, RoleType.FieldRep]}
      />
      <PrivateRoute
        path={`${path}/:id`}
        component={BlockEvalView}
        requiredRoles={[RoleType.Admin, RoleType.Analyst, RoleType.FieldRep]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
