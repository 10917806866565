import { darkBlue, darkGrey } from 'common/styles/colors';
import { FC } from 'react';
import styled from 'styled-components';

const StyledTab = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70px;
  padding: 0 10px;
  margin: 0 3px;

  span {
    font-family: 'KanitMedium';
    font-size: 18px;
    line-height: 148%;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: ${darkBlue};
    width: fit-content;
    text-align: center;
    white-space: nowrap;
  }

  &.selected {
    span {
      color: ${darkGrey};
    }

    .underline {
      display: block;
      position: absolute;
      bottom: 15px;
      height: 4px;
      width: 100%;
      background-color: ${darkGrey};
    }
  }

  @media (max-width: 1100px) {
    span {
      font-size: 15px;
    }
  }

  @media (max-width: 960px) {
    padding: 0 5px;

    span {
      white-space: normal;
    }
  }
`;

export const DetailTabHeader: FC<{ tabName: string; isSelected: boolean }> = ({
  tabName,
  isSelected = false,
}) => {
  return (
    <StyledTab className={isSelected ? 'selected' : ''}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span>{tabName}</span>
        <div className='underline' />
      </div>
    </StyledTab>
  );
};
