import { FC, useEffect } from 'react';
import { PickDataWizard } from '../components/PickDataWizard/PickDataWizard';
import { useHistory, useParams } from 'react-router-dom';
import { useGetScheduledPickByIdQuery } from 'common/api/pickSchedulingApi';
import { Constants } from 'utils/constants';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { useGetPickRatesQuery } from 'common/api/harvestDataApi';

export const PickDataWizardView: FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, isFetching, error } = useGetScheduledPickByIdQuery({
    id: parseInt(id, 10),
  });
  const {
    data: rates,
    isFetching: isFetchingRates,
    error: ratesError,
  } = useGetPickRatesQuery();

  useEffect(() => {
    if (error) {
      handleError(error, 'Unable to load scheduled pick data.');
      history.replace(`${Constants.routes.PICK_SCHEDULE}/${id}`);
    }
    if (ratesError) {
      handleError(ratesError, 'Unable to load default pick rates.');
    }
  }, [error, history, id, ratesError]);

  return (
    <WithLoadingOverlay isLoading={isFetching || isFetchingRates}>
      {data && <PickDataWizard scheduledPick={data} pickRates={rates} />}
    </WithLoadingOverlay>
  );
};
