import { yupResolver } from '@hookform/resolvers/yup';
import { Role, User } from 'common/models';
import { FC } from 'react';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import FormPrompt from 'common/components/FormPrompt';
import { SubmitButton } from 'common/styles/button';
import styled from 'styled-components';

import { StyledLoadingButton } from 'common/components/LoadingButton';
import { blueShadeHeader, tableHeaderGrey } from 'common/styles/colors';
import { Constants } from 'utils/constants';
import { Close } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { CustomSelect } from 'common/components/CustomSelect/CustomSelect';

export type FormData = Pick<User, 'email' | 'firstName' | 'lastName' | 'role'>;

export interface Props {
  availableRoles: Role[];
  defaultValues?: Partial<FormData>;
  submitButtonLabel?: string;
  onSubmit: (data: FormData) => void;
}

const formStyle = {
  width: '100%',
};

const StyledTitle = styled.h1`
  font-family: 'KanitRegular';
  font-weight: 300;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.0075em;

  color: ${blueShadeHeader};

  flex: none;
  order: 0;
  flex-grow: 0;
`;

const StyledFormGroup = styled(Form.Group)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${blueShadeHeader};
  gap: 10px;

  label {
    width: 100px;
  }
`;

const StyledInputWrapper = styled.div`
  flex-direction: column;
  flex-grow: 1;
  width: 100px;
`;

const StyledHr = styled.hr`
  margin: 0.5em;
`;

const SendInviteButton = styled(StyledLoadingButton)`
  letter-spacing: 0.1em;
  width: 163px;
`;

const CancelDiv = styled.div`
  display: flex;
  align-items: end;
  cursor: pointer;
  font-family: 'KanitRegular';
  font-weight: 600;
  font-size: 16px;
  line-height: 138%;
  padding: 8px 0;

  color: ${tableHeaderGrey};
`;

const StyledClose = styled(Close)`
  padding: 1%;
`;

const schema = yup.object({
  firstName: yup
    .string()
    .trim()
    .required(Constants.errorMessages.FIRST_NAME_REQUIRED),
  lastName: yup
    .string()
    .trim()
    .required(Constants.errorMessages.LAST_NAME_REQUIRED),
  role: yup.object({
    roleName: yup.string().required(Constants.errorMessages.ROLE_REQUIRED),
  }),
  email: yup.string().email().required(Constants.errorMessages.EMAIL_REQUIRED),
});

export const CreateUserCard: FC<Props> = ({
  availableRoles,
  defaultValues = {},
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, isDirty, isValid, errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues,
  });

  const history = useHistory();
  const goToUserListView = () => {
    history.push(Constants.routes.USERS_LIST);
  };

  return (
    <Form
      style={formStyle}
      name='create-user-form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <StyledTitle>Create New User</StyledTitle>
      <StyledFormGroup controlId='create-user-form-first-name'>
        <Form.Label>First Name</Form.Label>
        <StyledInputWrapper>
          <Form.Control
            type='text'
            placeholder='First Name'
            {...register('firstName')}
            isInvalid={!!errors.firstName}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.firstName?.message}
          </Form.Control.Feedback>
        </StyledInputWrapper>
      </StyledFormGroup>
      <StyledHr />
      <StyledFormGroup controlId='create-user-form-last-name'>
        <Form.Label>Last Name</Form.Label>
        <StyledInputWrapper>
          <Form.Control
            type='text'
            placeholder='Last Name'
            {...register('lastName')}
            isInvalid={!!errors.lastName}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.lastName?.message}
          </Form.Control.Feedback>
        </StyledInputWrapper>
      </StyledFormGroup>
      <StyledHr />
      <StyledFormGroup controlId='create-user-form-email'>
        <Form.Label>Email</Form.Label>
        <StyledInputWrapper>
          <Form.Control
            type='email'
            placeholder='email'
            {...register('email')}
            isInvalid={!!errors.email}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.email?.message}
          </Form.Control.Feedback>
        </StyledInputWrapper>
      </StyledFormGroup>
      <StyledHr />
      <StyledFormGroup controlId='create-user-form-role'>
        <Form.Label>Role</Form.Label>
        <StyledInputWrapper>
          <Controller
            control={control}
            name='role'
            render={({ field: { onChange } }) => (
              <CustomSelect<Role>
                placeholder='Select a role...'
                defaultValue={defaultValues.role}
                options={availableRoles}
                getOptionLabel={role => role.roleName}
                getOptionValue={role => role.roleName}
                onChange={onChange}
                isInvalid={!!errors.role}
              />
            )}
          />
          <Form.Control.Feedback type='invalid'>
            {errors.role?.roleName?.message}
          </Form.Control.Feedback>
        </StyledInputWrapper>
      </StyledFormGroup>
      <StyledHr />
      <StyledFormGroup>
        <SendInviteButton
          type='submit'
          as={SubmitButton}
          disabled={!isValid}
          loading={isSubmitting.toString()}
        >
          SEND INVITE
        </SendInviteButton>
        <CancelDiv onClick={goToUserListView}>
          Cancel <StyledClose />
        </CancelDiv>
      </StyledFormGroup>
      <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
    </Form>
  );
};
