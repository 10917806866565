import { useAppSelector } from 'app/redux';
import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { UpdateBlockStatusDto } from 'common/api/dto/growerBlockApiTypes';
import {
  useAddToSeasonMutation,
  useUpdateBlockStatusMutation,
} from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { LoadingButton } from 'common/components/LoadingButton';
import { BlockStatus } from 'common/models';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { SubmitButton } from 'common/styles/button';
import {
  lighterNavy,
  lightGreyText,
  orange,
  white,
} from 'common/styles/colors';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import {
  ModifiedAllSeasonData,
  SchedulePickWizardValues,
} from 'features/pick-schedule-views/pages/SchedulePickWizardView';
import { useRbac } from 'features/rbac';
import { Dispatch, SetStateAction, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { MakeInactiveButton } from './modals/MakeInactiveButton';

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;

  @media (max-width: ${mobile}) {
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
`;

const ViewDataButton = styled.button`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  width: 336px;
  background: ${lighterNavy};
  border-radius: 4px;
  margin-bottom: 20px;
`;

export const ActionButton = styled.button.attrs({ type: 'button' })`
  font-family: 'KanitRegular';
  font-size: 18px;
  letter-spacing: 0.12em;
  border-radius: 4px;
  border: none;
  color: ${white};
  padding: 0.5rem 1rem;

  background: ${orange};
  width: 200px;
`;

const UndoActionButton = styled(ActionButton)`
  background: ${lighterNavy};
  width: 180px;
  height: 40px;
`;

const AddToPickSchedBtn = styled.button`
  max-width: 336px;
  width: 100%;
  background: ${lightGreyText};
  font-family: KanitRegular;
  font-size: 18px;
  letter-spacing: 0.12em;
  color: ${lighterNavy};
  border: 1px solid ${lighterNavy};
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 0.5rem 1rem;
`;

export const BlockDetailsFooter: React.FC<{
  block: BlockDetails<WithPartialSeasonData>;
  showActiveModal: Dispatch<SetStateAction<boolean>>;
  showInactiveModal: Dispatch<SetStateAction<boolean>>;
  sizeEstimates?: SizeEstimate[];
}> = ({ block, showActiveModal, showInactiveModal, sizeEstimates }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { userHasPermission } = useRbac();
  const { id } = useParams<{ id: string }>();
  const [updateBlockStatus] = useUpdateBlockStatusMutation();
  const [addToSeason] = useAddToSeasonMutation();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const [isLoading, setIsLoading] = useState(false);
  const { seasonData } = block;
  const blockStatus = block ? seasonData?.status : undefined;

  const tryAddToSeason = async () => {
    try {
      setIsLoading(true);

      await addToSeason({
        seasonId: selectedSeason?.id as number,
        blockId: parseInt(id, 10),
      }).unwrap();

      notifier.showSuccessMessage('Block added successfully.');
    } catch (error) {
      handleError(error, Constants.errorMessages.UPDATE_BLOCK_FAILED);
    } finally {
      setIsLoading(false);
    }
  };

  const makeBlockActive = async () => {
    // We send the 'New' status and let the server determine if
    // the block should be given a different active status.
    const blockStatusDto: UpdateBlockStatusDto = {
      blockStatus: BlockStatus.New,
    };

    try {
      setIsLoading(true);
      const updatedBlock = await updateBlockStatus({
        seasonId: selectedSeason?.id as number,
        blockId: parseInt(id, 10),
        ...blockStatusDto,
      }).unwrap();

      setIsLoading(false);

      if (updatedBlock.status !== BlockStatus.New) {
        showActiveModal(true);
      }

      notifier.showSuccessMessage(
        Constants.errorMessages.UPDATE_STATUS_SUCCESS,
      );
    } catch (error) {
      handleError(error, Constants.errorMessages.UPDATE_BLOCK_FAILED);
      setIsLoading(false);
    }
  };

  const goToEvalEstPage = () => {
    history.push(
      `${Constants.routes.EVAL_EST_MAIN}/${block.id}${Constants.routes.ESTIMATES}`,
    );
  };

  const showAddToSchedule =
    pathname.includes(Constants.routes.EVAL_EST_MAIN) &&
    sizeEstimates &&
    seasonData?.status === BlockStatus.Active;

  const showViewEstimates =
    pathname.includes(Constants.routes.GROWER_BLOCK_DETAILS) &&
    (blockStatus === BlockStatus.Active ||
      blockStatus === BlockStatus.Harvested);

  const showMakeInactive =
    (blockStatus === BlockStatus.New ||
      blockStatus === BlockStatus.Pending ||
      blockStatus === BlockStatus.Active ||
      blockStatus === BlockStatus.Harvested) &&
    !block.seasonData?.latestHarvestEstimate?.binsPicked;

  const showMakeActive =
    blockStatus === BlockStatus.Non$Producing ||
    blockStatus === BlockStatus.Withdrawn ||
    blockStatus === BlockStatus.Inactive;

  return (
    <ButtonGroup>
      {
        // Add to season - only shown when block is not in the selected season.
        userHasPermission('blockStatus:change') &&
          blockStatus === undefined && (
            <LoadingButton
              as={ActionButton}
              onClick={tryAddToSeason}
              loading={isLoading}
            >
              ADD TO SEASON
            </LoadingButton>
          )
      }

      {
        // Add to pick schedule - only shown on E&E pages for an active block.
        userHasPermission('pick:schedule') && showAddToSchedule && (
          <AddToPickSchedBtn
            type='button'
            onClick={() => {
              const schedulingPayload: SchedulePickWizardValues = {
                // Replace the server size estimates with form ones as the server only
                // provides estimate size IDs instead of 'Size' entities.
                adHoc: {
                  ...block,
                  seasonData: {
                    ...seasonData,
                    sizeEstimates,
                  } as ModifiedAllSeasonData,
                },
              };
              history.push({
                pathname: `${Constants.routes.PICK_SCHEDULE}${Constants.routes.NEW_PICK}`,
                state: schedulingPayload,
              });
            }}
          >
            ADD TO PICK SCHEDULE
          </AddToPickSchedBtn>
        )
      }

      {
        // View estimates - only shown when block is in the selected season
        // and its status is one that should have estimates.
        showViewEstimates && (
          <ViewDataButton
            as={SubmitButton}
            loading='false'
            onClick={goToEvalEstPage}
          >
            VIEW ESTIMATES
          </ViewDataButton>
        )
      }

      {
        // These two buttons work in tandem to deactivate/reactivate a block -
        // one will always be shown if the block is in the selected season.
        userHasPermission('blockStatus:change') && (
          <>
            {showMakeInactive && (
              <MakeInactiveButton openModal={showInactiveModal} />
            )}
            {showMakeActive && (
              <LoadingButton
                as={UndoActionButton}
                loading={isLoading}
                onClick={makeBlockActive}
              >
                MAKE ACTIVE
              </LoadingButton>
            )}
          </>
        )
      }
    </ButtonGroup>
  );
};
