import { StyledLabel } from 'common/components/DetailControls/DetailControl';
import { DetailFormContext } from 'common/components/DetailControls/DetailForm';
import { StyledText } from 'common/components/DetailControls/DetailText';
import { grey, lighterNavy, red } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { ChangeEvent, FC, useContext, useMemo } from 'react';
import { Controller, Noop, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { Slider } from '@mui/material';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';

const RowStyling = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem 0;

  label {
    margin: 0;
  }

  > * {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  & > div:last-of-type {
    font-weight: bold;
    color: ${lighterNavy};
  }

  &.bot-border {
    border-bottom: 1px solid ${grey};
  }
`;

const RowTotal = styled.div`
  margin: 0.375rem 0;
  display: flex;
  justify-content: space-around;

  > div {
    color: ${lighterNavy};
    font-weight: bold;
    width: 50%;
    text-align: center;
  }
`;

const SizeLabel = styled(StyledLabel)`
  font-size: 20px;
`;

const SizeInput = styled(StyledText)`
  width: 4.5rem;
  text-align: center;
`;

const RangeGuide = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${grey};
  > div {
    width: 2.5rem;
    text-align: center;
  }
`;

const StyledRange = styled.div`
  padding: 0 ${FormPadding};

  .MuiSlider-track,
  .MuiSlider-thumb {
    color: ${lighterNavy};
  }

  .MuiSlider-rail {
    color: ${grey};
  }
`;

const ErrorStyle = styled.div`
  text-align: 'center';
  color: ${red};
  > div {
    width: 50%;
    text-align: center;
  }
`;

function GenerateGuideNumbers(steps: number, range: number) {
  const numArray: number[] = [];
  for (let i = 0; i <= range; i += steps) {
    numArray.push(i);
  }
  return (
    <>
      {numArray.map(number => {
        return <div key={`guide-${number}`}>{number}</div>;
      })}
    </>
  );
}

function ControlSizeInput(
  evt: React.ChangeEvent<HTMLInputElement>,
  index: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void,
  minValue: number,
  maxValue: number,
) {
  const inputValue = evt.target.value;
  if (!inputValue) {
    onChange(0);
    return;
  }
  let value: number = parseInt(inputValue, 10);
  if (Number.isNaN(value)) return;
  value = Math.min(Math.max(value, minValue), maxValue);
  onChange(value);
}

const SizeRow: FC<{
  estimate: SizeEstimate;
  index: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  onBlur: Noop;
  value: number;
}> = ({ estimate, index, onChange, onBlur, value }) => {
  const { editable } = useContext(DetailFormContext);

  const minValue = 0;
  const maxValue = 100;

  const inputProps = {
    onChange,
    onBlur,
    value: value ?? estimate.percentValue,
  };

  return (
    <>
      <RowStyling className={editable ? '' : 'bot-border'}>
        <SizeLabel htmlFor={estimate.size.id}>{estimate.size.value}</SizeLabel>
        <div>
          {editable ? (
            <SizeInput
              {...inputProps}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                ControlSizeInput(evt, index, onChange, minValue, maxValue)
              }
            />
          ) : (
            `${estimate.percentValue}%`
          )}
        </div>
      </RowStyling>
      {editable && (
        <>
          <StyledRange>
            <Slider step={1} min={minValue} max={maxValue} {...inputProps} />
          </StyledRange>

          <RangeGuide>{GenerateGuideNumbers(20, 100)}</RangeGuide>
        </>
      )}
    </>
  );
};

type SizesForm = {
  sizeEstimates: SizeEstimate[];
};

export const SizesFields: FC<{ estimates: SizeEstimate[] }> = ({
  estimates,
}) => {
  const {
    control,
    watch,
    formState: { isDirty, isValid },
  } = useFormContext<SizesForm>();
  const isError = isDirty && !isValid;

  const percentSum = useMemo(() => {
    let sum = 0;
    for (let i = 0; i < estimates.length; i += 1) {
      const value = watch(`sizeEstimates.${i}.percentValue`);

      if (value) {
        sum += value;
      }
    }

    const initialSum = estimates.reduce((sum, estimate) => {
      return sum + estimate.percentValue;
    }, 0);

    return sum ?? initialSum;
  }, [estimates, watch]);

  return (
    <>
      {estimates.map((estimate, index) => {
        return (
          <Controller<SizesForm, `sizeEstimates.${number}.percentValue`>
            key={estimate.size.id}
            control={control}
            name={`sizeEstimates.${index}.percentValue`}
            render={({ field: { onChange, onBlur, value } }) => (
              <SizeRow {...{ estimate, index, onChange, onBlur, value }} />
            )}
            shouldUnregister
          />
        );
      })}
      <RowTotal>
        <div>Total:</div>
        <div style={{ color: isError ? red : '' }}>{percentSum}%</div>
      </RowTotal>
      <ErrorStyle
        style={{
          display: isError ? 'flex' : 'none',
        }}
      >
        <div />
        <div>Total must be 100%.</div>
      </ErrorStyle>
    </>
  );
};
