import { Settings } from '@mui/icons-material';
import { secondaryWhite } from 'common/styles/colors';
import { SubText } from 'common/styles/texts';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { buttons } from 'utils/styleValues';

export const SettingsButton = styled(Settings)`
  color: ${secondaryWhite};
  cursor: pointer;
`;

const StyledButton = styled.button`
  display: flex;
  flex-wrap: nowrap;

  p {
    margin-bottom: ${buttons.textBottom};
    margin-left: ${buttons.textSide};
  }
`;

type Props = {
  onClick?: React.MouseEventHandler<HTMLElement>;
};

export const SettingsButtonComponent: FC<Props> = ({ onClick: callback }) => {
  const history = useHistory();
  const { userHasPermission } = useRbac();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (callback) callback(e);
    history.push(Constants.routes.SETTINGS);
  };

  return (
    <>
      {userHasPermission('settings:read') && (
        <StyledButton
          type='button'
          onClick={handleClick}
          style={{ background: 'none', border: 'none', color: 'inherit' }}
        >
          <SettingsButton /> <SubText>Settings</SubText>
        </StyledButton>
      )}
    </>
  );
};
