import { lighterNavy } from 'common/styles/colors';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { CustomSelect } from '../CustomSelect/CustomSelect';
import { UseFormControllerProps } from '../DetailControls/DetailDropDown';
import { GroupBase, StylesConfig } from 'react-select';
import { Form } from 'react-bootstrap';

const DropdownContainer = styled.div<{
  $maxWidth: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.$maxWidth};

  .invalid-feedback {
    display: flex;
  }
`;

const SelectDropdown = styled(CustomSelect)`
  div div {
    font-family: KanitSemiBold;
    color: ${lighterNavy};
  }
`;

/**
 * A yes/no set of dropdown options.
 *
 * @remark - Option values are strings because the `react-select` package
 * interprets a falsy `value` option (i.e: `0` or `false`) as `null` and
 * disallows the selection of that option.
 */
export const wizardYesNoOptions: WizardDropdownOption<string>[] = [
  { label: 'No', value: '0' },
  { label: 'Yes', value: '1' },
];

export type WizardDropdownOption<T> = {
  label: string;
  value: T;
};

type WizardDropdownProps<T> = UseFormControllerProps & {
  options: WizardDropdownOption<T>[];
  /**
   * An option to show the loading state of the dropdown.
   * False by default.
   */
  isLoading?: boolean;
  /**
   * Can be used to alter the width of the dropdown.
   * Default is set to '185px'.
   */
  maxWidth?: string;
  /**
   * Can be used to override base styling (i.e. if you wish to change the height,
   * you will need to affect the height of several children of the component. See
   * the styling of `SchedulePickWizard` for an example)
   */
  styles?: (
    isInvalid: boolean,
  ) => StylesConfig<unknown, false, GroupBase<unknown>>;
  /**
   * Optional error string to use when the dropdown is bound
   * to a child inside a collection of items.
   */
  nestedPathError?: string;
  /**
   * Optional string to override default placeholder.
   */
  placeholder?: string;
  /**
   * Optional boolean to disable the component.
   */
  isDisabled?: boolean;
  /**
   * Optional boolean to display a 'deselect' option in dropdown options;
   * the option will be a {@link WizardDropdownOption<T>} with `placeholder`
   * as the label and value set to `null`.
   */
  allowDeselect?: boolean;
};

export const WizardDropdown = <T,>({
  name,
  formControl,
  options,
  isLoading = false,
  maxWidth = '185px',
  styles,
  nestedPathError,
  placeholder = 'Select...',
  isDisabled = false,
  allowDeselect = false,
}: WizardDropdownProps<T>): ReactElement => {
  return (
    <Controller
      name={name}
      control={formControl as never}
      shouldUnregister
      render={({ field: { value, onChange }, formState: { errors } }) => {
        const error = errors[`${name}`];
        return (
          <DropdownContainer $maxWidth={maxWidth}>
            <SelectDropdown
              options={[
                ...(allowDeselect ? [{ label: placeholder, value: null }] : []),
                ...options,
              ]}
              value={value}
              onChange={opt =>
                onChange((opt as WizardDropdownOption<T>).value ? opt : null)
              }
              styles={styles ? styles(!!error) : {}}
              isLoading={isLoading}
              // Fix later: border outline for nestedPathError does not show
              isInvalid={!!error || !!nestedPathError}
              getOptionValue={opt => (opt as WizardDropdownOption<T>).label}
              placeholder={placeholder}
              isDisabled={isDisabled}
            />
            {(nestedPathError || error) && (
              <Form.Control.Feedback type='invalid'>
                <>{nestedPathError || error?.message}</>
              </Form.Control.Feedback>
            )}
          </DropdownContainer>
        );
      }}
    />
  );
};
