import {
  lightGreyBackground,
  lighterNavy,
  tableHeaderGrey,
} from 'common/styles/colors';
import styled, { css } from 'styled-components';

export const WeekSwitcherTextStyle = css`
  font-family: KanitSemiBold;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.0075em;
  color: ${lighterNavy};
`;

export const StyleContainer = styled.div`
  display: flex;
  align-items: center;

  p,
  span {
    ${WeekSwitcherTextStyle}
  }

  p {
    margin: 0 23px;
  }

  span {
    margin: 0 0 0 23px;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: ${lightGreyBackground};
    border-radius: 50%;
    width: 28px;
    height: 28px;
    color: ${tableHeaderGrey};
  }
`;
