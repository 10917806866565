import { useGetRolesQuery } from 'common/api/roleApi';
import { useCreateUserMutation } from 'common/api/userApi';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { StyledCardWrapper } from 'common/styles/form';
import { useRbac } from 'features/rbac';
import { FC, useState } from 'react';
import {
  CreateUserCard,
  FormData,
} from '../components/userCreation/CreateUserCard';

import { handleError } from 'common/api/handleError';
import { InviteSentCard } from '../components/userCreation/InviteSentCard';

const CardWrapperStyle = {
  width: 'min(100vw, 500px)',
};

export const CreateUserView: FC = () => {
  const { userHasPermission } = useRbac();
  const [createUser] = useCreateUserMutation();

  const [userCreatedSuccess, setUserCreatedSuccess] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');

  const { data: roles = [], isLoading: isLoadingRoles } = useGetRolesQuery();

  const availableRoles = roles.filter(role =>
    userHasPermission({ permission: 'role:read', data: role }),
  );

  const handleFormSubmit = async (data: FormData) => {
    try {
      setUserEmail(data.email);
      setUserRole(data.role.roleName);

      await createUser({ ...data, profilePicture: '' }).unwrap();
      setUserCreatedSuccess(true);
    } catch (error) {
      handleError(error, 'Unable to add user.');
    }
  };

  return (
    <WithLoadingOverlay isLoading={isLoadingRoles}>
      {userCreatedSuccess ? (
        <InviteSentCard email={userEmail} accountType={userRole} />
      ) : (
        <StyledCardWrapper style={CardWrapperStyle}>
          <CreateUserCard
            availableRoles={availableRoles}
            submitButtonLabel='CREATE'
            onSubmit={handleFormSubmit}
          />
        </StyledCardWrapper>
      )}
    </WithLoadingOverlay>
  );
};
