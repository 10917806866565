import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { grey, white } from 'common/styles/colors';
import { useAuth } from 'features/auth/hooks';
import styled from 'styled-components';
import Header from '../Header';
import { InitialsAvatar } from './InitialsAvatar';

const UserContent = styled.div`
  padding: 1.6rem 0.6rem;
  border-bottom: 1px solid ${grey};
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  h6,
  p {
    margin: 0;
    padding: 0;
  }
  p {
    font-size: 0.7rem;
  }
  a {
    text-decoration: none;
    color: ${white};
  }

  @media screen and (max-width: ${mobile}) {
    flex-direction: row;
    align-items: center;
    padding: 0.75rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    p {
      font-size: 1rem;
      color: ${grey};
    }
  }
`;

const UserCard: React.FC = (): JSX.Element => {
  const { user } = useAuth();
  const { width } = useWindowSize();

  return (
    <UserContent>
      <InitialsAvatar name={user?.firstName} lastName={user?.lastName} />
      <div>
        <Header variant={width > 768 ? 'h6' : 'h4'}>
          {user?.firstName} {user?.lastName}
        </Header>
        <p>{user?.role.roleName}</p>
      </div>
    </UserContent>
  );
};

export * from './InitialsAvatar';
export default UserCard;
