import styles from './headers.module.css';

export interface StyledHeaderProps {
  className?: string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: React.ReactNode;
}

const Header: React.FC<StyledHeaderProps> = ({
  className,
  variant = 'h1',
  children,
}) => {
  const HeaderType = variant;

  return (
    <HeaderType className={`${className} ${styles[HeaderType]}`}>
      {children}
    </HeaderType>
  );
};

export default Header;
