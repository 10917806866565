import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import { ToastContent, toast } from 'react-toastify';

export const showSuccessMessage = (message: string): void => {
  toast.success(message, {
    autoClose: 5000,
    icon: <CheckIcon />,
  });
};

export const showErrorMessage = (message: string | ToastContent): void => {
  toast.error(message, {
    autoClose: 5000,
    icon: <CancelIcon />,
  });
};
