import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useGetAppHealthQuery } from 'common/api/healthCheckApi';
import { mobile } from 'common/styles/breakpoints';
import { darkNavy } from 'common/styles/colors';
import { StyledFormWrapper, Title } from 'common/styles/form';
import { environment } from 'environment';
import { EnvironmentConfiguration } from 'environment/types';
import styled from 'styled-components';
import { Constants } from '../../utils/constants';
import { DeveloperTools } from './components/DeveloperTools';
import { SpokaneSync } from './components/SpokaneSync';

const { appName, version } = Constants;

const linkItems = [
  'Crew List',
  'Labor Rates',
  'Haul Rate',
  'Tarp Rate',
  'Mileage Rate',
  'Varieties',
  'Sub Varieties',
];

export const Subtitle = styled(Title)`
  font-size: 1rem;
  margin: 0px;
`;

const ViewWrapper = styled(StyledFormWrapper)`
  width: 100%;
`;

const MobileWrapper = styled.div`
  @media (max-width: ${mobile}) {
    margin: 0 20px;
    height: 100%;
  }
`;

const ManageItem = styled(Title)`
  font-family: KanitRegular;
  font-size: 1.2rem;
  color: ${darkNavy};
  margin: 0;
  padding: 0;
`;

const SettingsRow = styled.div`
  display: grid;
  grid-template-columns: 15% 85%;
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SettingsView = (): JSX.Element => {
  const { data: healthCheck } = useGetAppHealthQuery();
  return (
    <ViewWrapper>
      <MobileWrapper>
        <Title>Settings</Title>
        <Title style={{ fontSize: '1.5rem' }}>{appName}</Title>
        <SettingsRow>
          <Subtitle>Client Version</Subtitle>
          <span style={{ fontFamily: 'KanitBold' }}>{version}</span>
          <Subtitle>Server Version</Subtitle>
          <span style={{ fontFamily: 'KanitBold' }}>
            {healthCheck?.version ?? '-'}
          </span>
        </SettingsRow>
        <hr />
        <SettingsRow>
          <Subtitle>Spokane Sync</Subtitle>
          <SpokaneSync />
        </SettingsRow>
        <hr />
        <SettingsRow>
          <Subtitle>Manage Lists</Subtitle>
          <div>
            {linkItems.map(item => (
              <ItemContainer key={item}>
                <ManageItem>{item}</ManageItem>
                <ArrowForwardIosIcon style={{ fontSize: '1rem' }} />
              </ItemContainer>
            ))}
          </div>
        </SettingsRow>
        {(environment.name === EnvironmentConfiguration.Development ||
          environment.name === EnvironmentConfiguration.Testing) && (
          <>
            <hr />
            <SettingsRow>
              <Subtitle>Developer Tools</Subtitle>
              <DeveloperTools />
            </SettingsRow>
          </>
        )}
      </MobileWrapper>
    </ViewWrapper>
  );
};

export default SettingsView;
