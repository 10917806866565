import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { CmsDate } from 'common/models/cmsDate';
import { DateWithTime } from 'common/models/dateType';
import { BlockStatus, Variety } from 'common/models/growerBlock';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { ForwardArrow } from '../components/GrowerIcons';
import { StatusIcon } from '../components/StatusIcon';

export type GrowerBlockTableItem = {
  id: number;
  blockId: string;
  growerId: string;
  variety?: Partial<Variety>;
  acres: number | null;
  parentGrower: string | null;
  status: BlockStatus;
  lastStatusUpdate: DateWithTime;
};

export type UseGrowerBlockTableData = (
  agents?: BlockDetails<WithPartialSeasonData>[],
) => {
  columns: Column<GrowerBlockTableItem>[];
  data: GrowerBlockTableItem[];
};

export const useGrowerBlockTableData: UseGrowerBlockTableData = (
  blocks = [],
) => {
  const columns: Column<GrowerBlockTableItem>[] = useMemo(
    () => [
      {
        accessor: 'status',
        width: '3%',
        Cell: ({ value }) => <StatusIcon status={value} type='block' />,
      },
      {
        accessor: 'lastStatusUpdate',
        Header: 'Last Updated',
        width: '8%',
        Cell: ({ value }) => (
          <span>{CmsDate.parse(value)?.toDateString() ?? ''}</span>
        ),
      },
      {
        accessor: 'growerId',
        Header: 'Grower ID',
        Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
      },
      {
        accessor: 'blockId',
        Header: 'Block ID',
        Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
      },
      {
        accessor: 'variety',
        Header: 'Variety',
        Cell: ({ value }) => <VarietyCell variety={value} />,
      },
      {
        accessor: 'acres',
        Header: 'Acres',
        Cell: ({ value: acres }) => (
          <DataTableText>{acres || '-'}</DataTableText>
        ),
      },
      {
        accessor: 'parentGrower',
        Header: 'Parent Grower',
        Cell: ({ value: parentGrower }) => (
          <DataTableText>{parentGrower}</DataTableText>
        ),
      },
      {
        id: 'Arrow',
        Header: '',
        width: '5%',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );

  const data: GrowerBlockTableItem[] = useMemo(
    () =>
      blocks.map(block => {
        const {
          id,
          blockId,
          grower: { growerId, parentGrowerName },
          variety,
          acres,
          seasonData,
        } = block;

        return {
          id,
          blockId,
          growerId,
          parentGrower: parentGrowerName ?? '-',
          variety: variety ?? undefined,
          acres,
          status: seasonData?.status ?? BlockStatus.No_Status,
          lastStatusUpdate: seasonData?.lastStatusUpdate ?? '',
        };
      }),
    [blocks],
  );

  return { columns, data };
};
