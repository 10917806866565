import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { PickScheduleView } from './pages/PickScheduleView';
import { ScheduleNewPickView } from './pages/ScheduleNewPickView';
import { SchedulePickWizardView } from './pages/SchedulePickWizardView';
import { ScheduledPickView } from './pages/ScheduledPickView';
import { PickDataWizardView } from './pages/PickDataWizardView';
import { CrewAssignmentWizardView } from './pages/CrewAssignmentWizardView';

export const PickScheduleRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={PickScheduleView}
        requiredRoles={[
          RoleType.Admin,
          RoleType.Analyst,
          RoleType.FieldRep,
          RoleType.HarvestManager,
          RoleType.HarvestCo,
        ]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.NEW_PICK_LIST}`}
        component={ScheduleNewPickView}
        requiredRoles={[RoleType.Admin, RoleType.FieldRep]}
      />
      <PrivateRoute
        exact
        path={`${path}${Constants.routes.NEW_PICK}`}
        component={SchedulePickWizardView}
        requiredRoles={[RoleType.Admin, RoleType.FieldRep]}
      />
      <PrivateRoute
        exact
        path={`${path}/:id${Constants.routes.PICK_DATA}`}
        component={PickDataWizardView}
        requiredRoles={[
          RoleType.Admin,
          RoleType.HarvestManager,
          RoleType.HarvestCo,
        ]}
      />
      <PrivateRoute
        exact
        path={`${path}/:id${Constants.routes.CREW_ASSIGNMENT}`}
        component={CrewAssignmentWizardView}
        requiredRoles={[RoleType.Admin, RoleType.HarvestManager]}
      />
      <PrivateRoute
        path={`${path}/:id`}
        component={ScheduledPickView}
        requiredRoles={[
          RoleType.Admin,
          RoleType.HarvestCo,
          RoleType.HarvestManager,
          RoleType.Analyst,
          RoleType.FieldRep,
        ]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
