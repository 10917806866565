import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { SubVariety } from 'common/models';

export type GetSubVarietiesList = [Pick<SubVariety, 'id' | 'subVarietyCode'>];

export const subVarietyApi = createApi({
  reducerPath: 'subVarietyApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['SubVariety'],

  endpoints: builder => ({
    getSubVarieties: builder.query<GetSubVarietiesList, void>({
      query: () => ({
        url: '/subvariety',
      }),
      providesTags: (_, err) => (err ? [] : ['SubVariety']),
    }),
  }),
});

export const { useGetSubVarietiesQuery } = subVarietyApi;
