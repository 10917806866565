import { FC, useState } from 'react';
import {
  ForgotPasswordRequest,
  useForgotPasswordMutation,
} from 'common/api/userApi';
import { handleError } from 'common/api/handleError';
import { ForgotPasswordForm, FormData } from '../components/ForgotPasswordForm';
import styled from 'styled-components';
import Header from '../../../common/components/Header';
import { AuthSuccessModal } from 'features/auth-success-modal/AuthSuccessModal';
import { ReactComponent as EmailSentIcon } from 'assets/img/22-Mail.svg';
import { BodyText, SubText, LinkText } from 'common/styles/texts';
import { StyledContainer } from './styles';

const ForgotText = styled(BodyText)`
  margin-top: 10px;
`;

export const ForgotPasswordPage: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [forgotPassword] = useForgotPasswordMutation();

  const onSubmit = async (formData: FormData) => {
    try {
      // Force type here because validation must have passed by now.
      await forgotPassword(formData as ForgotPasswordRequest).unwrap();
      setIsOpen(!isOpen);
    } catch (error) {
      handleError(error);
    }
  };

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  const ForgotSuccessIcon = (
    <EmailSentIcon title='email sent icon' style={{ marginBottom: 10 }} />
  );

  const ForgotSuccessTitle = (
    <span className='modal-title'>Check your mail</span>
  );

  const ForgotSuccessBody = (
    <>
      <BodyText>
        We have sent a password reset link and instructions to the email you
        provided.
      </BodyText>
      <SubText>Did not receive the email? Check your spam filter, or </SubText>
      <LinkText onClick={closeModal}>try another email address</LinkText>
    </>
  );

  return (
    <StyledContainer data-testid='wrapper'>
      <Header variant='h4'>Forgot your password?</Header>
      <ForgotText>
        Let’s get you a new one. Enter the email associated with your account
        and we’ll send an email with instructions to reset your password.
      </ForgotText>
      <ForgotPasswordForm onSubmit={onSubmit} />
      <AuthSuccessModal
        isOpen={isOpen}
        closeModal={closeModal}
        icon={ForgotSuccessIcon}
        title={ForgotSuccessTitle}
        body={ForgotSuccessBody}
      />
    </StyledContainer>
  );
};
