import { NestedTab } from 'common/components/NestedTabs/NestedTabs';
import { Constants } from 'utils/constants';
import { DetailTabHeader } from 'common/components/DetailControls/DetailTabHeader';
import { SpecsNotesTab } from './fields/SpecsNotesTab';
import { BlockTab } from './fields/BlockTab';
import { ScheduledPick } from 'common/models';
import { StaffCrewTab } from './fields/StaffCrewTab';
import { LaborTab } from './fields/LaborTab';
import { BinsTab } from './fields/BinsTab';
import { NoLaborData } from './styles';

export const scheduledPickDetailTabs: (
  pickData: ScheduledPick,
) => NestedTab[] = pickData => [
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Block' />
    ),
    view: () => <BlockTab pickData={pickData} />,
    tabPath: Constants.routes.BLOCK,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Bins' />
    ),
    view: () => <BinsTab pickData={pickData} />,
    tabPath: Constants.routes.BINS,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Staff/Crew' />
    ),
    view: () => <StaffCrewTab pickData={pickData} />,
    tabPath: Constants.routes.STAFF_CREW,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Specs/Notes' />
    ),
    view: () => <SpecsNotesTab pickData={pickData} />,
    tabPath: Constants.routes.SPECS_NOTES,
  },
  {
    header: (isSelected: boolean) => (
      <DetailTabHeader isSelected={isSelected} tabName='Labor' />
    ),
    view: () => (
      <>
        {pickData.pickRecord ? (
          <LaborTab pickData={pickData} />
        ) : (
          <NoLaborData>No pick data to show.</NoLaborData>
        )}
      </>
    ),
    tabPath: Constants.routes.LABOR,
  },
];
