import { FC } from 'react';
import styled from 'styled-components';

const defaultDimension = 22;

const StyledCheckBox = styled.input<{
  $dimensions: number;
}>`
  height: ${({ $dimensions }) => $dimensions}px;
  width: ${({ $dimensions }) => $dimensions}px;
  margin: 5px;
  cursor: pointer;
`;

export const Checkbox: FC<{
  checked: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  dimensions?: number;
}> = ({ checked, onChange, dimensions = defaultDimension }) => (
  <StyledCheckBox
    type='checkbox'
    checked={checked}
    onChange={onChange}
    $dimensions={dimensions}
  />
);
