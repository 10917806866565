import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface DaySelectorState {
  value: number;
}

export const daySelectorSliceName = 'daySelectorrSlice';

export const daySelectorSlice = createSlice({
  name: daySelectorSliceName,
  // subtract 1 to start offset from Monday instead of Sunday
  initialState: { value: new Date().getDay() - 1 },
  reducers: {
    setOffset: (
      state: DaySelectorState,
      action: PayloadAction<DaySelectorState>,
    ) => {
      const { value } = action.payload;
      state.value = value;
    },
  },
});
