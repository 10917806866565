import { RootState, useAppDispatch, useAppSelector } from 'app/redux';
import {
  DaySelectorState,
  daySelectorSlice,
  daySelectorSliceName,
} from '../components/PickSchedule/DaySelectorSlice';
import { useMemo } from 'react';

type DaySelectorHookType = {
  offset: DaySelectorState;
  setOffset: (change: number) => void;
};

export const useDaySelector = (): DaySelectorHookType => {
  const offset = useAppSelector(
    (rootState: RootState) => rootState[daySelectorSliceName],
  );
  const dispatch = useAppDispatch();
  const setOffset = useMemo(() => {
    return (change: number) => {
      const action = { value: change };
      dispatch(daySelectorSlice.actions.setOffset({ ...action }));
    };
  }, [dispatch]);

  return { offset, setOffset };
};
