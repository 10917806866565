import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface props {
  children: React.ReactElement;
  title: string;
  tooltipText: string;
}

const TooltipTrigger: React.FC<props> = ({
  children,
  title,
  tooltipText,
}): JSX.Element => {
  return tooltipText ? (
    <OverlayTrigger
      placement='top'
      overlay={
        <Tooltip title={title}>
          <span>{tooltipText}</span>
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  ) : (
    <>{children}</>
  );
};

export default TooltipTrigger;
