import { yupResolver } from '@hookform/resolvers/yup';
import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { handleError } from 'common/api/handleError';
import { ChangeStatusModals } from 'common/components/ChangeStatusModals/ChangeStatusModals';
import { DetailForm } from 'common/components/DetailControls/DetailForm';
import FormPrompt from 'common/components/FormPrompt';
import {
  LocationButton,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import useWindowSize from 'common/hooks/useWindowSize';
import { BlockStatus, GrowerBlock } from 'common/models';
import * as notifier from 'common/services/notification';
import { mobile } from 'common/styles/breakpoints';
import { lightGreyText, lighterNavy, orange } from 'common/styles/colors';
import { StyledFormWrapper, Title } from 'common/styles/form';
import { MobileScrollWrapper } from 'common/styles/page';
import { useRbac } from 'features/rbac';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { blockSchema } from 'utils/schemas/blockSchema';
import { BlockDetailsFields } from './BlockDetails/BlockDetailsFields';
import { BlockDetailsFooter } from './BlockDetails/BlockDetailsFooter';
import { BlockDetailsInfo } from './BlockDetails/BlockDetailsInfo';
import { DetailsHeader } from './DetailsHeader';

const LargeFormWrapper = styled(StyledFormWrapper)`
  width: 100%;
  max-width: 1000px;
  @media (max-width: ${mobile}) {
    width: 100vw;
  }
`;

export const BlockInfoWrapper = styled.div.attrs(() => ({
  className: 'block-info-wrapper',
}))`
  display: flex;
  justify-content: space-between;
  .right-side-text {
    text-align: right;
  }
`;

export const BlockInfo = styled.h1`
  font-family: 'KanitBold';
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.0075em;
  color: ${lighterNavy};
  display: flex;
  align-items: center;
  @media (max-width: ${mobile}) {
    margin: 0 5px;
    padding: 0 20px;
  }
`;

export const BlockDetailForm: React.FC<{
  block: BlockDetails<WithPartialSeasonData>;
  updater: (req: GrowerBlock) => Promise<void>;
}> = ({ block, updater }) => {
  const history = useHistory();
  const { width } = useWindowSize();
  const { userHasPermission } = useRbac();
  const isMobile = width < parseInt(mobile, 10);
  const [isEdit, setIsEdit] = useState(false);

  // For modals
  const [isInactiveOpen, showInactiveModal] = useState(false);
  const [isActiveOpen, showActiveModal] = useState(false);

  const { latitude, longitude, grower, blockName, seasonData } = block;

  // Remove 'blockId' since it's not needed by the backend and
  // we don't want to inadvertently save the wrong value.
  const { blockId, ...defaultValues } = block;

  const mapParams = validateCoordinateInfo(latitude, longitude, blockId);

  const methods = useForm<GrowerBlock>({
    defaultValues,
    resolver: yupResolver(blockSchema),
    mode: 'all',
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isSubmitSuccessful, isDirty, isValid },
    reset,
    getValues,
    trigger,
  } = methods;

  const trySubmit = useCallback(
    async (evt: React.FormEvent) => {
      let isSuccessful: boolean;

      if (isDirty && isValid) {
        try {
          await handleSubmit(updater)(evt);
          notifier.showSuccessMessage('Block details updated.');
          isSuccessful = true;
        } catch (error) {
          handleError(error, Constants.errorMessages.SAVE_FAILED);
          isSuccessful = false;
        }
      } else if (!isValid) {
        trigger();
        notifier.showErrorMessage(Constants.errorMessages.VALIDATION_FAILED);
        isSuccessful = false;
      } else {
        isSuccessful = true; // No changes, continue normally.
      }

      return isSuccessful;
    },
    [isDirty, isValid, handleSubmit, updater, trigger],
  );

  // This effect clears the dirty flag.
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, reset, getValues]);

  const MobileWrapper = isMobile ? MobileScrollWrapper : React.Fragment;

  return (
    <FormProvider {...methods}>
      {!isMobile && <Title>Block Detail</Title>}

      {/* Modals are instantiated here to avoid their submit actions
      from submitting the DetailForm's form. */}
      <ChangeStatusModals
        isActiveOpen={isActiveOpen}
        isInactiveOpen={isInactiveOpen}
        showInactiveModal={showInactiveModal}
        showActiveModal={showActiveModal}
        block={block}
      />

      <DetailForm
        submitAction={trySubmit}
        disabled={isSubmitting}
        CustomWrapper={LargeFormWrapper}
        largeLabels
        setIsEdit={setIsEdit}
        reset={reset}
        closeHandler={() => history.push(Constants.routes.GROWERS_LIST)}
        canEdit={userHasPermission('block:edit') && !!seasonData?.id}
      >
        <MobileWrapper>
          <DetailsHeader
            status={seasonData ? seasonData.status : BlockStatus.No_Status}
            type='block'
            inactiveReason={seasonData?.reason}
          />
          <BlockInfoWrapper>
            <BlockInfo>{blockId}</BlockInfo>
            <BlockInfo className='right-side-text'>
              {blockName}
              <LocationButton
                backgroundColor={orange}
                iconColor={lightGreyText}
                mapParams={mapParams}
              />
            </BlockInfo>
          </BlockInfoWrapper>
          <BlockInfoWrapper>
            <BlockInfo>{grower?.growerId}</BlockInfo>
            <BlockInfo className='right-side-text'>{grower?.name}</BlockInfo>
          </BlockInfoWrapper>
          {seasonData && <BlockDetailsInfo blockStatus={seasonData.status} />}
          <BlockDetailsFields block={block} />
        </MobileWrapper>
        {!isEdit && (
          <BlockDetailsFooter
            block={block}
            showInactiveModal={showInactiveModal}
            showActiveModal={showActiveModal}
          />
        )}
        <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
      </DetailForm>
    </FormProvider>
  );
};
