import { lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { FC } from 'react';
import styled from 'styled-components';
import Header from '../Header';

const StyledSubHeader = styled(Header)`
  color: white;
  background-color: ${lighterNavy};
  padding: 8px;
  padding-left: ${FormPadding};
  font-size: 22px;
`;

/** Renders a sub-heading in the style of detail forms. */
export const DetailSubHeading: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return <StyledSubHeader variant='h6'>{children}</StyledSubHeader>;
};
