import { Variety } from 'common/models';
import { tableBodyGrey } from 'common/styles/colors';
import { FC } from 'react';
import styled from 'styled-components';
import TooltipTrigger from '../WithTooltip/WithTooltip';

const StyledSpan = styled.span`
  font-family: KanitLight;
  font-size: 16px;
  color: ${tableBodyGrey};
`;

/**
 * Displays the variety's code in a table cell and shows a tooltip
 * with the variety's name on hover.
 */
const VarietyCell: FC<{
  variety?: Partial<Variety> | null;
}> = ({ variety }) => {
  if (variety) {
    const label = `${variety.varietyName}${
      variety.category ? ` ${variety.category}` : ''
    }`;

    return (
      <TooltipTrigger title={label} tooltipText={label}>
        <StyledSpan>{variety.varietyCode}</StyledSpan>
      </TooltipTrigger>
    );
  }
  return <StyledSpan>-</StyledSpan>;
};

export default VarietyCell;
