import { Edit, FileDownloadOutlined } from '@mui/icons-material';
import {
  tableHeaderGrey,
  white,
  darkBlue,
  darkNavy,
  lightBlue,
} from 'common/styles/colors';
import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const ToggleWrapper = styled(Button).attrs(() => ({
  variant: 'transparent',
  type: 'submit',
  'data-testid': 'button',
}))`
  display: flex;
  align-items: center;
  :focus {
    outline: ${tableHeaderGrey} dotted 1px;
  }
  padding: 0;
  border-color: transparent;
  background-color: ${props => (props.$isEdit ? darkBlue : lightBlue)};
  border-radius: 50%;
`;

const EditIcon = styled(Edit).attrs(() => ({
  'data-testid': 'edit',
}))`
  color: ${darkNavy};
  margin: 17px;
`;

const SaveIcon = styled(FileDownloadOutlined).attrs(() => ({
  'data-testid': 'save',
}))`
  color: ${white};
  margin: 17px;
`;

/**
 * Defines properties accepted by the {@link DetailEditingToggle} control.
 */
interface DetailEditingToggleProps {
  /** Indicates whether the control should be in editing or viewing mode. */
  editing: boolean;

  /** A function that will take action when the control is toggled. */
  onToggle?: MouseEventHandler;
}

/**
 * A control for toggling between viewing or editing a detail form. When viewing,
 * the control reflects editing is available; when editing, the control reflects
 * saving is available.
 *
 * @param props The properties required by the control.
 */
export const DetailEditingToggle = (
  props: DetailEditingToggleProps,
): React.ReactElement => {
  const { editing, onToggle } = props;
  const notifyToggled = (dat: React.MouseEvent<Element, MouseEvent>) =>
    onToggle && onToggle(dat);

  // We use different button types to avoid an issue where a nested form using
  // these buttons submits the parent form.
  return editing ? (
    <ToggleWrapper type='submit' onClick={notifyToggled} $isEdit>
      <SaveIcon />
    </ToggleWrapper>
  ) : (
    <ToggleWrapper type='button' onClick={notifyToggled} $isEdit={false}>
      <EditIcon />
    </ToggleWrapper>
  );
};
