import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { CreateUserView } from './pages/CreateUserView';
import { UserDetailView } from './pages/UserDetailView';
import { UserListView } from './pages/UserListView';

export const UserRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={UserListView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}/create-user`}
        component={CreateUserView}
        requiredRoles={[RoleType.Admin]}
      />
      <PrivateRoute
        exact
        path={`${path}/update-user/:id`}
        component={UserDetailView}
        requiredRoles={[RoleType.Admin]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
