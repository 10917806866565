import { BodyText } from 'common/styles/texts';
import { FC, useContext } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { DetailFormContext } from './DetailForm';
import { ButtonStyling } from '../LoadingButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;
const StyledText = styled(BodyText)`
  margin: 1rem;
`;

const StyledButton = styled(Button)`
  ${ButtonStyling}
  margin-top: 0;
  letter-spacing: 0.12em;
`;

type Props = {
  formObject: unknown | null | undefined;
  message: string;
  /** Optional boolean to show/hide create button; default is true */
  canCreate?: boolean;
  children?: React.ReactNode;
};

/**
 * Renders a "Create" button if a given object does not exist, or the specified
 * children elements if it does. Only works inside of a detail form.
 */
export const CreateOrViewWrapper: FC<Props> = ({
  formObject,
  message,
  children,
  canCreate = true,
}) => {
  const formContext = useContext(DetailFormContext);

  return formObject || formContext?.editable ? (
    <>{children}</>
  ) : (
    <Wrapper>
      <StyledText>{message}</StyledText>
      {canCreate && <StyledButton type='submit'>CREATE</StyledButton>}
    </Wrapper>
  );
};
