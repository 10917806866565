import { lightOrange, white } from 'common/styles/colors';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const RememberPassword = styled.div`
  padding-top: 18px;

  .if-you-remember {
    font-family: KanitExtraLight;
    font-size: 20px;
    line-height: 134%;
    letter-spacing: 0.0075em;
  }
`;

const StyledLink = styled(Link)`
  color: ${white};
  font-family: KanitExtraLight;
  font-size: 20px;
  line-height: 134%;
  letter-spacing: 0.0075em;

  &:hover {
    color: ${lightOrange};
  }
`;

export const RememberPasswordLink: FC = () => (
  <RememberPassword>
    <span className='if-you-remember'>If you remember it,&nbsp;</span>
    <StyledLink data-testid='forgotPasswordLink' to='/auth/login'>
      Sign in
    </StyledLink>
  </RememberPassword>
);
