import { RoleType, User } from 'common/models';
import {
  blueShadeHeader,
  darkOrange,
  darkerBlueShade,
  green,
  orange,
} from 'common/styles/colors';
import { useMemo } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';
import { abbreviateRole, determineRoleDotColor } from 'utils/helperFunction';
import ErrorIcon from '@mui/icons-material/Error';
import WithTooltip from '../../../common/components/WithTooltip/WithTooltip';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';

const NotesWrapper = styled.div`
  overflow-wrap: break-word;
  width: 400px;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Circle = styled.div`
  height: 11px;
  width: 11px;
  min-height: 11px;
  min-width: 11px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;

  &.navy-blue {
    background-color: ${blueShadeHeader};
  }

  &.light-blue {
    background-color: ${darkerBlueShade};
  }

  &.orange {
    background-color: ${orange};
  }

  &.green {
    background-color: ${green};
  }

  &.dark-orange {
    background-color: ${darkOrange};
  }
`;

export type UserTableItem = {
  id: number;
  notActivated: boolean;
  fullName: { id: number; name: string };
  email: string;
  role: string;
  phone: string | null;
  notes: string | null;
};

export type UseUserTableData = (agents?: User[]) => {
  columns: Column<UserTableItem>[];
  data: UserTableItem[];
};

export const useUserTableData: UseUserTableData = (users = []) => {
  // Set up columns and headers
  const columns: Column<UserTableItem>[] = useMemo(
    () => [
      {
        accessor: 'notActivated',
        width: '24px',
        Cell: ({ value: notActivated }) =>
          notActivated ? (
            <WithTooltip title='Error Icon' tooltipText='Not Activated'>
              <ErrorIcon color='error' />
            </WithTooltip>
          ) : null,
      },
      {
        accessor: 'fullName',
        Header: 'Name',
        width: '210px',
        Cell: ({ value: values }) => <span>{`${values.name}`}</span>,
      },
      {
        accessor: 'role',
        Header: 'Role',
        width: '145px',
        Cell: ({ value }) => (
          <div className='d-flex align-items-center'>
            <Circle className={`${determineRoleDotColor(value as RoleType)}`} />
            <span>{abbreviateRole(value as RoleType)}</span>
          </div>
        ),
      },
      { accessor: 'email', Header: 'Email', width: '280px' },
      { accessor: 'phone', Header: 'Phone', width: '170px' },
      {
        accessor: 'notes',
        Header: 'Notes',
        Cell: ({ value }) => (
          <NotesWrapper>
            <span>{value}</span>
          </NotesWrapper>
        ),
      },
      {
        id: 'Arrow',
        Header: '',
        width: '2%',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );

  // Transform User objects into the data format expected by the table.
  const data: UserTableItem[] = useMemo(
    () =>
      users.map(user => ({
        id: user.id,
        notActivated: !user.activatedAt,
        fullName: { id: user.id, name: `${user.firstName} ${user.lastName}` },
        email: user.email,
        role: user.role.roleName,
        phone: user.phone,
        notes: user.notes,
      })),
    [users],
  );

  return { columns, data };
};
