import { mobile } from 'common/styles/breakpoints';
import { orange } from 'common/styles/colors';
import styled from 'styled-components';

const StyledAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: ${orange};
  border-radius: 50%;

  @media screen and (max-width: ${mobile}) {
    width: 40px;
    height: 40px;
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

/**
 * The properties accepted by the {@link InitialsAvatar} component.
 */
interface InitialsAvatarProps {
  name?: string;
  lastName?: string;
  className?: string;
}

/**
 * Displays an avatar icon containing a person's initials.
 *
 * @param props The properties required by the component.
 * @returns A component instance.
 */
export const InitialsAvatar = (props: InitialsAvatarProps): JSX.Element => {
  const { name, lastName, className } = props;
  return (
    <StyledAvatar className={className}>
      {(name ? name.charAt(0) : '?') + (lastName?.charAt(0) ?? '')}
    </StyledAvatar>
  );
};
