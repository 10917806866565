import { BlockStatus } from 'common/models';
import styled from 'styled-components';
import { lighterNavy, green } from 'common/styles/colors';
import { mobile } from 'common/styles/breakpoints';
import { FormPadding } from 'common/styles/form';
import { FC } from 'react';

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0 10px;
  @media (max-width: ${mobile}) {
    padding: ${FormPadding};
  }

  h5 {
    color: ${green};
    font-family: 'KanitBold';
    font-size: 20px;
    line-height: 110%;
    letter-spacing: 0.0075em;
  }

  span,
  li {
    color: ${lighterNavy};
    font-family: 'KanitSemiBold';
    font-size: 16px;
    line-height: 105%;
    letter-spacing: 0.0075em;
  }

  ol {
    padding: 0 0 0 30px;
    margin-bottom: 5px;
  }

  li {
    margin-bottom: 5px;
  }
`;

export const BlockDetailsInfo: FC<{
  blockStatus: BlockStatus | undefined;
}> = ({ blockStatus }) => {
  if (blockStatus === BlockStatus.New) {
    return (
      <InfoWrapper>
        <h5>To make this block active:</h5>
        <ol>
          <li>Select the variety.</li>
          <li>Enter a lot number.</li>
          <li>
            Select the Field Representative responsible for estimates and
            evaluations.
          </li>
          <li>Enter the number of acres.</li>
          <li>Enter the number of trees.</li>
          <li>Select the district.</li>
        </ol>
        <span>
          You may complete other information at this time, and the Field Rep
          will also be prompted to do so on their initial evaluation.
        </span>
      </InfoWrapper>
    );
  }

  if (blockStatus === BlockStatus.Pending) {
    return (
      <InfoWrapper>
        <span>
          The assigned Field Representative needs to conduct an initial
          evaluation for this block.
        </span>
      </InfoWrapper>
    );
  }

  return <></>;
};
