import { FC, useEffect } from 'react';
import { ScheduledPickDetail } from '../components/ScheduledPick/ScheduledPickDetail';
import { Constants } from 'utils/constants';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export const ScheduledPickView: FC = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const tabPath = pathname.substring(url.length);

    // if user tries to access page without a tab, redirect to details tab
    if (tabPath === '') {
      history.replace(`${url}${Constants.routes.BLOCK}`);
    }
  }, [history, pathname, url]);

  return <ScheduledPickDetail />;
};
