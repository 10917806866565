import React, { FC } from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { NavLinkConfig } from '../hooks/useNavLinks';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from 'styled-components';
import { darkNavy, orange, white } from 'common/styles/colors';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding 8px 16px;

  &.active {
    background-color: ${darkNavy};
  }

  &.child {
    background-color: ${white};
    color: ${darkNavy};
  }
`;

type Props = {
  link: NavLinkConfig;
  active?: boolean;
  child?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const CustomNavLink: FC<Props> = ({
  link,
  active,
  child,
  onClick: callback,
}) => {
  const Icon = link.icon;

  return (
    <Container className={`${active ? 'active' : ''} ${child ? 'child' : ''}`}>
      <Nav.Link
        as={NavLink}
        to={link.path}
        style={{ color: child ? darkNavy : white }}
        onClick={callback}
      >
        <div className='d-flex gap-1'>
          {Icon && <Icon style={{ fill: active ? orange : white }} />}
          {child && <>&nbsp;&nbsp;</>}
          <span style={{ whiteSpace: 'nowrap' }} data-testid='link-title'>
            {link.label}
          </span>
        </div>
      </Nav.Link>
      {active ? <ArrowForwardIosIcon /> : null}
    </Container>
  );
};
