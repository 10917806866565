import { Variety } from 'common/models';
import { FC, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  createSizeOptions,
  createVarietyOptions,
} from 'utils/formValues/blockDetails';
import { Option } from 'common/components/DetailControls';
import Select from 'react-select';
import { marketOptions } from 'utils/formValues/evaluation';
import { NewRowFormData } from './PackPlanTable';
import { NewRowFormContainer } from './styles';

const selectStyles = {
  control: (base: Record<string, unknown>) => ({
    ...base,
    '&:hover': { cursor: 'pointer' },
    width: '130px',
    marginRight: '5px',
  }),
};

type NewPackPlanRow = {
  variety: Option | null;
  size: Option | null;
  market: Option | null;
};

type KeyOfNewRow = keyof NewPackPlanRow;

export const NewPackRowForm: FC<{
  id: number;
  varieties: Variety[] | undefined;
  isLoading: boolean;
  onAddRowReq: (newRow: NewRowFormData) => void;
}> = ({ id, varieties, isLoading, onAddRowReq }) => {
  const { control, getValues, reset, setValue, watch } =
    useForm<NewPackPlanRow>({
      defaultValues: { variety: null, size: null, market: null },
      mode: 'all',
    });

  const selectedVariety = watch('variety');
  const selectedMarket = watch('market');
  const selectedSize = watch('size');

  const varietyOptions = createVarietyOptions(varieties, true);
  const sizeOptions: Option[] = useMemo(() => {
    if (!varieties) return [];
    const variety = varieties.find(
      variety => variety.id === selectedVariety?.id,
    );
    return createSizeOptions(variety?.sizes ?? []);
  }, [selectedVariety, varieties]);

  const dropdownData: {
    name: KeyOfNewRow;
    options: Option[];
  }[] = useMemo(
    () => [
      { name: 'variety', options: varietyOptions },
      { name: 'market', options: marketOptions },
      { name: 'size', options: sizeOptions },
    ],
    [sizeOptions, varietyOptions],
  );

  // Variables to trigger dropdown re-renders
  const [count, setCount] = useState(0);
  const [sizeCount, setSizeCount] = useState(0);
  const [previousVar, setPreviousVar] = useState({} as Option);

  useEffect(() => {
    // Logic to reset size dropdown when variety changes
    if (selectedVariety && !selectedSize) {
      setPreviousVar({ ...selectedVariety });
    } else if (selectedVariety?.id !== previousVar.id && selectedSize) {
      setValue('size', null);
      setSizeCount(sizeCount + 1);
    }

    // Form 'submit' logic
    if (selectedMarket && selectedSize && selectedVariety) {
      const formData: NewRowFormData = {
        rowId: `${selectedVariety.id}${selectedMarket.label}${selectedSize.id}`,
        varietyId: selectedVariety.id,
        market: selectedMarket.label,
        sizeId: selectedSize.id,
      };
      onAddRowReq(formData);
      reset({ variety: null, size: null, market: null });
      setCount(count + 1);
    }
  }, [
    id,
    count,
    selectedMarket,
    selectedSize,
    selectedVariety,
    setValue,
    previousVar.id,
    sizeCount,
    onAddRowReq,
    getValues,
    reset,
  ]);

  return (
    <NewRowFormContainer>
      <div className='dropdown-container'>
        {dropdownData.map(({ name, options }) => (
          <Controller
            key={`${name}-${id}-${count}${name === 'size' && sizeCount}`}
            name={name}
            shouldUnregister
            control={control}
            render={({
              field: { onChange, value },
              formState: { isSubmitting },
            }) => (
              <>
                <Select
                  inputId={`${name}-${id}`}
                  styles={selectStyles}
                  placeholder={`${name.charAt(0).toUpperCase()}${name.slice(
                    1,
                  )}`}
                  options={options}
                  value={options.find(option => option.id === value?.id)}
                  getOptionValue={opt => opt.label}
                  onChange={onChange}
                  isDisabled={
                    isSubmitting || (name === 'size' && !selectedVariety)
                  }
                  {...(name !== 'market' ? { isLoading } : {})}
                />
              </>
            )}
          />
        ))}
      </div>
    </NewRowFormContainer>
  );
};
