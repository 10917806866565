import { useGetVarietiesQuery } from 'common/api/varietyApi';
import { CreateOrViewWrapper } from 'common/components/DetailControls';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import { grey, lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import styled from 'styled-components';
import { SizesFields } from './fields/SizesFields';

const StyleWrapper = styled.div`
  padding: ${FormPadding} 0;

  div.row > div {
    width: 50%;
  }

  .row.headings {
    margin: 0;
    padding-bottom: 5px;
    color: ${lighterNavy};
    border-bottom: 1px solid ${grey};

    div {
      font-weight: bold;
      padding: 0;
      text-align: center;
    }
  }
`;

export const SizesForm: FC<{
  blockSeasonId?: number;
  sizeEstimates: SizeEstimate[];
}> = ({ blockSeasonId, sizeEstimates }) => {
  const { userHasPermission } = useRbac();
  const isNewSizeEst = !!sizeEstimates.find(
    sizeEst => sizeEst.percentValue > 0,
  );
  const { isLoading } = useGetVarietiesQuery();

  return (
    <WithLoadingOverlay isLoading={isLoading}>
      <CreateOrViewWrapper
        formObject={isNewSizeEst}
        message='No size estimates exist for this season.'
        canCreate={
          userHasPermission('evalEstTabs:create/edit') && !!blockSeasonId
        }
      >
        <StyleWrapper>
          <div className='row headings'>
            <div>Size</div>
            <div>Percentage</div>
          </div>
          <SizesFields estimates={sizeEstimates} />
        </StyleWrapper>
      </CreateOrViewWrapper>
    </WithLoadingOverlay>
  );
};
