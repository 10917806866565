import styled from 'styled-components';
import {
  AddBox as FilledAddSign,
  ArrowForwardIos as ArrowForward,
  Close as CloseIcon,
  ErrorRounded as ErrorIcon,
} from '@mui/icons-material';
import {
  green,
  lighterNavy,
  lightGreyBackground,
  red,
} from 'common/styles/colors';

export const InactiveErrorIcon = styled(ErrorIcon).attrs(() => ({
  sx: {
    color: `${lightGreyBackground}`,
  },
}))``;

export const NewBlockIcon = styled(FilledAddSign)`
  color: ${green};
`;

export const WithdrawnIcon = styled(CloseIcon)`
  color: ${red};
`;

export const ForwardArrow = styled(ArrowForward)`
  color: ${lighterNavy};
`;
