import { grey, white } from 'common/styles/colors';
import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useNavLinks } from '../hooks/useNavLinks';
import { CustomNavLink } from './CustomNavLink';
import { useLogoutModal } from '../hooks/useLogoutModal';
import { SettingsButtonComponent } from './SettingsButton';
import { LogoutButtonComponent } from './LogoutButton';
import { mobile } from 'common/styles/breakpoints';

export const LinksWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${white};
  width: 100%;
  height: 100%;

  @media (max-width: ${mobile}) {
    flex-grow: 1;
  }
`;

export const StyledNavLink = styled.div`
  border-bottom: 1px solid ${grey};
`;

export const SubNavLink = styled(StyledNavLink)``;

const ButtonDiv = styled.div`
  margin-top: auto;
  margin-bottom: 26px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
`;

interface Props {
  callback?: React.MouseEventHandler<HTMLElement>;
}

const NavMenu = ({ callback }: Props): JSX.Element => {
  const { pathname } = useLocation();
  const navLinks = useNavLinks();
  const { openLogoutModal } = useLogoutModal();

  return (
    <LinksWrapper>
      {navLinks.map(link => (
        <Fragment key={link.id}>
          <StyledNavLink key={link.id}>
            <CustomNavLink
              link={link}
              active={pathname.startsWith(link.path)}
              onClick={callback}
            />
          </StyledNavLink>

          {pathname.startsWith(link.path)
            ? link.subLinks &&
              link.subLinks?.map(subLink => (
                <SubNavLink key={subLink.id}>
                  <CustomNavLink link={subLink} child onClick={callback} />
                </SubNavLink>
              ))
            : null}
        </Fragment>
      ))}
      <ButtonDiv>
        <SettingsButtonComponent onClick={callback} />
        <LogoutButtonComponent onClick={openLogoutModal} />
      </ButtonDiv>
    </LinksWrapper>
  );
};

export default NavMenu;
