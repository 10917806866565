import { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ActivateAccountRequest,
  useActivateAccountMutation,
} from 'common/api/userApi';
import { handleError } from 'common/api/handleError';
import * as notificationService from 'common/services/notification';
import Header from 'common/components/Header';
import { PasswordSubtitle } from 'common/styles/texts';
import { FormData, ResetPasswordForm } from '../components/ResetPasswordForm';
import PasswordGuidelines from '../components/PasswordGuidelines';
import { StyledContainer } from './styles';

export const ActivateAccountPage: FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [activateAccount] = useActivateAccountMutation();

  const onSubmit = async (formData: FormData) => {
    const data = { ...formData, token };

    try {
      // Force type here because validation must have passed by now.
      await activateAccount(data as ActivateAccountRequest).unwrap();
      notificationService.showSuccessMessage(
        'This account has been activated. Please log in.',
      );
      history.push('/auth/login');
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <StyledContainer data-testid='wrapper'>
      <Header variant='h4'>Activate Account</Header>
      <PasswordSubtitle>Let’s create a new password</PasswordSubtitle>
      <PasswordGuidelines />
      <ResetPasswordForm onSubmit={onSubmit} hideCancel />
    </StyledContainer>
  );
};
