import { createApi } from '@reduxjs/toolkit/query/react';
import { Role } from 'common/models';
import { customBaseQuery } from './customBaseQuery';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['Role'],

  endpoints: builder => ({
    getRoles: builder.query<Role[], void>({
      query: () => ({ url: '/roles' }),
      providesTags: (_, err) => (err ? [] : ['Role']),
    }),
  }),
});

export const { useGetRolesQuery } = roleApi;
