import { RootState, useAppDispatch, useAppSelector } from 'app/redux';
import {
  WeekSwitcherAction,
  weekSwitcherSlice,
  weekSwitcherSliceName,
} from './weekSwitcherSlice';
import { useMemo } from 'react';
import { Week, getWeek } from 'utils/dateTimeHelpers';

interface WeekSwitcherState extends Week {
  offset: number;
}

type WeekSwitcherHookType = {
  /**
   * Object containing the identified week switcher's state.
   * - offset: the offset, in weeks, from today's date
   * - startDate: the current week-range's starting date
   * - weekNumber: the week-range's week number in the current year
   * - weekdays: array of weekday, date, month, year values for the week-range
   */
  state: WeekSwitcherState;
  /**
   * Sets the week offset to a new value.
   * @param adjustment number of weeks to offset from today's date
   */
  useSetOffset: (adjustment: number) => void;
  /**
   * Decrements the current week offset by 1.
   */
  useDecrement: () => void;
  /**
   * Increments the current week offset by 1.
   */
  useIncrement: () => void;
};

export enum WeekSwitcherIds {
  PackPlan = 'pack-plan',
  PickSchedule = 'pick-schedule',
  HarvPayroll = 'harvest-payroll',
}
/**
 * Hook for getting and setting state for a WeekSwitcher component
 * with the corresponding identifier.
 * @param identifier key of saved state information
 * @returns state, useSetOffset, useDecrement, useIncrement
 */
export const useWeekSwitcherState = (
  identifier: string,
): WeekSwitcherHookType => {
  const storedOffset =
    useAppSelector(
      (rootState: RootState) => rootState[weekSwitcherSliceName][identifier],
    ) ?? 0;
  const week = useMemo(() => getWeek({ offset: storedOffset }), [storedOffset]);

  const dispatch = useAppDispatch();
  const useSetOffset = useMemo(() => {
    return (change: number) => {
      const weekSwitcherAction: WeekSwitcherAction = {
        key: identifier,
        newOffset: change,
      };
      dispatch(weekSwitcherSlice.actions.setOffset(weekSwitcherAction));
    };
  }, [dispatch, identifier]);
  function useDecrement() {
    useSetOffset(storedOffset - 1);
  }
  function useIncrement() {
    useSetOffset(storedOffset + 1);
  }

  return {
    state: { offset: storedOffset, ...week },
    useSetOffset,
    useDecrement,
    useIncrement,
  };
};
