import { lightGreyText, lighterNavy, red, white } from 'common/styles/colors';
import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';
import styled from 'styled-components';
import { mobile } from 'common/styles/breakpoints';
import { boxShadow } from 'common/styles/page';
import { RemoveButton } from 'common/styles/button';
import { applyErrorBorder } from 'common/styles/form';

export const WizardContainer = styled.form<{
  $backgroundColor: string;
  $width: string;
}>`
  width: 100%;
  max-width: ${props => props.$width};
  background: ${props => props.$backgroundColor};
  border-radius: 8px;
  box-shadow: ${boxShadow};
  padding: 25px 20px;
  margin-top: 10px;

  @media (max-width: ${mobile}) {
    margin-top: 0;
    border-radius: 0;
  }
`;

export const WizardSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SummarySection = styled(WizardSection)`
  border-top: 2px solid ${lightGreyText};
  border-bottom: 2px solid ${lightGreyText};
  padding-bottom: 10px;
  margin-top: 5px;
  padding-top: 5px;
`;

export const WizardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Info = styled.span`
  display: flex;
  align-items: center;
  font-family: KanitSemiBold;
  font-size: 20px;
`;

export const InfoLabel = styled(Info)`
  color: ${lightGreyText};
`;

export const HaulingInfoLabel = styled(InfoLabel)`
  padding: 0 10px 5px;
`;

export const InfoValue = styled(Info)`
  margin-left: 10px;
`;

export const InfoContainer = styled(InfoValue)`
  margin-left: 0;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WizardDivider = styled.div`
  border-top: 2px solid ${lightGreyText};
  margin: 5px 0;
  width: 100%;
`;

export const Notes = styled.span`
  margin-top: 5px;
  font-family: KanitLight;
  font-size: 16px;
`;

export const LightGreyNotes = styled(Notes)`
  color: ${lightGreyText};
`;

export const Prompt = styled.span`
  font-family: KanitLight;
  font-size: 24px;
  margin-top: 10px;
`;

/**
 * Used to override `react-select`'s base styling.
 */
export const selectBaseStyling = (
  isInvalid: boolean,
): StylesConfig<unknown, false, GroupBase<unknown>> => ({
  control: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    '&:hover': { cursor: 'pointer' },
    height: '32px',
    minHeight: '32px',
    border: `${isInvalid ? `2px solid ${red}` : null}`,
  }),
  valueContainer: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    height: '30px',
    padding: '0 6px',
  }),
  input: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    margin: '0px',
  }),
  indicatorSeparator: (): CSSObjectWithLabel => ({
    display: 'none',
  }),
  indicatorsContainer: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...base,
    height: '30px',
  }),
});

export const Textarea = styled.textarea`
  width: 100%;
  overflow-y: scroll;
  background: ${white};
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${lighterNavy};
  border-radius: 4px;
  padding: 5px 10px;
  resize: none;
  border: none;
`;

export const Button = styled.button<{
  $textColor?: string;
}>`
  display: flex;
  align-items: center;
  border: none;
  border-radius: 4px;
  font-family: KanitRegular;
  font-size: 18px;
  letter-spacing: 0.12em;
  padding: 6px 20px;
  color: ${props => props.$textColor || lightGreyText};
`;

export const NoBackgroundBtn = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
`;

export const CancelBtn = styled(NoBackgroundBtn)`
  color: ${lightGreyText};
  font-family: KanitRegular;
  font-size: 16px;
  height: 24px;
`;

export const ButtonSection = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
`;

export const AddItemBtn = styled(NoBackgroundBtn)<{
  $isError: boolean;
  $textColor: string;
}>`
  color: ${props => props.$textColor};
  margin: 25px 0 0;
  border-radius: 4px;
  font-family: KanitSemiBold;
  ${props => applyErrorBorder(props.$isError)}
`;

export const StyledRemoveBtn = styled(RemoveButton)`
  justify-self: flex-end;
`;

export const ItemContainerWrapper = styled(WizardSection)`
  align-items: flex-end;
`;

export const ItemContainerDisplay = styled.div<{
  $columnNumber: number;
}>`
  display: grid;
  grid-template-columns: ${props =>
    `repeat(${props.$columnNumber}, minmax(50px, 1fr))`};
  grid-gap: ${props => (props.$columnNumber === 1 ? 'none' : '30px')};
  width: 100%;

  @media (max-width: ${mobile}) {
    grid-template-columns: 1fr;
  }
`;

export const ItemContainerErrorMsg = styled(WizardSection)`
  align-items: flex-end;
  margin: 0 0 5px;
  color: ${red};
`;

export const ItemContainerError = styled.span`
  font-family: KanitRegular;
  font-size: 14px;
  color: ${red};
`;
