import * as yup from 'yup';
import { Constants } from '../constants';
import {
  dateSchemaRequired,
  dropdownOptional,
  dropdownRequired,
  nullableStringSchema,
} from './common';

const scheduledBins = yup
  .number()
  .nullable()
  .transform((_, val) => (!val ? null : Number(val)))
  .required(Constants.errorMessages.FIELD_REQUIRED)
  .integer(Constants.errorMessages.INTEGER_REQUIRED)
  .moreThan(0, Constants.errorMessages.POSITIVE_NUMBER_REQUIRED);

export const scheduledPickSchema = yup.object({
  pickDay: dateSchemaRequired,
  binsToPick: scheduledBins.when('$binsToGo', (binsToGo, schema) =>
    schema.lessThan(
      binsToGo + 1,
      `Field must be less than or equal to ${binsToGo}.`,
    ),
  ),
  packHouse: dropdownRequired,
  market: dropdownOptional,
  size: dropdownOptional,
  pool: dropdownRequired,
  cleanPick: dropdownRequired,
  pickType: dropdownRequired,
  notes: nullableStringSchema,
});

export const editScheduledPickSchema = yup.object({
  bins: scheduledBins,
  pool: dropdownRequired,
});
