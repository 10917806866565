import { lighterNavy } from 'common/styles/colors';
import { ReactElement, useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { DetailControl } from './DetailControl';
import { DetailFormContext } from './DetailForm';
import { StyledText } from './DetailText';
import { EditableTextDetailProps } from './types';
import { CustomSelect } from '../CustomSelect/CustomSelect';

const StyledSelector = styled(CustomSelect)`
  div div {
    font-family: KanitSemiBold;
    color: ${lighterNavy};
  }
`;

/** Represents an option displayed by the control when editable. */
export interface Option {
  id: number;
  label: string;
}

/**
 * Defines properties required to enable binding the control's value to a form
 * via the {@link useForm} hook's {@link Controller}.
 */
export type UseFormControllerProps = {
  /** The name of the data field the control is bound to. */
  name: string;

  /**
   * The 'control' object returned by the {@link useForm} hook. This is of type
   * unknown because it's not used here - it's merely passed through.
   */
  formControl: unknown;
};

/**
 * Defines properties accepted by the {@link DetailDropDown} control.
 */
type DetailDropDownProps = EditableTextDetailProps &
  UseFormControllerProps & {
    /** The options to display when in editable mode. */
    options: Option[];

    /** The value to pass for first render */
    defaultValue: Option | null;

    textValue: string;

    /** The function that will transform a selected option back to a custom type. */
    optionTransformer?: (opt: Option) => unknown;

    /** The boolean that will trigger the `react-select` loading state */
    isLoading?: boolean;

    /** The boolean to disable the dropdown */
    isDisabled?: boolean;

    /** Whether the field is editable when the form is in edit mode. */
    editable?: boolean;

    /** The string to replace the default placeholder */
    placeholder?: string;
  };

/**
 * Displays a textual value in read-only mode, or a drop-down menu with given
 * options when in editable mode. For use in item detail forms.
 *
 * @remarks
 * The dropdown component ignores falsy values (i.e. the number `0` or the boolean `false`) when
 * they are passed as selected options. To avoid this behavior, default or selected options must
 * have an id of 1 or higher and clean up logic to convert fields to correct types should be done
 * after form submission.
 *
 * @param props The properties needed by the control.
 * @returns A new instance of the control.
 */
export const DetailDropDown = (props: DetailDropDownProps): ReactElement => {
  const {
    label,
    largeLabel,
    labelMax,
    controlId,
    withSeparator,
    defaultValue,
    textValue,
    options,
    name,
    formControl,
    optionTransformer,
    isLoading,
    isDisabled,
    editable,
    placeholder = 'Select...',
  } = props;

  const context = useContext(DetailFormContext);
  const canEdit = context.editable && editable !== false;

  return (
    <DetailControl
      label={label}
      largeLabel={largeLabel}
      labelMax={labelMax}
      controlId={controlId}
      withSeparator={withSeparator}
      valueRenderer={id => (
        // Need to wrap the selector in this Controller for 'useForm' support.
        <Controller
          name={name}
          control={formControl as never}
          // Avoids a memory leak warning since this Controller is conditionally rendered
          shouldUnregister
          render={({
            field: { onChange, value },
            formState: { isSubmitting, errors },
          }) => {
            return canEdit ? (
              <>
                <StyledSelector
                  id={id}
                  options={options}
                  defaultValue={defaultValue}
                  value={
                    value
                      ? options.find(
                          option => option.label === value[`${name}`],
                        )
                      : null
                  }
                  getOptionValue={opt =>
                    (opt as Option)?.id ? (opt as Option).id.toString() : ''
                  }
                  onChange={opt => {
                    onChange(
                      optionTransformer
                        ? optionTransformer(opt as Option)
                        : opt,
                    );
                  }}
                  isLoading={isLoading}
                  isDisabled={isSubmitting || isDisabled}
                  isInvalid={!!errors[`${name}`]}
                  placeholder={placeholder}
                />
                {errors[`${name}`] ? (
                  <Form.Control.Feedback type='invalid'>
                    <>{errors[`${name}`]?.message}</>
                  </Form.Control.Feedback>
                ) : null}
              </>
            ) : (
              <StyledText
                id={id}
                value={textValue}
                readOnly
                plaintext
                disabled
              />
            );
          }}
        />
      )}
    />
  );
};
