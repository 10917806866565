import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import { DetailText } from 'common/components/DetailControls';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ScheduledPick } from 'common/models';
import React from 'react';

const StyledRow = styled(ResponsiveRow)`
  padding-left: 15px;
  padding-right: 20px;
  .col-lg-2 {
    min-width: 30%;
  }
  .col {
    padding-left: 20px;
  }
`;

export const BlockTab: React.FC<{ pickData: ScheduledPick }> = ({
  pickData,
}) => {
  const { block, pool } = pickData;

  return (
    <StyledStack>
      <StyledRow>
        <Col>
          <DetailText name='grower' label='Grower' text={block?.grower?.name} />
        </Col>
        <Col>
          <DetailText name='pool' label='Pool' text={pool?.poolId} />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <DetailText
            name='grower id'
            label='Grower ID'
            text={block?.grower?.growerId}
          />
        </Col>
        <Col>
          <DetailText
            name='subvariety'
            label='Sub Variety'
            text={block?.subVariety?.subVarietyCode}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <DetailText name='block' label='Block' text={block?.blockName} />
        </Col>
        <Col>
          <DetailText
            name='manager'
            label='Manager'
            text={block?.grower?.managerName}
          />
        </Col>
      </StyledRow>
      <StyledRow>
        <Col>
          <DetailText name='lot id' label='Lot ID' text={block?.lotNumber} />
        </Col>
        <Col>
          <DetailText
            name='manager cell'
            label='Manager Cell'
            text={block?.grower?.managerCell}
          />
        </Col>
      </StyledRow>
    </StyledStack>
  );
};
