import { FC, ReactNode } from 'react';
import LogoHeader from 'features/auth/components/LogoHeader';
import {
  BackgroundImage,
  ChildWrapper,
  NoTokenContainer,
  ViewContainer,
} from './styles';

const NoTokenLayout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ViewContainer>
      <BackgroundImage />
      <NoTokenContainer>
        <LogoHeader />
        <ChildWrapper>{children}</ChildWrapper>
      </NoTokenContainer>
    </ViewContainer>
  );
};

export default NoTokenLayout;
