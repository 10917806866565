import { useRbac } from 'features/rbac';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { links } from '../services/navbarLinks';

export type NavLinkConfig = {
  id: number;
  icon?:
    | OverridableComponent<SvgIconTypeMap>
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  path: string;
  subLinks?: NavLinkConfig[];
};

export const useNavLinks = (): NavLinkConfig[] => {
  const { userHasPermission } = useRbac();
  const navLinks: NavLinkConfig[] = [];

  links.forEach(link => {
    switch (link.label) {
      case 'Users':
        if (userHasPermission('user:read')) {
          navLinks.push(link);
        }
        break;
      case 'Growers':
        if (userHasPermission('growers:read')) {
          navLinks.push(link);
        }
        break;
      case 'Evaluations & Estimates':
        if (userHasPermission('evalEst:read')) {
          navLinks.push(link);
        }
        break;
      case 'Pack Plan':
        if (userHasPermission('packPlan:read')) {
          navLinks.push(link);
        }
        break;
      case 'Pick Schedule':
        if (userHasPermission('pickSchedule:read')) {
          navLinks.push(link);
        }
        break;
      case 'Harvest Payroll':
        if (userHasPermission('payroll:read')) {
          navLinks.push(link);
        }
        break;
      default:
        break;
    }
  });

  return navLinks;
};
