import { ResponsiveRow } from 'common/components/FormRow';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ScheduledPick } from 'common/models';
import { roundBins } from 'common/models/harvestData/utilities';
import React from 'react';
import { DetailTextWithNoChange } from './StaffCrewTab';
import { DisableMobileStacking } from '../styles';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))`
  .col-lg-3 {
    display: flex;
    align-items: center;
    min-width: fit-content;
  }
  .col {
    display: flex;
  }
  input {
    margin-left: 1rem;
  }
`;

const TextEndStyledRow = styled(ResponsiveRow)`
  margin-left: 5px;
  margin-right: 0;
  .col-lg-3 {
    display: flex;
    align-items: center;
    min-width: fit-content;
  }
  .col {
    display: flex;
  }
  input {
    text-align: end;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 50%;
  .row {
    height: auto !important;
  }
`;

const LargeValueRow = styled(TextEndStyledRow)`
  label,
  input {
    font-size: 1.3em;
  }
`;

const LargeLabeledRow = styled(LargeValueRow)`
  label {
    font-weight: bold;
  }
`;

export const LaborTab: React.FC<{ pickData: ScheduledPick }> = ({
  pickData,
}) => {
  const renderCrewData = () => {
    const { contractorRecords } = pickData?.pickRecord;
    const components: JSX.Element[] = [];

    if (contractorRecords && contractorRecords.length) {
      contractorRecords.forEach((record, idx) => {
        components.push(
          <div key={record.id}>
            {idx > 0 ? <br /> : ''}
            <LargeLabeledRow>
              <DetailTextWithNoChange
                name={`crew-${idx}`}
                label={`Crew ${idx + 1}`}
                text={record?.contractor.code}
              />
            </LargeLabeledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='head-count'
                label='Head Count'
                text={record?.headCount.toString()}
              />
            </TextEndStyledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='transfer-time'
                label='Transfer Time'
                text={record?.transferTime.substring(0, 5)}
              />
            </TextEndStyledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='start-time'
                label='Start Time'
                text={new Date(record?.startTime).toLocaleString([], {
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true,
                })}
              />
            </TextEndStyledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='hours-worked'
                label='Hours Worked'
                text={record?.hoursWorked.substring(0, 5)}
              />
            </TextEndStyledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='bins-picked'
                label='Bins Picked'
                text={record?.binsPicked.toString()}
              />
            </TextEndStyledRow>
          </div>,
        );
      });
    }

    return components;
  };

  const renderHaulData = () => {
    const { haulerRecords } = pickData?.pickRecord;
    const components: JSX.Element[] = [];

    if (haulerRecords && haulerRecords.length) {
      haulerRecords.forEach((record, idx) => {
        components.push(
          <div key={record.id}>
            {idx > 0 ? <br /> : ''}
            <LargeLabeledRow>
              <DetailTextWithNoChange
                name={`hauler-${idx}`}
                label={`Hauler ${idx + 1}`}
                text={record?.hauler.code}
              />
            </LargeLabeledRow>
            <TextEndStyledRow>
              <DetailTextWithNoChange
                name='binsHauled'
                label='Bins Hauled'
                text={roundBins(record.binsHauled).toString()}
              />
              <DetailTextWithNoChange
                name='tarps'
                label='Tarps'
                text={record.tarps.toString()}
              />
            </TextEndStyledRow>
          </div>,
        );
      });
    }

    return components;
  };

  return (
    <DisableMobileStacking>
      <StyledRow>
        {pickData?.pickRecord && (
          <>
            <StyledCol>{renderCrewData()}</StyledCol>
            <StyledCol>{renderHaulData()}</StyledCol>
          </>
        )}
      </StyledRow>
    </DisableMobileStacking>
  );
};
