import { mobile } from 'common/styles/breakpoints';
import {
  darkNavy,
  lighterNavy,
  secondaryWhite,
  white,
  lightGreyText,
} from 'common/styles/colors';
import citrusTreeBackground from '../../../assets/img/citrus-bg.png';
import styled from 'styled-components';

export const ViewContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(180deg, #112457 0%, #10523a 100%);
  color: ${lightGreyText};
`;

export const NoTokenContainer = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
  max-width: 1060px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackgroundImage = styled.div`
  background-image: url(${citrusTreeBackground});
  background-size: cover;
  height: 16vh;
  width: 100%;
  margin-bottom: 30px;
`;

export const ChildWrapper = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(300px, 80vw, 650px);

  @media (max-width: ${mobile}) {
    align-items: center;
    margin: 23px 0 100px;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100vw;
  display: flex;
  background-color: ${secondaryWhite};

  @media (max-width: ${mobile}) {
    flex-direction: column;
  }
`;

export const ContentContainer = styled.div`
  width: calc(100vw - max(280px, 15vw));
  margin-left: max(280px, 15vw);

  @media (max-width: ${mobile}) {
    margin-left: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
`;

export const StyledNav = styled.nav`
  position: fixed;
  background-color: ${lighterNavy};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${white};
  width: max(280px, 15vw);
  height: 100vh;
`;

export const SectionContainer = styled.section`
  padding: 40px;
  width: 100%;

  @media (max-width: ${mobile}) {
    padding: 0;
  }
`;

export const TopBar = styled.div`
  background-color: ${darkNavy};
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${white};

  h4,
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: ${white};
  }
`;

export const SeasonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 50%;

  h4 {
    margin-right: 20px;
  }

  @media (max-width: ${mobile}) {
    justify-content: center;
  }
`;

export const SeasonError = styled.p`
  @media (max-width: ${mobile}) {
    text-align: center;
    padding: 20px;
  }
`;
