import { useAppSelector } from 'app/redux';
import { useGetAreasQuery } from 'common/api/areaApi';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { useGetBlockSeasonEvalDataQuery } from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { useGetSubVarietiesQuery } from 'common/api/subVarietyApi';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import {
  DataTable,
  DataTableFilters,
  DataTableMobile,
  DataTableProps,
  MobileStyling,
} from 'common/components/DataTable';
import { usePSFQuery } from 'common/hooks';
import useWindowSize from 'common/hooks/useWindowSize';
import { PaginatedResult } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { useRbac } from 'features/rbac';
import { FC, useEffect, useMemo } from 'react';
import { UseQuery } from 'rtk-query-config';
import { Constants } from 'utils/constants';
import { PSFKeys, usePSFState } from 'utils/hooks/usePSFState';
import {
  areaFilter,
  blockIdFilter,
  BlocksFilterKeys,
  subVarietyFilter,
  varietyFilter,
} from 'utils/tableFilters/blockFilters';
import {
  growerIdFilter,
  parentGrowerFilter,
} from 'utils/tableFilters/growerFilters';
import {
  createAreaFilters,
  createSubVarietyFilters,
  createVarietyFilters,
} from 'utils/tableFilters/helpers';
import { AsyncCsvButton } from '../../../common/components/CsvButton/AsyncCsvButton';
import {
  BlockEvalTableItem,
  useBlockEvalTableData,
} from '../hooks/useBlockEvalTableData';
import { useBlockEvalTableDataMobile } from '../hooks/useBlockEvalTableDataMobile';
import { allEvalTableTitle, myBocksTableTitle } from '../pages/EvalEstMainView';
import { formatAllEvalCsvData } from '../utils/exportAllCsvUtils';
import { BlockEvalData } from 'common/api/dto/growerBlockApiTypes';

const blockTableFilters: DataTableFilters[] = [
  parentGrowerFilter,
  growerIdFilter,
  blockIdFilter,
  varietyFilter,
  subVarietyFilter,
  areaFilter,
];

export const BlockEvalTable: FC<{
  title: string;
  blockQuery: UseQuery<PaginatedResult<BlockDetails<WithAllSeasonData>>>;
  psfKey: PSFKeys;
}> = ({ title, blockQuery, psfKey }) => {
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const { query, setQuery } = usePSFState(psfKey);
  const { userHasPermission } = useRbac();
  const {
    data,
    isLoading,
    isFetching,
    pagination,
    applyFilters,
    filters,
    error: getBlocksError,
  } = usePSFQuery<PaginatedResult<BlockDetails<WithAllSeasonData>>>(
    blockQuery,
    {},
    { ...query },
    setQuery,
    selectedSeason?.id ? { seasonId: selectedSeason.id } : {},
  );
  const growerBlocks = useMemo(() => data?.results ?? [], [data]);
  const { columns, data: tableData } = useBlockEvalTableData(
    isLoading || isFetching,
    growerBlocks,
  );
  const columnsMobile = useBlockEvalTableDataMobile();
  const { data: varieties = [], error: varietyError } = useGetVarietiesQuery();
  const { data: subVarieties = [], error: subVarietyError } =
    useGetSubVarietiesQuery();
  const { data: areas = [], error: areaError } = useGetAreasQuery();
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  const showExportButton =
    !isLoading &&
    !isFetching &&
    (title === allEvalTableTitle || title === myBocksTableTitle) &&
    userHasPermission('export:eval-and-est');

  const ExportAllEvalEstBtn = () => (
    <AsyncCsvButton<BlockEvalData[]>
      label={`Export ${selectedSeason?.year}`}
      fetcher={useGetBlockSeasonEvalDataQuery}
      params={{ seasonId: selectedSeason?.id as number }}
      formatter={formatAllEvalCsvData}
      filenameOptions={{
        name: `block_evaluations_${selectedSeason?.year}`,
        appendDate: false,
      }}
    />
  );

  const tableProps: DataTableProps<BlockEvalTableItem> = {
    title,
    unitToPaginate: 'blocks',
    isLoading,
    isFetching,
    columns: isMobile ? columnsMobile : columns,
    data: tableData,
    pagination,
    filtering: {
      filterOptions: blockTableFilters,
      applyFilters,
      defaultFilters: filters,
    },
    onRowClick: block =>
      `${Constants.routes.EVAL_EST_MAIN}/${block.blockInfo.id}`,
    loadError: !!getBlocksError,
    ...(showExportButton && {
      FooterComponent: ExportAllEvalEstBtn,
    }),
  };

  useMemo(() => {
    if (!varietyError) {
      const filterIdx = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.varietyKey,
      );
      blockTableFilters[filterIdx].options = createVarietyFilters(varieties);
    }
  }, [varieties, varietyError]);

  useMemo(() => {
    if (!subVarietyError) {
      const filterIdx = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.subVarietyKey,
      );
      blockTableFilters[filterIdx].options =
        createSubVarietyFilters(subVarieties);
    }
  }, [subVarieties, subVarietyError]);

  useMemo(() => {
    if (!areaError) {
      const filterIndex = blockTableFilters.findIndex(
        filter => filter.category.key === BlocksFilterKeys.areaKey,
      );
      blockTableFilters[filterIndex].options = createAreaFilters(areas);
    }
  }, [areas, areaError]);

  useEffect(() => {
    if (getBlocksError) {
      handleError(getBlocksError, `Unable to load '${title}' blocks.`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBlocksError]);

  return (
    <>
      {isMobile ? (
        <MobileStyling>
          <DataTableMobile<BlockEvalTableItem> {...tableProps} />
        </MobileStyling>
      ) : (
        <DataTable<BlockEvalTableItem> {...tableProps} />
      )}
    </>
  );
};
