import * as DetailControls from 'common/components/DetailControls';
import { mobile } from 'common/styles/breakpoints';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import styled, { css, StyledComponent } from 'styled-components';
import { CloseButton } from '../CloseButton/CloseButton';
import { FormPadding, StyledFormWrapper } from 'common/styles/form';
import {
  lighterBlueShade,
  lightGreyText,
  darkNavy,
  tableHeaderGrey,
} from 'common/styles/colors';
import { fadedClass, hiddenClass, LoadingOverlay } from '../LoadingOverlay';
import { delay } from 'utils/delay';
import { Close } from '@mui/icons-material';

const vertFlex = css`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TopBar = styled.div.attrs(() => ({
  className: 'form-top-bar',
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${mobile}) {
    padding: ${FormPadding};
    padding-bottom: 0px;
  }
  margin-bottom: 5px;
`;

const ContentDiv = styled.div`
  @media (max-width: ${mobile}) {
    ${vertFlex}
    padding: 0px 20px;
    flex-shrink: 1;
    overflow: auto;
    align-self: stretch;
  }
`;

const StyledForm = styled(Form)`
  ${vertFlex}
`;

const StyledContainer = styled.div`
  ${vertFlex}
`;

const Fieldset = styled.fieldset`
  ${vertFlex}
`;

const CancelButton = styled(Button).attrs(() => ({
  variant: 'transparent',
  type: 'button',
  'aria-label': 'cancel edit',
}))`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.2rem;
  border: none;
  background-color: transparent;
  font-family: KanitLight;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.0275em;
  color: ${darkNavy};

  :focus {
    outline: ${tableHeaderGrey} dotted 1px;
  }
`;

/**
 * Executes an asynchronous action when the form is submitted and returns an
 * indication of success.
 */
type AsyncSubmitEventHandler = (evt: React.FormEvent) => Promise<boolean>;

/**
 * Defines properties accepted by the form for passing through to children via
 * context.
 */
export interface DetailFormContextProps {
  /**
   * Indicates whether to make all detail labels larger than default.
   */
  largeLabels?: boolean;

  /**
   * Indicates whether all detail controls should be editable or read-only.
   */
  editable?: boolean;
}

/**
 * Defines properties accepted by the form.
 */
interface DetailFormProps {
  /** The function that will take action when edits to the form are submitted. */
  submitAction: AsyncSubmitEventHandler;

  /** Indicates whether the entire form should be disabled. */
  disabled?: boolean;
  reset?: () => void;
  closeHandler?: () => void;

  setIsEdit?: (isEdit: boolean) => void;

  /** Styled div that can replace the default wrapper */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CustomWrapper?: StyledComponent<'div', any, any, never>;

  /** An optional boolean to show/hide edit ability; default is true */
  canEdit?: boolean;
  /**
   * Indicates whether to make all detail labels larger than default.
   */
  largeLabels?: boolean;
  children?: React.ReactNode;
}

/**
 * A component that makes {@link DetailForm}-level properties available to
 * children controls.
 */
export const DetailFormContext = React.createContext<DetailFormContextProps>(
  {},
);

/**
 * A form that can switch between displaying and editing an item's details.
 *
 * @param props The properties accepted by the form.
 *
 * @remarks
 *
 * This form is a host for other controls that will display the actual details
 * of some item or entity, and it's in charge of managing an 'editable' state.
 *
 * Children controls that want to be editable should read (and presumably, apply)
 * the {@link DetailFormContextProps.editable} property from context, which will
 * be set by this form as the state changes.
 *
 * There are pre-defined controls intended for use with the form, such as
 * {@link DetailControls.DetailText} or {@link DetailControls.DetailDropDown},
 * which support editing as specified above.
 */
export const DetailForm: FC<DetailFormProps> = ({
  submitAction,
  children,
  disabled,
  CustomWrapper,
  largeLabels,
  reset,
  closeHandler,
  setIsEdit,
  canEdit = true,
}) => {
  const [editable, setEditable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [overlayClass, setOverlayClass] = useState(hiddenClass);

  const showWaitWithFade = useCallback(async () => {
    setOverlayClass(hiddenClass);
    // This is needed so that a transition occurs between 0 and the final opacity
    await delay(0);
    setOverlayClass(fadedClass);
  }, []);

  // This function handles toggling between edit and read-only modes, making sure
  // changes submit successfully when going back to read-only mode.
  const handleEditOrSubmit = useCallback(
    async (evt: React.FormEvent) => {
      evt.preventDefault();

      if (editable) {
        setLoading(true);
        await showWaitWithFade();
        const result = await submitAction(evt);
        setLoading(false);

        if (result) {
          setEditable(false);
        }
      } else {
        setEditable(true);
      }
    },
    [editable, showWaitWithFade, submitAction],
  );

  useEffect(() => {
    if (setIsEdit) {
      setIsEdit(editable);
    }
  }, [editable, setIsEdit]);

  const cancelEdit = () => {
    if (reset) reset();
    setEditable(false);
  };

  const Wrapper = CustomWrapper || StyledFormWrapper;

  return (
    <>
      {isLoading && <LoadingOverlay className={overlayClass} />}
      {/* When the form is NOT editable, we make sure to disable the form
      by providing a 'div' component wrapper and disable editability. */}
      {canEdit ? (
        <DetailFormContext.Provider value={{ largeLabels, editable }}>
          <Wrapper
            style={{
              backgroundColor: editable ? lighterBlueShade : lightGreyText,
            }}
          >
            <StyledForm onSubmit={handleEditOrSubmit}>
              <Fieldset disabled={disabled}>
                <ContentDiv>
                  <TopBar>
                    {editable ? (
                      <CancelButton type='button' onClick={cancelEdit}>
                        <Close style={{ color: `${tableHeaderGrey}` }} />
                        Cancel
                      </CancelButton>
                    ) : (
                      <CloseButton closeHandler={closeHandler} />
                    )}
                    <DetailControls.DetailEditingToggle editing={editable} />
                  </TopBar>
                  {children}
                </ContentDiv>
              </Fieldset>
            </StyledForm>
          </Wrapper>
        </DetailFormContext.Provider>
      ) : (
        <DetailFormContext.Provider value={{ largeLabels, editable: false }}>
          <Wrapper
            style={{
              backgroundColor: lightGreyText,
            }}
          >
            <StyledContainer>
              <Fieldset disabled={disabled}>
                <ContentDiv>
                  <TopBar>
                    <CloseButton closeHandler={closeHandler} />
                  </TopBar>
                  {children}
                </ContentDiv>
              </Fieldset>
            </StyledContainer>
          </Wrapper>
        </DetailFormContext.Provider>
      )}
    </>
  );
};
