import { ModalWrapper } from 'common/components/ModalWrapper';
import { FC } from 'react';
import { Close } from '@mui/icons-material';
import {
  HeaderWrapper,
  TitleDiv,
  CancelButton,
  BodyWrapper,
  ButtonDiv,
} from './styles';
import { darkNavy } from 'common/styles/colors';

interface ModalProps {
  redirect: () => void;
  isOpen: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EditPackPlanModal: FC<ModalProps> = ({
  redirect,
  isOpen,
  closeModal,
}) => {
  const Header: FC = () => (
    <HeaderWrapper>
      <TitleDiv>Edit Pack Plan</TitleDiv>
      <CancelButton type='button' onClick={closeModal}>
        <span>Cancel</span>
        <Close />
      </CancelButton>
    </HeaderWrapper>
  );

  const Body: FC = () => (
    <BodyWrapper>
      <ButtonDiv>
        <button type='button' onClick={closeModal}>
          <span>EDIT BIN REQUEST</span>
        </button>
        <button type='button' onClick={redirect}>
          <span>SCHEDULE BLOCKS</span>
        </button>
      </ButtonDiv>
    </BodyWrapper>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      backgroundColor={darkNavy}
      closeModal={closeModal}
      header={<Header />}
      body={<Body />}
    />
  );
};
