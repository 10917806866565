import { FetchArgs } from '@reduxjs/toolkit/dist/query';
import { FilterCategoryOptions } from 'common/hooks';

export const FilterValueSeparator = '|';

export interface FilterParams {
  filters: FilterCategoryOptions[];
}

/**
 * Builds the arguments object for a query, ensuring that filters are serialized
 * in the format expected by the backend.
 *
 * @param params The object whose properties will be used as query parameters,
 *  including, at a minimum, the filters to process.
 * @param baseUrl The base URL to which parameters will be appended.
 */
export function withFilters<T extends FilterParams>(
  { filters, ...otherParams }: T,
  baseUrl: string,
): FetchArgs {
  // Serialize and encode filters. We can't use built-in encoding on this whole
  // string because the server expects separators (such as the colon) literally.
  const serializedFilters = filters
    .map(filter => {
      const encodedOptions = filter.options.map(opt => encodeURIComponent(opt));
      return `${filter.category}:${encodedOptions.join(FilterValueSeparator)}`;
    })
    .join(',');

  const filtersParam = serializedFilters ? `?filter=${serializedFilters}` : '';

  // Append filters manually because we don't want them processed any further
  // (see above). Passing other params straight thru lets built-in encoding apply.
  return {
    url: `${baseUrl}${filtersParam}`,
    params: otherParams,
  };
}
