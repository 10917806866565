import { FC, ReactElement } from 'react';
import { Column, usePagination, useSortBy, useTable } from 'react-table';
import { DataTableFilter } from '../DataTableFilter/DataTableFilter';
import { Paginator } from './Paginator';
import { FilterCategoryOptions } from 'common/hooks';
import {
  LoadingOrEmpty,
  TableContainer,
  TableFooter,
  TitleContainer,
} from './DataTableStyles';
import { LoadingSpinner } from '../LoadingSpinner';
import { useHistory } from 'react-router-dom';

export type PaginationState = {
  page: number;
  pageSize: number;
  count: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

type PaginationActions = {
  getPage: (page: number) => void;
  getNextPage: () => void;
  getPreviousPage: () => void;
  changePageSize: (size: number) => void;
};

export type Option = {
  label: string;
  value: string;
};

export type DataTablePaginationProps = PaginationState & PaginationActions;

export type DataTableFilters = {
  /**
   * Optional text to show below filter title and above options
   */
  subHeader?: string;
  /**
   * The options to render for the specified category.
   *
   *  @remarks
   * - Since filters can come from DB, on failure, options can be `undefined`.
   * - Passing a jagged array results in separators rendered in between each inner array.
   */
  options: Option[] | Option[][] | undefined;
  category: {
    label: string;
    /**
     * Filter and operator that is used to call the server
     */
    key: string;
  };
};

export type DataTableProps<D extends Record<string, unknown>> = {
  title?: string;
  unitToPaginate?: string;
  isLoading: boolean;
  isFetching: boolean;
  columns: Column<D>[];
  data: D[];
  pagination?: DataTablePaginationProps;
  filtering?: {
    filterOptions: DataTableFilters[];
    applyFilters: (filters: FilterCategoryOptions[]) => void;
    defaultFilters?: FilterCategoryOptions[];
  };
  sortBy?: {
    // TODO
  };
  /**
   * @returns {string | undefined}
   * - If a string is returned, the row will be navigated to that URL.
   * - If undefined is returned, the row will not be clickable.
   */
  onRowClick?: (item: D) => string | undefined;
  loadError?: boolean;
  /** Component to display on the right side of the table footer. */
  FooterComponent?: FC;
};

export const DataTable = <D extends Record<string, unknown>>({
  title,
  unitToPaginate,
  isLoading,
  isFetching,
  columns,
  data,
  pagination,
  filtering,
  sortBy,
  onRowClick,
  FooterComponent,
}: DataTableProps<D>): ReactElement => {
  // Evaluate these conditions once instead of re-computing in multiple places.
  const sortByEnabled = sortBy !== undefined;
  const paginationEnabled = pagination !== undefined;

  // Determine which react-table plugins should be enabled.
  const plugins = [
    // The order in which plugins are specified matters to react-table.
    // Order needs to be useFilter -> useSortBy -> usePagination.
    ...(sortByEnabled ? [useSortBy] : []),
    ...(paginationEnabled ? [usePagination] : []),
  ];

  // Generate the required initial state for the enabled plugins.
  const initialState = {
    ...(sortByEnabled ? {} : {}), // TODO: add initial state for sorting
    ...(paginationEnabled
      ? {
          pageIndex: pagination.page,
          pageSize: pagination.pageSize,
        }
      : {}),
  };

  // Generate the required table options for the enabled plugins.
  const extraTableOptions = {
    ...(sortByEnabled ? {} : {}), // TODO: add table options for sorting
    ...(paginationEnabled
      ? { manualPagination: true, pageCount: pagination.pageCount }
      : {}),
  };

  // In order to identify whether a column has footers, we default the footer
  // to an empty string and check against that.
  const defaults = { Footer: '' };

  // Currently, the plugins are not used (we use PSFQuery). If these are used
  // in the future, synching of pagination properties might be needed.
  const tableInstance = useTable(
    {
      columns,
      defaultColumn: defaults,
      data,
      initialState,
      ...extraTableOptions,
    },
    ...plugins,
  );

  const {
    // Basic instance props
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    // Pagination specific props
    page,
  } = tableInstance;

  // If the 'usePagination' plugin is enabled, the 'page' instance prop contains
  // the table rows for the current page. Otherwise, the 'rows' instance prop
  // contains the table rows.
  const tableRows = paginationEnabled ? page : rows;

  const hasTableData = tableRows.length > 0;
  const hasFooterData = footerGroups.some(footerGroup => {
    return footerGroup.headers.some(column => column.Footer !== '');
  });

  const history = useHistory();

  return (
    <TableContainer>
      <TitleContainer>
        <h3>{title}</h3>
        {filtering ? (
          <DataTableFilter
            filterOptions={filtering?.filterOptions}
            appliedFilters={filtering?.defaultFilters}
            setFilters={filtering?.applyFilters}
          />
        ) : null}
      </TitleContainer>

      <div className='scrollable-container'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    style={{ width: `${column.width}` }}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {!isLoading && !isFetching && hasTableData && (
            <tbody {...getTableBodyProps()}>
              {tableRows.map((row, index) => {
                let link: undefined | string;
                prepareRow(row);
                if (onRowClick) {
                  link = onRowClick(row.original);
                }

                return (
                  <tr
                    onClick={({ target }) => {
                      if (
                        target instanceof HTMLInputElement &&
                        target.type === 'checkbox'
                      ) {
                        return;
                      }
                      if (typeof link === 'string') {
                        history.push(link);
                      }
                    }}
                    className={`${
                      index % 2 ? 'blue-row' : ''
                    } table-component-row`}
                    {...row.getRowProps()}
                    {...(link && {
                      style: { cursor: 'pointer' },
                    })}
                    role='link'
                  >
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          )}
          {!isLoading && !isFetching && hasTableData && hasFooterData && (
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <td {...column.getFooterProps()}>
                      {column.render('Footer')}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      {(isLoading || isFetching || !hasTableData) && (
        <LoadingOrEmpty>
          {isLoading || isFetching ? (
            <LoadingSpinner size='3em' />
          ) : (
            <p>No Data Available</p>
          )}
        </LoadingOrEmpty>
      )}

      <TableFooter>
        {/* If the usePagination plugin is enabled, render a paginator to
            allow users to page through the data. */}
        {!isLoading && paginationEnabled && hasTableData && (
          <Paginator
            {...pagination}
            unitToPaginate={unitToPaginate}
            isFetching={isFetching}
          />
        )}
        {FooterComponent && FooterComponent({})}
      </TableFooter>
    </TableContainer>
  );
};
