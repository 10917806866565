import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { mobile } from 'common/styles/breakpoints';
import { boxShadow } from 'common/styles/page';
import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

const StyledModal = styled(Modal)`
  .modal-dialog {
    margin: auto;
    max-width: ${props => props.width ?? '432px'};

    @media (max-width: ${mobile}) {
      margin: 5px;
    }
  }

  .modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: ${boxShadow};
    ${props =>
      props.backgroundcolor
        ? `background-color: ${props.backgroundcolor}`
        : null};
    text-align: left;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    border-bottom: none;
    border-top: none;
    padding: 0;
    justify-content: flex-start;
  }
`;

interface ModalProps {
  isOpen: boolean;
  closeModal?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  preventBackdropClosure?: boolean;
  isLoading?: boolean;
  width?: string;
  backgroundColor?: string;
  header?: React.ReactNode;
  body: React.ReactNode;
  footer?: React.ReactNode;
}

export const ModalWrapper: FC<ModalProps> = ({
  isOpen,
  closeModal,
  preventBackdropClosure,
  isLoading,
  width,
  backgroundColor,
  header,
  body,
  footer,
}) => {
  return (
    <StyledModal
      show={isOpen}
      onHide={closeModal}
      width={width}
      backgroundcolor={backgroundColor}
      centered
      {...(preventBackdropClosure
        ? { backdrop: 'static', keyboard: false }
        : {})}
    >
      <WithLoadingOverlay isLoading={isLoading || false}>
        <>
          {header ? <Modal.Header>{header}</Modal.Header> : <></>}
          <Modal.Body>{body}</Modal.Body>
          {footer ? <Modal.Footer>{footer}</Modal.Footer> : <></>}
        </>
      </WithLoadingOverlay>
    </StyledModal>
  );
};
