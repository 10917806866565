import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PSFConfig } from 'common/hooks';
import { GrowerStatus } from 'common/models';
import { GrowersFilterKeys } from 'utils/tableFilters/growerFilters';

export interface QueryState {
  [key: string]: Partial<PSFConfig>;
}

export interface PSFMemoType {
  query: Partial<PSFConfig>;
  setQuery: (query: Partial<PSFConfig>) => void;
}

export interface QuerySelection extends Partial<PSFConfig> {
  key: string;
  query: Partial<PSFConfig>;
}

export const sliceName = 'psfSlice';

const showActiveGrowersFilter = {
  growers: {
    filters: [
      {
        category: GrowersFilterKeys.growerStatusKey,
        options: [GrowerStatus.Active.toString()],
      },
    ],
  },
};

export const psfQuerySlice = createSlice({
  name: sliceName,
  initialState: { ...showActiveGrowersFilter } as QueryState,
  reducers: {
    setQuery: (state: QueryState, action: PayloadAction<QuerySelection>) => {
      const { key, query } = action.payload;
      state[key] = { ...state[key], ...query };
    },
  },
});
