import { Address } from './address';
import { DateWithTime, DateWithoutTime } from './dateType';
import { GrowerBlock } from './growerBlock';
import { GrowerSeason } from './growerSeason';

/**
 * Defines the possible statuses of a grower.
 */
export enum GrowerStatus {
  No_Status,
  New,
  Active,
  Non$Producing,
  Withdrawn,
  Harvested,
  Inactive,
}

export interface Grower {
  id: number;
  growerId: string;
  name: string;
  parentGrowerName: string | null;
  address: Address;
  email: string;
  phone: string | null;
  managerName: string | null;
  managerCell: string | null;
  grpNumber: number | null;
  skNumber: string;
  certExp: DateWithoutTime | null;
  gapStatus: string | null;
  gapCertNotes: string | null;
  gapUpdatedAt: DateWithTime | null;
  ggnNumber: number | null;
  permitNumber: number | null;
  notes: string | null;
  growerBlocks: GrowerBlock[];
  secondGrower: string | null;
  growerSeasons: GrowerSeason[];
}
