/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

export const hiddenClass = 'hidden';
export const fadedClass = 'faded';

const Overlay = styled.div`
  position: fixed;
  z-index: 999; // layer in front
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div:first-of-type {
    position: fixed;
  }

  div:last-of-type {
    width: 100%;
    height: 100%;
    background-color: black;
    transition: opacity 0.5s ease-in;
  }

  & .${hiddenClass} {
    opacity: 0;
  }

  & .${fadedClass} {
    opacity: 0.35;
  }
`;

function blockInput(e: React.MouseEvent): void {
  e.stopPropagation();
}

export const LoadingOverlay: FC<{
  className: string;
}> = ({ className }) => (
  <Overlay>
    <Spinner
      animation='border'
      variant='primary'
      role='status'
      style={{ width: '8em', height: '8em' }}
    >
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
    <div onClick={blockInput} className={className} />
  </Overlay>
);
