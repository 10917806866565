import { useAppSelector } from 'app/redux';
import { usePickTable } from 'features/harvest-payroll/hooks/usePickTable';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { RowMetadata } from 'features/harvest-payroll/utils/payrollDataTypes';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const Input = styled.input`
  width: 100%;
  height: 24px;
  border: none;
  text-align: center;
  border-radius: 4px;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

/**
 * An input component that leverages `useForm` to update
 * a harvest row's quantity or rate on input.
 */
export const PayrollInput: React.FC<{
  rowMetadata: RowMetadata;
  cellKey: 'quantity' | 'rate';
  defaultValue: number;
}> = ({ rowMetadata, cellKey, defaultValue }) => {
  const dispatch = useDispatch();
  const { editHarvestRow } = usePickTable();
  const { isDirty } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const { register, watch } = useForm({
    defaultValues: {
      [cellKey]: defaultValue,
    },
  });

  useEffect(() => {
    const subscription = watch(data => {
      if (data && data[cellKey]) {
        editHarvestRow(
          rowMetadata,
          cellKey,
          // Assert type as useForm is causing type issues.
          data[cellKey]?.toString() as string,
        );

        if (!isDirty) {
          dispatch(payrollSlice.actions.setIsDirty(true));
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [cellKey, dispatch, editHarvestRow, isDirty, rowMetadata, watch]);

  return <Input type='number' {...register(cellKey)} />;
};
