import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';

export const syncApi = createApi({
  reducerPath: 'syncApi',
  baseQuery: customBaseQuery,

  endpoints: builder => ({
    startSync: builder.mutation<void, void>({
      query: () => ({
        url: '/data-sync/start',
        method: 'PATCH',
      }),
    }),
  }),
});

export const { useStartSyncMutation } = syncApi;
