import { FC } from 'react';
import Form, { FormProps } from 'react-bootstrap/Form';
import styled from 'styled-components';
import { mobile } from './breakpoints';
import { blueShadeHeader, lightGreyText, red } from './colors';
import { MobileScrollWrapper, boxShadow } from './page';

const BootstrapForm: FC<FormProps> = ({ children, ...rest }) => (
  <Form {...rest}>{children}</Form>
);

export const Title = styled.p`
  font-family: KanitLight;
  color: ${blueShadeHeader};
  font-size: 2em;
`;

export const StyledForm = styled(BootstrapForm)`
  background-color: ${props => props.theme.forms.backgroundColor};
  padding: 40px;
  border-radius: 5px;
  width: 400px;
`;

export const FormPadding = '20px';

export const StyledFormWrapper = styled(MobileScrollWrapper)`
  width: min(65vw, 754px);
  padding: ${FormPadding};
  box-shadow: ${boxShadow};
  border-radius: 0.25rem;

  & .col > .row {
    display: flex;
    align-items: center;
    height: 100%; // fix row misalignment on multi lines
  }

  @media (max-width: ${mobile}) {
    width: 100vw;
    padding: 0;
    display: flex;
    flex-direction: column;

    & > form > fieldset > div:first-of-type {
      padding: 0;
    }

    & .col {
      padding: 0;
    }

    & .row {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }

  & label {
    color: ${props => props.theme.forms.labelColor};
    margin: 5px;
  }
  & .form-control.is-invalid,
  .form-select.is-invalid {
    border-color: ${props => props.theme.forms.errorBorderColor};
    border-width: 2px;
  }

  & .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
  }

  & .form-select.is-invalid:not([multiple]):not([size]),
  .form-select.is-invalid:not([multiple])[size='1'],
  .was-validated .form-select:invalid:not([multiple]):not([size]),
  .was-validated .form-select:invalid:not([multiple])[size='1'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  }

  & .invalid-feedback {
    /* Modify style of validation error messages here */
    color: ${props => props.theme.forms.errorTextColor};
    padding-bottom: 5px;
  }
  & control {
    color: ${props => props.theme.forms.control};
  }
`;

export const StyledCardWrapper = styled.div`
  width: min(65vw, 754px);
  padding: ${FormPadding};
  background-color: ${lightGreyText};
  box-shadow: ${boxShadow};
  border-radius: 0.25rem;
  & label {
    margin: 5px;
  }
  & .form-control.is-invalid {
    border-color: ${props => props.theme.forms.errorBorderColor};
  }

  & .form-control.is-invalid,
  .was-validated .form-control:invalid {
    background-image: none;
  }

  & .invalid-feedback {
    /* Modify style of validation error messages here */
    color: ${props => props.theme.forms.errorTextColor};
    padding-bottom: 5px;
  }

  padding: ${FormPadding};

  position: relative;
  width: 500px;

  background: #e6ecff;

  box-shadow: ${boxShadow};
  border-radius: 8px;

  display: flex;
  flex-direction: row;
`;

export const applyErrorBorder = (isError: boolean): string | null =>
  isError ? `border: 2px solid ${red};` : null;
