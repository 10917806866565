import { Option } from 'common/components/DetailControls';
import { CmsDate } from 'common/models/cmsDate';
import { Evaluation, GibbRate } from 'common/models/evaluation';
import { Market } from 'common/models/market';
import { UpdateEvalFormValues } from 'features/evaluation-views/types';
import { getEnumLabels } from 'utils/enumFunctions';
import { yesNoOptions } from './commonOptions';

export const gibbOptions: Option[] = yesNoOptions;
export const [gibbFalseOption, gibbTrueOption] = gibbOptions;

export const marketOptions: Option[] = Object.values(Market).map(
  (market, index) => ({ id: index + 1, label: market }),
);

export const gibbRateOptions: Option[] = getEnumLabels(GibbRate)
  // Remove enum option 'None'
  .slice(1)
  .map((rate, index) => ({
    id: index + 1,
    label: rate,
  }));

/** Returns an ordered array of number options */
export const getNumberedOptions = (
  start: number,
  end: number,
  excludeZero = true,
): Option[] => {
  return Array.from({ length: end + 1 - start }, (_, index) => {
    const indexWithoutZero = index + 1;
    return {
      id: excludeZero ? indexWithoutZero : index,
      label: (excludeZero ? indexWithoutZero : index).toString(),
    };
  });
};

/** Returns default values for the evaluation form */
export const createEvalFormValues = (
  evaluation?: Evaluation | null,
): UpdateEvalFormValues => ({
  evaluationDate: CmsDate.parse(evaluation?.evaluationDate)?.toDate() ?? null,
  gibb: {
    id: evaluation?.gibb ? gibbTrueOption.id : gibbFalseOption.id,
    label: evaluation?.gibb ? gibbTrueOption.label : gibbFalseOption.label,
  },
  gibbRate: evaluation?.gibbRate ?? null,
  market: evaluation?.market ?? null,
  color: evaluation?.color ?? null,
  drop: evaluation?.drop ?? null,
  priority: evaluation?.priority ?? null,
  condition: evaluation?.condition ?? null,
});
