import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { BinRequest } from 'common/models/binRequest';
import { PackHouse, PackPlan } from 'common/models/packHouse';
import {
  RemovePlanRowData,
  UpdateBinReqData,
} from 'features/pack-plan-views/hooks/usePackPlanActions';

const packHouseBaseUrl = '/pack-house';

const packPlanTag = 'PackPlan';

export const packHouseApi = createApi({
  reducerPath: 'packHouseApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['PackHouses', packPlanTag, 'BinRequest'],

  endpoints: builder => ({
    getPackHouses: builder.query<PackHouse[], void>({
      query: () => ({
        url: `${packHouseBaseUrl}`,
      }),
      providesTags: (_, err) => (err ? [] : ['PackHouses']),
    }),

    getPackPlan: builder.query<
      PackPlan,
      { packHouseId: number; year: number; month: number; day: number }
    >({
      query: ({ packHouseId, year, month, day }) => ({
        url: `${packHouseBaseUrl}/${packHouseId}/pack-plan`,
        params: { year, month, day },
      }),
      providesTags: (_, err, arg) =>
        err ? [] : [{ type: packPlanTag, id: arg.packHouseId }],
    }),

    updateBinRequest: builder.mutation<BinRequest, UpdateBinReqData>({
      query: ({
        packHouseId,
        binId,
        varietyId,
        market,
        sizeId,
        bins,
        date,
      }) => ({
        url: `${packHouseBaseUrl}/${packHouseId}/bin-request`,
        method: 'PUT',
        body: {
          ...(binId ? { binId } : {}),
          date,
          varietyId,
          market,
          sizeId,
          bins,
        },
      }),
      invalidatesTags: (_, err, arg) =>
        err ? [] : [{ type: packPlanTag, id: arg.packHouseId }],
    }),

    deletePlanRow: builder.mutation<void, RemovePlanRowData>({
      query: ({ startDate, packHouseId, variety, market, size }) => ({
        url: `${packHouseBaseUrl}/${packHouseId}/pack-plan-row`,
        method: 'DELETE',
        params: { startDate, variety, market, size },
      }),
      invalidatesTags: (_, err, arg) =>
        err ? [] : [{ type: packPlanTag, id: arg.packHouseId }],
    }),
  }),
});

export const {
  useGetPackHousesQuery,
  useGetPackPlanQuery,
  useUpdateBinRequestMutation,
  useDeletePlanRowMutation,
} = packHouseApi;
