import { ResponsiveRow } from 'common/components/FormRow';
import { DetailText } from 'common/components/DetailControls';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ContractorPickAssignment, ScheduledPick } from 'common/models';
import { compareContractorCodes } from 'features/pick-schedule-views/utils/pickDetailUtils';
import { ContractorRecord } from 'common/models/harvestData/contractor';
import { roundBins } from 'common/models/harvestData/utilities';
import { DisableMobileStacking } from '../styles';

const StyledRow = styled(ResponsiveRow)`
  margin-left: 5px;
  margin-right: 0;

  .col-lg-3 {
    display: flex;
    align-items: center;
    min-width: fit-content;
  }

  .col {
    display: flex;
  }

  input {
    text-align: end;
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  width: 50%;

  .row {
    height: auto !important;
  }
`;

const IndentedRow = styled(StyledRow)`
  .row {
    padding-left: 40px;
  }
`;

const LargeValueRow = styled(StyledRow)`
  label,
  input {
    font-size: 1.3em;
  }
`;

export const BinsTab: React.FC<{ pickData: ScheduledPick }> = ({
  pickData,
}) => {
  const renderCrewScheduledRows = (
    contractorAssigments: ContractorPickAssignment[],
  ) => {
    const components: JSX.Element[] = [];
    let binTotal = 0;

    components.push(
      <LargeValueRow key='bins-scheduled'>
        <DetailText
          name='bins-scheduled'
          label='Bins Scheduled:'
          largeLabel
          text=' '
        />
      </LargeValueRow>,
    );
    if (contractorAssigments.length) {
      const sortedContractorAssigments = [...contractorAssigments].sort(
        compareContractorCodes,
      );
      sortedContractorAssigments.forEach(assignment => {
        binTotal += assignment.bins;
        components.push(
          <IndentedRow key={assignment.id}>
            <DetailText
              name={`${assignment.contractor.code}`}
              label={`${assignment.contractor.code}`}
              largeLabel
              text={assignment.bins ? assignment.bins.toString() : '-'}
            />
          </IndentedRow>,
        );
      });
    }
    components.push(
      <LargeValueRow key='bins-scheduled-total'>
        <DetailText
          name='bins-scheduled-total'
          label='Total'
          largeLabel
          text={binTotal.toString()}
        />
      </LargeValueRow>,
    );

    return components;
  };

  const renderCrewReceivedRows = (contractorRecords: ContractorRecord[]) => {
    const components = [];
    let binTotal = 0;

    components.push(
      <LargeValueRow key='bins-received'>
        <DetailText
          name='bins-received'
          label='Bins Received:'
          largeLabel
          text=' '
        />
      </LargeValueRow>,
    );
    if (contractorRecords.length) {
      const sortedContractorRecords = [...contractorRecords].sort(
        compareContractorCodes,
      );
      sortedContractorRecords.forEach(record => {
        binTotal += record.binsPicked;
        components.push(
          <IndentedRow key={record.id}>
            <DetailText
              name={`${record.contractor.code}`}
              label={`${record.contractor.code}`}
              largeLabel
              text={record.binsPicked ? record.binsPicked.toString() : '-'}
            />
          </IndentedRow>,
        );
      });
    }
    components.push(
      <LargeValueRow key='bins-received-total'>
        <DetailText
          name='bins-received-total'
          label='Total'
          largeLabel
          text={roundBins(binTotal).toString()}
        />
      </LargeValueRow>,
    );

    return components;
  };

  return (
    <DisableMobileStacking>
      <StyledRow>
        <StyledCol>
          {renderCrewScheduledRows(
            pickData?.pickAssignment?.contractorPickAssignment || [],
          )}
        </StyledCol>
        <StyledCol>
          {renderCrewReceivedRows(
            pickData?.pickRecord?.contractorRecords || [],
          )}
        </StyledCol>
      </StyledRow>
    </DisableMobileStacking>
  );
};
