import moment from 'moment';

/**
 * Represents a date, or date-time, as agreed upon by all CMS application
 * components.
 *
 * @remarks
 *
 * CMS dates are always in ISO 8601 format, and may be date-only or include a
 * time component. When there is a time component, it must always include the
 * timezone offset. Additionally, CMS dates are never in an invalid state (they
 * either exist as a valid date, or they are `null`).
 *
 * This class helps enforce these rules, allowing for a simpler interface when
 * compared to the native `Date` object. This should make it easier to display
 * and serialize dates in a consistent manner.
 */
export class CmsDate {
  private static readonly localizationDefaults = Object.freeze({
    locale: undefined, // Let the system default apply.
    dateOptions: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions,
    timeOptions: {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
    } as Intl.DateTimeFormatOptions,
  });

  private readonly nativeDate: Date;

  private constructor(
    private datePart: string,
    private timePart?: string,
  ) {
    this.isDateOnly = !timePart;
    this.nativeDate = !timePart
      ? new Date(`${this.datePart}T00:00:00`)
      : new Date(`${this.datePart}T${this.timePart}`);
  }

  /**
   * Indicates whether the object contains a date versus a date-time value.
   */
  public isDateOnly: boolean;

  /**
   * Inspects a string to determine if it is a valid CMS date, returning a new
   * {@link CmsDate} object if it is, or `null` otherwise.
   */
  public static parse(dateString: string | null | undefined): CmsDate | null {
    let parsedDate: CmsDate | null;
    const isIso =
      dateString && moment(dateString, moment.ISO_8601, true).isValid();

    if (isIso) {
      const [date, time] = dateString.split('T');
      const isCmsValid =
        !time || time.endsWith('Z') || time.match(/(\+|-)\d{2}:\d{2}$/);

      parsedDate = isCmsValid ? new CmsDate(date, time) : null;
    } else {
      parsedDate = null;
    }

    return parsedDate;
  }

  /**
   * Creates a new {@link CmsDate} object from a {@link Date} object.
   *
   * @param date - The native `Date` object to convert.
   * @param dateOnly - Indicates whether to discard the time and use only the
   *  calendar date, or to take both the date and time.
   *
   * @returns A new {@link CmsDate}, or `null` if the given date is nullish or
   * invalid.
   *
   * @remarks
   *
   * Note that to ease conversion, the time zone offset for date-time values will
   * always be UTC (or "Zulu" time) internally. This does *not* change the moment
   * in time represented by the object and should be harmless or even beneficial
   * (e.g., for serialization consistency).
   *
   * However, this may be unexpected in the following case: a {@link CmsDate} is
   * initialized from a date-time string with a specific timezone offset, and it
   * is then converted to a `Date` and then back to ISO.
   *
   * In this case, the resulting ISO string will be different from the original,
   * due to the offset, even though the moment in time has not changed. This is
   * not expected to be an issue in this application.
   */
  public static from(
    date: Date | null | undefined,
    dateOnly = false,
  ): CmsDate | null {
    let cmsDate: CmsDate | null;

    if (!date || Number.isNaN(date.getTime())) {
      cmsDate = null;
    } else if (dateOnly) {
      const paddedYear = date.getFullYear().toString().padStart(4, '0');
      const paddedMonth = (date.getMonth() + 1).toString().padStart(2, '0');
      const paddedDay = date.getDate().toString().padStart(2, '0');

      cmsDate = new CmsDate(`${paddedYear}-${paddedMonth}-${paddedDay}`);
    } else {
      const [datePart, timePart] = date.toISOString().split('T');

      cmsDate = new CmsDate(datePart, timePart);
    }

    return cmsDate;
  }

  /**
   * Creates a date-only {@link CmsDate} from a {@link Date} object.
   *
   * @remarks
   *
   * This is simply an alternative to calling {@link from} with `dateOnly = true`,
   * to make calls more readable and/or clarify intent.
   */
  public static fromDateOnly(date: Date | null | undefined): CmsDate | null {
    return CmsDate.from(date, true);
  }

  /**
   * Returns a string representation of the date, or date-time, in the format
   * specified by the current system's locale.
   *
   * @remarks
   *
   * The value returned by this method is suitable, and intended, for display
   * purposes.
   *
   * - For date-only values: this will output the original calendar date passed
   *   to the object, regardless of the current system's time zone. This is in
   *   keeping with the nature of the value (unconcerned with time).
   *
   * - For date-time values: this will output the moment in time converted to the
   *   current time zone, since in this case the time component is relevant.
   */
  public toString(): string {
    const { locale, dateOptions, timeOptions } = CmsDate.localizationDefaults;

    return this.isDateOnly
      ? this.nativeDate.toLocaleDateString(locale, dateOptions)
      : this.nativeDate.toLocaleString(locale, {
          ...dateOptions,
          ...timeOptions,
        });
  }

  /**
   * Returns a date-only representation of the object's value, in the format
   * specified by the current system's locale.
   *
   * @remarks
   *
   * The value returned by this method is suitable, and intended, for display
   * purposes. It behaves in the same way as `toString()`, except that for
   * date-time values, it will return the date portion only.
   */
  public toDateString(): string {
    const { locale, dateOptions } = CmsDate.localizationDefaults;

    return this.nativeDate.toLocaleDateString(locale, dateOptions);
  }

  /**
   * Returns a {@link Date} object representing the object's value.
   *
   * @remarks
   *
   * The value returned by this method is intended for display purposes, e.g.,
   * for binding to components that work against native Date objects.
   *
   * Avoid using this for other purposes, particularly serialization (convert to
   * a {@link CmsDate} object first and use {@link toISO} instead).
   */
  public toDate(): Date {
    return new Date(this.nativeDate);
  }

  /**
   * Returns the date, or date-time, as an ISO 8601 string.
   *
   * @remarks
   *
   * This is intended for serialization purposes.
   *
   * Note that it's possible for this to return a string with the time offset
   * changed to UTC, even if the original string had a different offset. This is
   * an odd case that should not be a problem in this application, see remarks
   * on {@link from}.
   */
  public toISO(): string {
    return this.isDateOnly
      ? this.datePart
      : `${this.datePart}T${this.timePart}`;
  }
}
