import { FC } from 'react';
import { StyleContainer } from './styles';
import { useWeekSwitcherState } from './useWeekSwitcherState';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

type Props = {
  identifier: string;
  leftProps?: React.HTMLProps<HTMLButtonElement>;
  rightProps?: React.HTMLProps<HTMLButtonElement>;
  disableButtons?: boolean;
};

export const WeekSwitcher: FC<Props> = ({
  identifier,
  leftProps,
  rightProps,
  disableButtons = false,
}) => {
  const { state, useDecrement, useIncrement } =
    useWeekSwitcherState(identifier);

  return (
    <StyleContainer>
      <button
        {...leftProps}
        type='button'
        onClick={useDecrement}
        disabled={disableButtons}
      >
        <ChevronLeftIcon />
      </button>
      <p>
        Week:<span>{state.weekNumber}</span>
      </p>
      <button
        {...rightProps}
        type='button'
        onClick={useIncrement}
        disabled={disableButtons}
      >
        <ChevronRightIcon />
      </button>
    </StyleContainer>
  );
};
