export enum RoleType {
  Admin = 'System Administrator',
  Analyst = 'Analyst',
  HarvestCo = 'Harvest Coordinator',
  HarvestManager = 'Harvest Manager',
  FieldRep = 'Field Representative',
  None = 'None',
}

export const RoleHierarchy: Record<RoleType, number> = {
  'System Administrator': 5,
  Analyst: 4,
  'Field Representative': 3,
  'Harvest Manager': 2,
  'Harvest Coordinator': 1,
  None: 0,
};

export interface Role {
  id: number;
  roleName: RoleType;
}
