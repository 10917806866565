import { grey, lighterNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { Table } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const DetailSeparatorStyle = css`
  border-bottom: 1px solid ${grey};
`;

/** Extends a detail separator over the edges of the containing form. */
export const ExtendedDetailSeparatorStyle = css`
  ${DetailSeparatorStyle}

  // Extend separator across the form...
  margin-left: -${FormPadding};
  margin-right: -${FormPadding};
  // ...but make sure the content stays put.
  padding-left: ${FormPadding};
  padding-right: ${FormPadding};
`;

/**
 * A wrapper for {@link GenericTable} that applies the style of a simple detail
 * table, i.e., a table without headers intended for display to the right of a
 * label.
 */
export const SimpleDetailTableStyler = styled(Table)`
  word-break: break-word;
  margin: 10px 0;

  thead > tr,
  tr {
    th:nth-child(1),
    td:nth-child(1) {
      text-align: left;
    }

    th,
    td {
      font-family: KanitSemiBold;
      color: ${lighterNavy};
      text-align: center;
      border: none;
    }

    th:nth-last-child(1),
    td:nth-last-child(1) {
      text-align: right;
    }
  }

  // This is intended for the message shown when there's no items to display.
  div {
    font-family: KanitSemiBold;
    color: ${lighterNavy};
  }
`;
