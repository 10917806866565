import { lighterBlueShade, tableBodyGrey } from 'common/styles/colors';
import { StylableFC } from 'common/types';
import styled from 'styled-components';
import { DetailSeparatorStyle, SimpleDetailTableStyler } from './styles';

/**
 * A wrapper for {@link GenericTable} that applies the styling of a "rich"
 * detail table.
 */
const RichDetailTableStyler = styled(SimpleDetailTableStyler)`
  thead > tr {
    ${DetailSeparatorStyle}
  }

  tr:nth-child(even) {
    background-color: ${lighterBlueShade};
  }

  thead > tr,
  tbody > tr {
    th:first-child,
    td:first-child {
      padding-left: 10px;
    }

    th,
    td {
      color: ${tableBodyGrey};
    }
    td {
      font-family: KanitLight;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 10px;
    }
  }

  // For the message shown when there's no items to display.
  div {
    color: ${tableBodyGrey};
  }
`;

/**
 * A container for {@link GenericTable} that applies the styling of a rich detail
 * table.
 *
 * @remarks
 *
 * The style applied by this is "rich" compared to the {@link SimpleDetailTableStyler},
 * that is, this styles headers and alternating row backgrounds, and is intended
 * for a table that occupies the full width of the form (no label to the left as
 * in other detail controls).
 */
export const RichDetailTableContainer: StylableFC = ({
  children,
  className,
}) => {
  return (
    <RichDetailTableStyler className={className}>
      {children}
    </RichDetailTableStyler>
  );
};
