import { ReactElement, useState } from 'react';
import { DataTableFilters, Option } from '../DataTable/DataTable';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import styled from 'styled-components';
import {
  darkGrey,
  grey,
  tableBodyGrey,
  tableHeaderGrey,
} from 'common/styles/colors';
import { FilterCheckbox } from './FilterCheckbox';
import { SubText } from 'common/styles/texts';
import { boxShadow } from 'common/styles/page';

const FilterRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const FilterRow = styled.button`
  display: flex;
  justify-content: space-between;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid ${grey};
  margin-bottom: 5px;
`;

const FilterCheckboxContainer = styled.div`
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  margin: 0 0 5px 16px;
`;

const Category = styled.div`
  font-family: KanitSemiBold;
  font-size: 16px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: ${darkGrey};
`;

const TextInput = styled.input`
  height: 32px;
  box-shadow: ${boxShadow};
  border: 1px solid ${tableHeaderGrey};
  border-radius: 4px;
  padding: 2px 8px;
`;

const SubHeader = styled(SubText)`
  color: ${tableBodyGrey};
  padding-left: 16px;
`;

interface FilterCategoryProps {
  filterCategory: DataTableFilters;
  userInput: { value: string; category: string }[];
  addCheckboxFilter: (filterValue: string, filterCategory: string) => void;
  addTextFilter: (filterValue: string, filterCategory: string) => void;
  removeFilter: (filterValue: string) => void;
  subHeader?: string;
}

export const FilterCategory = ({
  filterCategory,
  userInput,
  addCheckboxFilter,
  addTextFilter,
  removeFilter,
  subHeader,
}: FilterCategoryProps): ReactElement => {
  const [optionsExpanded, setOptionsExpanded] = useState(false);
  const [textInputValue, setTextInputValue] = useState('');
  const showCheckBox: boolean =
    optionsExpanded &&
    !!filterCategory?.options &&
    filterCategory?.options?.length !== 0;
  const showInput: boolean =
    optionsExpanded &&
    !!filterCategory?.options &&
    filterCategory?.options?.length === 0;
  const showErrMsg: boolean = optionsExpanded && !filterCategory;
  const includeSeparator = (
    options: Option[] | Option[][],
  ): options is Option[][] => Array.isArray(options[0]);

  const options = filterCategory.options ?? [];

  const renderOptions = (option: Option, separator = false) => (
    <FilterCheckboxContainer key={option.value}>
      <FilterCheckbox
        filterLabel={option.label}
        filterValue={option.value}
        filterCategory={filterCategory.category.key}
        isChecked={
          !!userInput.find(selection => selection.value === option.value)
        }
        addCheckboxFilter={addCheckboxFilter}
        removeFilter={removeFilter}
        separator={separator}
      />
    </FilterCheckboxContainer>
  );

  const optionsElements = includeSeparator(options)
    ? options
        .filter(group => group.length > 0)
        .map((group, i, groups) =>
          group.map((option, j) => {
            // only display separator in between groups and not below the final group
            const renderSeparator =
              j + 1 === group.length && i + 1 !== groups.length;
            return renderOptions(option, renderSeparator);
          }),
        )
        .flat()
    : options.map(option => renderOptions(option));

  return (
    <FilterRowContainer>
      <FilterRow
        type='button'
        onClick={() => setOptionsExpanded(!optionsExpanded)}
      >
        <Category>{filterCategory.category.label}</Category>
        {optionsExpanded ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </FilterRow>
      {subHeader && (showCheckBox || showInput) && (
        <SubHeader>{subHeader}</SubHeader>
      )}
      {showCheckBox && optionsElements}
      {showInput && (
        <TextInput
          type='text'
          value={
            userInput.find(
              input => input.category === filterCategory.category.key,
            )
              ? textInputValue
              : ''
          }
          onChange={event =>
            setTextInputValue((event.target as HTMLInputElement).value)
          }
          onInput={event => {
            addTextFilter(
              (event.target as HTMLInputElement).value,
              filterCategory.category.key,
            );
          }}
        />
      )}
      {showErrMsg && <p>There was an issue showing options</p>}
    </FilterRowContainer>
  );
};
