import { useGetAreasQuery } from 'common/api/areaApi';
import { useGetCountiesQuery } from 'common/api/countyApi';
import { useGetDistrictsQuery } from 'common/api/districtApi';
import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { handleError } from 'common/api/handleError';
import { useGetUsersByRolesQuery } from 'common/api/userApi';
import { useGetVarietiesQuery } from 'common/api/varietyApi';
import {
  DetailDropDown,
  DetailText,
  Option,
} from 'common/components/DetailControls';
import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import { GrowerBlock, RoleHierarchy } from 'common/models';
import { useRbac } from 'features/rbac';
import { FC, useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import {
  createAreaOptions,
  createCountyOptions,
  createDistrictOptions,
  createEvaluatorOptions,
  createVarietyDependencies,
  createVarietyOptions,
} from 'utils/formValues/blockDetails';
import { getUserFullName } from 'utils/helperFunction';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))``;

const StyledCol = styled(Col)`
  .form-label {
    font-family: 'KanitMedium';
  }
`;

const LastCol = styled(Col)`
  @media (min-width: 768px) {
    flex: none;
    width: 50%;
  }
`;

function createOption(optionId: number, optionLabel: string): Option {
  return { id: optionId, label: optionLabel };
}

function findOptionInList<T extends { id: number }>(
  optionSelection: Option,
  list: T[],
): T | undefined {
  return list.find(item => item.id === optionSelection.id);
}

export const BlockDetailsFields: FC<{
  block: BlockDetails<WithPartialSeasonData>;
}> = ({ block }) => {
  const history = useHistory();

  const { userHasPermission } = useRbac();
  const canEditField = userHasPermission('block:admin-edit');

  const {
    control,
    formState: { errors, dirtyFields },
    getValues,
    register,
    setError,
    setValue,
    watch,
  } = useFormContext<GrowerBlock>();

  // fetch dropdown data
  const {
    data: varieties = [],
    isLoading: isLoadingVariety,
    error: varietyError,
  } = useGetVarietiesQuery();
  const {
    data: evaluators = [],
    isLoading: isLoadingFieldRep,
    error: fieldRepError,
  } = useGetUsersByRolesQuery({
    roleIds: [RoleHierarchy['Field Representative']],
  });
  const {
    data: counties = [],
    isLoading: isLoadingCounty,
    error: countyError,
  } = useGetCountiesQuery();
  const {
    data: districts = [],
    isLoading: isLoadingDistrict,
    error: districtError,
  } = useGetDistrictsQuery();
  const {
    data: areas = [],
    isLoading: isLoadingArea,
    error: areaError,
  } = useGetAreasQuery();

  // create watchers
  const watchVariety = watch('variety');
  const watchPrimaryEval = watch('primaryEvaluator');
  const watchSecondaryEval = watch('secondaryEvaluator');

  // create dropdown options
  const varietyOptions = useMemo(
    () => createVarietyOptions(varieties),
    [varieties],
  );
  const initialVarietyOption = useMemo(() => block.variety, [block.variety]);
  const { subVarietyOptions, rootstockOptions } = useMemo(
    () => createVarietyDependencies(watchVariety ?? null, varieties),
    [watchVariety, varieties],
  );
  const primaryEvaluatorOptions = useMemo(
    () => createEvaluatorOptions(evaluators),
    [evaluators],
  );
  const secondaryEvaluatorOptions = useMemo(() => {
    const evalOptions = createEvaluatorOptions(evaluators);
    return canEditField
      ? evalOptions
      : evalOptions.filter(
          evaluator => evaluator.id !== block.primaryEvaluator?.id,
        );
  }, [canEditField, evaluators, block.primaryEvaluator?.id]);
  const countyOptions = useMemo(
    () => createCountyOptions(counties),
    [counties],
  );
  const districtOptions = useMemo(
    () => createDistrictOptions(districts),
    [districts],
  );
  const areaOptions = useMemo(() => createAreaOptions(areas), [areas]);

  // watch variety changes
  useEffect(() => {
    if (dirtyFields.variety && watchVariety?.id !== initialVarietyOption?.id) {
      setValue('subVariety', null);
      setValue('rootstock', null);
    }
  }, [initialVarietyOption, setValue, watchVariety, dirtyFields]);

  // watch primary evaluator change
  useEffect(() => {
    const { secondaryEvaluator } = getValues();
    if (secondaryEvaluator?.id === watchPrimaryEval?.id)
      setValue('secondaryEvaluator', null);
  }, [getValues, setValue, watchPrimaryEval]);

  // watch second evaluator change
  useEffect(() => {
    const { primaryEvaluator } = getValues();
    if (primaryEvaluator && primaryEvaluator?.id === watchSecondaryEval?.id) {
      setValue('primaryEvaluator', null);
      setError('primaryEvaluator', {
        type: 'required',
        message: Constants.errorMessages.FIELD_REQUIRED,
      });
    }
  }, [getValues, setError, setValue, watchSecondaryEval]);

  /* Remove ref through destructuring, otherwise react will attempt to assign
  a forward ref and throw an error */
  const { ref: varietyRef, ...varietyProps } = register('variety');
  const { ref: subVarietyRef, ...subVarietyProps } = register('subVariety');
  const { ref: rootstockRef, ...rootstockProps } = register('rootstock');
  const { ref: primaryEvaluatorRef, ...primaryEvaluatorProps } =
    register('primaryEvaluator');
  const { ref: secondaryEvaluatorRef, ...secondaryEvaluatorProps } =
    register('secondaryEvaluator');
  const { ref: countyRef, ...countyProps } = register('county');
  const { ref: districtRef, ...districtProps } = register('district');
  const { ref: areaRef, ...areaProps } = register('area');

  useEffect(() => {
    const errorArray = [
      varietyError,
      fieldRepError,
      countyError,
      districtError,
      areaError,
    ];
    errorArray.forEach((error, index, array) => {
      if (error) {
        handleError(
          error,
          `Unable to load ${Object.keys(array)[index].replace(
            'Error',
            '',
          )}. Returning to grower list`,
        );
        history.replace(Constants.routes.GROWERS_LIST);
      }
    });
  }, [
    areaError,
    countyError,
    districtError,
    fieldRepError,
    history,
    varietyError,
  ]);

  return (
    <>
      <StyledStack>
        <StyledRow>
          <StyledCol>
            <DetailDropDown
              label='Variety'
              defaultValue={
                block.variety
                  ? createOption(
                      block.variety.id,
                      `${block.variety.varietyName} | ${block.variety.varietyCode}`,
                    )
                  : null
              }
              textValue={
                block.variety
                  ? `${block.variety.varietyName} | ${block.variety.varietyCode}`
                  : ''
              }
              {...varietyProps}
              options={varietyOptions}
              optionTransformer={opt => findOptionInList(opt, varieties)}
              formControl={control}
              isLoading={isLoadingVariety}
              editable={canEditField}
            />
          </StyledCol>
          <Col>
            <DetailDropDown
              label='Sub Variety'
              defaultValue={
                block.subVariety
                  ? createOption(
                      block.subVariety.id,
                      block.subVariety.subVarietyCode,
                    )
                  : null
              }
              textValue={block.subVariety?.subVarietyCode || ''}
              {...subVarietyProps}
              options={subVarietyOptions}
              optionTransformer={opt =>
                findOptionInList(opt, watchVariety?.subVarieties ?? [])
              }
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Lot #'
              name='lotNumber'
              validation={errors.lotNumber?.message}
              editable={canEditField}
            />
          </StyledCol>
          <Col>
            <DetailDropDown
              label='Rootstock'
              defaultValue={
                block.rootstock
                  ? createOption(
                      block.rootstock.id,
                      block.rootstock.rootstockCode,
                    )
                  : null
              }
              textValue={block.rootstock?.rootstockCode || ''}
              {...rootstockProps}
              options={rootstockOptions}
              optionTransformer={opt =>
                findOptionInList(opt, watchVariety?.rootstocks ?? [])
              }
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailDropDown
              label='Field Rep.'
              defaultValue={
                block.primaryEvaluator
                  ? createOption(
                      block.primaryEvaluator.id,
                      getUserFullName({
                        ...block.primaryEvaluator,
                        newEmail: null,
                        profilePicture: null,
                      }),
                    )
                  : null
              }
              textValue={
                block.primaryEvaluator
                  ? getUserFullName({
                      ...block.primaryEvaluator,
                      newEmail: null,
                      profilePicture: null,
                    })
                  : ''
              }
              {...primaryEvaluatorProps}
              options={primaryEvaluatorOptions}
              optionTransformer={opt => findOptionInList(opt, evaluators)}
              formControl={control}
              isLoading={isLoadingFieldRep}
              editable={canEditField}
            />
          </StyledCol>
          <Col>
            <DetailDropDown
              label='2nd Field Rep.'
              defaultValue={
                block.secondaryEvaluator
                  ? createOption(
                      block.secondaryEvaluator.id,
                      getUserFullName({
                        ...block.secondaryEvaluator,
                        newEmail: null,
                        profilePicture: null,
                      }),
                    )
                  : null
              }
              textValue={
                block.secondaryEvaluator
                  ? getUserFullName({
                      ...block.secondaryEvaluator,
                      newEmail: null,
                      profilePicture: null,
                    })
                  : ''
              }
              {...secondaryEvaluatorProps}
              options={secondaryEvaluatorOptions}
              optionTransformer={opt => findOptionInList(opt, evaluators)}
              formControl={control}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Acres'
              name='acres'
              validation={errors.acres?.message}
              editable={canEditField}
            />
          </StyledCol>
          <Col>
            <DetailText
              label='Year Planted'
              name='yearPlanted'
              validation={errors.yearPlanted?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailText
              label='Trees (No.)'
              name='trees'
              validation={errors.trees?.message}
            />
          </StyledCol>
          <Col>
            <DetailText
              label='Latitude'
              name='latitude'
              validation={errors.latitude?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <StyledCol>
            <DetailDropDown
              label='District'
              defaultValue={
                block.district
                  ? createOption(
                      block.district.id,
                      block.district.districtId.toString(),
                    )
                  : null
              }
              textValue={
                block.district?.districtId
                  ? block.district.districtId.toString()
                  : ''
              }
              {...districtProps}
              options={districtOptions}
              optionTransformer={opt => findOptionInList(opt, districts)}
              formControl={control}
              isLoading={isLoadingDistrict}
            />
          </StyledCol>
          <Col>
            <DetailText
              label='Longitude'
              name='longitude'
              validation={errors.longitude?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='Area'
              defaultValue={
                block.area
                  ? createOption(block.area.id, block.area.areaName)
                  : null
              }
              textValue={block.area?.areaName || ''}
              {...areaProps}
              options={areaOptions}
              optionTransformer={opt => findOptionInList(opt, areas)}
              formControl={control}
              isLoading={isLoadingArea}
            />
          </Col>
          <Col>
            <DetailText
              label='Notes'
              name='notes'
              validation={errors.notes?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <LastCol>
            <DetailDropDown
              label='County'
              defaultValue={
                block.county
                  ? createOption(block.county.id, block.county.countyName)
                  : null
              }
              textValue={block.county?.countyName || ''}
              {...countyProps}
              options={countyOptions}
              optionTransformer={opt => findOptionInList(opt, counties)}
              formControl={control}
              isLoading={isLoadingCounty}
            />
          </LastCol>
        </StyledRow>
      </StyledStack>
    </>
  );
};
