import { useAppSelector } from 'app/redux';
import { handleError } from 'common/api/handleError';
import { useGetVarietalSummaryQuery } from 'common/api/varietyApi';
import {
  DataTable,
  DataTableMobile,
  DataTableProps,
  MobileStyling,
} from 'common/components/DataTable';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { FC, useEffect, useState } from 'react';
import {
  useVarietalSumTableData,
  VarietalSumTableItem,
} from '../hooks/useVarietalSumTableData';
import { useVarietalSumTableDataMobile } from '../hooks/useVarietalSumTableDataMobile';
import { VarietyDetailModal } from './VarietyDetailModal';

export const VarietalSumTable: FC<{
  title?: string;
}> = () => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [variety, setVariety] = useState<VarietalSumTableItem>(
    {} as VarietalSumTableItem,
  );
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const {
    data,
    isLoading,
    isFetching,
    error: getVarietalSummaryError,
  } = useGetVarietalSummaryQuery({
    // The component only renders when season exists so we safely assert the value here.
    seasonId: selectedSeason?.id as number,
  });

  const {
    columns,
    data: { rows: tableData },
  } = useVarietalSumTableData(data ?? []);

  const mobileColumns = useVarietalSumTableDataMobile();

  const tableProps: DataTableProps<VarietalSumTableItem> = {
    title: 'Varietal Summary',
    isLoading,
    isFetching,
    columns: isMobile ? mobileColumns : columns,
    data: tableData,
    loadError: !!getVarietalSummaryError,
  };

  useEffect(() => {
    if (getVarietalSummaryError) {
      handleError(getVarietalSummaryError, 'Unable to load summary data.');
    }
  }, [getVarietalSummaryError]);

  return (
    <>
      {isMobile ? (
        <>
          {modalOpen && (
            <VarietyDetailModal
              title='Varietal Summary'
              fields={variety}
              toggleOpenState={setModalOpen}
              modalState={modalOpen}
            />
          )}
          <MobileStyling>
            <DataTableMobile<VarietalSumTableItem>
              {...tableProps}
              onRowClick={vrty => {
                setVariety(vrty);
                setModalOpen(true);

                return undefined;
              }}
            />
          </MobileStyling>
        </>
      ) : (
        <DataTable<VarietalSumTableItem> {...tableProps} />
      )}
    </>
  );
};
