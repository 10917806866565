import { FC } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { CancelButton, SubmitButton } from 'common/styles/button';
import { LoadingButton } from 'common/components/LoadingButton';
import { useAppSelector } from 'app/redux';
import { selectConfirmationModalState } from './slice';
import { useConfirmationModal } from './useConfirmationModal';
import {
  darkNavy,
  lighterNavy,
  orange,
  secondaryWhite,
} from 'common/styles/colors';

// Based on the styled-components docs at https://styled-components.com/docs/api#caveat-with-function-components,
// in order for typechecking to work correctly with styled components that extend a function components, we need
// to define the component and it's type first as done here.
const BootstrapModal: FC<ModalProps> = ({ children, ...rest }) => (
  <Modal {...rest}>{children}</Modal>
);

const StyledModal = styled(BootstrapModal)`
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-family: Kanit;

  & .modal-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
  }

  & .modal-content {
    width: min(500px, 100vw);
    display: flex;
    flex-grow: 1;
    padding: 50px 20px 20px 20px;
    background-color: ${darkNavy};
    border-radius: 8px;
  }

  & .modal-title {
    padding: 0 40px;
    color: ${orange};
  }

  & .modal-body {
    padding: 10px 40px;
    font-family: KanitLight;
    font-weight: 300;
    font-size: 20px;
    line-height: 134%;
    color: ${secondaryWhite};
  }

  & .modal-footer {
    padding: 0;
    margin-top: 30px;
    gap: 10px;
    border: none;
    justify-content: center;
  }

  button {
    flex-grow: 1;
    flex-shrink: 1;
    font-weight: 500;
    font-size: 18px;
    line-height: 148%;
    padding: 6px;
    border: none;
  }

  button:first-of-type {
    color: ${lighterNavy};
    background-color: ${secondaryWhite};
  }

  button:last-of-type {
    background-color: ${orange};
  }
`;

export const ConfirmationModal: FC = () => {
  const { show, loading, message, confirmButtonLabel, declineButtonLabel } =
    useAppSelector(selectConfirmationModalState);

  const { declineModal, confirmModal } = useConfirmationModal();

  return (
    <StyledModal show={show} onHide={declineModal}>
      <Modal.Title>{message}</Modal.Title>
      <Modal.Body>Do you want to continue?</Modal.Body>
      <Modal.Footer>
        <CancelButton onClick={declineModal}>{declineButtonLabel}</CancelButton>
        <LoadingButton
          as={SubmitButton}
          loading={loading}
          onClick={confirmModal}
        >
          {confirmButtonLabel}
        </LoadingButton>
      </Modal.Footer>
    </StyledModal>
  );
};
