import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ArrowBackIos } from '@mui/icons-material';
import { grey, white } from 'common/styles/colors';

interface props {
  to: string;
}

const StyledLink = styled(Link)`
  color: ${white};
  text-decoration: none;
  font-size: 20px;
  line-height: 134%;
  letter-spacing: 0.0075em;
  height: fit-content;

  &:hover {
    color: ${grey};
  }
`;

const BackButton: React.FC<props> = ({ to }): JSX.Element => {
  return (
    <StyledLink to={to}>
      <ArrowBackIos style={{ paddingBottom: 5 }} />
      <span>Back</span>
    </StyledLink>
  );
};

export default BackButton;
