import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type MapParameters = {
  latLong: { lat: number; lng: number };
  blockId: string;
};

export type MapsModalState = {
  showModal: boolean;
  mapParams: MapParameters[] | null;
};

export const mapsModalSliceName = 'gmapsModalSliceName';

export const mapsModalSlice = createSlice({
  name: mapsModalSliceName,
  initialState: {
    showModal: false,
    mapParams: null,
  },
  reducers: {
    setShowModal: (state: MapsModalState) => {
      const { showModal } = state;
      state.showModal = !showModal;
    },
    setMapsParams: (
      state: MapsModalState,
      action: PayloadAction<MapParameters[]>,
    ) => {
      state.mapParams = action.payload;
    },
  },
});
