import * as yup from 'yup';
import { Constants } from '../constants';

const twoDecimalsRegEx = /^(?:0|\d*\.\d{1,2})$/;

export const dropdownRequired = yup
  .object()
  .nullable()
  .required(Constants.errorMessages.FIELD_REQUIRED);

export const dropdownOptional = yup.object().nullable();

export const nullableNumberSchema = yup
  .number()
  .nullable()
  .transform((_, val) => (!val ? null : Number(val)))
  .typeError(Constants.errorMessages.MUST_BE_A_NUMBER);

export const twoDecimalNumbersSchema = yup
  .number()
  .nullable()
  .moreThan(0, Constants.errorMessages.POSITIVE_NUMBER_REQUIRED)
  .test('is-decimal', 'Only two decimals allowed.', val => {
    const value = val?.toString();
    if (value?.includes('.')) return !!value?.match(twoDecimalsRegEx);
    return true;
  })
  .typeError(Constants.errorMessages.NUMBER_REQUIRED);

export const numberSchemaRequired = yup
  .number()
  .nullable()
  .required(Constants.errorMessages.FIELD_REQUIRED)
  .moreThan(0, Constants.errorMessages.POSITIVE_NUMBER_REQUIRED)
  .integer(Constants.errorMessages.INTEGER_REQUIRED)
  .typeError(Constants.errorMessages.NUMBER_REQUIRED);

export const nullableStringSchema = yup.string().nullable().trim();

export const stringSchemaRequired = yup
  .string()
  .nullable()
  .required(Constants.errorMessages.FIELD_REQUIRED)
  .trim();

export const yearSchema = nullableNumberSchema
  .integer(Constants.errorMessages.INTEGER_REQUIRED)
  .positive(Constants.errorMessages.POSITIVE_NUMBER_REQUIRED)
  .test(
    'enforce-year-length',
    Constants.errorMessages.YEAR_LENGTH_MISMATCH,
    val =>
      val === null ||
      val === undefined ||
      val.toString().length === 4 ||
      val.toString().length === 2,
  )
  .max(new Date().getFullYear(), Constants.errorMessages.CURRENT_YEAR_OR_LESS);

export const dateSchema = yup.date().nullable();

export const dateSchemaRequired = yup
  .date()
  .nullable()
  .required(Constants.errorMessages.FIELD_REQUIRED);

export const percentageSchema = nullableNumberSchema
  .min(0, Constants.errorMessages.ZERO_OR_GREATER)
  .max(100, 'Percentage must be less than or equal to 100.');
