import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Constants } from 'utils/constants';
import FormPrompt from 'common/components/FormPrompt';
import { LoadingButton } from 'common/components/LoadingButton';
import { AuthPagesMainBtn } from 'common/styles/button';
import { white } from 'common/styles/colors';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

export type FormData = {
  newPassword: string | null;
  confirmPassword: string | null;
};

type Props = {
  onSubmit: (data: FormData) => void;
  isSuccessful?: boolean;
  hideCancel?: boolean;
};

const schema: yup.SchemaOf<FormData> = yup.object().shape({
  newPassword: yup
    .string()
    .required(Constants.errorMessages.NEW_PASSWORD_REQUIRED)
    .min(8, Constants.errorMessages.PASSWORD_LENGTH)
    .matches(
      Constants.patterns.LOWERCASE_REGEX,
      Constants.errorMessages.PASSWORD_LOWERCASE,
    )
    .matches(
      Constants.patterns.UPPERCASE_REGEX,
      Constants.errorMessages.PASSWORD_UPPERCASE,
    )
    .matches(
      Constants.patterns.SYMBOL_REGEX,
      Constants.errorMessages.PASSWORD_SPECIAL_CHARACTER,
    )
    .matches(
      Constants.patterns.DIGIT_REGEX,
      Constants.errorMessages.PASSWORD_NUMBER,
    ),

  confirmPassword: yup
    .string()
    .required(Constants.errorMessages.CONFIRM_PASSWORD_REQUIRED)
    .oneOf(
      [yup.ref('newPassword')],
      Constants.errorMessages.PASSWORD_MUST_MATCH,
    ),
});

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0;
`;

const CancelBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: ${white};
  margin-left: 30px;

  span {
    color: ${white};
    font-family: KanitLight;
    font-size: 20px;
    line-height: 134%;
    letter-spacing: 0.0075em;
    text-decoration: underline;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    height: 18px;
    width: 18px;
    margin-left: 10px;
  }
`;

export const ResetPasswordForm: FC<Props> = ({
  onSubmit,
  isSuccessful,
  hideCancel,
}) => {
  const {
    formState: { errors, isDirty, isSubmitting, isValid },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      newPassword: null,
      confirmPassword: null,
    },
  });

  const history = useHistory();
  const handleClick = () => {
    history.push('/auth/login');
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label htmlFor='newPassword'>New Password (required)</Form.Label>
        <Form.Control
          id='newPassword'
          type='password'
          {...register('newPassword')}
          placeholder='New password'
          isInvalid={!!errors.newPassword}
        />
        <Form.Control.Feedback type='invalid' role='alert'>
          <>{errors.newPassword?.message}</>
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label htmlFor='confirmPassword' style={{ marginTop: 12 }}>
          Confirm Password (required)
        </Form.Label>
        <Form.Control
          id='confirmPassword'
          type='password'
          {...register('confirmPassword')}
          placeholder='Re-enter password'
          isInvalid={!!errors.confirmPassword}
        />
        <Form.Control.Feedback type='invalid' role='alert'>
          <>{errors.confirmPassword?.message}</>
        </Form.Control.Feedback>
      </Form.Group>
      <BtnContainer>
        <LoadingButton
          type='submit'
          as={AuthPagesMainBtn}
          disabled={!isValid}
          loading={isSubmitting}
        >
          NEXT
        </LoadingButton>
        {!hideCancel && (
          <CancelBtn type='button' onClick={handleClick}>
            <span>Cancel</span>
            <div>X</div>
          </CancelBtn>
        )}
      </BtnContainer>
      <FormPrompt
        isDirty={isDirty}
        isSubmitting={isSubmitting}
        isSuccessful={isSuccessful}
      />
    </Form>
  );
};
