import {
  CsvHeaders,
  emptyCellValue,
} from 'common/components/CsvButton/CsvButton';
import { Week } from 'utils/dateTimeHelpers';
import { PackPlanRowCombo } from '../components/PackPlanTable/PackPlanTable';

export const enum CsvPlanHeaderKeys {
  pHouseCode = 'pHouseCode',
  variety = 'variety',
  market = 'market',
  size = 'size',
  day = 'number/number', // Placeholder for dynamically generated columns
  totalRequested = 'totalRequested',
  totalPicked = 'totalPicked',
}

export type PackPlanCsvData = {
  headers: CsvHeaders<CsvPlanHeaderKeys>[];
  rows: CsvComboRows;
};

export type CsvComboRows = Record<CsvPlanHeaderKeys, string>[];

const initialHeaders: CsvHeaders<CsvPlanHeaderKeys>[] = [
  { label: 'Packing House', key: CsvPlanHeaderKeys.pHouseCode },
  { label: 'Variety', key: CsvPlanHeaderKeys.variety },
  { label: 'Market', key: CsvPlanHeaderKeys.market },
  { label: 'Size', key: CsvPlanHeaderKeys.size },
];

const finalHeaders: CsvHeaders<CsvPlanHeaderKeys>[] = [
  { label: 'Total Sales', key: CsvPlanHeaderKeys.totalRequested },
  { label: 'Total Pick', key: CsvPlanHeaderKeys.totalPicked },
];

export const prepareCsvPlanData = (
  week: Week,
  pHouseCode: string,
  rows: PackPlanRowCombo[],
  dayTotals: {
    date: string;
    total: number;
  }[],
  reqPickedTotals: {
    totalRequested: number;
    totalPicked: number;
  },
) => {
  let headers = [...initialHeaders];
  const comboRows = [] as CsvComboRows;
  const totalsRow = {
    [`${CsvPlanHeaderKeys.pHouseCode}`]: pHouseCode,
    [`${CsvPlanHeaderKeys.variety}`]: emptyCellValue,
    [`${CsvPlanHeaderKeys.market}`]: emptyCellValue,
    [`${CsvPlanHeaderKeys.size}`]: 'Totals:',
  } as Record<string, string>;

  rows.forEach(row => {
    const comboRow = {
      [`${CsvPlanHeaderKeys.pHouseCode}`]: pHouseCode,
      [`${CsvPlanHeaderKeys.variety}`]: row.variety.varietyCode,
      [`${CsvPlanHeaderKeys.market}`]: row.market,
      [`${CsvPlanHeaderKeys.size}`]: row.size.value,
    } as Record<string, string>;
    row.binRequests.forEach((req, index) => {
      const day = week.weekdays[index];
      comboRow[`${day.month}/${day.day}`] = req.bins.toString();
    });
    comboRow.totalRequested = row.totalRequested?.toString() || '0';
    comboRow.totalPicked = row.totalPicked?.toString() || '0';
    comboRows.push(comboRow);
  });

  dayTotals.forEach(obj => {
    totalsRow[`${obj.date}`] = obj.total.toString();
  });
  totalsRow.totalRequested = reqPickedTotals.totalRequested?.toString() || '0';
  totalsRow.totalPicked = reqPickedTotals.totalPicked?.toString() || '0';
  comboRows.push(totalsRow);

  week.weekdays.forEach(day => {
    headers.push({
      label: `${day.weekday} ${day.month}/${day.day}`,
      // Enforcing type because bin request date columns are generated dynamically
      key: `${day.month}/${day.day}` as CsvPlanHeaderKeys.day,
    });
  });

  headers = [...headers.concat(finalHeaders)];

  return {
    headers,
    rows: comboRows,
  };
};
