import { useAppDispatch, useAppSelector } from 'app/redux';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import { Season } from 'common/models/season';
import { selectBaseStyling } from 'features/pick-schedule-views/components/WizardComponents/wizardStyles';
import { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createSeasonOptions } from 'utils/formValues/dropdownOptions';
import { seasonSlice, seasonSliceName, SeasonState } from './seasonSlice';

type SelectedSeason = {
  season: WizardDropdownOption<Season> | null;
};

export const SeasonSelector: FC<{ isLoading: boolean }> = ({ isLoading }) => {
  const dispatch = useAppDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { availableSeasons, selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const seasonOptions: WizardDropdownOption<Season>[] = useMemo(
    () => createSeasonOptions(availableSeasons),
    [availableSeasons],
  );

  const { control, reset, watch } = useForm<SelectedSeason>({
    defaultValues: { season: null },
  });

  // Reset form to access the default season
  // as it is not yet available on first load.
  useEffect(() => {
    if (selectedSeason && isFirstLoad) {
      setIsFirstLoad(false);
      reset({ season: { label: selectedSeason.year, value: selectedSeason } });
    }
  }, [selectedSeason, dispatch, reset, isFirstLoad]);

  // Set season globally.
  useEffect(() => {
    const subscription = watch(data => {
      // Check if it is first load to avoid an infinite loop.
      if (data.season?.value && !isFirstLoad) {
        // Enforce type as useForm is causing type issue.
        dispatch(seasonSlice.actions.setSeason(data.season.value as Season));
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch, isFirstLoad, watch]);

  return (
    <WizardDropdown<Season>
      name='season'
      formControl={control}
      options={seasonOptions}
      isLoading={isLoading}
      styles={selectBaseStyling}
      maxWidth='100px'
    />
  );
};
