import { PayrollTableRow } from 'features/harvest-payroll/utils/payrollDataTypes';
import { createRowKey } from 'features/harvest-payroll/utils/payrollPageUtils';
import { FC } from 'react';
import { PayrollTableLine } from './PayrollTableLine';

export const EmptyRow: FC<{ rowData: PayrollTableRow }> = ({ rowData }) => (
  <PayrollTableLine
    key={createRowKey(rowData.metadata)}
    row={rowData}
    changeBackground={false}
  />
);
