import * as yup from 'yup';
import {
  dropdownRequired,
  nullableStringSchema,
  numberSchemaRequired,
} from './common';

const contractorAndBinsAssignment = yup.object({
  contractor: dropdownRequired,
  bins: numberSchemaRequired,
});

const haulerAssignment = yup.object({
  hauler: dropdownRequired,
});

export const crewAssignmentSchema = yup.object({
  coordinator: dropdownRequired,
  contractorsAndBins: yup
    .array()
    .of(contractorAndBinsAssignment)
    .min(1, 'At least one crew is required.'),
  haulers: yup.array().of(haulerAssignment),
  notes: nullableStringSchema,
});
