import { FC, useEffect } from 'react';
import { PayrollHeader } from '../components/PayrollHeader/PayrollHeader';
import {
  useGetHarvestCrewsQuery,
  useGetPayrollDataByContractorQuery,
} from 'common/api/harvestDataApi';
import { handleError } from 'common/api/handleError';
import { mobile } from 'common/styles/breakpoints';
import { blueShadeHeader } from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';
import {
  WeekSwitcherIds,
  useWeekSwitcherState,
} from 'common/components/WeekSwitcher/useWeekSwitcherState';
import { skipToken } from '@reduxjs/toolkit/query';
import { PayrollBody } from '../components/PayrollBody/PayrollBody';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { getISODateOnlyString } from 'utils/dateTimeHelpers';
import { PayrollPageState, payrollSliceName } from '../PayrollSlice';
import { useAppSelector } from 'app/redux';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: ${boxShadow};
  padding: 20px 0;
  border-radius: 8px;
  width: 100%;

  h1 {
    margin: 0;
    font-family: KanitLight;
    font-size: 32px;
    color: ${blueShadeHeader};
  }

  h4 {
    margin: 0;
    padding-top: 10px;
    font-family: KanitSemiBold;
    font-size: 24px;
    color: ${blueShadeHeader};
  }

  @media (max-width: ${mobile}) {
    padding: 5px;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  with: 100%;
  height: 100px;
`;

export const HarvestPayrollView: FC = () => {
  const {
    data: crewData,
    isLoading: isLoadingCrews,
    error: crewError,
  } = useGetHarvestCrewsQuery();
  const { state: week } = useWeekSwitcherState(WeekSwitcherIds.HarvPayroll);
  const { selectedContractor } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );
  const {
    data: payrollData,
    error: payrollDataError,
    isLoading,
    isFetching,
  } = useGetPayrollDataByContractorQuery(
    selectedContractor
      ? {
          contractorId: selectedContractor.id,
          startDate: getISODateOnlyString(week.startDate),
        }
      : skipToken,
    { refetchOnMountOrArgChange: true },
  );

  useEffect(() => {
    if (crewError) handleError(crewError, 'Unable to load contractors.');
    if (payrollDataError)
      handleError(
        payrollDataError,
        'Unable to fetch harvesting and hauling information.',
      );
  }, [crewError, payrollDataError]);

  return (
    <StyledContainer>
      <PayrollHeader
        payrollData={payrollData}
        contractors={crewData?.fieldLaborContractors}
        isLoadingContractors={isLoadingCrews}
        isLoadingPayrollData={isLoading || isFetching}
      />
      {selectedContractor && (
        <>
          <WithLoadingOverlay isLoading={isLoading || isFetching}>
            {payrollData && payrollData.picks.length ? (
              <PayrollBody payrollData={payrollData} />
            ) : (
              <NoData>No payroll information was found for this week.</NoData>
            )}
          </WithLoadingOverlay>
        </>
      )}
    </StyledContainer>
  );
};
