import { FC, useEffect } from 'react';
import { CrewAssignmentWizard } from '../components/CrewAssignmentWizard/CrewAssignmentWizard';
import { useHistory, useParams } from 'react-router-dom';
import { useGetScheduledPickByIdQuery } from 'common/api/pickSchedulingApi';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { Constants } from 'utils/constants';

export const CrewAssignmentWizardView: FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, isFetching, error } = useGetScheduledPickByIdQuery({
    id: parseInt(id, 10),
  });

  useEffect(() => {
    if (error) {
      handleError(error, 'Unable to load scheduled pick data.');
      history.replace(`${Constants.routes.PICK_SCHEDULE}/${id}`);
    }
  }, [error, history, id]);

  return (
    <WithLoadingOverlay isLoading={isFetching}>
      {data && <CrewAssignmentWizard scheduledPick={data} />}
    </WithLoadingOverlay>
  );
};
