import GroupIcon from '@mui/icons-material/Group';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import { Constants } from 'utils/constants';

export const links = [
  {
    id: 1,
    icon: GroupIcon,
    label: 'Users',
    path: '/users',
    subLinks: [
      {
        id: 11,
        label: 'Add User',
        path: '/users/create-user',
      },
    ],
  },
  {
    id: 2,
    icon: AgricultureIcon,
    label: 'Growers',
    path: '/growers',
  },
  {
    id: 3,
    icon: NaturePeopleIcon,
    label: 'Evaluations & Estimates',
    path: '/evaluations-estimates',
  },
  {
    id: 4,
    icon: AppRegistrationIcon,
    label: 'Pack Plan',
    path: Constants.routes.PACK_PLAN,
  },
  {
    id: 5,
    icon: DateRangeIcon,
    label: 'Pick Schedule',
    path: Constants.routes.PICK_SCHEDULE,
  },
  {
    id: 6,
    icon: RequestQuoteIcon,
    label: 'Harvest Payroll',
    path: Constants.routes.HARVEST_PAYROLL,
  },
];
