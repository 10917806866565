import { RootState, useAppDispatch, useAppSelector } from 'app/redux';
import {
  PSFMemoType,
  QuerySelection,
  psfQuerySlice,
  sliceName,
} from 'app/redux/psfQuerySlice';
import { useMemo } from 'react';
import { PSFConfig, PaginationConfig } from '../../common/hooks/usePSFQuery';

export enum PSFKeys {
  Users = 'users',
  Growers = 'growers',
  BlockChanges = 'blockChanges',
  MyEvals = 'myEvals',
  OtherEvals = 'otherEvals',
  BlankEvals = 'blankEvals',
  AllEvals = 'allEvals',
  VarietalSummary = 'varietalSummary',
  SchedulableBlocks = 'schedulableBlocks',
}

/**
 * Allows using globally stored psf state, per component key.
 * @returns An object containing:
 * - `query` which holds the state.
 * - `setQuery` which updates the stored state.
 */
export const usePSFState = (
  key: string,
  paginationConfig?: Partial<PaginationConfig>,
): PSFMemoType => {
  const query =
    useAppSelector((rootState: RootState) => rootState[sliceName][key]) ??
    paginationConfig;

  const dispatch = useAppDispatch();
  const useSetPSFQuery = useMemo(() => {
    return (query: Partial<PSFConfig>) => {
      const querySelection: QuerySelection = {
        key,
        query: { ...paginationConfig, ...query },
      };
      dispatch(psfQuerySlice.actions.setQuery(querySelection));
    };
    // We omit the paginationConfig as a dependency because we only want the
    // values on the first call and an infinite re-render occurs otherwise.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, key]);

  return { query, setQuery: useSetPSFQuery };
};
