import { Contractor } from 'common/models';
import {
  ContractorInvoicePick,
  HarvestLineType,
  HaulingLineType,
  MiscLineType,
  PayrollData,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import { roundBins } from 'common/models/harvestData/utilities';
import currency from 'currency.js';
import { Week, getUSFormattedDate } from 'utils/dateTimeHelpers';
import { generateWeekEndString } from 'utils/helperFunction';
import { PicksTableRows, TransfersTableRows } from '../PayrollSlice';
import {
  AccountsPayableKeys,
  PayrollKeys,
  PayrollTableRow,
  RowGroupKeys,
  RowMetadata,
  emptyPayrollRow,
} from './payrollDataTypes';

/** Helper function to create a unique key from a given row's metadata. */
export const createRowKey = (metadata: RowMetadata): string => {
  const {
    pickId,
    contractorRecordId,
    haulerRecordId,
    itemId,
    discriminator,
    descCategory,
    descType,
  } = metadata;
  return (
    `${pickId}-${contractorRecordId}-${haulerRecordId}` +
    `-${itemId}-${discriminator}-${descCategory}-${descType}`
  );
};

/**
 * Returns a string of 'MM/DD'format.
 *
 * Note: Only use this function if the string passed originated in
 * a UTC context. Otherwise, the date may change depending on the time
 * of the day. See {@link getISODateOnlyString} for in depth explanation.
 */
export const createTableDate = (date: string): string => {
  const newDate = new Date(date);
  // increment needed to account for 0 basing in months
  return `${newDate.getUTCMonth() + 1}/${newDate.getUTCDate()}`;
};

export const createRowSubtotal = (
  rate: number,
  amount: number,
  isPercent = false,
): string => {
  const subtotal = isPercent ? (rate * amount) / 100 : rate * amount;
  return currency(subtotal).format();
};

/** Determines whether a row can be removed in a table. */
export const isRemovableRow = (description: string): boolean => {
  switch (description) {
    case HarvestLineType[HarvestLineType.Breaks]:
    case HarvestLineType[HarvestLineType.NPT]:
    case HarvestLineType[HarvestLineType.Minimum_Wage]:
    case MiscLineType[MiscLineType.MISC]:
    case TransferLineType[TransferLineType.Transfer]:
    case HaulingLineType[HaulingLineType.Minimum_Load]:
    case HaulingLineType[HaulingLineType.Minimum_Load_Surcharge]:
      return true;
    default:
      return false;
  }
};

/** Generates the filename for csv files downloaded on the payroll page. */
export const generateFilename = (
  week: Week,
  contractor: Contractor,
  isSummary = false,
): string => {
  const lastDayInfo = week.weekdays[6];

  return `${generateWeekEndString(lastDayInfo)}_${contractor?.code}${
    isSummary ? '_summary' : '_payroll'
  }`;
};

/**
 * Creates a special row whose visible fields are empty, but with the metadata
 * necessary to create a unique identifier for rendering purposes (i.e., to avoid
 * "unique key" warnings).
 */
const createSeparatorRow = (group: RowGroupKeys): PayrollTableRow => {
  return {
    ...emptyPayrollRow,
    metadata: {
      ...emptyPayrollRow.metadata,
      pickId: group.pickId,
      contractorRecordId: group.contractorRecordId,
      haulerRecordId: group.haulerRecordId,
    },
  };
};

/**
 * Determines if the specified keys indicate that two rows belong to the same
 * group.
 */
export const isSameGroup = (
  firstGroup: RowGroupKeys,
  secondGroup: RowGroupKeys,
): boolean => {
  return (
    firstGroup.pickId === secondGroup.pickId &&
    firstGroup.contractorRecordId === secondGroup.contractorRecordId &&
    firstGroup.haulerRecordId === secondGroup.haulerRecordId &&
    firstGroup.descCategory === secondGroup.descCategory
  );
};

/**
 * Combines the harvest and hauling rows for a pick into a single list of rows,
 * matching harvest and haul rows for the same contractor record along the way
 * so they're displayed as a group.
 *
 * @param pick - The object containing the pick rows to be flattened.
 */
export const flattenHarvestAndHaulRows = (
  pick: PicksTableRows,
  options?: {
    /**
     * Indicates whether to add a special separator row between groups, for
     * display purposes. The default is to add a separator.
     */
    withSeparator: boolean;
  },
) => {
  const harvestEntries = Object.entries(pick.harvestRows);
  let allRows: PayrollTableRow[] = [];
  const withSeparator = options?.withSeparator ?? true;

  if (harvestEntries.length) {
    // This will keep track of hauler entries we've matched to harvest entries.
    const matchedHaulerEntries: number[] = [];

    allRows = harvestEntries.reduce((acc, [id, harvestRows]) => {
      const recordId = parseInt(id, 10);
      const haulRows = pick.haulRows[recordId] ?? [];

      if (haulRows.length) {
        matchedHaulerEntries.push(recordId);
      }

      if (acc.length > 0 && withSeparator) {
        acc.push(createSeparatorRow(acc.findLast(() => true)!.metadata));
      }

      acc.push(...harvestRows, ...haulRows);

      return acc;
    }, [] as PayrollTableRow[]);

    // Add any remaining haul rows that don't have matching harvest rows.
    allRows = Object.entries(pick.haulRows).reduce((acc, [id, haulRows]) => {
      if (!matchedHaulerEntries.includes(parseInt(id, 10))) {
        if (withSeparator) {
          acc.push(createSeparatorRow(acc.findLast(() => true)!.metadata));
        }

        acc.push(...haulRows);
      }
      return acc;
    }, allRows);
  } else {
    // Haul-only pick.
    allRows = Object.values(pick.haulRows).reduce((acc, haulRows) => {
      if (acc.length > 0 && withSeparator) {
        acc.push(createSeparatorRow(acc.findLast(() => true)!.metadata));
      }

      acc.push(...haulRows);

      return acc;
    }, [] as PayrollTableRow[]);
  }

  return allRows;
};

/** Creates the rows for the contractor payroll csv download. */
export const generatePayrollCsv = (
  picks: PicksTableRows[],
  transfers: TransfersTableRows[],
  picksTotal: PayrollTableRow,
  transfersTotal: PayrollTableRow,
  invoiceTotal: PayrollTableRow,
): Record<PayrollKeys, string>[] => {
  let payrollCsvData: Record<PayrollKeys, string>[] = [];

  picks.forEach(pick => {
    const updatedMiscRows = pick.miscRows.map(row => ({
      ...row,
      description: `MISC - ${row.description}`,
    }));

    payrollCsvData = payrollCsvData.concat([
      ...flattenHarvestAndHaulRows(pick),
      ...updatedMiscRows,
      pick.pickTotal,
      emptyPayrollRow,
    ]);
  });

  payrollCsvData = payrollCsvData.concat([picksTotal, emptyPayrollRow]);

  transfers.forEach(transfer => {
    payrollCsvData = payrollCsvData.concat([
      ...transfer.rows,
      transfer.transferTotal,
      emptyPayrollRow,
    ]);
  });

  payrollCsvData = payrollCsvData.concat([
    transfersTotal,
    emptyPayrollRow,
    invoiceTotal,
  ]);

  return payrollCsvData;
};

/** Creates the rows for the Accounts Payable csv download. */
export const generateApCsv = (
  data: PayrollData,
  selectedContractor: Contractor,
  picksTableRows: PicksTableRows[],
  transfersTableRows: TransfersTableRows[],
  week: Week,
): Record<AccountsPayableKeys, string>[] => {
  const newRows: Record<AccountsPayableKeys, string>[] = [];

  // Default values for all rows:
  const { vendorId, code } = selectedContractor;
  const lastDayInfo = week.weekdays[6];
  const invoiceId = generateWeekEndString(lastDayInfo);
  const invoiceDate = getUSFormattedDate(new Date());
  const period = invoiceId.substring(2, 4);
  const bankId = 'VRB';
  const advanceTransCode = '';
  const advanceDescription = '';

  // Default values for Pick and Haul rows:
  const glId = '260-10';
  const pickDesc = 'P';
  const haulDesc = 'H';
  const pickHaulTransCode = 'CH';
  const pickHaulPostToGrower = 'P';

  // Default values for Transfer row:
  const transferApDesc = 'T';
  const transferTransCode = '';
  const transferPostToGrower = 'N';

  // Default values for MISC rows:
  const miscApDesc = 'M';
  const miscPostToGrower = 'N';

  const createApRow = (
    rows: PayrollTableRow[],
    apDescType: 'P' | 'H' | 'T' | 'M',
    record?: ContractorInvoicePick,
  ) => {
    const isTransfer = apDescType === transferApDesc;
    const isMisc = apDescType === miscApDesc;

    const { quantity, transferGlId, invoiceAmount } = rows.reduce(
      (smm, row, index) => {
        // Add up the quantity once per group. This avoids adding the quantity
        // multiple times for the same group, and avoids an error for rows that
        // don't have a quantity.
        if (
          index === 0 ||
          !isSameGroup(row.metadata, rows[index - 1].metadata)
        ) {
          smm.quantity += parseFloat(row.quantity);
        }

        if (isTransfer && row.block) {
          smm.transferGlId = row.block.toString();
        }

        smm.invoiceAmount = smm.invoiceAmount.add(row.subtotal);

        return smm;
      },
      { quantity: 0, transferGlId: '', invoiceAmount: currency(0) },
    );

    newRows.push({
      vendorId,
      glId,
      period,
      invoiceId,
      invoiceDate,
      dueDate: invoiceDate,
      bankId,
      transCode: pickHaulTransCode,
      advanceTransCode,
      advanceDescription,
      postToGrower: pickHaulPostToGrower,
      poolId: record?.poolId || '',
      growerId: record?.growerId || '',
      blockId: record?.blockId || '',
      invoiceAmount: invoiceAmount.format(),
      apDescription: `${roundBins(quantity)}, ${apDescType}, ${code}`,
      ...(isTransfer && {
        glId: transferGlId,
        transCode: transferTransCode,
        postToGrower: transferPostToGrower,
      }),
      ...(isMisc && {
        glId: '',
        transCode: '',
        postToGrower: miscPostToGrower,
        apDescription: apDescType,
      }),
    });
  };

  data.picks.forEach(record => {
    const pickRecord = picksTableRows.find(
      rowRecord => rowRecord.pickId === record.pickId,
    );
    const harvestRows = Object.values(pickRecord?.harvestRows ?? {}).flat();
    const haulRows = Object.values(pickRecord?.haulRows ?? {}).flat();

    if (harvestRows && harvestRows.length > 0) {
      createApRow(harvestRows, pickDesc, record);
    }

    if (haulRows && haulRows.length > 0) {
      createApRow(haulRows, haulDesc, record);
    }

    if (pickRecord?.miscRows && pickRecord.miscRows.length > 0) {
      createApRow(pickRecord.miscRows, miscApDesc);
    }
  });

  transfersTableRows.forEach(tableRecord => {
    createApRow(tableRecord.rows, transferApDesc);
  });

  return newRows;
};
