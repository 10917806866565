import { Prompt } from 'react-router-dom';

type PromptProps = {
  isDirty: boolean;
  isSubmitting: boolean;
  isSuccessful?: boolean;
};

const FormPrompt = ({
  isDirty,
  isSubmitting,
  isSuccessful,
}: PromptProps): JSX.Element => {
  return (
    <Prompt
      when={isDirty && !isSubmitting && !isSuccessful}
      message='You have unsaved changes, are you sure you want to leave?'
    />
  );
};

export default FormPrompt;
