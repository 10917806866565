import { Close } from '@mui/icons-material';
import { darkNavy, lighterNavy, tableHeaderGrey } from 'common/styles/colors';
import { FC } from 'react';
import { DetailEditingToggle } from '../DetailControls';
import styled from 'styled-components';

const FormBtnWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CancelBtn = styled.button.attrs(() => ({
  type: 'button',
  'aria-label': 'cancel edit',
}))`
  display: flex;
  align-items: center;
  border: none;
  padding: 5px;
  background-color: transparent;
  font-family: KanitRegular;
  font-size: 18px;
  color: ${lighterNavy};
  margin-right: 15px;

  :focus {
    outline: ${tableHeaderGrey} dotted 1px;
  }
`;

export const FormButtonHeader: FC<{
  isEditable: boolean;
  handleCancelClick: () => void;
  handleEditClick: () => void;
}> = ({ isEditable, handleCancelClick, handleEditClick }) => {
  return (
    <FormBtnWrapper>
      {isEditable && (
        <CancelBtn onClick={handleCancelClick}>
          <Close style={{ color: `${darkNavy}` }} />
          Cancel
        </CancelBtn>
      )}
      <DetailEditingToggle editing={isEditable} onToggle={handleEditClick} />
    </FormBtnWrapper>
  );
};
