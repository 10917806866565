import { useAppDispatch, useAppSelector } from 'app/redux';
import { FormButtonHeader } from 'common/components/FormHeader/FormButtonHeader';
import { useSavePayroll } from 'features/harvest-payroll/hooks/useSavePayroll';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import {
  PayrollTableRow,
  picksHeaders,
  transfersHeaders,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { FC } from 'react';
import { PickRows } from './PickRows';
import {
  EmptyHeaderCell,
  EmptyTotalCell,
  FormHeader,
  TableContainer,
  Total,
  TotalPrompt,
} from './styles';
import { TransferRows } from './TransferRows';

/** Component that handles the display of either the picks or transfers table  */
export const PayrollTable: FC<{
  tableData: PayrollTableRow[];
  tableTotalRow: PayrollTableRow;
  /** When `true`, the the payroll form edit button displays. Default is `false`. */
  isPicksTable?: boolean;
  /** Function to reset table data to its origininal state */
  setInitialTableData?: () => void;
}> = ({
  isPicksTable = true,
  tableData,
  tableTotalRow,
  setInitialTableData,
}) => {
  const dispatch = useAppDispatch();
  const { handleSavePayroll } = useSavePayroll();
  const { isEditable, isDirty } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const handleEditClick = async () => {
    if (isEditable && isDirty) {
      const isSuccess = await handleSavePayroll();
      if (!isSuccess) {
        return;
      }
    }
    dispatch(payrollSlice.actions.setIsEditable(!isEditable));
  };

  const handleCancelClick = () => {
    if (setInitialTableData && isDirty) {
      setInitialTableData();
      dispatch(payrollSlice.actions.setIsDirty(false));
    }
    dispatch(payrollSlice.actions.setIsEditable(false));
  };

  return isPicksTable ? (
    <>
      <FormHeader>
        <h4>Picks</h4>
        <FormButtonHeader
          isEditable={isEditable}
          handleCancelClick={handleCancelClick}
          handleEditClick={handleEditClick}
        />
      </FormHeader>
      <TableContainer>
        {picksHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <PickRows tableData={tableData} isEditable={isEditable} />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  ) : (
    <>
      <FormHeader>
        <h4>Transfers</h4>
      </FormHeader>
      <TableContainer>
        {transfersHeaders.map(header => (
          <EmptyHeaderCell key={`${header.label}`}>
            {header.label}
          </EmptyHeaderCell>
        ))}
        <EmptyHeaderCell />
        <TransferRows tableData={tableData} isEditable={isEditable} />
        <TotalPrompt>{tableTotalRow.rate}</TotalPrompt>
        <Total>{tableTotalRow.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
    </>
  );
};
