import { useAppDispatch } from 'app/redux';
import { useLoginMutation } from 'common/api/authApi';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as authLocalStorage from '../authLocalStorage';
import { authSlice } from '../authSlice';
import { determinePathOnLogin } from 'utils/helperFunction';

export interface Credentials {
  email: string;
  password: string;
}

export type UseLoginHook = () => {
  login: (credentials: Credentials) => Promise<void>;
  isLoading: boolean;
};

export const useLogin: UseLoginHook = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [login, { isLoading }] = useLoginMutation();

  const loginUser = useCallback(
    async (credentials: Credentials) => {
      const session = await login(credentials).unwrap();
      const auth = { token: session.token, user: session.user };
      dispatch(authSlice.actions.userLoggedIn(auth));
      authLocalStorage.saveAuthState(auth);
      history.replace(determinePathOnLogin(session.user.role.roleName));
    },
    [login, dispatch, history],
  );

  return { login: loginUser, isLoading };
};
