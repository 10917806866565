import { FC, useEffect, useMemo } from 'react';
import { PayrollTable } from '../PayrollTable/PayrollTable';
import { useAppDispatch, useAppSelector } from 'app/redux';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { useTransferTable } from 'features/harvest-payroll/hooks/useTransferTable';
import {
  PayrollTableRow,
  emptyPayrollRow,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import {
  PayrollData,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import {
  EmptyTotalCell,
  TotalPrompt,
  TableContainer,
  Total,
} from '../PayrollTable/styles';
import { FormContainer } from './styles';
import FormPrompt from 'common/components/FormPrompt';
import { usePickTable } from 'features/harvest-payroll/hooks/usePickTable';
import { flattenHarvestAndHaulRows } from 'features/harvest-payroll/utils/payrollPageUtils';

export const PayrollBody: FC<{
  payrollData: PayrollData;
}> = ({ payrollData }) => {
  const dispatch = useAppDispatch();
  const {
    picks,
    transfers,
    picksTotal,
    transfersTotal,
    invoiceTotal,
    isEditable,
    isDirty,
  } = useAppSelector<PayrollPageState>(state => state[payrollSliceName]);
  const { createPickDefaults } = usePickTable();
  const { createTransferDefaults } = useTransferTable();

  const setInitialTableData = () => {
    // Create default rows.
    createPickDefaults(payrollData.picks);
    createTransferDefaults(payrollData.transfers);

    // Calculate table and invoice totals.
    dispatch(payrollSlice.actions.setPickTotal({ updateInvTotal: false }));
    dispatch(payrollSlice.actions.setTransferTotal({ updateInvTotal: false }));
    dispatch(payrollSlice.actions.setInvoiceTotal());
  };

  useEffect(() => {
    setInitialTableData();

    return () => {
      // Reset component on unmount (used mainly to reset the form if navigating away).
      dispatch(payrollSlice.actions.resetTableDataAndForm());
    };
    // Adding the dispatch and function triggers an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payrollData]);

  const finalPicksTableRows = useMemo(() => {
    let rows = [] as PayrollTableRow[];

    picks.forEach(record => {
      const newEmptyRow: PayrollTableRow = {
        ...emptyPayrollRow,
        metadata: {
          ...emptyPayrollRow.metadata,
          pickId: record.pickId,
        },
      };

      rows = [
        ...rows,
        ...flattenHarvestAndHaulRows(record),
        ...record.miscRows,
        record.pickTotal,
        newEmptyRow,
      ];
    });

    return rows;
    // Only create final rows when the total is calculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [picksTotal]);

  const finalTransfersTableRows = useMemo(() => {
    let rows = [] as PayrollTableRow[];

    transfers.forEach((record, index) => {
      const isLastRow = transfers.length - 1 === index;
      const newEmptyRow = {
        ...emptyPayrollRow,
        metadata: {
          ...emptyPayrollRow.metadata,
          pickId: record.id,
          descCategory: TransferLineType[TransferLineType.Transfer],
        },
      };

      rows = [...rows, ...record.rows, record.transferTotal];
      if (!isLastRow) {
        rows.push(newEmptyRow);
      }
    });

    return rows;
    // Only create final rows when the total is calculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfersTotal]);

  return (
    <FormContainer $showBackground={isEditable}>
      <PayrollTable
        tableData={finalPicksTableRows}
        tableTotalRow={picksTotal}
        setInitialTableData={setInitialTableData}
      />
      <PayrollTable
        isPicksTable={false}
        tableData={finalTransfersTableRows}
        tableTotalRow={transfersTotal}
      />
      <TableContainer>
        <TotalPrompt>{invoiceTotal.rate}</TotalPrompt>
        <Total>{invoiceTotal.subtotal}</Total>
        <EmptyTotalCell />
      </TableContainer>
      <FormPrompt isDirty={isDirty} isSubmitting={false} />
    </FormContainer>
  );
};
