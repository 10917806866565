import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { Area } from 'common/models';

export const areaApi = createApi({
  reducerPath: 'areaApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['Area'],

  endpoints: builder => ({
    getAreas: builder.query<Area[], void>({
      query: () => ({
        url: '/area',
      }),
      providesTags: (_, err) => (err ? [] : ['Area']),
    }),
  }),
});

export const { useGetAreasQuery } = areaApi;
