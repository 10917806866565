import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import { DetailText } from 'common/components/DetailControls';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import { ScheduledPick, pickTypeLabels } from 'common/models';
import { lighterNavy } from 'common/styles/colors';
import { mobile } from 'common/styles/breakpoints';

const StyledRow = styled(ResponsiveRow)`
  padding-left: 15px;
  padding-right: 20px;
  .col-lg-2 {
    min-width: 30%;
  }
  .col {
    padding-left: 20px;
  }
`;

const NotesRow = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: ${mobile}) {
    flex-direction: column;
  }
`;

const NotesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 10px;

  h6 {
    font-family: 'KanitLight';
    color: ${lighterNavy};
  }

  span {
    font-family: 'KanitRegular';
    margin-left: 10px;
    color: ${lighterNavy};
  }
`;

const FRNotes = styled(NotesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 28px;

  @media (max-width: ${mobile}) {
    margin-left: 20px;
    width: 90%;
  }
`;

const HCNotes = styled(NotesContainer)`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-left: 18px;

  @media (max-width: ${mobile}) {
    margin-left: 20px;
    width: 90%;
  }
`;

export const SpecsNotesTab: React.FC<{ pickData: ScheduledPick }> = ({
  pickData,
}) => {
  return (
    <StyledStack>
      <StyledRow>
        <Col>
          <DetailText
            name='clean'
            label='Clean'
            text={pickData?.cleanPick ? 'Yes' : 'No'}
          />
        </Col>
        <Col>
          <DetailText
            name='picktype'
            label='Pick Type'
            text={pickTypeLabels[pickData?.pickType]}
          />
        </Col>
      </StyledRow>
      <NotesRow>
        <FRNotes>
          <h6>FR Notes</h6>
          <span>{pickData?.notes}</span>
        </FRNotes>
        <HCNotes>
          <h6>HC Notes</h6>
          <span>{pickData?.pickAssignment?.notes}</span>
        </HCNotes>
      </NotesRow>
      <NotesRow>
        <FRNotes>
          <h6>Pick Notes</h6>
          <span>{pickData?.pickRecord?.notes}</span>
        </FRNotes>
      </NotesRow>
    </StyledStack>
  );
};
