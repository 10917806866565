import { DataTableFilters } from 'common/components/DataTable';
import { GrowerStatus } from 'common/models';
import {
  getEnumLabels,
  getKeyFromEnumLabel,
  toEnumLabel,
} from 'utils/enumFunctions';
import { SearchOps } from './helpers';

export enum GrowersFilterKeys {
  growerIdKey = `growerId${SearchOps.ICONTAINS}`,
  parentGrowerKey = `parentGrowerName${SearchOps.ICONTAINS}`,
  growerStatusKey = `status${SearchOps.EQUALS}`,
  gapStatusKey = `gapStatus${SearchOps.ICONTAINS}`,
}

export const growerIdFilter: DataTableFilters = {
  options: [],
  category: {
    key: GrowersFilterKeys.growerIdKey,
    label: 'Grower ID',
  },
};

export const parentGrowerFilter: DataTableFilters = {
  options: [],
  category: {
    key: GrowersFilterKeys.parentGrowerKey,
    label: 'Parent Grower',
  },
};

export const growerStatusFilter: DataTableFilters = {
  options: getEnumLabels(GrowerStatus).map(status => ({
    label:
      status === toEnumLabel(GrowerStatus[GrowerStatus.No_Status])
        ? `(${status})`
        : status,
    value: getKeyFromEnumLabel(GrowerStatus, status)?.toString() || '-1',
  })),
  category: {
    key: GrowersFilterKeys.growerStatusKey,
    label: 'Grower Status',
  },
};

export const gapStatusFilter: DataTableFilters = {
  options: [],
  category: {
    key: GrowersFilterKeys.gapStatusKey,
    label: 'Gap Status',
  },
};
