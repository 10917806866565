import { grey, lighterNavy, white } from 'common/styles/colors';
import { CSVLink } from 'react-csv';
import styled from 'styled-components';

export const CsvBtn = styled(CSVLink)<{
  $isLoading: boolean;
  $isDisabled: boolean;
}>`
  text-decoration: none;
  background: ${props =>
    props.$isLoading || props.$isDisabled ? grey : lighterNavy};
  color: ${white};
  padding: 2px 12px;
  font-family: 'KanitRegular';
  font-size: 15px;
  border: none;
  border-radius: 4px;
  cursor: ${props => {
    if (props.$isDisabled) {
      return 'not-allowed';
    }
    return props.$isLoading ? 'wait' : 'pointer';
  }};
`;
