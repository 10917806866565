import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './customBaseQuery';

export const healthCheckApi = createApi({
  reducerPath: 'healthCheckApi',
  baseQuery: customBaseQuery,
  // Always refetch data, don't use cache.
  keepUnusedDataFor: 0,
  refetchOnReconnect: true,

  endpoints: builder => ({
    getAppHealth: builder.query<{ message: string; version: string }, void>({
      query: () => ({
        url: '/health-check',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAppHealthQuery } = healthCheckApi;
