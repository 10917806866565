import { lighterNavy, grey } from 'common/styles/colors';
import { applyErrorBorder } from 'common/styles/form';
import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { UseFormControllerProps } from '../DetailControls';

const InputContainer = styled.div<{
  $maxWidth: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${props => props.$maxWidth};

  .invalid-feedback {
    display: flex;
  }
`;

const Input = styled.input<{
  $isError: boolean;
  $disabledColor: string;
}>`
  height: 32px;
  width: 100%;
  border: none;
  border-radius: 4px;
  font-family: KanitSemiBold;
  color: ${lighterNavy};
  text-align: center;
  ${props => applyErrorBorder(props.$isError)}

  :disabled {
    background-color: ${props => props.$disabledColor};
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

type WizardNumericInputProps = Pick<UseFormControllerProps, 'name'> & {
  /**
   * Can be used to alter the width of the input field.
   * Default is set to '185px'.
   */
  maxWidth?: string;
  /**
   * Optional error string to use when the input is bound
   * to a child inside a collection of items.
   */
  nestedPathError?: string;
  /**
   * Optional boolean to hide an input without unregistering it.
   */
  isHidden?: boolean;
  /**
   * Optional string to display as a placeholder in the input field.
   * Default is '0'.
   */
  placeholder?: string;
  /**
   * Optional boolean to disable the input.
   */
  isDisabled?: boolean;
  /**
   * Optional string color value to be used for the input background.
   */
  disabledColor?: string;
};

export const WizardNumericInput: FC<WizardNumericInputProps> = ({
  name,
  maxWidth = '185px',
  nestedPathError,
  isHidden = false,
  placeholder = '0',
  isDisabled = false,
  disabledColor = grey,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const error = errors[`${name}`];

  return (
    <InputContainer $maxWidth={maxWidth} hidden={isHidden}>
      <Input
        {...register(name)}
        type='number'
        // Fix later: border outline for nestedPathError does not show
        $isError={!!error || !!nestedPathError}
        $disabledColor={disabledColor}
        placeholder={placeholder}
        step='any'
        disabled={isDisabled}
      />
      {/* The error Feedback control does not work without this hidden Input */}
      <Form.Check.Input hidden isInvalid={!!error || !!nestedPathError} />
      {(nestedPathError || error) && (
        <Form.Control.Feedback type='invalid'>
          <>{nestedPathError || error?.message}</>
        </Form.Control.Feedback>
      )}
    </InputContainer>
  );
};
