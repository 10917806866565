import { EnvironmentConfiguration } from 'environment/types';
import { MapParameters } from './GmapsModalSlice';
import { Loader } from '@googlemaps/js-api-loader';
import * as Sentry from '@sentry/react';

type GoogleMapsHooks = {
  tryCreateMapWithLocations: (
    mapParams: MapParameters[],
    mapElementId: string,
    blockTagClassName: string,
    version?: string,
  ) => Promise<boolean>;
};

export const useGoogleMaps = (): GoogleMapsHooks => {
  /**
   * Returns the `apiKey` and `mapId` values set in the `.env`
   * for the environment the application is currently running on.
   *
   * @remarks
   * An **_undefined_** or **_empty_** string for `mapId` in the **staging** or
   * **production** environments will trigger Google's error in the map component.
   *
   * An **_undefined_** or **_empty_** string for `mapId` in the **testing** or
   * **development** environments will fallback to `'DEMO_MAP_ID'`
   * (Google's valid testing string). A warning will show in the console
   * to make note of this.
   */
  const determineApiKeyAndMapId = (): {
    apiKey: string;
    mapId: string;
  } => {
    let apiKey: string | undefined;
    let mapId: string | undefined;
    const devMapIdString = 'DEMO_MAP_ID';
    const mapIdWarning = `No Map ID was provided; defaulting to '${devMapIdString}'.`;

    switch (process.env.REACT_APP_CONFIGURATION) {
      case EnvironmentConfiguration.Production:
        apiKey = process.env.REACT_APP_PROD_GCP_KEY;
        mapId = process.env.REACT_APP_PROD_MAP_ID;
        break;
      case EnvironmentConfiguration.Staging:
      case EnvironmentConfiguration.Demo:
        apiKey = process.env.REACT_APP_STG_GCP_KEY;
        mapId = process.env.REACT_APP_STG_MAP_ID;
        break;
      case EnvironmentConfiguration.Testing:
        apiKey = process.env.REACT_APP_TST_GCP_KEY;
        mapId = process.env.REACT_APP_TST_MAP_ID || devMapIdString;
        break;
      default:
        apiKey = process.env.REACT_APP_DEV_GCP_KEY;
        mapId = process.env.REACT_APP_DEV_MAP_ID || devMapIdString;
    }

    // Warn user when using fallback
    // eslint-disable-next-line no-console
    if (mapId === devMapIdString) console.warn(mapIdWarning);

    return { apiKey: apiKey || '', mapId: mapId || '' };
  };

  /**
   * A function that leverages Google's Dynamic Maps API to display one or multiple
   * block locations on a GoogleMaps map.
   *
   * @remarks
   * The locations icon are titled with the block Id and contain a clickable
   * prompt to open the location in the device's GoogleMaps app.
   * The version set as default is the recommended `'weekly'` version.
   * The `maps` and `marker` libraries are automagically used
   * to construct the map and its associated markers inside an iframe.
   * The iframe is then injected through a DOM element matching the id `mapElementId`.
   */
  const tryCreateMapWithLocations = async (
    mapParams: MapParameters[],
    mapElementId: string,
    blockTagClassName: string,
    version = 'weekly',
  ): Promise<boolean> => {
    let map: google.maps.Map;
    let infoWindow: google.maps.InfoWindow;
    const { apiKey, mapId } = determineApiKeyAndMapId();
    const loader = new Loader({ apiKey, version });
    let isSuccessful = false;

    try {
      const [{ Map, InfoWindow }, { AdvancedMarkerElement }] =
        await Promise.all([
          loader.importLibrary('maps'),
          loader.importLibrary('marker'),
        ]);

      map = new Map(document.getElementById(mapElementId) as HTMLElement, {
        center: mapParams[0].latLong,
        zoom: 12,
        mapId,
      });
      infoWindow = new InfoWindow();

      mapParams.forEach((params, index) => {
        const blockTag = document.createElement('div');
        blockTag.className = blockTagClassName;
        blockTag.textContent = params.blockId;

        const marker = new AdvancedMarkerElement({
          map,
          position: mapParams[index].latLong,
          content: blockTag,
        });
        const locationUrl = `https://www.google.com/maps/search/?api=1&query=${params.latLong.lat}%2C${params.latLong.lng}`;

        marker.addListener('click', () => {
          infoWindow.close();
          infoWindow.setContent(
            `<a href="${locationUrl}">View on Google Maps</a>`,
          );
          infoWindow.open(marker.map, marker);
        });
      });

      isSuccessful = true;
    } catch (error) {
      // Log error to Sentry and print it as a warning in the console
      Sentry.captureException(new Error(error as string), {
        extra: { mapParams: JSON.stringify(mapParams) },
      });
      // eslint-disable-next-line no-console
      console.warn('Google Maps error:', '\n', error);
    }

    return isSuccessful;
  };

  return {
    tryCreateMapWithLocations,
  };
};
