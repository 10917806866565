import { mobile } from 'common/styles/breakpoints';
import {
  blueShadeHeader,
  secondaryWhite,
  shadowGrey,
} from 'common/styles/colors';
import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 740px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 3px 12px ${shadowGrey};

  @media (max-width: ${mobile}) {
    padding: 0 5px;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${mobile}) {
    margin-left: -5px;
    padding: 10px 10px 10px 5px;
    position: fixed;
    background-color: ${secondaryWhite};
    z-index: 1;
    justify-content: center;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobile}) {
    overflow-x: scroll;
    margin-top: 70px;
  }
`;

export const SwitcherCsvBtn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;

export const Title = styled.p`
  font-family: KanitLight;
  font-size: 32px;
  line-height: 140%;
  letter-spacing: 0.0075em;
  color: ${blueShadeHeader};
  margin: 0 10px 0 0;

  @media (max-width: ${mobile}) {
    display: none;
  }
`;
