import { lighterNavy } from 'common/styles/colors';
import { FC } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { UseFormControllerProps } from '../DetailControls/DetailDropDown';
import { Form } from 'react-bootstrap';
import { applyErrorBorder } from 'common/styles/form';

const PickDateWrapper = styled.div`
  max-width: 185px;
`;

const PickDateSelector = styled(ReactDatePicker)<{
  $isError: boolean;
  $sidePadding: number;
}>`
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 4px;
  font-family: KanitSemiBold;
  color: ${lighterNavy};
  text-align: center;
  ${props => `padding: 0 ${props.$sidePadding}px;`}
  ${props => applyErrorBorder(props.$isError)}

  ::placeholder {
    color: ${lighterNavy};
  }
`;

type WizardDateTimePickerProps = UseFormControllerProps & {
  /**
   * An option to disable the selection of any day prior to the provided date.
   */
  minDate?: Date;
  /**
   * An option to disable the selection of any day after the provided date.
   */
  maxDate?: Date;
  /**
   * An option to display a time (`00:00 AM/PM`) over a date.
   * A time selector replaces the calendar popup.
   */
  showTime?: boolean;
  /**
   * An option to format date to 'MM/DD'.
   */
  showMonthDateFormat?: boolean;
  /**
   * An option to set the time interval between each popup option.
   * The default is set to `5`.
   */
  timeIntervals?: number;
  placeholder?: string;
  /**
   * Optional error string to use when the date selector is bound
   * to a child inside a collection of items.
   */
  nestedPathError?: string;
  /**
   * Optional styling to adjust the padding of the sides of the input.
   */
  sidePadding?: number;
};

/**
 * A component that has the ability to manage a date or a time selection. This
 * relies on `useForm` state.
 * */
export const WizardDateTimePicker: FC<WizardDateTimePickerProps> = ({
  name,
  formControl,
  minDate,
  maxDate,
  showTime,
  showMonthDateFormat = false,
  timeIntervals = 5,
  placeholder = 'Select...',
  nestedPathError,
  sidePadding = 10,
}) => (
  <PickDateWrapper>
    <Controller
      name={name}
      control={formControl as never}
      shouldUnregister
      render={({ field: { onChange, value }, formState: { errors } }) => {
        const error = errors[`${name}`];
        return (
          <>
            <PickDateSelector
              placeholderText={placeholder}
              selected={value}
              onChange={onChange}
              {...(minDate && { minDate })}
              {...(maxDate && { maxDate })}
              {...(showTime && {
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeCaption: 'Time',
                dateFormat: 'h:mm aa',
                timeIntervals,
              })}
              {...(showMonthDateFormat && {
                dateFormat: 'MM/dd',
              })}
              $isError={!!error || !!nestedPathError}
              $sidePadding={sidePadding}
            />
            {/* The error Feedback control does not work without this hidden Select */}
            <Form.Select hidden isInvalid={!!error || !!nestedPathError} />
            {(nestedPathError || error) && (
              <Form.Control.Feedback type='invalid'>
                <>{nestedPathError || error?.message}</>
              </Form.Control.Feedback>
            )}
          </>
        );
      }}
    />
  </PickDateWrapper>
);
