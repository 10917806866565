import { BaseModel } from './baseModel';
import { DateWithoutTime } from './dateType';

export enum GibbRate {
  None,
  Half,
  Full,
  Late,
}

export interface Evaluation extends BaseModel {
  evaluationDate: DateWithoutTime;
  gibb: boolean;
  gibbRate: GibbRate;
  market: string;
  color: number;
  drop: number;
  priority: number;
  condition: number;
}
