import {
  DataTablePaginationProps,
  PaginationState,
} from 'common/components/DataTable';
import { isPaginatedResult, PaginatedResult } from 'common/models';
import { useCallback, useEffect, useReducer } from 'react';
import { UseQuery, UseQueryOptions, UseQueryResult } from 'rtk-query-config';

// --------------------------------------------------------------------------------------------------------------------

export type PaginationConfig = {
  basePage: 0 | 1;
  initialPage: number;
  initialPageSize: number;
  minPageSize: number;
  maxPageSize: number;
};

export type PaginationManager = {
  pagination: DataTablePaginationProps;
};

const defaultPaginationConfig: PaginationConfig = {
  basePage: 1,
  initialPage: 1,
  initialPageSize: 10,
  minPageSize: 1,
  maxPageSize: 100,
};

// --------------------------------------------------------------------------------------------------------------------
type SortState = {
  // TODO
};

type SortActions = {
  // TODO
};

type SortManager = SortState & SortActions;

// --------------------------------------------------------------------------------------------------------------------
export type FilterCategoryOptions = {
  options: string[];
  category: string;
};

type FilterState = {
  filters: FilterCategoryOptions[];
};

type FilterActions = {
  applyFilters: (filters: FilterCategoryOptions[]) => void;
};

type FilterManager = FilterState & FilterActions;

// --------------------------------------------------------------------------------------------------------------------
type PSFQueryState = PaginationState & SortState & FilterState;

type PSFQueryAction =
  | { type: 'pagination/setPage'; payload: { page: number } }
  | { type: 'pagination/setPageSize'; payload: { size: number } }
  | {
      type: 'all/dataUpdated';
      payload: { data: PaginatedResult<unknown> | unknown };
    }
  | {
      type: 'filter/apply';
      payload: { filters: FilterCategoryOptions[] };
    };

export type PSFQueryManager = PaginationManager & SortManager & FilterManager;

export type PSFConfig = PaginationConfig & FilterState & SortState;

// --------------------------------------------------------------------------------------------------------------------

export const usePSFQuery = <ResultType>(
  useQuery: UseQuery<ResultType>,
  options?: UseQueryOptions,
  psfConfig?: Partial<PSFConfig>,
  saveQuery?: (query: Partial<PSFConfig>) => void,
  extraParams?: Record<string, unknown>,
): UseQueryResult<ResultType> & PSFQueryManager => {
  // Override default configs with user specified configs if provided.
  const config = { ...defaultPaginationConfig, ...(psfConfig ?? {}) };

  const initialPaginationState: PaginationState = {
    page: config.initialPage,
    pageSize: config.initialPageSize,
    count: 0,
    pageCount: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  };

  const initialSortState: SortState = {
    // TODO
  };

  const initialFilterState: FilterState = {
    filters: config?.filters ?? [],
  };

  const initialState: PSFQueryState = {
    ...initialPaginationState,
    ...initialSortState,
    ...initialFilterState,
  };

  // Set up the reducer that will create and manage the pagination, sort, and filter states of the query.
  // `useReducer` was prefferred over`useState` here because:
  //    - the state update logic is complex and the next state often depends on the previous state
  //    - there is a lot of state and individual calls to `useState` would be less performant
  const reducer = (
    state: PSFQueryState = initialState,
    action: PSFQueryAction,
  ): PSFQueryState => {
    switch (action.type) {
      case 'pagination/setPage': {
        const { page } = action.payload;
        const { basePage } = config;
        const { pageCount } = state;
        const lastPage = basePage + pageCount - 1;

        if (basePage <= page && page <= lastPage) {
          const hasPreviousPage = basePage < page;
          const hasNextPage = page < lastPage;
          return { ...state, page, hasPreviousPage, hasNextPage };
        }

        return state;
      }

      case 'pagination/setPageSize': {
        const { size } = action.payload;
        const { minPageSize, maxPageSize } = config;

        if (minPageSize <= size && size <= maxPageSize) {
          const pageSize = size;
          return { ...state, pageSize };
        }

        return state;
      }

      case 'all/dataUpdated': {
        const { data } = action.payload;
        let newState = { ...state };

        // If the data is paginated, we need to update the count and pageCount in case the values
        // have changed (e.g. a filter was applied and the result set is smaller). This also
        // requires re-calculating if there are previous and next pages.
        if (isPaginatedResult(data)) {
          const { count, pageCount, page } = data.meta;
          const { basePage } = config;
          const hasPreviousPage = basePage < page;
          const hasNextPage = page < basePage + pageCount - 1;

          newState = {
            ...newState,
            count,
            pageCount,
            hasNextPage,
            hasPreviousPage,
          };
        }

        return newState;
      }

      case 'filter/apply': {
        return {
          ...state,
          filters: [...action.payload.filters],
          page: config.basePage,
        };
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  // Use the query hook.
  const { page, pageSize, filters } = state;
  const queryArg = { page, pageSize, filters, ...extraParams };
  const queryResult = useQuery(queryArg, options);

  // Certain metadata is returned as part of the response from the server. For example, `count` and `pageCount`
  // are returned as part of the paginated result and can't be known ahead of time. Whenever the data is updated
  // we need to dispatch an action that updates the state that depends on this metadata.
  useEffect(() => {
    dispatch({
      type: 'all/dataUpdated',
      payload: { data: queryResult?.data },
    });
  }, [queryResult?.data]);

  // Check if the requested page is greater than the page count. This can happen
  // when a saved query state requests a page that no longer exists due to table
  // data changing.
  useEffect(() => {
    const data = queryResult?.data;
    if (
      isPaginatedResult(data) &&
      (data.meta.page > data.meta.pageCount || data.meta.page < config.basePage)
    ) {
      const lastPage = data.meta.pageCount;
      dispatch({
        type: 'pagination/setPage',
        payload: { page: lastPage },
      });
      saveQuery?.({ initialPage: config.basePage });
    }
  }, [config.basePage, queryResult?.data, saveQuery]);

  // Additional action dispatchers that will be exposed as part of the public interface of the query manager.
  // Components or other custom hooks using the usePSFQuery hook can use these methods to update the pagination,
  // sort, and filter states of the query.
  const getPage = useCallback(
    (page: number) => {
      dispatch({ type: 'pagination/setPage', payload: { page } });
      saveQuery?.({ initialPage: page });
    },
    [saveQuery],
  );

  const getPreviousPage = useCallback(
    () => getPage(state.page - 1),
    [getPage, state.page],
  );

  const getNextPage = useCallback(
    () => getPage(state.page + 1),

    [getPage, state.page],
  );

  const changePageSize = useCallback(
    (size: number) =>
      dispatch({ type: 'pagination/setPageSize', payload: { size } }),
    [dispatch],
  );

  const applyFilters = useCallback(
    (filters: FilterCategoryOptions[]) => {
      dispatch({ type: 'filter/apply', payload: { filters } });
      saveQuery?.({ filters, initialPage: config.basePage });
    },
    [config.basePage, saveQuery],
  );

  return {
    ...queryResult,
    ...state,
    pagination: {
      ...state,
      getPage,
      getPreviousPage,
      getNextPage,
      changePageSize,
    },
    applyFilters,
  };
};
