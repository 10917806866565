import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Season } from 'common/models/season';

export const seasonSliceName = 'seasonSlice';

export type SeasonState = {
  selectedSeason: Season | null;
  availableSeasons: Season[];
};

const initialState: SeasonState = {
  selectedSeason: null,
  availableSeasons: [],
};

export const seasonSlice = createSlice({
  name: seasonSliceName,
  initialState,
  reducers: {
    setSeason: (state: SeasonState, action: PayloadAction<Season>) => {
      state.selectedSeason = action.payload;
    },
    setAvailableSeasons: (
      state: SeasonState,
      action: PayloadAction<Season[]>,
    ) => {
      state.availableSeasons = action.payload;
    },
  },
});
