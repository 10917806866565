import { Accordion, AccordionDetails } from '@mui/material';
import { DataTable, DataTableProps } from 'common/components/DataTable';
import { CmsDate } from 'common/models/cmsDate';
import { EstimateErrorDetails } from 'common/models/databaseSync/syncNotification';
import { FC, useState } from 'react';
import { Column } from 'react-table';
import { Constants } from 'utils/constants';
import { AccordionHeader, ErrorTableStyledWrapper } from './SpokaneSyncStyles';

function buildDetailsLink(id: number) {
  return `${Constants.routes.EVAL_EST_MAIN}/${id}${Constants.routes.ESTIMATES}`;
}

type props = {
  estimateErrors: EstimateErrorDetails[];
};

const columns: Column<EstimateErrorDetails>[] = [
  {
    accessor: 'blockName',
    Header: 'Block ID',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    accessor: 'estimateDate',
    Header: 'Estimate Date',
    Cell: ({ value }) => <span>{CmsDate.parse(value)?.toDateString()}</span>,
  },
  {
    accessor: 'estimateCreator',
    Header: 'Estimate Creator',
    Cell: ({ value }) => <span>{value}</span>,
  },
  {
    accessor: 'messages',
    Header: 'Messages',
    width: '50%',
    Cell: ({ value }) => (
      <ul>
        {value.map(message => (
          <li>{message}</li>
        ))}
      </ul>
    ),
  },
];

export const EstimateErrorTable: FC<props> = ({ estimateErrors }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const tableProps: DataTableProps<EstimateErrorDetails> = {
    isLoading: false,
    isFetching: false,
    columns,
    data: estimateErrors,
    onRowClick: error => buildDetailsLink(error.blockId),
  };

  return (
    <>
      {estimateErrors?.length && (
        <ErrorTableStyledWrapper>
          <Accordion>
            <AccordionHeader onClick={() => setExpanded(!expanded)}>
              <p>Estimate Errors ({estimateErrors.length})</p>
            </AccordionHeader>
            <AccordionDetails>
              <DataTable<EstimateErrorDetails> {...tableProps} />
            </AccordionDetails>
          </Accordion>
        </ErrorTableStyledWrapper>
      )}
    </>
  );
};
