import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useAppSelector } from 'app/redux';
import {
  HarvestLineType,
  HaulingLineType,
  MiscLineType,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import { RemoveButton } from 'common/styles/button';
import { tableHeaderGrey } from 'common/styles/colors';
import currency from 'currency.js';
import { usePickTable } from 'features/harvest-payroll/hooks/usePickTable';
import { useTransferTable } from 'features/harvest-payroll/hooks/useTransferTable';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import {
  PayrollTableRow,
  RowMetadata,
  totalIdKey,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { isRemovableRow } from 'features/harvest-payroll/utils/payrollPageUtils';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { PayrollInput } from './PayrollInput';
import {
  EmptyCell,
  QuantityCell,
  RateCell,
  RemoveRowCell,
  SubtotalCell,
  TableCell,
} from './styles';

export const PayrollTableLine: FC<{
  row: PayrollTableRow;
  changeBackground: boolean;
}> = ({ row, changeBackground }) => {
  const dispatch = useDispatch();
  const { removePickRow, removeMiscRow } = usePickTable();
  const { removeTransfer } = useTransferTable();
  const { isEditable, isDirty } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );
  const {
    pickDay,
    pick,
    block,
    quantity,
    metadata,
    description,
    rate,
    subtotal,
  } = row;
  const isTransferLine =
    metadata.descCategory === TransferLineType[TransferLineType.Transfer];
  const isTotalLine = metadata.descType === totalIdKey;
  const hasEditableFields =
    metadata.descType === HarvestLineType[HarvestLineType.NPT] ||
    metadata.descType === HarvestLineType[HarvestLineType.Breaks];
  const parsedQuantity = parseInt(quantity, 10);
  const parsedRate = currency(rate).value;

  /** Form submission handler to remove a table row. */
  const handleRemoveClick = (data: RowMetadata) => {
    const { descCategory } = data;

    switch (descCategory) {
      case HarvestLineType[HarvestLineType.Harvesting]:
      case HaulingLineType[HaulingLineType.Hauling]:
        removePickRow(data);
        break;

      case MiscLineType[MiscLineType.MISC]:
        removeMiscRow(data);
        break;

      case TransferLineType[TransferLineType.Transfer]:
        removeTransfer(data);
        break;

      default:
      // do nothing
    }

    if (!isDirty) {
      dispatch(payrollSlice.actions.setIsDirty(true));
    }
  };

  return (
    <>
      <TableCell
        $changeBackground={isTransferLine && changeBackground}
        $isEditable={isEditable}
      >
        {pickDay}
      </TableCell>
      <TableCell
        $changeBackground={isTransferLine && changeBackground}
        $isEditable={isEditable}
      >
        {pick}
      </TableCell>
      <TableCell
        $changeBackground={isTransferLine && changeBackground}
        $isEditable={isEditable}
      >
        {block}
      </TableCell>
      <QuantityCell
        $changeBackground={changeBackground}
        $isEditable={isEditable}
      >
        {hasEditableFields && isEditable ? (
          <PayrollInput
            rowMetadata={row.metadata}
            cellKey='quantity'
            defaultValue={
              typeof parsedQuantity === 'number' ? parsedQuantity : 0
            }
          />
        ) : (
          quantity
        )}
      </QuantityCell>
      <TableCell $changeBackground={changeBackground} $isEditable={isEditable}>
        {metadata.descType === MiscLineType[MiscLineType.MISC]
          ? `${MiscLineType[MiscLineType.MISC]} - ${description}`
          : description}
      </TableCell>
      <RateCell
        $isTotalLine={isTotalLine}
        $changeBackground={changeBackground}
        $isEditable={isEditable}
      >
        {hasEditableFields && isEditable ? (
          <PayrollInput
            rowMetadata={row.metadata}
            cellKey='rate'
            defaultValue={typeof parsedRate === 'number' ? parsedRate : 0}
          />
        ) : (
          rate
        )}
      </RateCell>
      <SubtotalCell
        $isTotalLine={isTotalLine}
        $changeBackground={changeBackground}
        $isEditable={isEditable}
      >
        {subtotal}
      </SubtotalCell>
      {isEditable && isRemovableRow(metadata.descType) ? (
        <RemoveRowCell
          $changeBackground={changeBackground}
          $isEditable={isEditable}
        >
          <RemoveButton onClick={() => handleRemoveClick(metadata)}>
            <CancelOutlinedIcon htmlColor={tableHeaderGrey} />
          </RemoveButton>
        </RemoveRowCell>
      ) : (
        <EmptyCell
          $changeBackground={changeBackground}
          $isEditable={isEditable}
        />
      )}
    </>
  );
};
