import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { Market } from 'common/models/market';
import { PackHouse } from 'common/models/packHouse';
import usePackPlanActions from 'features/pack-plan-views/hooks/usePackPlanActions';
import { useRbac } from 'features/rbac';
import { FC, useContext, useState } from 'react';
import { getISODateOnlyString } from 'utils/dateTimeHelpers';
import {
  PlanViewContext,
  ReqToSchedule,
} from '../../pages/PackPlanView/PackPlanView';
import { PackPlanRowCombo } from './PackPlanTable';
import {
  DateColumnContainer,
  InfoColumnContainer,
  TableRow,
  TotalsColumnContainer,
  ComboContainer,
  BinReqContainer,
  TotalsRow,
  RemoveRowBtnContainer,
} from './styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { tableHeaderGrey } from 'common/styles/colors';
import { RemoveButton } from 'common/styles/button';

export type RowCombo = {
  combo: Omit<
    PackPlanRowCombo,
    'binRequests' | 'totalRequested' | 'totalPicked' | 'rowId'
  >;
};

export const PackPlanCombo: FC<RowCombo> = ({
  combo: { variety, market, size },
}) => {
  const newMarketStr =
    Object.keys(Market)[(Object.values(Market) as string[]).indexOf(market)];
  return (
    <>
      <WithTooltip title='Variety' tooltipText={variety.varietyName}>
        <ComboContainer>{variety.varietyCode}</ComboContainer>
      </WithTooltip>
      <WithTooltip title='Market' tooltipText={market}>
        <ComboContainer>{newMarketStr}</ComboContainer>
      </WithTooltip>
      <ComboContainer>{size.value}</ComboContainer>
    </>
  );
};

const BinRequestInput: FC<{
  packHouse: PackHouse;
  rowId: string;
  variety: Variety;
  market: string;
  size: Size;
  binId: number | null;
  bins: number;
  date: string;
  removeNewRow: (rowId: string) => void;
}> = ({
  packHouse,
  rowId,
  variety,
  market,
  size,
  binId,
  bins,
  date,
  removeNewRow,
}) => {
  const { userHasPermission } = useRbac();
  const { toggleModal, setReqToSchedule, setIsSavingReq, redirectToSchedule } =
    useContext(PlanViewContext);
  const { saveBinRequest } = usePackPlanActions();
  const [modalWasOpened, setModalWasOpened] = useState(false);
  const [binReqValue, setBinReqValue] = useState(bins > 0 ? bins : '');
  const hasPermission = () => {
    if (userHasPermission('packPlan:edit-bin-request')) {
      return true;
    }
    if (userHasPermission('pick:schedule')) {
      if (binReqValue) return true;
    }
    return false;
  };

  const handleFocus = () => {
    if (userHasPermission('pick:schedule') && bins && binId) {
      const binReq: ReqToSchedule = {
        packHouse,
        variety,
        market,
        size,
        date,
        bins,
        binId,
      };

      // Admin
      if (userHasPermission('packPlan:edit-bin-request')) {
        if (!modalWasOpened) {
          toggleModal(true);
          setModalWasOpened(true);
          setReqToSchedule(binReq);
        } else if (modalWasOpened) {
          setModalWasOpened(false);
        }
      }
      // Field Rep
      else {
        redirectToSchedule(binReq);
      }
    }
  };

  const handleInput = (event: React.FocusEvent<HTMLInputElement>) =>
    setBinReqValue(!event.target.value ? '' : parseInt(event.target.value, 10));

  const handleBlur = async (event: React.FocusEvent<HTMLInputElement>) => {
    const newBinsValue = !event.target.value
      ? 0
      : parseInt(event.target.value, 10);
    const formattedDate = getISODateOnlyString(date);

    if (
      newBinsValue !== bins &&
      userHasPermission('packPlan:edit-bin-request')
    ) {
      setIsSavingReq(true);
      const isSuccessful = await saveBinRequest({
        packHouseId: packHouse.id,
        varietyId: variety.id,
        market,
        sizeId: size.id,
        binId,
        bins: newBinsValue,
        date: formattedDate,
      });
      if (isSuccessful) {
        removeNewRow(rowId);
      }
      setIsSavingReq(false);
    }
  };

  return (
    <BinReqContainer
      type='number'
      className={`${!!binReqValue && 'positive-bins'} ${
        hasPermission() && 'show-pointer'
      }`}
      placeholder='00'
      value={binReqValue}
      onFocus={handleFocus}
      onInput={handleInput}
      onBlur={handleBlur}
      disabled={!hasPermission()}
    />
  );
};

export const PackPlanRow: FC<{
  packHouse: PackHouse;
  row: PackPlanRowCombo;
  newRows: PackPlanRowCombo[];
  removeNewRow: (rowId: string) => void;
}> = ({
  packHouse,
  row: {
    rowId,
    variety,
    market,
    size,
    binRequests,
    totalRequested,
    totalPicked,
  },
  newRows,
  removeNewRow,
}) => {
  const { userHasPermission } = useRbac();
  const { openRemoveRowModal } = usePackPlanActions();
  const { weekStartDate } = useContext(PlanViewContext);
  const formattedDateString = getISODateOnlyString(weekStartDate);

  const handleRemoveRow = () => {
    if (newRows.find(row => row.rowId === rowId)) return removeNewRow(rowId);
    return openRemoveRowModal(
      formattedDateString,
      packHouse.id,
      variety,
      market,
      size,
    );
  };

  return (
    <TableRow>
      <InfoColumnContainer>
        <PackPlanCombo combo={{ variety, market, size }} />
      </InfoColumnContainer>
      {binRequests.map(({ date, bins, id }) => (
        <DateColumnContainer key={date}>
          <BinRequestInput
            packHouse={packHouse}
            rowId={rowId}
            binId={id}
            bins={bins}
            date={date}
            variety={variety}
            market={market}
            size={size}
            removeNewRow={removeNewRow}
          />
        </DateColumnContainer>
      ))}
      <RemoveRowBtnContainer>
        {userHasPermission('packPlan:create-row') && (
          <RemoveButton onClick={handleRemoveRow}>
            <CancelOutlinedIcon htmlColor={tableHeaderGrey} />
          </RemoveButton>
        )}
      </RemoveRowBtnContainer>
      <TotalsColumnContainer>
        <TotalsRow>{totalRequested || '00'}</TotalsRow>
        <TotalsRow>{totalPicked || '00'}</TotalsRow>
      </TotalsColumnContainer>
    </TableRow>
  );
};
