import styled from 'styled-components';
import { FC } from 'react';
import { SubText } from 'common/styles/texts';
import { secondaryWhite } from 'common/styles/colors';
import { Logout } from '@mui/icons-material';
import { buttons } from 'utils/styleValues';

export const LogoutButton = styled(Logout)`
  color: ${secondaryWhite};
  cursor: pointer;
`;

const LogoutGroup = styled.button`
  display: flex;

  p {
    margin-bottom: ${buttons.textBottom};
    margin-right: ${buttons.textSide};
  }
`;

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement>;
}

export const LogoutButtonComponent: FC<Props> = ({
  onClick: openLogoutModal,
}) => {
  return (
    <LogoutGroup
      type='button'
      onClick={openLogoutModal}
      style={{ background: 'none', border: 'none', color: 'inherit' }}
    >
      <SubText>Log Out</SubText>
      <LogoutButton />
    </LogoutGroup>
  );
};
