import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import { StatusIcon } from 'features/grower-views/components/StatusIcon';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { BlockEvalTableItem } from './useBlockEvalTableData';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { getISODateOnlyString, toUSFormattedDate } from 'utils/dateTimeHelpers';

export const useBlockEvalTableDataMobile = (): Column<BlockEvalTableItem>[] => {
  return useMemo(
    () => [
      {
        accessor: 'blockStatus',
        Cell: ({ value: blockStatus }) => (
          <StatusIcon status={blockStatus} type='block' />
        ),
      },
      {
        accessor: 'grower',
        Header: 'Grower',
        Cell: ({ value: growerData }) => (
          <DataTableText>{`${growerData?.growerId}`}</DataTableText>
        ),
      },
      {
        accessor: 'blockInfo',
        Header: 'Block',
        Cell: ({ value: blockInfo }) => (
          <DataTableText>{`${blockInfo.blockId}`}</DataTableText>
        ),
      },
      {
        accessor: 'variety',
        Header: 'VAR',
        Cell: ({ value }) => <VarietyCell variety={value} />,
      },
      {
        accessor: 'latestEval',
        Header: 'Latest Eval.',
        Cell: ({ value }) => (
          <DataTableText>
            {value ? toUSFormattedDate(getISODateOnlyString(value)) : '-'}
          </DataTableText>
        ),
      },
      {
        id: 'Arrow',
        Header: '',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );
};
