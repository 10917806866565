import { useAppSelector } from 'app/redux';
import { PayrollPageState, payrollSliceName } from '../PayrollSlice';
import {
  HarvestInvoiceItem,
  HarvestLineType,
  HaulingInvoiceItem,
  HaulingLineType,
  MiscInvoiceItem,
  TransferInvoiceItem,
  TransferLineType,
} from 'common/models/harvestData/payrollData';
import * as notifier from 'common/services/notification';
import { handleError } from 'common/api/handleError';
import currency from 'currency.js';
import {
  useGetTransferRatesQuery,
  useSavePayrollMutation,
} from 'common/api/harvestDataApi';
import { PayrollTableRow } from '../utils/payrollDataTypes';
import { Contractor } from 'common/models';
import { FuelSurcharge, TransferRate } from 'common/models/harvestData/hauler';
import { getKeyFromEnumLabel } from 'utils/enumFunctions';

export type SavePayrollPayload = {
  picks: {
    contractorRecords: {
      id: number;
      harvLineItems: HarvestInvoiceItem[];
      haulLineItems: HaulingInvoiceItem[];
    }[];
    haulerRecords: { id: number; haulLineItems: HaulingInvoiceItem[] }[];
  };
  miscLineItems: MiscInvoiceItem[];
  transfers: TransferInvoiceItem[];
  transfersToDelete: number[];
  miscItemsToDelete: number[];
};

/** Hook used for saving payroll table data. */
export const useSavePayroll = () => {
  const [savePayroll] = useSavePayrollMutation();
  const { data: tansferRates } = useGetTransferRatesQuery();
  const {
    picks,
    transfers,
    transfersToDelete,
    miscItemsToDelete,
    selectedContractor,
  } = useAppSelector<PayrollPageState>(state => state[payrollSliceName]);

  /** Helper function to convert a table row to an invoice item. */
  const createInvoiceItem = (
    row: PayrollTableRow,
  ): HarvestInvoiceItem | HaulingInvoiceItem | undefined => {
    const {
      metadata: {
        contractorRecordId,
        haulerRecordId,
        itemId,
        descCategory,
        descType,
        invItemType,
      },
      quantity,
      rate,
      description,
    } = row;
    const isHarvCategory =
      descCategory === HarvestLineType[HarvestLineType.Harvesting];

    const item = {
      ...(itemId && { id: itemId }),
      rate: currency(rate).value,
    };

    switch (descType) {
      case HarvestLineType[HarvestLineType.Harvesting]:
      case HarvestLineType[HarvestLineType.Breaks]:
      case HarvestLineType[HarvestLineType.NPT]:
      case HarvestLineType[HarvestLineType.Supervision]:
      case HarvestLineType[HarvestLineType.Operator]:
      case HarvestLineType[HarvestLineType.Forklift]:
      case HarvestLineType[HarvestLineType.Minimum_Wage]:
        return {
          ...item,
          contractorRecordId,
          quantity: parseFloat(quantity),
          description: getKeyFromEnumLabel(
            HarvestLineType,
            description,
          ) as number,
        } as HarvestInvoiceItem;

      case HaulingLineType[HaulingLineType.Hauling]:
      case HaulingLineType[HaulingLineType.Tarps]:
      case HaulingLineType[HaulingLineType.Fuel_Surcharge]:
      case HaulingLineType[HaulingLineType.Minimum_Load]:
      case HaulingLineType[HaulingLineType.Minimum_Load_Surcharge]:
        return {
          ...item,
          ...(contractorRecordId && {
            contractorRecordId,
            haulerRecordId: null,
          }),
          ...(haulerRecordId && {
            contractorRecordId: null,
            haulerRecordId,
          }),
          quantity: parseFloat(quantity),
          description: getKeyFromEnumLabel(
            HaulingLineType,
            description,
          ) as number,
        } as HaulingInvoiceItem;

      case HarvestLineType[HarvestLineType.Commission]:
      case HaulingLineType[HaulingLineType.Commission]:
        return {
          ...item,
          ...(invItemType === 'harv'
            ? {
                contractorRecordId,
              }
            : {
                contractorRecordId: contractorRecordId || null,
                haulerRecordId: haulerRecordId || null,
              }),
          quantity: null,
          description: getKeyFromEnumLabel(
            isHarvCategory ? HarvestLineType : HaulingLineType,
            description,
          ) as number,
        } as HarvestInvoiceItem | HaulingInvoiceItem;

      default:
        return undefined;
    }
  };

  /**
   * Helper function to create or update an array of
   * contractor records and attach a given invoice item.
   */
  const handleContractorRecord = (
    contractorRecordId: number,
    records: {
      id: number;
      harvLineItems: HarvestInvoiceItem[];
      haulLineItems: HaulingInvoiceItem[];
    }[],
    item: HarvestInvoiceItem | HaulingInvoiceItem,
    invItemType: 'harv' | 'haul' | 'null',
  ) => {
    const ctrRecords = [...records];

    const recordId = ctrRecords.findIndex(
      record => record.id === contractorRecordId,
    );

    if (invItemType === 'harv') {
      if (recordId > -1) {
        const otherItems = ctrRecords[recordId].harvLineItems;

        // Update ctr record harvest invoice items
        ctrRecords[recordId] = {
          ...ctrRecords[recordId],
          harvLineItems: [...otherItems, item as HarvestInvoiceItem],
        };
      } else {
        // Create ctr record with a harvest invoice item
        ctrRecords.push({
          id: contractorRecordId,
          harvLineItems: [item as HarvestInvoiceItem],
          haulLineItems: [],
        });
      }
    } else if (invItemType === 'haul') {
      if (recordId > -1) {
        const otherItems = ctrRecords[recordId].haulLineItems;

        // Add to ctr record's haul invoice items
        ctrRecords[recordId] = {
          ...ctrRecords[recordId],
          haulLineItems: [...otherItems, item as HaulingInvoiceItem],
        };
      } else {
        // Create ctr record with a haul invoice item
        ctrRecords.push({
          id: contractorRecordId,
          harvLineItems: [],
          haulLineItems: [item as HaulingInvoiceItem],
        });
      }
    }

    return ctrRecords;
  };

  const convertRowsToRecords = (): SavePayrollPayload => {
    const itemsToSave: SavePayrollPayload = {
      picks: {
        contractorRecords: [],
        haulerRecords: [],
      },
      miscLineItems: [],
      transfers: [],
      transfersToDelete,
      miscItemsToDelete,
    };

    picks.forEach(pick => {
      const harvestRows = Object.values(pick.harvestRows).flat();
      const haulRows = Object.values(pick.haulRows).flat();

      // Harvesting rows can only be tied to contractor records
      harvestRows.forEach(row => {
        const {
          metadata: { invItemType },
        } = row;
        const item = createInvoiceItem(row);

        if (item && item.contractorRecordId) {
          // Contractor record
          const newCtrRecordsArr = handleContractorRecord(
            item.contractorRecordId,
            itemsToSave.picks.contractorRecords,
            item,
            invItemType,
          );

          itemsToSave.picks.contractorRecords = newCtrRecordsArr;
        }
      });

      // Hauling rows can be tied to both ctr records and hlr records
      haulRows.forEach(row => {
        const {
          metadata: { invItemType },
        } = row;
        const item = createInvoiceItem(row);
        const hlrRecords = itemsToSave.picks.haulerRecords;
        const haulItem = item as HaulingInvoiceItem;

        if (item && item.contractorRecordId && !haulItem.haulerRecordId) {
          // Contractor record
          const newCtrRecordsArr = handleContractorRecord(
            item.contractorRecordId,
            itemsToSave.picks.contractorRecords,
            item,
            invItemType,
          );

          itemsToSave.picks.contractorRecords = newCtrRecordsArr;
        } else if (
          haulItem &&
          haulItem.haulerRecordId &&
          !haulItem.contractorRecordId
        ) {
          // Hauler record
          const recordId = hlrRecords.findIndex(
            record => record.id === haulItem.haulerRecordId,
          );

          if (recordId > -1) {
            const otherItems = hlrRecords[recordId].haulLineItems;

            // Update hlr record with haul invoice items
            hlrRecords[recordId] = {
              ...hlrRecords[recordId],
              haulLineItems: [...otherItems, haulItem],
            };
          } else {
            // Create hlr record with a haul invoice item
            hlrRecords.push({
              id: haulItem.haulerRecordId,
              haulLineItems: [haulItem],
            });
          }
        }
      });

      pick.miscRows.forEach(row => {
        const {
          metadata: { itemId, pickId },
        } = row;

        itemsToSave.miscLineItems.push({
          ...(itemId && { id: itemId }),
          pickId,
          contractorId: selectedContractor?.id as number,
          quantity: parseFloat(row.quantity),
          rate: currency(row.rate).value,
          note: row.description,
        });
      });
    });

    itemsToSave.transfers = transfers.map(transfer => {
      const tsfToSave = {} as TransferInvoiceItem;

      transfer.rows.forEach(row => {
        const {
          metadata: { descType, itemId, transferDate, surchargeId },
          pick,
          block,
          quantity,
          rate,
          description,
        } = row;

        if (descType === TransferLineType[TransferLineType.Transfer]) {
          if (itemId) {
            tsfToSave.id = itemId;
          }
          if (transferDate) {
            tsfToSave.date = transferDate;
          }
          tsfToSave.contractorId = (selectedContractor as Contractor).id;
          tsfToSave.details = pick;
          tsfToSave.glNumber = block;
          tsfToSave.loads = parseFloat(quantity);
          tsfToSave.rate = {
            id: tansferRates?.find(rate => rate.route === description)
              ?.id as number,
          } as TransferRate;
        } else if (descType === TransferLineType[TransferLineType.Commission]) {
          tsfToSave.commissionRate = parseFloat(rate);
        } else if (
          descType === TransferLineType[TransferLineType.Fuel_Surcharge]
        ) {
          tsfToSave.surcharge = { id: surchargeId } as FuelSurcharge;
        }
      });

      return tsfToSave;
    });

    return itemsToSave;
  };

  /** Method that calls the PUT save payroll route.  */
  const handleSavePayroll = async (): Promise<boolean> => {
    const itemsToSave = convertRowsToRecords();

    try {
      await savePayroll(itemsToSave).unwrap();
      notifier.showSuccessMessage('Invoice updated.');
      return true;
    } catch (error) {
      handleError(
        error,
        'Unable to save invoice data, please try again later.',
      );
      return false;
    }
  };

  return {
    handleSavePayroll,
  };
};
