import { skipToken } from '@reduxjs/toolkit/query';
import { handleError } from 'common/api/handleError';
import { useGetPoolsQuery } from 'common/api/harvestDataApi';
import { useGetScheduledPickByIdQuery } from 'common/api/pickSchedulingApi';
import { DetailForm } from 'common/components/DetailControls/DetailForm';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { NestedTabs } from 'common/components/NestedTabs/NestedTabs';
import { ScheduledPick } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import { orange } from 'common/styles/colors';
import { StyledFormWrapper } from 'common/styles/form';
import { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { scheduledPickDetailTabs } from './ScheduledPickDetailTabs';
import { ScheduledPickForm } from './ScheduledPickForm';

const PickDetailFormWrapper = styled(StyledFormWrapper)`
  max-width: 930px;
  width: 100%;
  border-top: 10px solid ${orange};

  /* Undo form padding START */
  padding: 20px 0;

  .status-wrapper,
  .block-info-wrapper,
  .styled-row {
    padding: 0 20px;
    width: 100%;
  }

  .form-top-bar {
    padding: 0 20px 5px;
  }

  @media (max-width: ${mobile}) {
    width: 100vw;
    padding: 5px 0;

    .form-top-bar {
      padding: 0;
      justify-content: flex-end;
      margin: 0;
    }
  }
`;

export const ScheduledPickDetail: FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    data: pickData,
    isFetching,
    error,
  } = useGetScheduledPickByIdQuery({
    id: parseInt(id, 10),
  });

  const varietyId = pickData?.block?.variety?.id;
  const seasonId = pickData?.pool?.seasonId;
  const {
    data: pools,
    isLoading: isLoadingPools,
    error: fetchPoolsError,
  } = useGetPoolsQuery(
    varietyId !== undefined && seasonId !== undefined
      ? { varietyId, seasonId }
      : skipToken,
  );

  useEffect(() => {
    if (error) {
      handleError(error, Constants.errorMessages.READ_PICK_DETAILS_FAILED);
      history.goBack();
    }
    if (fetchPoolsError) {
      handleError(fetchPoolsError, 'Unable to fetch pools.');
    }
  }, [fetchPoolsError, error, history]);

  return (
    // This DetailForm is no more than a shell, simply providing a consistent
    // structure and a close button. The actual submittable form is the nested
    // ScheduledPickForm, which has its own edit/submit button.
    <DetailForm
      CustomWrapper={PickDetailFormWrapper}
      submitAction={() => Promise.resolve(true)}
      reset={() => {
        /* unused */
      }}
      closeHandler={() => history.push(`${Constants.routes.PICK_SCHEDULE}`)}
      canEdit={false}
    >
      <WithLoadingOverlay isLoading={isFetching || isLoadingPools}>
        {pickData && (
          // If pools are not available we still want to show the form since
          // they're not required to see the pick's details.
          <ScheduledPickForm pickData={pickData} pools={pools || []} />
        )}
        <NestedTabs
          tabs={scheduledPickDetailTabs(pickData || ({} as ScheduledPick))}
          isDisabled={false}
        />
      </WithLoadingOverlay>
    </DetailForm>
  );
};
