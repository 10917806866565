/* eslint-disable camelcase */
import { useAppSelector } from 'app/redux';
import {
  UpdateDetailsDto,
  UpdateEvalDto,
  UpdateLatestEstimateDto,
  UpdateMonitoringDto,
  UpdateSizesDto,
} from 'common/api/dto/growerBlockApiTypes';
import {
  useUpdateBlockMutation,
  useUpdateEvaluationMutation,
  useUpdateLatestEstimateMutation,
  useUpdateMonitoringMutation,
  useUpdateSizesMutation,
} from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { CmsDate } from 'common/models/cmsDate';
import * as notifier from 'common/services/notification';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { yesOption } from 'utils/formValues/commonOptions';
import { gibbTrueOption } from 'utils/formValues/evaluation';
import { BlockEvalFormData, UpdateLatestEstimateFormValues } from '../types';

type BlockEvalActions = {
  updateBlock: (formData: BlockEvalFormData) => Promise<void>;
  updateEvaluation: (formData: BlockEvalFormData) => Promise<void>;
  updateHarvestEstimate: (formData: BlockEvalFormData) => Promise<void>;
  updateSizeEstimates: (formData: BlockEvalFormData) => Promise<void>;
  updateOrchardMonitoring: (formData: BlockEvalFormData) => Promise<void>;
};

const useBlockEvalActions = (): BlockEvalActions => {
  const { id } = useParams<{ id: string }>();
  const [updateGrowerBlock] = useUpdateBlockMutation();
  const [updateBlockEvaluation] = useUpdateEvaluationMutation();
  const [updateLatestEstimate] = useUpdateLatestEstimateMutation();
  const [updateSizes] = useUpdateSizesMutation();
  const [updateMonitoring] = useUpdateMonitoringMutation();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const updateBlock = useCallback(
    async (formData: BlockEvalFormData) => {
      const {
        lotNumber,
        variety,
        primaryEvaluator,
        secondaryEvaluator,
        subVariety,
        rootstock,
        county,
        district,
        area,
        acres,
        trees,
        yearPlanted,
        latitude,
        longitude,
        notes,
      } = formData;
      const detailsDto = {
        lotNumber,
        variety,
        primaryEvaluator,
        secondaryEvaluator,
        subVariety,
        rootstock,
        county,
        district,
        area,
        acres,
        trees,
        yearPlanted,
        latitude,
        longitude,
        notes,
      } as UpdateDetailsDto;

      try {
        await updateGrowerBlock({
          seasonId: selectedSeason?.id as number,
          blockId: parseInt(id, 10),
          ...detailsDto,
        }).unwrap();
        notifier.showSuccessMessage('Block details updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, selectedSeason, updateGrowerBlock],
  );

  const updateEvaluation = useCallback(
    async (formData: BlockEvalFormData) => {
      const {
        evaluationDate,
        gibb,
        gibbRate,
        market,
        color,
        drop,
        priority,
        condition,
      } = formData;
      const evalDto = {
        evaluationDate: CmsDate.fromDateOnly(evaluationDate)?.toISO() ?? null,
        // convert gibb dropdown option from type 'Option' to type 'boolean'
        gibb: gibb.label === gibbTrueOption.label,
        gibbRate,
        market,
        color,
        drop,
        priority,
        condition,
      } as UpdateEvalDto;

      try {
        await updateBlockEvaluation({
          seasonId: selectedSeason?.id as number,
          blockId: parseInt(id, 10),
          ...evalDto,
        }).unwrap();
        notifier.showSuccessMessage('Evaluation updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, selectedSeason, updateBlockEvaluation],
  );

  const updateHarvestEstimate = useCallback(
    async (formData: BlockEvalFormData) => {
      const { estimatedBins: estimate }: UpdateLatestEstimateFormValues =
        formData;
      const latestEstDto = {
        estimatedBins: estimate,
      } as UpdateLatestEstimateDto;

      try {
        await updateLatestEstimate({
          seasonId: selectedSeason?.id as number,
          blockId: parseInt(id, 10),
          ...latestEstDto,
        }).unwrap();
        notifier.showSuccessMessage('Latest estimate updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, updateLatestEstimate, selectedSeason],
  );

  const updateSizeEstimates = useCallback(
    async (formData: BlockEvalFormData) => {
      const sizesDto = {
        sizeEstimates: formData.sizeEstimates,
      } as UpdateSizesDto;
      try {
        await updateSizes({
          seasonId: selectedSeason?.id as number,
          blockId: parseInt(id, 10),
          ...sizesDto,
        }).unwrap();
        notifier.showSuccessMessage('Size estimates updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, selectedSeason, updateSizes],
  );

  const updateOrchardMonitoring = useCallback(
    async (formData: BlockEvalFormData) => {
      const {
        monitorDate,
        harvestDate,
        skirtPruned,
        copperSpray_1,
        copperSpray_2,
        frbSpray_1,
        frbSpray_2,
        brownRot,
        usdaList,
        ckApproved,
        sampleDate,
        brix,
        acid,
        ratio,
      } = formData;
      const monitoringDto = {
        monitorDate: CmsDate.fromDateOnly(monitorDate)?.toISO(),
        harvestDate: CmsDate.fromDateOnly(harvestDate)?.toISO() ?? null,
        copperSpray_1: CmsDate.fromDateOnly(copperSpray_1)?.toISO() ?? null,
        copperSpray_2: CmsDate.fromDateOnly(copperSpray_2)?.toISO() ?? null,
        frbSpray_1: CmsDate.fromDateOnly(frbSpray_1)?.toISO() ?? null,
        frbSpray_2: CmsDate.fromDateOnly(frbSpray_2)?.toISO() ?? null,
        sampleDate: CmsDate.fromDateOnly(sampleDate)?.toISO() ?? null,
        skirtPruned: skirtPruned.label === yesOption.label,
        brownRot: brownRot.label === yesOption.label,
        usdaList: usdaList.label === yesOption.label,
        ckApproved: ckApproved.label === yesOption.label,
        brix,
        acid,
        ratio,
      } as UpdateMonitoringDto;

      try {
        await updateMonitoring({
          seasonId: selectedSeason?.id as number,
          blockId: parseInt(id, 10),
          ...monitoringDto,
        }).unwrap();
        notifier.showSuccessMessage('Orchard monitoring updated.');
      } catch (error) {
        handleError(error, Constants.errorMessages.SAVE_FAILED);
        throw error;
      }
    },
    [id, selectedSeason, updateMonitoring],
  );

  return {
    updateBlock,
    updateEvaluation,
    updateHarvestEstimate,
    updateSizeEstimates,
    updateOrchardMonitoring,
  };
};

export default useBlockEvalActions;
