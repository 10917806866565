import { darkNavy, lighterNavy } from 'common/styles/colors';
import { applyErrorBorder } from 'common/styles/form';
import { FC } from 'react';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { UseFormControllerProps } from '../DetailControls';

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 185px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled.input<{
  $isError: boolean;
}>`
  height: 32px;
  border: none;
  border-radius: 4px;
  font-family: KanitSemiBold;
  color: ${lighterNavy};
  text-align: center;
  ${props => applyErrorBorder(props.$isError)}
  width: 45%;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
`;

const ColonSeparator = styled.div`
  display: flex;
  justify-content: center;
  width: 10%;
  font-family: KanitSemiBold;
  font-size: 20px;
  color: ${darkNavy};
`;

export type TimeDuration = { hours: number; minutes: number };

type WizardDurationInputProps = UseFormControllerProps & {
  /**
   * An option to alter the max value allowed for the hour input.
   * The default is set to `23`.
   */
  maxHours?: number;
  /**
   * An option to alter the max value allowed for the minutes input.
   * The default is set to `59`.
   */
  maxMinutes?: number;
  /**
   * Optional error string to use when the input is bound
   * to a child inside a collection of items.
   */
  nestedPathError?: string;
};

/**
 * A component that has the ability to track time duration.
 * This relies on `useForm` state. The default max number input
 * for hours is `23` and the default max input for minutes is `59`.
 *
 * @remarks
 * When calling `useForm`, ensure the default value passed to the form
 * is of type {@link TimeDuration} for this component to function properly.
 */
export const WizardDurationInput: FC<WizardDurationInputProps> = ({
  name,
  formControl,
  maxHours = 23,
  maxMinutes = 59,
  nestedPathError,
}) => (
  <ComponentWrapper>
    <Controller
      name={name}
      control={formControl as never}
      shouldUnregister
      render={({
        field: { value: useFormValue, onChange },
        formState: { errors },
      }) => {
        const error = errors[`${name}`];

        const validate = (type: 'hours' | 'minutes', value: number): number => {
          if (type === 'hours') return value > maxHours ? maxHours : value;
          return value > maxMinutes ? maxMinutes : value;
        };

        return (
          <>
            <InputContainer>
              <Input
                type='number'
                placeholder='00'
                value={!useFormValue?.hours ? '' : useFormValue?.hours}
                onChange={event => {
                  const currentValue = parseInt(event.target.value, 10);
                  onChange({
                    hours: validate('hours', currentValue),
                    minutes: useFormValue?.minutes,
                  });
                }}
                $isError={!!error || !!nestedPathError}
              />
              <ColonSeparator>:</ColonSeparator>
              <Input
                type='number'
                placeholder='00'
                value={!useFormValue?.minutes ? '' : useFormValue?.minutes}
                onChange={event => {
                  const currentValue = parseInt(event.target.value, 10);
                  onChange({
                    hours: useFormValue?.hours,
                    minutes: validate('minutes', currentValue),
                  });
                }}
                $isError={!!error || !!nestedPathError}
              />
            </InputContainer>
            {/* The error Feedback control does not work without this hidden Input */}
            <Form.Check.Input hidden isInvalid={!!error || !!nestedPathError} />
            {(nestedPathError || error) && (
              <Form.Control.Feedback type='invalid'>
                <>{nestedPathError || error?.message}</>
              </Form.Control.Feedback>
            )}
          </>
        );
      }}
    />
  </ComponentWrapper>
);
