import { LoadingButton } from 'common/components/LoadingButton';
import { mobile } from 'common/styles/breakpoints';
import { AuthPagesMainBtn } from 'common/styles/button';
import styled from 'styled-components';
import { RememberPasswordLink } from './RememberPasswordLink';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  @media (max-width: ${mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

interface props {
  isValid: boolean;
  isSubmitting: boolean;
}

const ForgotPasswordFooter: React.FC<props> = ({
  isValid,
  isSubmitting,
}): JSX.Element => {
  return (
    <Wrapper>
      <LoadingButton
        as={AuthPagesMainBtn}
        style={{ marginBottom: '15%' }}
        type='submit'
        disabled={!isValid}
        loading={isSubmitting}
      >
        SEND
      </LoadingButton>
      <RememberPasswordLink />
    </Wrapper>
  );
};

export default ForgotPasswordFooter;
