import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { GrowersView, GrowerDetailView } from './pages';
import { BlockDetailView } from './pages/BlockDetailView';

export const GrowerRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={GrowersView}
        requiredRoles={[RoleType.Admin, RoleType.Analyst, RoleType.FieldRep]}
      />
      <PrivateRoute
        exact
        path={`${path}/update-grower/:id`}
        component={GrowerDetailView}
        requiredRoles={[RoleType.Admin, RoleType.Analyst, RoleType.FieldRep]}
      />
      <PrivateRoute
        exact
        path={`${path}/update-grower-block/:id`}
        component={BlockDetailView}
        requiredRoles={[RoleType.Admin, RoleType.Analyst, RoleType.FieldRep]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
