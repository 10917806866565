import { mobile } from 'common/styles/breakpoints';
import styled from 'styled-components';
import {
  Button,
  InfoValue,
  Notes,
  Prompt,
} from '../WizardComponents/wizardStyles';
import { darkNavy, lightGreyText, orange } from 'common/styles/colors';

export const MobileWrapper = styled.div`
  display: flex;

  div:first-child {
    margin-right: 30px;
  }

  @media (max-width: ${mobile}) {
    flex-direction: column;

    div:first-child {
      margin-right: 0;
    }
  }
`;

export const SummaryContainer = styled.div`
  width: 100%;
`;

export const NavyNotes = styled(Notes)`
  color: ${darkNavy};
`;

export const OrangePrompt = styled(Prompt)`
  color: ${orange};
`;

export const NavyPrompt = styled(Prompt)`
  color: ${darkNavy};
`;

export const OrangeButton = styled(Button)`
  background: ${darkNavy};
`;

export const NavyButton = styled(Button)`
  background: ${darkNavy};
`;

export const DataInfoValue = styled(InfoValue)<{
  $color: string;
}>`
  color: ${props => props.$color};
`;

export const NotesValue = styled(Notes)<{
  $color: string;
}>`
  color: ${props => props.$color};
`;

export const CrewInfoLabel = styled(DataInfoValue)`
  margin-left: 0;
`;

export const FormHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CrewHeader = styled.span`
  font-family: KanitSemiBold;
  font-size: 24px;
  letter-spacing: 0.0075em;
  color: ${lightGreyText};
  margin-right: 5px;
`;

export const TotalBinsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 20px;
`;

export const RemoveBtnWrapper = styled.div`
  display: grid;
  width: 100%;
`;
