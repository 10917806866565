import packageInfo from '../../package.json';
import { EnvironmentConfiguration, IEnvironment } from './types';

const createConfig = (
  name: EnvironmentConfiguration,
  apiUrl: string | undefined,
): IEnvironment => {
  return {
    name,
    isProductionBuild: process.env.NODE_ENV === 'production',
    apiRoute: apiUrl ?? '',
    appName: packageInfo.name,
    version: packageInfo.version,
  };
};

const createDevelopmentConfig = (): IEnvironment => {
  return createConfig(
    EnvironmentConfiguration.Development,
    process.env.REACT_APP_DEV_API_URL,
  );
};
const createTestingConfig = (): IEnvironment => {
  return createConfig(
    EnvironmentConfiguration.Testing,
    process.env.REACT_APP_TST_API_URL,
  );
};
const createStagingConfig = (): IEnvironment => {
  return createConfig(
    EnvironmentConfiguration.Staging,
    process.env.REACT_APP_STG_API_URL,
  );
};
const createDemoConfig = (): IEnvironment => {
  return createConfig(
    EnvironmentConfiguration.Demo,
    process.env.REACT_APP_DMO_API_URL,
  );
};
const createProductionConfig = (): IEnvironment => {
  return createConfig(
    EnvironmentConfiguration.Production,
    process.env.REACT_APP_PROD_API_URL,
  );
};

const getProdBuildConfig = (configName: string | undefined): IEnvironment => {
  let config: IEnvironment;

  switch (configName) {
    case EnvironmentConfiguration.Staging:
      config = createStagingConfig();
      break;

    case EnvironmentConfiguration.Demo:
      config = createDemoConfig();
      break;

    case EnvironmentConfiguration.Testing:
      config = createTestingConfig();
      break;

    default:
      config = createProductionConfig();
      break;
  }

  return config;
};

const getDevBuildConfig = (configName: string | undefined): IEnvironment => {
  let config: IEnvironment;

  switch (configName) {
    case EnvironmentConfiguration.Production:
      config = createProductionConfig();
      break;

    case EnvironmentConfiguration.Staging:
      config = createStagingConfig();
      break;

    case EnvironmentConfiguration.Demo:
      config = createDemoConfig();
      break;

    case EnvironmentConfiguration.Testing:
      config = createTestingConfig();
      break;

    default:
      config = createDevelopmentConfig();
      break;
  }

  return config;
};

/**
 * Loads environment configuration based on the current build type (`NODE_ENV`)
 * and requested configuration name (`REACT_APP_CONFIGURATION`).
 *
 * @returns The loaded configuration object.
 */
const loadEnvConfig = (): IEnvironment => {
  let envConfig: IEnvironment;
  const buildType = process.env.NODE_ENV;
  const requestedConfig = process.env.REACT_APP_CONFIGURATION;

  switch (buildType) {
    case 'production':
      envConfig = getProdBuildConfig(requestedConfig);
      break;

    case 'development':
      envConfig = getDevBuildConfig(requestedConfig);
      break;

    default:
      envConfig = getDevBuildConfig('');
      break;
  }

  return envConfig;
};

export default loadEnvConfig;
