import { useConfirmationModal } from 'features/confirmation-modal';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import * as notifier from 'common/services/notification';
import { handleError } from 'common/api/handleError';
import {
  pickScheduleApi,
  scheduledPickTag,
  useEditScheduledPickMutation,
  useSchedulePickMutation,
} from 'common/api/pickSchedulingApi';
import {
  useAssignTeamMutation,
  useSavePickRecordMutation,
} from 'common/api/harvestDataApi';
import { useAppDispatch } from 'app/redux';
import { HaulRate } from 'common/models/harvestData/hauler';
import { Contractor } from 'common/models/harvestData/contractor';
import { TimeWithoutDate } from 'common/models/dateType';
import { isApiError } from 'common/api/isApiError';

export type ScheduledPickPayload = {
  bins: number;
  pickDay: Date;
  blockId: number;
  packHouseId: number;
  poolId: number;
  cleanPick: boolean;
  pickType: number;
  allowMultiplePicks?: boolean;
  requestId?: number;
  market?: string;
  sizeId?: number;
  notes?: string;
};

export type AssignTeamPayload = {
  pickAssignmentId: number;
  scheduledPickId: number;
  coordinatorId: number;
  contractorPickAssignments: { contractorId: number; bins: number }[];
  haulers: Contractor[];
  notes: string;
};

export type PickRecordPayload = {
  pickRecordId?: number;
  schedulePickId: number;
  contractorRecords: {
    id?: number;
    startTime: Date;
    contractor: Contractor;
    headCount: number;
    hoursWorked: TimeWithoutDate;
    transferTime: TimeWithoutDate;
    binsPicked: number;
    pickRate: number;
  }[];
  haulerRecords: {
    id?: number;
    binsHauled: number;
    tarps: number;
    haulRate: HaulRate;
    hauler: Contractor;
    surcharge: number;
  }[];
  binsLeft: number;
  notes?: string;
};

export type EditScheduledPickPayload = {
  id: number;
  schedId: number;
  bins: number;
  poolId: number;
};

type PickScheduleActions = {
  openCancelSchedulingModal: () => void;
  savePickToSchedule: (
    pick: ScheduledPickPayload,
    force?: boolean,
  ) => Promise<'OK' | 'FAILED' | 'PENDING'>;
  assignHarvestTeam: (team: AssignTeamPayload) => Promise<boolean>;
  savePickData: (data: PickRecordPayload) => Promise<boolean>;
  tryEditScheduledPick: (payload: EditScheduledPickPayload) => Promise<boolean>;
};

const usePickScheduleActions = (): PickScheduleActions => {
  const history = useHistory();
  const { openModal } = useConfirmationModal();
  const [schedulePick] = useSchedulePickMutation();
  const [editScheduledPick] = useEditScheduledPickMutation();
  const [assignTeamForPick] = useAssignTeamMutation();
  const [savePickRecord] = useSavePickRecordMutation();
  const dispatch = useAppDispatch();

  const openCancelSchedulingModal = () => {
    openModal({
      message: 'If you exit, your changes will not be saved.',
      confirmButtonLabel: 'EXIT',
      declineButtonLabel: 'STAY',
      onConfirm: () => history.goBack(),
    });
  };

  const savePickToSchedule = useCallback(
    async (pick: ScheduledPickPayload, force?: boolean) => {
      try {
        await schedulePick({ ...pick, allowMultiplePicks: force }).unwrap();
        notifier.showSuccessMessage('Pick scheduled.');
        return 'OK';
      } catch (error) {
        const isExpectedException = isApiError(error);
        if (isExpectedException && error.status === 471) {
          notifier.showErrorMessage(
            'This block has already been scheduled for this packing house.',
          );
        } else if (isExpectedException && error.status === 472) {
          return 'PENDING';
        } else {
          handleError(
            error,
            'Unable to schedule pick, please try again later.',
          );
        }
        return 'FAILED';
      }
    },
    [schedulePick],
  );

  const assignHarvestTeam = useCallback(
    async (team: AssignTeamPayload) => {
      try {
        await assignTeamForPick(team).unwrap();
        notifier.showSuccessMessage('Team assigned.');
        // Force a refetch of the pick schedule object to show new assignment
        dispatch(pickScheduleApi.util.invalidateTags([scheduledPickTag]));
        return true;
      } catch (error) {
        handleError(error, 'Unable to assign team, please try again later.');
        return false;
      }
    },
    [assignTeamForPick, dispatch],
  );

  const savePickData = useCallback(
    async (data: PickRecordPayload) => {
      try {
        await savePickRecord(data).unwrap();
        notifier.showSuccessMessage('Pick data saved.');
        // Force a refetch of the pick schedule object to show new pick data
        dispatch(pickScheduleApi.util.invalidateTags([scheduledPickTag]));
        return true;
      } catch (error) {
        handleError(error, 'Unable to save pick data, please try again later.');
        return false;
      }
    },
    [savePickRecord, dispatch],
  );

  const tryEditScheduledPick = async (payload: EditScheduledPickPayload) => {
    try {
      await editScheduledPick(payload).unwrap();
      return true;
    } catch (error) {
      handleError(
        error,
        'Unable to edit scheduled pick, please try again later.',
      );
      return false;
    }
  };

  return {
    openCancelSchedulingModal,
    savePickToSchedule,
    assignHarvestTeam,
    savePickData,
    tryEditScheduledPick,
  };
};

export default usePickScheduleActions;
