import { DataTableText } from 'common/components/DataTable';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';
import { useMemo, useState } from 'react';
import { Column } from 'react-table';
import { MapParameters } from 'common/components/GoogleMapsModal/GmapsModalSlice';
import { useCheckboxColumn } from 'common/components/DataTable/hooks/useCheckboxColumn';
import { lightGreyText, orange } from 'common/styles/colors';
import {
  LocationButton,
  multipleLocationTooltip,
  validateCoordinateInfo,
} from 'common/components/LocationButton/LocationButton';
import { PickSchedule } from 'common/models';

export const dailyTotalStr = 'Total:';

export type PackHousePickScheduleTableData = {
  id: number;
  varietyName: string;
  pool: string;
  market: string;
  growerId: string;
  blockId: string;
  binsScheduled: number;
  binsPicked: number;
  binsLeft: number;
  fieldRep: string;
  harvestCoordinator: string;
  location: MapParameters | null;
  gapStatus: string;
  lotNumber: string;
  assignedPickCrews: string;
  assignedHaulers: string;
};

export type PackHousePickScheduleTableDataType = (
  isLoading: boolean,
  scheduledTotal?: number | null,
  pickedTotal?: number | null,
  binsLeftTotal?: number | null,
  scheduledPicks?: PickSchedule[],
) => {
  columns: Column<PackHousePickScheduleTableData>[];
  data: PackHousePickScheduleTableData[];
};

export const usePackHousePickScheduleTableData: PackHousePickScheduleTableDataType =
  (
    isLoading,
    scheduledTotal,
    pickedTotal,
    binsLeftTotal,
    scheduledPicks = [],
  ) => {
    const [locations, setLocations] = useState<MapParameters[]>([]);
    const [locationsToShow, setLocationsToShow] = useState<MapParameters[]>([]);

    const locationsColumn: Column<PackHousePickScheduleTableData> =
      useCheckboxColumn(
        'location',
        '1fr',
        locations,
        setLocationsToShow,
        () => (
          <LocationButton
            backgroundColor={orange}
            iconColor={lightGreyText}
            mapParams={locationsToShow}
            disabledTooltip={multipleLocationTooltip}
          />
        ),
        isLoading,
      );
    const columns: Column<PackHousePickScheduleTableData>[] = useMemo(
      () => [
        {
          accessor: 'growerId',
          Header: 'Grower',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'blockId',
          Header: 'Block',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'varietyName',
          Header: 'Variety',
          Footer: dailyTotalStr,
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'binsScheduled',
          Header: () => (
            <>
              Bins
              <br />
              Scheduled
            </>
          ),
          Footer: scheduledTotal || 0,
          width: '1fr',
          Cell: ({ value }) => <span>{value}</span>,
        },
        {
          accessor: 'binsPicked',
          Header: () => (
            <>
              Bins
              <br />
              Picked
            </>
          ),
          Footer: pickedTotal || 0,
          width: '1fr',
          Cell: ({ value }) => <span>{value}</span>,
        },
        {
          accessor: 'binsLeft',
          Header: () => (
            <>
              Bins
              <br />
              Left
            </>
          ),
          Footer: binsLeftTotal || 0,
          width: '1fr',
          Cell: ({ value }) => <span>{value}</span>,
        },
        {
          accessor: 'market',
          Header: 'Market',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'fieldRep',
          Header: 'FR',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'harvestCoordinator',
          Header: 'HC',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'assignedPickCrews',
          Header: 'Pick Crews',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'assignedHaulers',
          Header: () => (
            <>
              Haul-Only
              <br />
              Crews
            </>
          ),
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'gapStatus',
          Header: () => (
            <>
              Gap
              <br />
              Status
            </>
          ),
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'lotNumber',
          Header: 'Lot #',
          width: '1fr',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        locationsColumn,
        {
          id: 'Arrow',
          Header: '',
          width: '1fr',
          Cell: () => <ForwardArrow />,
        },
      ],
      [binsLeftTotal, locationsColumn, pickedTotal, scheduledTotal],
    );

    const data: PackHousePickScheduleTableData[] = useMemo(
      () =>
        scheduledPicks.map((scheduledPick, index) => {
          const {
            market,
            blockId,
            fieldRep,
            harvestCoordinator,
            latitude,
            longitude,
            crews,
            haulers,
            gapStatus,
            lotNumber,
            ...rest
          } = scheduledPick;

          const location = validateCoordinateInfo(latitude, longitude, blockId);
          if (location) {
            setLocations(prev => {
              // When the index is zero, we know data is being reloaded (e.g., filter
              // applied or page change), so we take the chance to clear previous
              // locations, otherwise we would preserve stale selections. This is a
              // workaround for the inability to clear selections via useEffect.
              if (index === 0) return [location];
              return [...prev, location];
            });
          }

          const createContractorList = (
            contractors: {
              code: string;
              name: string;
            }[],
          ) => contractors.map(contractor => contractor.code).join(', ');

          return {
            market: market || '-',
            blockId,
            fieldRep:
              fieldRep.firstName && fieldRep.lastName
                ? `${fieldRep.lastName}`
                : '-',
            harvestCoordinator:
              harvestCoordinator.firstName && harvestCoordinator.lastName
                ? harvestCoordinator.lastName
                : '-',
            location,
            assignedPickCrews:
              crews.length > 0 ? createContractorList(crews) : '-',
            assignedHaulers:
              haulers.length > 0 ? createContractorList(haulers) : '-',
            gapStatus: gapStatus || '-',
            lotNumber: lotNumber || '-',
            ...rest,
          };
        }),
      [scheduledPicks],
    );

    return { columns, data };
  };
