import { FC, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AddNewLineBtn,
  CancelBtn,
  DescriptionInput,
  LineItemTextarea,
  NewLineBtnsContainer,
  NewLineContainer,
  QuantityInput,
  RateInput,
  SubmitNewLineBtn,
} from './styles';
import { orange } from 'common/styles/colors';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import {
  WizardDropdown,
  WizardDropdownOption,
} from 'common/components/WizardControls/WizardDropdown';
import { WizardNumericInput } from 'common/components/WizardControls/WizardNumericInput';
import { EmptyCell } from '../styles';
import { usePickTable } from 'features/harvest-payroll/hooks/usePickTable';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  HaulingLineType,
  HarvestLineType,
  MiscLineType,
} from 'common/models/harvestData/payrollData';
import { toEnumLabel } from 'utils/enumFunctions';
import { addPickLineSchema } from 'utils/schemas/payrollSchema';
import { useAppSelector } from 'app/redux';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { useDispatch } from 'react-redux';

type PickRowValues = {
  quantity: string | null;
  category: WizardDropdownOption<string> | null;
  type: WizardDropdownOption<string> | null;
  rate: string | null;
  note: string | null;
};

export type ValidatedPickRow = {
  [K in keyof PickRowValues]: NonNullable<PickRowValues[K]>;
};

/** Component to add or edit a picks table line item. */
export const AddPickLineForm: FC<{
  pickId: number;
  defaultQuantity: string | number;
  defaultCtrRecId: number | null;
  defaultHaulRecIds: {
    contractorRecordId: number | null;
    haulerRecordId: number | null;
  };
  rates: { haulRate: string; surchargeRate: string };
}> = ({
  pickId,
  defaultQuantity,
  defaultCtrRecId,
  defaultHaulRecIds,
  rates,
}) => {
  const prompt = 'Add Pick Line';
  const dispatch = useDispatch();

  // State value to show or hide the form.
  const [toggleOptions, setToggleOptions] = useState(false);

  // State values to handle form input display.
  const [hideQuantityInput, setHideQuantityInput] = useState(true);
  const [hideRateInput, setHideRateInput] = useState(false);
  const [categorySelected, setCategorySelected] = useState<string | null>(null);
  const [disableType, setDisableType] = useState(true);
  const [typeOptions, setTypeOptions] = useState<
    WizardDropdownOption<string>[]
  >([]);
  const [showNoteField, setShowNoteField] = useState(false);

  const { addPickRow, addMiscRow } = usePickTable();
  const { isDirty, newRowCount } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );

  const methods = useForm<PickRowValues>({
    defaultValues: {
      quantity: null,
      category: null,
      type: null,
      rate: null,
      note: null,
    },
    resolver: yupResolver(addPickLineSchema),
    mode: 'all',
    context: {
      quantityRequired: !hideQuantityInput,
      rateRequired: !hideRateInput,
      noteRequired: showNoteField,
    },
  });
  const {
    control,
    formState: { isSubmitSuccessful, errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  const descriptionCatOptions: WizardDropdownOption<string>[] = [
    HarvestLineType[HarvestLineType.Harvesting],
    HaulingLineType[HaulingLineType.Hauling],
    MiscLineType[MiscLineType.MISC],
  ].map(value => ({ label: toEnumLabel(value), value }));

  const harvestTypeKeys = Object.keys(HarvestLineType);

  const harvestTypeOptions: WizardDropdownOption<string>[] = harvestTypeKeys
    .splice(harvestTypeKeys.length / 2)
    .filter(key => key !== HarvestLineType[HarvestLineType.Harvesting])
    .map(value => ({ label: toEnumLabel(value), value }));

  const haulTypeOptions: WizardDropdownOption<string>[] = [
    HaulingLineType[HaulingLineType.Hauling],
    HaulingLineType[HaulingLineType.Fuel_Surcharge],
    HaulingLineType[HaulingLineType.Minimum_Load],
    HaulingLineType[HaulingLineType.Commission],
  ].map(value => ({ label: toEnumLabel(value), value }));

  // Listen to user input and change form display accordingly.
  useEffect(() => {
    const watchForm = watch(value => {
      const categorySelected = value?.category?.value;
      const typeSelected = value?.type?.value;
      const typesWithQuantities = [
        HarvestLineType[HarvestLineType.Breaks],
        HarvestLineType[HarvestLineType.Minimum_Wage],
        HarvestLineType[HarvestLineType.NPT],
        MiscLineType[MiscLineType.MISC],
        HaulingLineType[HaulingLineType.Minimum_Load],
      ];

      const setHarvHaulState = (options: WizardDropdownOption<string>[]) => {
        setShowNoteField(false);
        setDisableType(false);
        setTypeOptions(options);
        setHideQuantityInput(true);
        setHideRateInput(true);

        if (typeSelected) {
          if (typesWithQuantities.includes(typeSelected)) {
            setHideQuantityInput(false);
          }

          if (typeSelected === HaulingLineType[HaulingLineType.Minimum_Load]) {
            setHideRateInput(true);
          } else {
            setHideRateInput(false);
          }
        }
      };

      switch (categorySelected) {
        case undefined:
          // Reset form to initial values.
          setDisableType(true);
          setTypeOptions([]);
          setHideQuantityInput(true);
          setHideRateInput(true);
          break;

        case HarvestLineType[HarvestLineType.Harvesting]:
          setHarvHaulState(harvestTypeOptions);
          break;

        case HaulingLineType[HaulingLineType.Hauling]:
          setHarvHaulState(haulTypeOptions);
          break;

        case MiscLineType[MiscLineType.MISC]:
          setShowNoteField(true);
          setHideQuantityInput(false);
          setHideRateInput(false);
          break;

        default:
        // do nothing
      }
    });

    return () => watchForm.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    if (hideQuantityInput) {
      setValue('quantity', null);
    }
  }, [hideQuantityInput, setValue]);

  useEffect(() => {
    if (hideRateInput) {
      setValue('rate', null);
    }
  }, [hideRateInput, setValue]);

  useEffect(() => {
    if (showNoteField) {
      setValue('type', null);
      setValue('quantity', '1');
    }
  }, [setValue, showNoteField]);

  // Set type to 'null' when category changes, then set new category.
  useEffect(() => {
    const selectedCategory = getValues('category')?.value;

    if (selectedCategory && selectedCategory !== categorySelected) {
      setValue('type', null);
      setCategorySelected(selectedCategory);
    }
  }, [typeOptions, getValues, setValue, categorySelected]);

  // Clean up
  useEffect(() => {
    if (isSubmitSuccessful) {
      setDisableType(true);
      setShowNoteField(false);
      reset();
    }
  }, [isSubmitSuccessful, reset]);

  /** Form submission handler to add (or edit) a picks table row. */
  const handleCustomRow = (formData: PickRowValues) => {
    if (pickId) {
      // Force type here because validation must have passed by now.
      if (formData?.category?.value === MiscLineType[MiscLineType.MISC]) {
        addMiscRow(pickId, newRowCount, formData as ValidatedPickRow);
      } else {
        const isSurcharge =
          formData?.type?.value ===
          HaulingLineType[HaulingLineType.Fuel_Surcharge];
        const isMinLoad =
          formData?.type?.value ===
          HaulingLineType[HaulingLineType.Minimum_Load];

        addPickRow(
          pickId,
          newRowCount,
          {
            ...formData,
            ...(isSurcharge ? { quantity: defaultQuantity } : {}),
          } as ValidatedPickRow,
          defaultCtrRecId,
          defaultHaulRecIds,
          isMinLoad ? rates : undefined,
        );
      }

      if (!isDirty) {
        dispatch(payrollSlice.actions.setIsDirty(true));
      }
    }
  };

  return toggleOptions ? (
    <FormProvider {...methods}>
      <NewLineContainer onSubmit={handleSubmit(handleCustomRow)}>
        <QuantityInput>
          <WizardNumericInput name='quantity' isHidden={hideQuantityInput} />
        </QuantityInput>
        <DescriptionInput>
          <WizardDropdown
            name='category'
            formControl={control}
            options={descriptionCatOptions}
            placeholder='Select category...'
          />
          {showNoteField ? (
            <>
              <LineItemTextarea
                {...register('note')}
                placeholder='Enter note...'
                rows={1}
                $isError={!!errors.note}
              />
              {/* The error Feedback control does not work without this hidden Input */}
              <Form.Check.Input hidden isInvalid={!!errors.note} />
              {!!errors.note && (
                <Form.Control.Feedback type='invalid'>
                  <>{errors.note?.message}</>
                </Form.Control.Feedback>
              )}
            </>
          ) : (
            <WizardDropdown
              name='type'
              formControl={control}
              options={typeOptions}
              placeholder='Select type...'
              isDisabled={disableType}
            />
          )}
        </DescriptionInput>
        <RateInput>
          <WizardNumericInput name='rate' isHidden={hideRateInput} />
        </RateInput>
        <NewLineBtnsContainer>
          <CancelBtn
            type='button'
            onClick={() => {
              setToggleOptions(false);
              setDisableType(true);
              setShowNoteField(false);
              reset();
            }}
          >
            Cancel
          </CancelBtn>
          <SubmitNewLineBtn type='submit'>
            <AddCircleOutlineOutlinedIcon htmlColor={orange} />
            {prompt}
          </SubmitNewLineBtn>
        </NewLineBtnsContainer>
      </NewLineContainer>
      <EmptyCell />
      <EmptyCell />
    </FormProvider>
  ) : (
    <>
      <AddNewLineBtn type='button' onClick={() => setToggleOptions(true)}>
        <AddCircleOutlineOutlinedIcon htmlColor={orange} />
        {prompt}
      </AddNewLineBtn>
      <EmptyCell />
    </>
  );
};
