import { useMemo } from 'react';
import { Column } from 'react-table';
import { GrowerTableItem } from './useGrowerTableData';
import { StatusIcon } from '../components/StatusIcon';
import { DataTableText } from 'common/components/DataTable';
import { ForwardArrow } from '../components/GrowerIcons';

export const useGrowerTableDataMobile = (): Column<GrowerTableItem>[] => {
  return useMemo(
    () => [
      {
        accessor: 'status',
        Cell: ({ value: status }) => (
          <StatusIcon status={status} type='grower' />
        ),
      },
      {
        accessor: 'name',
        Header: 'Grower name',
        Cell: ({ value: values }) => (
          <DataTableText>{`${values.name}`}</DataTableText>
        ),
      },
      {
        accessor: 'growerId',
        Header: 'Grower ID',
        Cell: ({ value }) => <DataTableText>{`${value}`}</DataTableText>,
      },
      {
        id: 'Arrow',
        Header: '',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );
};
