import { ModalWrapper } from 'common/components/ModalWrapper';
import {
  WeekSwitcherIds,
  useWeekSwitcherState,
} from 'common/components/WeekSwitcher/useWeekSwitcherState';
import { FC } from 'react';
import { useDaySelector } from '../../hooks/useDaySelector';
import styled, { css } from 'styled-components';
import { blueShade, darkNavy } from 'common/styles/colors';
import { FormPadding } from 'common/styles/form';
import { LoginButton } from 'common/styles/button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoadingButton } from 'common/components/LoadingButton';

interface ModalProps {
  isOpen: boolean;
  isLoading: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onConfirm: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const textColor = css`
  color: ${blueShade};
  padding: 0 ${FormPadding};
  font-size: 24px;
`;

const Header = styled.div`
  ${textColor}
  margin-top: ${FormPadding};
  h3 {
    font-family: KanitExtraLight;
  }
`;

const Body = styled.div`
  ${textColor}
  p {
    font-family: KanitExtraLight;
    margin-bottom: 4px;
  }
`;

const Footer = styled.div`
  ${textColor}
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${FormPadding};
`;

const BackButton = styled(LoginButton)`
  font-size: 14pt;
  background-color: transparent;
  padding: 0;
  text-transform: capitalize;
`;

const ConfirmButton = styled(LoginButton)`
  max-width: fit-content;
`;

export const ApproveScheduleModal: FC<ModalProps> = ({
  isOpen,
  isLoading,
  closeModal,
  onConfirm,
}) => {
  const {
    state: { startDate },
  } = useWeekSwitcherState(WeekSwitcherIds.PickSchedule);
  const { offset } = useDaySelector();
  const currentDate = new Date(startDate);
  currentDate.setDate(startDate.getDate() + offset.value);

  const header = (
    <Header>
      <h3>Approve Schedule?</h3>
      <h4>
        {currentDate.toLocaleString([], { weekday: 'long' })}
        &nbsp;&bull;&nbsp;
        {currentDate.toLocaleString([], { month: 'numeric', day: 'numeric' })}
      </h4>
    </Header>
  );
  const body = (
    <Body>
      <p>By approving:</p>
      <ul>
        <li>I understand the schedule will be sent to Spokane</li>
        <li>I agree to send tomorrow&apos;s pick text message</li>
      </ul>
    </Body>
  );

  const footer = (
    <Footer>
      <BackButton type='button' onClick={closeModal} disabled={isLoading}>
        <ChevronLeftIcon />
        &nbsp;Back
      </BackButton>

      <LoadingButton as={ConfirmButton} loading={isLoading} onClick={onConfirm}>
        CONFIRM
      </LoadingButton>
    </Footer>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      backgroundColor={darkNavy}
      isLoading={false}
      width='min(620px, 100%)'
      header={header}
      body={body}
      footer={footer}
      closeModal={closeModal}
      preventBackdropClosure={isLoading}
    />
  );
};
