import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import { PackHouse, PackPlanRowData } from 'common/models/packHouse';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { DayInfo, Week } from 'utils/dateTimeHelpers';
import {
  DateColumnContainer,
  FooterText,
  PackDate,
  PackDay,
  PHCode,
  RemoveRowBtnContainer,
  TableFooter,
  TableHeader,
  TableRow,
  TotalsColumnContainer,
  TotalsFooter,
  TotalsHeader,
} from './styles';
import { NewPackRowForm } from './NewPackRowForm';
import { Variety } from 'common/models';
import { useRbac } from 'features/rbac';
import * as notifier from 'common/services/notification';
import { Constants } from 'utils/constants';
import { useGetPackPlanQuery } from 'common/api/packHouse.api';
import { PackPlanRow } from './PackPlanRow';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { handleError } from 'common/api/handleError';
import { BinRequest } from 'common/models/binRequest';
import { roundBins } from 'common/models/harvestData/utilities';
import { PlanViewContext } from 'features/pack-plan-views/pages/PackPlanView/PackPlanView';
import { CsvHeaders } from 'common/components/CsvButton/CsvButton';
import {
  CsvPlanHeaderKeys,
  prepareCsvPlanData,
} from 'features/pack-plan-views/utils/prepareCsvPlanData';

export type PackPlanRowCombo = {
  rowId: string;
} & PackPlanRowData;

export type NewRowFormData = {
  rowId: string;
  varietyId: number;
  market: string;
  sizeId: number;
};

export const PackPlanTable: FC<{
  packHouse: PackHouse;
  week: Week;
  varieties: Variety[] | undefined;
  isLoadingVarieties: boolean;
  setCsvPlanData: React.Dispatch<
    React.SetStateAction<{
      headers: CsvHeaders<CsvPlanHeaderKeys>[];
      rows: Record<string, Record<string, string>[]>;
    }>
  >;
}> = ({ packHouse, week, varieties, isLoadingVarieties, setCsvPlanData }) => {
  const { weekdays } = week;
  const { year, month, day } = weekdays[0];
  const {
    data: planData,
    isLoading: isLoadingPlan,
    isFetching: isFetchingPlan,
    error: fetchPlanError,
  } = useGetPackPlanQuery({
    packHouseId: packHouse.id,
    year,
    month,
    day,
  });

  const { packPlan, dayTotals } = planData ?? {};
  const { isSavingReq, setLoadingPlanCount } = useContext(PlanViewContext);
  const { userHasPermission } = useRbac();
  const columnTotals = weekdays.map(weekday => {
    const columnTotal = dayTotals?.find(
      ({ date }) => new Date(date).getUTCDate() === weekday.day,
    );

    return {
      date: `${weekday.month}/${weekday.day}`,
      total: columnTotal ? columnTotal.total : 0,
    };
  });
  const getEmptyReq = ({ year, month, day }: DayInfo): BinRequest => {
    // decrement needed to account for 0 basing in months
    const prevMonth = month - 1 < 0 ? 11 : month - 1;
    return {
      id: null,
      date: new Date(year, prevMonth, day).toString(),
      bins: 0,
    };
  };
  const [newRows, setNewRows] = useState([] as PackPlanRowCombo[]);

  const planRows = useMemo(() => {
    const formattedRows: PackPlanRowCombo[] | undefined = packPlan?.map(
      ({
        variety,
        market,
        size,
        binRequests,
        totalRequested,
        totalPicked,
      }) => ({
        rowId: `${variety.id}${market}${size.id}`,
        variety,
        market,
        size,
        binRequests: weekdays.map(weekday => {
          const binReq = binRequests.find(
            req => weekday.day === new Date(req.date).getUTCDate(),
          );
          if (!binReq) return getEmptyReq(weekday);
          return binReq;
        }),
        totalRequested,
        totalPicked,
      }),
    );
    return [...(formattedRows || []), ...newRows].sort((a, b) => {
      let result = a.variety.varietyCode.localeCompare(b.variety.varietyCode);
      if (result === 0) result = a.market.localeCompare(b.market);
      if (result === 0) result = a.size.value.localeCompare(b.size.value);
      return result;
    });
  }, [newRows, packPlan, weekdays]);

  const reqPickedTotals = useMemo(() => {
    const totals = { totalRequested: 0, totalPicked: 0 };
    packPlan?.forEach(row => {
      totals.totalRequested += row.totalRequested;
      totals.totalPicked += row.totalPicked;
    });
    return totals;
  }, [packPlan]);

  const addNewRow = ({ varietyId, market, sizeId }: NewRowFormData) => {
    const rowId = `${varietyId}${market}${sizeId}`;
    const comboExists = !!planRows.find(row => row.rowId === rowId);
    const targetVariety = varieties?.find(vty => vty.id === varietyId);
    const targetSize = targetVariety?.sizes.find(size => size.id === sizeId);

    if (comboExists) {
      notifier.showErrorMessage(Constants.errorMessages.COMBINATION_EXISTS);
    } else if (!targetVariety) {
      notifier.showErrorMessage('Variety not available.');
    } else if (!targetSize) {
      notifier.showErrorMessage('Size not available.');
    } else {
      setNewRows([
        ...newRows,
        {
          rowId,
          variety: targetVariety,
          market,
          size: targetSize,
          binRequests: weekdays.map(weekday => getEmptyReq(weekday)) as Extract<
            PackPlanRowCombo,
            'binRequests'
          >,
          totalRequested: 0,
          totalPicked: 0,
        },
      ]);
    }
  };

  const removeNewRow = (rowId: string) =>
    setNewRows([...newRows.filter(row => row.rowId !== rowId)]);

  // When querying the plan, inform parent of loading state.
  useEffect(() => {
    if (isLoadingPlan || isFetchingPlan) {
      setLoadingPlanCount(prev => prev + 1);
    } else {
      setLoadingPlanCount(prev => (prev > 0 ? prev - 1 : prev));
    }
  }, [isLoadingPlan, isFetchingPlan, setLoadingPlanCount]);

  // On plan load, update parent component of new data for csv file
  useEffect(() => {
    const { headers, rows } = prepareCsvPlanData(
      week,
      packHouse.code,
      planRows,
      columnTotals,
      reqPickedTotals,
    );
    setCsvPlanData(data => ({
      headers,
      rows: {
        ...data.rows,
        ...{
          [`${rows[0].pHouseCode}`]: rows,
        },
      },
    }));
    // Subscribe only to pack plan changes as all other values derive from it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packPlan]);

  useEffect(() => {
    if (fetchPlanError) {
      handleError(
        fetchPlanError,
        `Unable to load ${packHouse.name} Pack Plan.`,
      );
    }
  }, [fetchPlanError, packHouse.name]);

  return (
    <>
      <TableHeader>
        <PHCode>
          <WithTooltip title='Pack House' tooltipText={packHouse.name}>
            <span>{packHouse.code}</span>
          </WithTooltip>
        </PHCode>
        {weekdays.map(({ weekday, month, day }) => (
          <DateColumnContainer key={`${month}/${day}`}>
            <PackDay>{weekday}</PackDay>
            <PackDate>{`${month}/${day}`}</PackDate>
          </DateColumnContainer>
        ))}
        <RemoveRowBtnContainer />
        <TotalsColumnContainer>
          <TotalsHeader>Total Sales</TotalsHeader>
          <TotalsHeader>Total Pick</TotalsHeader>
        </TotalsColumnContainer>
      </TableHeader>

      <WithLoadingOverlay
        isLoading={isLoadingPlan || isFetchingPlan || isSavingReq}
        size='2em'
      >
        {planRows.map(row => (
          <PackPlanRow
            key={row.rowId}
            packHouse={packHouse}
            row={row}
            newRows={newRows}
            removeNewRow={removeNewRow}
          />
        ))}
      </WithLoadingOverlay>

      {userHasPermission('packPlan:create-row') && (
        <TableRow>
          <NewPackRowForm
            id={packHouse.id}
            varieties={varieties}
            isLoading={isLoadingVarieties}
            onAddRowReq={addNewRow}
          />
        </TableRow>
      )}

      <TableFooter>
        <FooterText>Totals</FooterText>
        {columnTotals.map(({ date, total }) => (
          <DateColumnContainer key={date} className='day-total'>
            <span>{total || '00'}</span>
          </DateColumnContainer>
        ))}
        <RemoveRowBtnContainer />
        <TotalsColumnContainer>
          <TotalsFooter>{reqPickedTotals.totalRequested || '00'}</TotalsFooter>
          <TotalsFooter>
            {roundBins(reqPickedTotals.totalPicked) || '00'}
          </TotalsFooter>
        </TotalsColumnContainer>
      </TableFooter>
    </>
  );
};
