import { StyledText } from 'common/components/DetailControls';
import { StyledLabel } from 'common/components/DetailControls/DetailControl';
import { darkNavy, grey, white } from 'common/styles/colors';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { VarietalSumTableItem } from '../hooks/useVarietalSumTableData';
import { ArrowBackIos } from '@mui/icons-material';

const varietyDetailHeaders: {
  title: string;
  accessor: keyof VarietalSumTableItem;
}[] = [
  {
    title: 'Acres',
    accessor: 'acres',
  },
  {
    title: 'Original Est.',
    accessor: 'originalEstimate',
  },
  {
    title: 'Revised Est.',
    accessor: 'revisedEstimate',
  },
  {
    title: 'Rev. vs. Orig.',
    accessor: 'estimateDifference',
  },
  {
    title: 'Harvested',
    accessor: 'harvestedBins',
  },
  {
    title: 'Percent Harvested',
    accessor: 'harvestedPercent',
  },
  {
    title: 'Bins to Go',
    accessor: 'binsRemaining',
  },
];

type VarietyDetailModalProps = {
  title: string;
  fields: VarietalSumTableItem;
  toggleOpenState: (state: boolean) => void;
  modalState: boolean;
};

const VarietalData = styled.div`
  background-color: ${white};
  padding: 1rem;
  border-radius: 1rem;
`;

const VarietySubHeader = styled(Modal.Title)`
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  color: ${darkNavy};
`;

const VarietySubHeaderArrow = styled(ArrowBackIos)`
  color: ${grey};
  font-size: '1.3rem';
`;

const VarietyName = styled.h4`
  color: ${darkNavy};
`;

const FieldRow = styled(Row)`
  align-items: 'flex-end';
  border-bottom: 1px solid ${grey};
  background-color: ${white};
`;

export const VarietyDetailModal = ({
  title,
  fields,
  toggleOpenState,
  modalState,
}: VarietyDetailModalProps): React.ReactElement => {
  return (
    <Modal
      show={modalState}
      onHide={() => toggleOpenState(false)}
      style={{ marginTop: '25vh' }}
    >
      <VarietalData>
        <VarietySubHeader>
          <VarietySubHeaderArrow onClick={() => toggleOpenState(false)} />
          {title}
        </VarietySubHeader>
        <VarietyName>{fields.varietyInfo.code}</VarietyName>
        {varietyDetailHeaders.map(({ title, accessor }) => (
          <FieldRow key={accessor}>
            <Col>
              <StyledLabel>{title}</StyledLabel>
            </Col>
            <Col>
              <StyledText value={fields[accessor]} readOnly plaintext />
            </Col>
          </FieldRow>
        ))}
      </VarietalData>
    </Modal>
  );
};
