import { mobile } from 'common/styles/breakpoints';
import {
  grey,
  lighterNavy,
  orange,
  tableHeaderGrey,
} from 'common/styles/colors';
import { FC, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { DataTablePaginationProps } from './DataTable';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

/**
 * Creates the array of numbers for the paginator to display to the user.
 *
 * @param currentPage The page that the user is currently on.
 * @param pageCount The total number of pages available.
 * @param linkCount The max amount of page numbers to show.
 */
function createPageNumArray(
  currentPage: number,
  pageCount: number,
  linkCount = 3,
): number[] {
  const arr: number[] = [];
  if (linkCount >= pageCount) {
    for (let i = 0; i < pageCount; i += 1) {
      arr.push(i + 1);
    }
    return arr;
  }

  const range = Math.floor(linkCount / 2);
  const firstLink = currentPage - range;
  const lastLink = currentPage + (linkCount - range - 1);
  let offset = 0;

  if (firstLink < 1) {
    offset = 1 + Math.abs(firstLink);
  } else if (lastLink > pageCount) {
    offset = pageCount - lastLink;
  }

  for (let i = 0; i < linkCount; i += 1) {
    arr.push(offset + firstLink + i);
  }

  return arr;
}

type Props = DataTablePaginationProps & {
  unitToPaginate?: string;
  isFetching: boolean;
};

const StyledContainer = styled.div`
  @media (max-width: ${mobile}) {
    justify-content: center;
  }

  p {
    color: ${grey};
    margin: 0;
    width: 25px;
    text-align: center;
  }

  button {
    padding-right: 0;
    padding-left: 0;
  }

  .viewing-page {
    color: ${lighterNavy};
  }

  .viewing-page-underscore {
    background-color: ${orange};
  }
`;

const StyledSpan = styled.span`
  color: ${tableHeaderGrey};
  font-weight: 600;
  @media (max-width: ${mobile}) {
    text-align: center;
  }
`;

export const Paginator: FC<Props> = ({
  unitToPaginate,
  page,
  pageSize,
  count,
  pageCount,
  getPage,
  getNextPage,
  getPreviousPage,
  hasNextPage,
  hasPreviousPage,
  isFetching,
}) => {
  const rangeStart = pageSize * (page - 1) + 1;
  const rangeEnd = page < pageCount ? pageSize * page : count;

  const pageNumArr = useMemo(
    () => createPageNumArray(page, pageCount),
    [page, pageCount],
  );

  return (
    <div className='d-flex flex-column mt-2'>
      <StyledSpan>
        Showing {rangeStart} to {rangeEnd} of {count} {unitToPaginate}
      </StyledSpan>
      <StyledContainer className='d-flex align-items-center'>
        <Button
          variant='link'
          disabled={!hasPreviousPage || isFetching}
          onClick={() => getPreviousPage()}
        >
          <ChevronLeftIcon />
        </Button>
        <p style={{ height: '100%' }}>{page - 3 > 1 ? '...' : ''}</p>
        {pageNumArr.map(num => (
          <button
            type='button'
            disabled={isFetching}
            tabIndex={num}
            key={num}
            className='d-flex flex-column m-1'
            onClick={() => getPage(num)}
            style={{ border: 'none', background: 'inherit' }}
          >
            <p className={num === page ? 'viewing-page' : ''}>{num}</p>
            <div
              className={num === page ? 'viewing-page-underscore' : ''}
              style={{ width: '100%', height: 3 }}
            />
          </button>
        ))}
        <p style={{ height: '100%' }}>{page + 3 < pageCount ? '...' : ''}</p>
        <Button
          variant='link'
          disabled={!hasNextPage || isFetching}
          onClick={() => getNextPage()}
        >
          <ChevronRightIcon />
        </Button>
      </StyledContainer>
    </div>
  );
};
