import { RoleType } from 'common/models';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { abbreviateRole, determineRoleDotColor } from 'utils/helperFunction';
import ErrorIcon from '@mui/icons-material/Error';
import { UserTableItem, Circle } from './useUserTableData';
import { ForwardArrow } from 'features/grower-views/components/GrowerIcons';

export const useUserTableDataMobile = (): Column<UserTableItem>[] => {
  const linkToUpdateUser = (values: Record<string, unknown>) => (
    <span>{`${values.name}`}</span>
  );

  return useMemo(
    () => [
      {
        accessor: 'notActivated',
        Cell: ({ value: notActivated }) =>
          notActivated ? <ErrorIcon color='error' /> : null,
      },
      {
        accessor: 'fullName',
        Header: 'Name',
        Cell: ({ value }) => linkToUpdateUser(value),
      },
      {
        accessor: 'role',
        Header: 'Role',
        Cell: ({ value }) => (
          <div className='d-flex align-items-center'>
            <Circle className={`${determineRoleDotColor(value as RoleType)}`} />
            <span style={{ fontFamily: 'KanitLight' }}>
              {abbreviateRole(value as RoleType)}
            </span>
          </div>
        ),
      },
      {
        id: 'Arrow',
        Header: '',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );
};
