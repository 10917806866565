import { blueShade, orange } from 'common/styles/colors';
import styled from 'styled-components';
import {
  Button,
  InfoValue,
  Prompt,
  WizardRow,
} from '../WizardComponents/wizardStyles';

export const InfoRow = styled(WizardRow)`
  justify-content: flex-end;
`;

export const BlueShadeInfoValue = styled(InfoValue)`
  color: ${blueShade};
`;

export const DataEntryRow = styled(WizardRow)`
  margin-top: 20px;
`;

export const OrangePrompt = styled(Prompt)`
  color: ${orange};
`;

export const OrangeButton = styled(Button)`
  background: ${orange};
`;
