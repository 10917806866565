import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { PaginatedResult, PaginationQueryParams, Session } from 'common/models';
import { User } from 'common/models/user';
import { FilterCategoryOptions } from 'common/hooks';
import {
  FilterValueSeparator,
  withFilters,
} from 'common/api/utils/apiFunctions';

export type ActivateAccountRequest = {
  token: string;
  newPassword: string;
  confirmPassword: string;
};

export type ChangePasswordRequest = Pick<User, 'id'> & {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};
export type ConfirmChangeEmailRequest = {
  token: string;
  verificationCode: number;
};
export type CreateUserRequest = Pick<
  User,
  'email' | 'firstName' | 'lastName' | 'profilePicture' | 'role'
>;
export type ForgotPasswordRequest = Pick<User, 'email'>;
export type ForgotPasswordResponse = { message: string };
export type ResendActivationEmailRequest = Pick<User, 'id'>;
export type ResendChangeEmailVerificationEmailRequest = Pick<User, 'id'>;
export type ResetPasswordRequest = {
  token: string;
  newPassword: string;
  confirmPassword: string;
};
export type SignUpRequest = Pick<User, 'email' | 'firstName' | 'lastName'>;
export type UpdateProfileRequest = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'profilePicture'
>;
export type UpdateUserRequest = Pick<
  User,
  | 'id'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'profilePicture'
  | 'role'
  | 'phone'
  | 'notes'
>;
export type UserChangeEmailRequest = Pick<User, 'id' | 'email'>;

type UserFilterQueryParams = {
  filters: FilterCategoryOptions[];
};

const usersBaseUrl = '/users';

const userTag = 'user';
const usersTableTag = 'users';
const usersByRoleTag = 'users-by-role';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: customBaseQuery,
  tagTypes: [userTag, usersTableTag, usersByRoleTag],

  endpoints: builder => ({
    getUsers: builder.query<
      PaginatedResult<User>,
      PaginationQueryParams & UserFilterQueryParams
    >({
      query: prm => withFilters(prm, usersBaseUrl),
      providesTags: (_, err) => (err ? [] : [usersTableTag]),
    }),

    getUsersByRoles: builder.query<User[], { roleIds: number[] }>({
      query: payload => {
        const rolesParam = payload.roleIds.join(FilterValueSeparator);

        return {
          url: `${usersBaseUrl}/by-roles/${rolesParam}`,
          method: 'GET',
        };
      },
      // Use a different cache per page (i.e.: the drowdown in
      // 'Block Details' lists all users whereas the 'Crew Assignment'
      // lists only field reps).
      providesTags: (_, err, arg) =>
        err
          ? []
          : [
              {
                type: usersByRoleTag,
                id: arg.roleIds.join(FilterValueSeparator),
              },
            ],
    }),

    getUserDetails: builder.query<User, { id: number | string }>({
      query: req => ({
        url: `${usersBaseUrl}/${req.id}`,
        method: 'GET',
      }),
      providesTags: (_, err, arg) =>
        err ? [] : [{ type: userTag, id: arg.id }],
    }),

    createUser: builder.mutation<User, CreateUserRequest>({
      query: payload => ({
        url: usersBaseUrl,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: (_, err) => (err ? [] : [usersTableTag]),
    }),

    updateUser: builder.mutation<User, UpdateUserRequest>({
      query: ({ id, ...payload }) => ({
        url: `${usersBaseUrl}/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, arg) =>
        err ? [] : [usersTableTag, { type: userTag, id: arg.id }],
    }),

    deleteUser: builder.mutation<void, number>({
      query: userId => ({
        url: `${usersBaseUrl}/${userId}`,
        method: 'DELETE',
      }),
      // InvalidateTags was removed because it causes the user details
      // page to re-render for a non-existing user, triggering an error.
      // If this is an issue in the future, the user detail page will have to be fixed.
    }),

    changePassword: builder.mutation<Session, ChangePasswordRequest>({
      query: ({ id, ...payload }) => ({
        url: `${usersBaseUrl}/change-password/${id}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    forgotPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordRequest
    >({
      query: payload => ({
        url: `${usersBaseUrl}/forgot-password`,
        method: 'POST',
        body: payload,
      }),
    }),

    activateAccount: builder.mutation<User, ActivateAccountRequest>({
      query: ({ token, ...payload }) => ({
        url: `${usersBaseUrl}/activate-account/${token}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    resetPassword: builder.mutation<User, ResetPasswordRequest>({
      query: ({ token, ...payload }) => ({
        url: `${usersBaseUrl}/reset-password/${token}`,
        method: 'PUT',
        body: payload,
      }),
    }),

    resendActivationEmail: builder.mutation<void, ResendActivationEmailRequest>(
      {
        query: ({ id }) => ({
          url: `${usersBaseUrl}/resend-activation-email/${id}`,
          method: 'GET',
        }),
      },
    ),

    resendChangeEmailVerificationEmail: builder.mutation<
      void,
      ResendChangeEmailVerificationEmailRequest
    >({
      query: ({ id }) => ({
        url: `${usersBaseUrl}/resend-change-email-verification-email/${id}`,
        method: 'GET',
      }),
    }),

    confirmChangeEmail: builder.mutation<User, ConfirmChangeEmailRequest>({
      query: ({ token, ...payload }) => ({
        url: `${usersBaseUrl}/confirm-change-email/${token}`,
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useActivateAccountMutation,
  useChangePasswordMutation,
  useConfirmChangeEmailMutation,
  useCreateUserMutation,
  useDeleteUserMutation,
  useForgotPasswordMutation,
  useGetUsersByRolesQuery,
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useResendActivationEmailMutation,
  useResendChangeEmailVerificationEmailMutation,
  useResetPasswordMutation,
  useUpdateUserMutation,
} = userApi;
