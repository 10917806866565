import { lightGreyBackground } from 'common/styles/colors';
import { FC } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components';

const HeaderWrapper = styled.section.attrs(() => ({
  className: 'tabs-header-wrapper',
}))`
  display: flex;
  width: 100%;

  overflow: scroll hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding: 0 12px;
  background-color: ${lightGreyBackground};

  a {
    width: 100%;
    color: unset;
    text-decoration: unset;
  }
`;

const ViewWrapper = styled.section`
  width: 100%;
`;

/**
 * The shape of a tab `object` passed to {@link NestedTabs}
 * @prop {function} header - Returns JSX for the tab header
 * @prop {function} view - Returns JSX for the content of the tab
 * @prop {string} tabPath - The relative path for the tab
 */
export interface NestedTab {
  /**
   * Creates the tab header; `boolean` param disables tab clicking
   */
  header: (isSelected: boolean) => JSX.Element;
  view: () => JSX.Element;
  tabPath: string;
}

interface NestedTabsProps {
  tabs: NestedTab[];
  /**
   * Optional boolean to disable all tabs
   * (e.g. used when a form inside a tab is in edit mode)
   */
  isDisabled?: boolean;
}

/**
 * Component that renders nested `React Router` tabs
 * @param props {@link NestedTabsProps}
 */
export const NestedTabs: FC<NestedTabsProps> = ({
  tabs,
  isDisabled = false,
}) => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  let tabHeaders = <></>;

  tabs.forEach(tab => {
    const currentTabPath = pathname.substring(url.length);
    const isSelected = currentTabPath === `${tab.tabPath}`;

    tabHeaders = (
      <>
        {tabHeaders}
        <Link
          to={`${url}${tab.tabPath}`}
          style={isSelected || isDisabled ? { pointerEvents: 'none' } : {}}
        >
          {tab.header(isSelected)}
        </Link>
      </>
    );
  });

  const tabViews = (
    <Switch>
      {tabs.map(tab => {
        return (
          <Route key={tab.tabPath} exact path={`${path}${tab.tabPath}`}>
            {tab.view()}
          </Route>
        );
      })}
      {/* Catch invalid tab paths */}
      <Route>
        <Redirect to='/error' />
      </Route>
    </Switch>
  );

  return (
    <>
      <HeaderWrapper>{tabHeaders}</HeaderWrapper>
      <ViewWrapper>{tabViews}</ViewWrapper>
    </>
  );
};
