import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { District } from 'common/models';

export type GetDistrictList = [Pick<District, 'id' | 'districtId'>];

export const districtApi = createApi({
  reducerPath: 'districtApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['District'],

  endpoints: builder => ({
    getDistricts: builder.query<GetDistrictList, void>({
      query: () => ({
        url: '/district',
      }),
      providesTags: (_, err) => (err ? [] : ['District']),
    }),
  }),
});

export const { useGetDistrictsQuery } = districtApi;
