import { mobile } from 'common/styles/breakpoints';
import { lighterNavy } from 'common/styles/colors';
import { ReactElement, ReactNode, useCallback, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styled, { css } from 'styled-components';
import { DetailFormContext } from './DetailForm';
import { ExtendedDetailSeparatorStyle } from './styles';

const StyledRow = styled(Form.Group).attrs(() => ({
  as: Row,
}))`
  color: ${lighterNavy};

  ${({ $onViewMode }) => $onViewMode && `pointer-events: none;`}

  @media (max-width: ${mobile}) {
    margin: 0;
    display: flex;
    gap: 10px;

    & > div {
      width: auto;
      padding: 5px 0;
    }

    & > div:first-of-type {
      width: 100px;
      padding-left: 1rem;
    }

    & > div:last-of-type {
      padding-right: 1rem;
    }
  }

  ${props =>
    props.$withSeparator &&
    css`
      ${ExtendedDetailSeparatorStyle}
    `}
`;

const StyledLabelCol = styled(Col)`
  max-width: ${props => (props.$labelMax ? `${props.$labelMax}%` : 'none')};
  padding: 5px 0;
  margin-top: 0;
`;

export const StyledLabel = styled(Form.Label)`
  && {
    font-family: KanitLight;
    color: ${lighterNavy};
    line-height: 138%;
    white-space: no-wrap;
  }
  @media (max-width: ${mobile}) {
    width: 100%;
  }
`;

/**
 * Defines properties common to all {@link DetailControl}-based controls.
 */
export interface DetailControlProps {
  /** The user-friendly label that describes the value. */
  label: string;

  /**
   * Indicates whether to make the label column larger than the default,
   * useful to prevent labels from wrapping to another line.
   */
  largeLabel?: boolean;

  /**
   * Indicates whether to limit the label column to a maximum width (as a
   * percentage of its container).
   */
  labelMax?: number;

  /** Indicates whether to show a separator under the control. Defaults to true. */
  withSeparator?: boolean;

  /**
   * A custom ID to set on the control. If not provided, one is generated from
   * the label.
   */
  controlId?: string;
}

/**
 * Defines properties accepted by the {@link DetailControl}.
 */
interface DetailRendererProps extends DetailControlProps {
  /**
   * The function that will render the control's value. This is passed an ID
   * that can be set on a rendered element, tying it the control's label.
   * */
  valueRenderer: (id: string) => ReactNode;

  isViewMode?: boolean;
}

/**
 * A base control that can be used to build specialized "detail items", that is,
 * items with a label and an associated value rendered in a custom way.
 *
 * @param props The properties needed by the control.
 * @returns A new instance of the control.
 */
export const DetailControl = (props: DetailRendererProps): ReactElement => {
  const {
    label,
    largeLabel,
    labelMax,
    valueRenderer,
    withSeparator = true,
    controlId,
    isViewMode,
  } = props;

  const context = useContext(DetailFormContext);

  const generateId = useCallback((): string => {
    return label
      .split(' ')
      .map((word, i) => (i === 0 ? word.toLowerCase() : word))
      .join('');
  }, [label]);

  return (
    <StyledRow $withSeparator={withSeparator} $onViewMode={isViewMode}>
      <StyledLabelCol
        lg={largeLabel || context.largeLabels ? 3 : 2}
        className='g-lg-1'
        $labelMax={labelMax}
      >
        <StyledLabel htmlFor={controlId ?? generateId()}>{label}</StyledLabel>
      </StyledLabelCol>
      <Col className='g-lg-0'>{valueRenderer(controlId ?? generateId())}</Col>
    </StyledRow>
  );
};
