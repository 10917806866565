import { CsvBtn } from './style';

export const emptyCellValue = '';

type CsvKeys<T extends string> = `${T}`;

export type CsvHeaders<T extends string> = {
  label: string;
  key: CsvKeys<T>;
};

/**
 * A generic type is passed to ensure that the name of the keys of objects
 * in the `data` array match one of the values of the `key`
 * prop in the `headers` array. Without a match, an empty row will show
 * instead.
 */
type CsvButtonProps<T extends string> = {
  /** The column headers of the table */
  headers: CsvHeaders<T>[];
  /** The array of rows containing cell data */
  data: Record<T, string>[];
  /** An optional string to name the saved file */
  filename?: string;
  /**
   * Callback that allows both synchronous vs asynchronous work to be done
   * before generating the csv file. See `react-csv` docs for implementation details.
   * */
  onClick?: () => Promise<boolean> | boolean | void;
  /**
   * Disables the button and applies a grey background to indicate so. Default is `false`.
   * */
  isLoading?: boolean;
  /**
   * Disables the button and applies a grey background to indicate so. Default is `false`.
   * */
  isDisabled?: boolean;
  /**
   * An optional prop to change the label of the button. Default is
   * 'Export to CSV'.
   */
  label?: string;
  /**
   * An optional prop to expose the inherent 'id' attribute. Useful for manual
   * event triggering.
   */
  id?: string;
  /**
   * An optional prop to expose the inherent 'hidden' attribute.
   */
  isHidden?: boolean;
};

/**
 * A wrapper for the `CsvLink` component from the `react-csv` package.
 */
export const CsvButton = <T extends string>({
  headers,
  data,
  filename,
  onClick = () => true,
  isLoading = false,
  isDisabled = false,
  label = 'Export to CSV',
  id = undefined,
  isHidden = false,
}: CsvButtonProps<T>) => {
  return (
    <CsvBtn
      headers={headers}
      data={data}
      onClick={() => (isDisabled || isLoading ? false : onClick())}
      {...(filename && { filename })}
      {...(id && { id })}
      hidden={isHidden}
      $isLoading={isLoading}
      $isDisabled={isDisabled}
    >
      {label}
    </CsvBtn>
  );
};
