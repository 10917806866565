import { ReactElement, useCallback } from 'react';
import { DetailControl, DetailControlProps } from './DetailControl';
import { SimpleDetailTableStyler } from './styles';
import { BaseTableItem, GenericTable, TableHeader } from '../GenericTable';

/**
 * Defines properties accepted by the {@link DetailTable} control.
 */
interface DetailTableProps<TItem extends BaseTableItem>
  extends DetailControlProps {
  /** The items to display as a table. */
  items: TItem[];

  /**
   * A message to show when the table is empty. If not provided, a generic
   * message is shown.
   */
  emptyMessage?: string;
}

/**
 * Displays a table of items to the side of a label, for use in item detail
 * forms.
 *
 * @param props The properties needed by the control.
 * @returns A new instance of the control.
 */
export const DetailTable = <TableItem extends BaseTableItem>(
  props: DetailTableProps<TableItem>,
): ReactElement => {
  const { items, emptyMessage } = props;
  type AutoGeneratedHeader = TableHeader<TableItem>;

  // This generates headers from the items' property names, setting both key and
  // label the same, since these are not intended for display.
  const getHeaders = useCallback((): AutoGeneratedHeader[] => {
    return items.length === 0
      ? []
      : Object.getOwnPropertyNames(items[0]).map(prp => {
          return { key: prp as keyof TableItem, label: prp };
        });
  }, [items]);

  return (
    <DetailControl
      {...props}
      valueRenderer={() => (
        <SimpleDetailTableStyler>
          <GenericTable
            headers={getHeaders()}
            items={items}
            showHeaders={false}
            emptyMessage={emptyMessage}
          />
        </SimpleDetailTableStyler>
      )}
    />
  );
};
