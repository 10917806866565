import { useMemo } from 'react';
import { Column } from 'react-table';
import { VarietalSummaryRow } from 'common/models';
import { DataTableText } from 'common/components/DataTable/DataTableStyles';
import styled from 'styled-components';
import WithTooltip from 'common/components/WithTooltip/WithTooltip';

export type VarietalSumTableItem = {
  varietyInfo: { name: string; code: string };
  district: number;
  acres: number;
  originalEstimate: number;
  revisedEstimate: number;
  estimateDifference: number;
  harvestedBins: number;
  harvestedPercent: number;
  binsRemaining: number;
};

export type UseVarietalSumTableData = (agents?: VarietalSummaryRow[]) => {
  columns: Column<VarietalSumTableItem>[];
  data: { rows: VarietalSumTableItem[]; totalRow: VarietalSumTableItem };
};

const centerStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
};

const totalStyle = {
  ...centerStyle,
  fontFamily: 'KanitMedium',
};

const TableHeader = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: 'KanitSemiBold';
  font-size: 16px;
`;

export const toFormattedNumber = (
  value: number | undefined,
  round = false,
): string => {
  if (value) {
    return (round ? Math.round(value) : value).toLocaleString();
  }
  return '0';
};

export const useVarietalSumTableData: UseVarietalSumTableData = (rows = []) => {
  const data: { rows: VarietalSumTableItem[]; totalRow: VarietalSumTableItem } =
    useMemo(() => {
      let acresTotal = 0;
      let orgEstTotal = 0;
      let revEstTotal = 0;
      let estDiffTotal = 0;
      let harvPercTotal = 0;
      let harvBinsTotal = 0;
      let binsRemTotal = 0;

      const newRows: VarietalSumTableItem[] = rows.map(
        ({
          variety,
          varietyCode,
          district,
          acres,
          originalEstimate,
          revisedEstimate,
          estimateDifference,
          harvestedBins,
          harvestedPercent,
          binsRemaining,
        }) => {
          const varietyInfo = {
            name: variety,
            code: varietyCode,
          };

          acresTotal += acres;
          orgEstTotal += originalEstimate;
          revEstTotal += revisedEstimate;
          estDiffTotal += estimateDifference;
          harvPercTotal += harvestedPercent;
          harvBinsTotal += harvestedBins;
          binsRemTotal += binsRemaining;

          return {
            varietyInfo,
            district,
            acres,
            originalEstimate,
            revisedEstimate,
            estimateDifference,
            harvestedBins,
            harvestedPercent,
            binsRemaining,
          };
        },
      );

      const totalRow: VarietalSumTableItem = {
        varietyInfo: { name: '', code: '' },
        district: 0,
        acres: acresTotal,
        originalEstimate: orgEstTotal,
        revisedEstimate: revEstTotal,
        estimateDifference: estDiffTotal,
        harvestedBins: harvBinsTotal,
        harvestedPercent: Math.round((harvPercTotal / rows.length) * 100) / 100,
        binsRemaining: binsRemTotal,
      };

      return { rows: newRows, totalRow };
    }, [rows]);

  const columns: Column<VarietalSumTableItem>[] = useMemo(
    () => [
      {
        accessor: 'varietyInfo',
        Header: () => (
          <span
            style={{
              ...centerStyle,
              fontFamily: 'KanitSemiBold',
              fontSize: 16,
            }}
          >
            Variety
          </span>
        ),
        Cell: ({ value: variety }) => (
          <WithTooltip title='variety-name' tooltipText={variety.name}>
            <span style={{ ...centerStyle }}>{variety.code}</span>
          </WithTooltip>
        ),
      },
      {
        accessor: 'district',
        Header: () => <TableHeader>District</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {value ? value.toLocaleString() : '-'}
          </DataTableText>
        ),
        Footer: () => <span style={{ ...totalStyle }}>Total:</span>,
      },
      {
        accessor: 'acres',
        Header: () => <TableHeader>Acres</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {data.totalRow.acres.toLocaleString()}
          </span>
        ),
      },
      {
        accessor: 'originalEstimate',
        Header: () => <TableHeader>Original Est.</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {data.totalRow.originalEstimate.toLocaleString()}
          </span>
        ),
      },
      {
        accessor: 'revisedEstimate',
        Header: () => <TableHeader>Revised Est.</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value, true)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {toFormattedNumber(data.totalRow.revisedEstimate, true)}
          </span>
        ),
      },
      {
        accessor: 'estimateDifference',
        Header: () => <TableHeader>Rev. vs Orig.</TableHeader>,
        width: '1fr',
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {data.totalRow.estimateDifference.toLocaleString()}
          </span>
        ),
      },
      {
        accessor: 'harvestedBins',
        Header: () => <TableHeader>Harvested</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value, true)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {toFormattedNumber(data.totalRow.harvestedBins, true)}
          </span>
        ),
      },
      {
        accessor: 'binsRemaining',
        Header: () => <TableHeader>Bins to Go</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {toFormattedNumber(value)}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {data.totalRow.binsRemaining.toLocaleString()}
          </span>
        ),
      },
      {
        accessor: 'harvestedPercent',
        Header: () => <TableHeader>% Harvested</TableHeader>,
        Cell: ({ value }) => (
          <DataTableText style={centerStyle}>
            {value ? `${value}%` : '0%'}
          </DataTableText>
        ),
        Footer: () => (
          <span style={{ ...totalStyle }}>
            {`${data.totalRow.harvestedPercent.toLocaleString()}%`}
          </span>
        ),
      },
    ],
    [data],
  );

  return { columns, data };
};
