import { BlockEvalData } from 'common/api/dto/growerBlockApiTypes';
import { CsvHeaders } from 'common/components/CsvButton/CsvButton';
import { BlockStatus } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';

const enum BlockDetailKeys {
  growerId = 'growerId',
  blockId = 'blockId',
  status = 'status',
  variety = 'variety',
  subVariety = 'subVariety',
  acres = 'acres',
  trees = 'trees',
  parentGrower = 'parentGrower',
  evaluator = 'evaluator',
}

const blockDetailLabels: Record<BlockDetailKeys, string> = {
  growerId: 'Grower ID',
  blockId: 'Block ID',
  status: 'Status',
  variety: 'Variety',
  subVariety: 'Sub Variety',
  acres: 'Acres',
  trees: 'Trees',
  parentGrower: 'Parent Grower',
  evaluator: 'Assigned',
};

const blockDetailHeaders: CsvHeaders<BlockDetailKeys>[] = [
  { label: blockDetailLabels.growerId, key: BlockDetailKeys.growerId },
  { label: blockDetailLabels.blockId, key: BlockDetailKeys.blockId },
  { label: blockDetailLabels.status, key: BlockDetailKeys.status },
  { label: blockDetailLabels.variety, key: BlockDetailKeys.variety },
  { label: blockDetailLabels.subVariety, key: BlockDetailKeys.subVariety },
  { label: blockDetailLabels.acres, key: BlockDetailKeys.acres },
  { label: blockDetailLabels.trees, key: BlockDetailKeys.trees },
  { label: blockDetailLabels.parentGrower, key: BlockDetailKeys.parentGrower },
  { label: blockDetailLabels.evaluator, key: BlockDetailKeys.evaluator },
];

const enum EvalKeys {
  evalDate = 'evalDate',
  market = 'market',
  gibb = 'gibb',
}

const evalLabels: Record<EvalKeys, string> = {
  evalDate: 'Eval. Date',
  market: 'Market',
  gibb: 'Gibb',
};

const evaluationHeaders: CsvHeaders<EvalKeys>[] = [
  { label: evalLabels.evalDate, key: EvalKeys.evalDate },
  { label: evalLabels.market, key: EvalKeys.market },
  { label: evalLabels.gibb, key: EvalKeys.gibb },
];

const enum EstimateKeys {
  initialEst = 'initialEst',
  currentEst = 'currentEst',
  binsPicked = 'binsPicked',
  binsLeft = 'binsLeft',
}

const estimateLabels: Record<EstimateKeys, string> = {
  initialEst: 'Initial Estimate',
  currentEst: 'Current Estimate',
  binsPicked: 'Bins Harvested',
  binsLeft: 'Bins To Go',
};

const estimateHeaders: CsvHeaders<EstimateKeys>[] = [
  { label: estimateLabels.initialEst, key: EstimateKeys.initialEst },
  { label: estimateLabels.currentEst, key: EstimateKeys.currentEst },
  { label: estimateLabels.binsPicked, key: EstimateKeys.binsPicked },
  { label: estimateLabels.binsLeft, key: EstimateKeys.binsLeft },
];

type DynamicSizeKeys = string;

type DynamicSizeHeaders = { label: string; key: DynamicSizeKeys }[];

type AllEvalKeys = BlockDetailKeys | EvalKeys | EstimateKeys | DynamicSizeKeys;

/**
 * Returns csv formated headers and rows containing all block evaluations
 * and estimates.
 */
export const formatAllEvalCsvData = (
  data: BlockEvalData[],
): {
  rows: Record<AllEvalKeys, string>[];
  headers: CsvHeaders<AllEvalKeys>[];
} => {
  const sizeHeaders: DynamicSizeHeaders = [];
  const rows: Record<AllEvalKeys, string>[] = [];

  data.forEach(block => {
    const blockSizes: Record<DynamicSizeKeys, string> = {};

    (block?.sizeEstimates || []).forEach(({ size, percentage }) => {
      const hasSize = sizeHeaders.some(header => header.label === size);

      if (!hasSize) {
        sizeHeaders.push({ label: size, key: size });
      }
      blockSizes[size] = percentage ? `${percentage}%` : '';
    });

    rows.push({
      blockId: block.blockId,
      status: block.status ? BlockStatus[block.status] : '',
      growerId: block.growerId,
      variety: block.variety || '',
      subVariety: block.subVariety || '',
      acres: block.acres?.toString() || '',
      trees: block.trees?.toString() || '',
      parentGrower: block.parentGrower || '',
      evaluator: `${block.evaluatorFirst}${
        block.evaluatorLast ? ` ${block.evaluatorLast}` : ''
      }`,
      evalDate: CmsDate.parse(block.evalDate)?.toString() || '',
      market: block?.market || '',
      gibb: block?.gibb ? 'Y' : 'N',
      initialEst: block?.initialEst?.toString() || '',
      currentEst: block?.currentEst?.toString() || '',
      binsPicked: block?.binsPicked?.toString() || '',
      binsLeft: block?.binsLeft?.toString() || '',
      ...blockSizes,
    });
  });

  return {
    rows,
    headers: [
      ...blockDetailHeaders,
      ...evaluationHeaders,
      ...estimateHeaders,
      ...sizeHeaders.sort(
        (a, b) => parseInt(a.label, 10) - parseInt(b.label, 10),
      ),
    ],
  };
};
