import { FC, ReactNode } from 'react';
import { WizardContainer } from './wizardStyles';
import { orange } from 'common/styles/colors';

type WizardContainerProps = {
  children?: ReactNode;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  backgroundColor: string;
};

export const PickDataWizardContainer: FC<WizardContainerProps> = ({
  children,
  onSubmit,
  backgroundColor,
}) => {
  return (
    <WizardContainer
      onSubmit={onSubmit}
      $backgroundColor={backgroundColor ?? orange}
      $width='755px'
    >
      {children}
    </WizardContainer>
  );
};
