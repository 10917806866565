import {
  CsvHeaders,
  emptyCellValue,
} from 'common/components/CsvButton/CsvButton';
import { DateWithoutTime } from 'common/models/dateType';

export type PayrollTableRow = Record<PayrollKeys, string> & {
  metadata: RowMetadata;
};

export const emptyIdKey = 'empty';
export const totalIdKey = 'total';

/**
 * Metadata attached to every row to hold data for:
 * - Creating React keys in JSX loops,
 * - Keeping track of ids to create or update invoice items.
 */
export type RowMetadata = {
  /** Required on all rows */
  pickId: number;
  /**
   * Can be set to `null`.
   *
   * Note: if this property is defined,
   * `haulerRecordId` should be `null`.
   */
  contractorRecordId: number | null;
  /**
   * Can be set to `null`.
   *
   * Note: if this property is defined,
   * `contractorRecordId` should be `null`.
   */
  haulerRecordId: number | null;
  /** Tracks an existing invoice item. If `null`, the item was just created
   * and was not saved yet. */
  itemId: number | null;
  /** Needed for JSX key generation for new rows. */
  discriminator: number | null;
  descCategory: string;
  descType: string;
  invItemType: 'harv' | 'haul' | 'null';

  // Transfer-specific metadata
  transferDate?: DateWithoutTime;
  surchargeId?: number;
  chargeTarps?: boolean;
};

/**
 * A combination of keys that identify the group to which a pick row belongs.
 *
 * @remarks
 *
 * A group of rows is displayed together in the invoice and their amounts can
 * be added or processed in some way to produce a meaningful result. For
 * example, a commission can be calculated for all the rows in a group.
 *
 * Also note that, technically, just comparing the contractor or hauler record
 * IDs, plus the category, would be sufficient to determine if two rows belong
 * to the same group, but having the pick ID as part of this is harmless and can
 * provide useful context.
 */
export type RowGroupKeys = Pick<
  RowMetadata,
  'pickId' | 'contractorRecordId' | 'haulerRecordId' | 'descCategory'
>;

export const enum PayrollKeys {
  pickDay = 'pickDay',
  pick = 'pick',
  block = 'block',
  quantity = 'quantity',
  description = 'description',
  rate = 'rate',
  subtotal = 'subtotal',
}

export const enum AccountsPayableKeys {
  vendorId = 'vendorId',
  poolId = 'poolId',
  growerId = 'growerId',
  blockId = 'blockId',
  glId = 'glId',
  period = 'period',
  invoiceId = 'invoiceId',
  invoiceDate = 'invoiceDate',
  dueDate = 'dueDate',
  invoiceAmount = 'invoiceAmount',
  bankId = 'bankId',
  transCode = 'transCode',
  apDescription = 'apDescription',
  advanceTransCode = 'advanceTransCode',
  advanceDescription = 'advanceDescription',
  postToGrower = 'postToGrower',
}

const picksHeaderLabels: Record<PayrollKeys, string> = {
  pickDay: 'Date',
  pick: 'Pick',
  block: 'Block',
  quantity: 'Bins/Qty',
  description: 'Description/note',
  rate: 'Rate',
  subtotal: 'Subtotal',
};

const transfersHeaderLabels: Record<PayrollKeys, string> = {
  pickDay: 'Date',
  pick: 'Transfer',
  block: '',
  quantity: 'Loads',
  description: 'Description/note',
  rate: 'Rate',
  subtotal: 'Subtotal',
};

const accountsPayableLabels: Record<AccountsPayableKeys, string> = {
  vendorId: 'Vendor ID',
  poolId: 'Pool ID',
  growerId: 'Grower ID',
  blockId: 'Block #',
  glId: 'G/L #',
  period: 'Period',
  invoiceId: 'Invoice #',
  invoiceDate: 'Invoice Date',
  dueDate: 'Due Date',
  invoiceAmount: 'Invoice Amount',
  bankId: 'Bank ID',
  transCode: 'Trans Code',
  apDescription: 'A/P Description',
  advanceTransCode: 'Advance Trans Code',
  advanceDescription: 'Advance Description',
  postToGrower: 'Post to Grower (G/P/Y/N)',
};

export const emptyPayrollRow: PayrollTableRow = {
  metadata: {
    pickId: -1,
    contractorRecordId: null,
    haulerRecordId: null,
    itemId: null,
    discriminator: null,
    descCategory: emptyIdKey,
    descType: emptyIdKey,
    invItemType: 'null',
  },
  [`${PayrollKeys.pickDay}`]: emptyCellValue,
  [`${PayrollKeys.pick}`]: emptyCellValue,
  [`${PayrollKeys.block}`]: emptyCellValue,
  [`${PayrollKeys.quantity}`]: emptyCellValue,
  [`${PayrollKeys.description}`]: emptyCellValue,
  [`${PayrollKeys.rate}`]: emptyCellValue,
  [`${PayrollKeys.subtotal}`]: emptyCellValue,
};

export const picksHeaders: CsvHeaders<PayrollKeys>[] = [
  { label: picksHeaderLabels.pickDay, key: PayrollKeys.pickDay },
  { label: picksHeaderLabels.pick, key: PayrollKeys.pick },
  { label: picksHeaderLabels.block, key: PayrollKeys.block },
  { label: picksHeaderLabels.quantity, key: PayrollKeys.quantity },
  { label: picksHeaderLabels.description, key: PayrollKeys.description },
  { label: picksHeaderLabels.rate, key: PayrollKeys.rate },
  { label: picksHeaderLabels.subtotal, key: PayrollKeys.subtotal },
];

export const transfersHeaders: CsvHeaders<PayrollKeys>[] = [
  { label: transfersHeaderLabels.pickDay, key: PayrollKeys.pickDay },
  { label: transfersHeaderLabels.pick, key: PayrollKeys.pick },
  { label: transfersHeaderLabels.block, key: PayrollKeys.block },
  { label: transfersHeaderLabels.quantity, key: PayrollKeys.quantity },
  { label: transfersHeaderLabels.description, key: PayrollKeys.description },
  { label: transfersHeaderLabels.rate, key: PayrollKeys.rate },
  { label: transfersHeaderLabels.subtotal, key: PayrollKeys.subtotal },
];

export const AccountsPayableHeaders: CsvHeaders<AccountsPayableKeys>[] = [
  { label: accountsPayableLabels.vendorId, key: AccountsPayableKeys.vendorId },
  { label: accountsPayableLabels.poolId, key: AccountsPayableKeys.poolId },
  { label: accountsPayableLabels.growerId, key: AccountsPayableKeys.growerId },
  { label: accountsPayableLabels.blockId, key: AccountsPayableKeys.blockId },
  { label: accountsPayableLabels.glId, key: AccountsPayableKeys.glId },
  { label: accountsPayableLabels.period, key: AccountsPayableKeys.period },
  {
    label: accountsPayableLabels.invoiceId,
    key: AccountsPayableKeys.invoiceId,
  },
  {
    label: accountsPayableLabels.invoiceDate,
    key: AccountsPayableKeys.invoiceDate,
  },
  { label: accountsPayableLabels.dueDate, key: AccountsPayableKeys.dueDate },
  {
    label: accountsPayableLabels.invoiceAmount,
    key: AccountsPayableKeys.invoiceAmount,
  },
  { label: accountsPayableLabels.bankId, key: AccountsPayableKeys.bankId },
  {
    label: accountsPayableLabels.transCode,
    key: AccountsPayableKeys.transCode,
  },
  {
    label: accountsPayableLabels.apDescription,
    key: AccountsPayableKeys.apDescription,
  },
  {
    label: accountsPayableLabels.advanceTransCode,
    key: AccountsPayableKeys.advanceTransCode,
  },
  {
    label: accountsPayableLabels.advanceDescription,
    key: AccountsPayableKeys.advanceDescription,
  },
  {
    label: accountsPayableLabels.postToGrower,
    key: AccountsPayableKeys.postToGrower,
  },
];
