import { FC, ReactElement } from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  ItemContainerErrorMsg,
  AddItemBtn,
  ItemContainerDisplay,
  ItemContainerWrapper,
  StyledRemoveBtn,
  ItemContainerError,
} from './wizardStyles';

type ItemContainerProps<T> = {
  /** The name to pass to the 'Add' button. */
  itemName: string;
  /**
   * The default item added to the collection when
   * the 'Add' button is clicked.
   */
  items: T[];
  /**
   * The react component to use when creating an item.
   * Its required `removeBtn` prop receives a react button component
   * from its parent, the `ItemContainer` component.
   */
  addItem: () => void;
  removeItem: (id: number) => void;
  error?: string;
  ItemComponent: FC<{
    itemIndex: number;
    itemData: T;
    isLoading: boolean;
    removeBtn: () => JSX.Element;
  }>;
  /**
   * An option to pass a flag to the child `ItemComponent` if the child
   * must wait for api information to finish fetching. Default is `false`.
   */
  isLoading?: boolean;
  /**
   * An option to pass the number of columns to display items in. The default
   * is `1`.
   */
  columnNumber?: number;
  iconColor: string;
  textColor: string;
};

/**
 * A component that handles adding and removing a given type of item
 * from a set collection. The react child component used for every item
 * receives a delete button as a prop.
 */
export const ItemContainer = <T extends { id: string }>({
  itemName,
  items,
  addItem,
  removeItem,
  error,
  ItemComponent,
  isLoading = false,
  columnNumber = 1,
  iconColor,
  textColor,
}: ItemContainerProps<T>): ReactElement => {
  const RemoveItemBtn: FC<{ index: number; iconColor: string }> = ({
    index,
    iconColor,
  }) => (
    <StyledRemoveBtn type='button' onClick={() => removeItem(index)}>
      <CancelOutlinedIcon htmlColor={iconColor} />
    </StyledRemoveBtn>
  );

  return (
    <ItemContainerWrapper>
      <ItemContainerDisplay $columnNumber={columnNumber}>
        {items.map((item, index) => (
          <ItemContainerWrapper key={item.id}>
            <ItemComponent
              itemIndex={index}
              itemData={item}
              isLoading={isLoading}
              removeBtn={() => (
                <RemoveItemBtn index={index} iconColor={iconColor} />
              )}
            />
          </ItemContainerWrapper>
        ))}
      </ItemContainerDisplay>
      <AddItemBtn
        type='button'
        onClick={addItem}
        $textColor={textColor}
        $isError={!!error}
      >
        <AddCircleIcon htmlColor={iconColor} /> Add {itemName}
      </AddItemBtn>
      <ItemContainerErrorMsg>
        {error && <ItemContainerError>{error}</ItemContainerError>}
      </ItemContainerErrorMsg>
    </ItemContainerWrapper>
  );
};
