import { SizeEstimate } from 'common/models/growerBlock/sizeEstimate';
import * as yup from 'yup';

export const sizeEstimatesSchema = yup
  .object()
  .shape({
    sizeEstimates: yup.array().of(
      yup.object().shape({
        percentValue: yup.number().required(),
      }),
    ),
  })
  .test(formData => {
    const estimates: Partial<SizeEstimate>[] | undefined =
      formData.sizeEstimates;
    let sum = 0;
    if (estimates) {
      estimates.forEach(estimate => {
        if (estimate.percentValue) {
          sum += estimate.percentValue;
        }
      });
    }

    return sum === 100;
  });
