import * as yup from 'yup';
import { Constants } from '../constants';
import {
  dropdownOptional,
  dropdownRequired,
  nullableNumberSchema,
  nullableStringSchema,
  numberSchemaRequired,
  twoDecimalNumbersSchema,
  yearSchema,
} from './common';

export const blockSchema = yup.object({
  // Required fields
  variety: dropdownRequired,
  lotNumber: yup
    .string()
    .nullable()
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .trim(),
  primaryEvaluator: dropdownRequired,
  trees: numberSchemaRequired,
  acres: twoDecimalNumbersSchema.required(
    Constants.errorMessages.FIELD_REQUIRED,
  ),
  district: dropdownRequired,

  // Optional fields
  secondaryEvaluator: dropdownOptional,
  subVariety: dropdownOptional,
  rootstock: dropdownOptional,
  county: dropdownOptional,
  area: dropdownOptional,
  yearPlanted: yearSchema,
  latitude: nullableNumberSchema,
  longitude: nullableNumberSchema,
  notes: nullableStringSchema,
});
