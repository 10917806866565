import * as yup from 'yup';

export const addPickLineSchema = yup.object({
  quantity: yup
    .number()
    .when('$quantityRequired', (quantityRequired, schema) => {
      if (quantityRequired) {
        return schema
          .nullable()
          .required('Quantity is required.')
          .moreThan(0, 'Quantity must be more than 0.')
          .typeError('Quantity is required.');
      }
      return schema.nullable();
    }),
  category: yup.object().nullable().required('Category is required.'),
  type: yup.object().when('$noteRequired', (noteRequired, schema) => {
    if (noteRequired) {
      // Type is not needed when entering a miscellaneous line.
      return schema.nullable();
    }
    return schema
      .nullable()
      .required('Type is required.')
      .typeError('Type is required.');
  }),
  rate: yup.number().when('$rateRequired', (rateRequired, schema) => {
    if (rateRequired) {
      return schema.when(
        '$noteRequired',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (noteRequired: boolean, schema: any) => {
          if (noteRequired) {
            // A miscellaneous line can have a negative number.
            return schema
              .nullable()
              .required('Rate is required.')
              .typeError('Rate is required.');
          }
          return schema
            .nullable()
            .required('Rate is required.')
            .moreThan(0, 'Rate must be more than 0.')
            .typeError('Rate is required.');
        },
      );
    }
    return schema.nullable();
  }),
  note: yup.string().when('$noteRequired', (noteRequired, schema) => {
    if (noteRequired) {
      return schema.nullable().required('Note is required').trim();
    }
    // Note is not needed when entering a harvesting or hauling line.
    return schema.nullable();
  }),
});

export const addTransferLineSchema = yup.object({
  date: yup.date().nullable().required('Date is required.'),
  details: yup.string().nullable().required('Details are required.').trim(),
  glNumber: yup.string().nullable().required('Entry is required.').trim(),
  loads: yup
    .number()
    .nullable()
    .required('Quantity is required.')
    .moreThan(0, 'Quantity must be more than 0.')
    .typeError('Quantity is required.'),
  rate: yup.object().nullable().required('Route is required.'),
  commissionType: yup.object().nullable().required('Type is required.'),
  commissionRate: yup
    .number()
    .nullable()
    .required('Commission rate is required.')
    .moreThan(0, 'Rate must be more than 0.')
    .typeError('Commission rate is required.'),
});
