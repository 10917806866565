import { TransferLineType } from 'common/models/harvestData/payrollData';
import {
  emptyPayrollRow,
  PayrollTableRow,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { createRowKey } from 'features/harvest-payroll/utils/payrollPageUtils';
import { FC, Fragment } from 'react';
import { AddTransferLineForm } from './AddCustomLineForm/AddTransferLineForm';
import { EmptyRow } from './EmptyRow';
import { PayrollTableLine } from './PayrollTableLine';
import { NoTransfersCell } from './styles';

export const TransferRows: FC<{
  tableData: PayrollTableRow[];
  isEditable: boolean;
}> = ({ tableData, isEditable }) => {
  let changeBackground = true;

  return (
    <>
      {tableData.length ? (
        tableData.map(row => {
          if (
            row.metadata.descType ===
            TransferLineType[TransferLineType.Transfer]
          ) {
            changeBackground = !changeBackground;
          }

          return (
            <Fragment key={createRowKey(row.metadata)}>
              <PayrollTableLine row={row} changeBackground={changeBackground} />
            </Fragment>
          );
        })
      ) : (
        <NoTransfersCell>No transfers entered.</NoTransfersCell>
      )}
      {isEditable ? (
        <AddTransferLineForm />
      ) : (
        <EmptyRow rowData={emptyPayrollRow} />
      )}
    </>
  );
};
