export enum BlockStatus {
  No_Status,
  New,
  Pending,
  Active,
  Non$Producing,
  Withdrawn,
  Harvested,
  Inactive,
}
