import { FC } from 'react';
import { BlockChangesTable } from '../components/BlockChangesTable';
import { GrowersTable } from '../components/GrowersTable';
import styled from 'styled-components';

const Divider = styled.div`
  height: 20px;
`;

export const GrowersView: FC = () => (
  <>
    <GrowersTable />
    <Divider />
    <BlockChangesTable />
  </>
);
