import { useAppSelector } from 'app/redux';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { validateCoordinateInfo } from 'common/components/LocationButton/LocationButton';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { PackHouse } from 'common/models/packHouse';
import { blueShade, lightGreyText, orange } from 'common/styles/colors';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { ModifiedAllSeasonData } from 'features/pick-schedule-views/pages/SchedulePickWizardView';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { WizardHeader } from '../WizardComponents/WizardHeader';
import {
  InfoLabel,
  LightGreyNotes,
  SummarySection,
  WizardDivider,
  WizardRow,
} from '../WizardComponents/wizardStyles';
import { BlueShadeInfoValue } from './styles';

export const WizardInfoSection: FC<{
  step: number;
  availBlock?: BlockDetails<WithAllSeasonData>;
  adHocBlock?: BlockDetails<ModifiedAllSeasonData>;
  binsToGo: number;
  packHouse: PackHouse | undefined;
  variety: Variety | undefined;
  size: Size | undefined;
}> = ({ step, availBlock, adHocBlock, binsToGo, packHouse, variety, size }) => {
  const { getValues } = useFormContext();
  const { availableSeasons } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const mapParams = () => {
    const block = adHocBlock || availBlock;

    return validateCoordinateInfo(
      block?.latitude ?? null,
      block?.longitude ?? null,
      block?.blockId ?? null,
    );
  };

  const seasonToSchedule = useMemo(() => {
    const seasonId = (adHocBlock || availBlock)?.seasonData?.seasonId;

    return availableSeasons.find(ssn => ssn.id === seasonId);
  }, [adHocBlock, availBlock, availableSeasons]);

  return (
    <>
      <WizardHeader
        growerId={availBlock?.grower?.growerId || adHocBlock?.grower?.growerId}
        blockId={availBlock?.blockId || adHocBlock?.blockId}
        varietyCode={variety?.varietyCode || adHocBlock?.variety?.varietyCode}
        evaluator={
          availBlock
            ? `${availBlock?.primaryEvaluator?.firstName} ${availBlock?.primaryEvaluator?.lastName}`
            : `${adHocBlock?.primaryEvaluator?.firstName} ${adHocBlock?.primaryEvaluator?.lastName}`
        }
        binsRemaining={binsToGo}
        // List the sizes of estimates over 0% when scheduling ad hoc blocks.
        size={adHocBlock?.seasonData?.sizeEstimates
          .map(estimate => (estimate.percentValue ? estimate.size.value : ''))
          .filter(size => !!size)
          .join('/')}
        mapParams={mapParams()}
        locationStyles={{ backgroundColor: orange, iconColor: lightGreyText }}
        textColor={blueShade}
        showSize={!!adHocBlock}
      />
      {adHocBlock && step === 1 ? (
        <WizardDivider />
      ) : (
        <SummarySection>
          <WizardRow>
            <InfoLabel>Season</InfoLabel>
            <BlueShadeInfoValue>
              {seasonToSchedule?.year || '-'}
            </BlueShadeInfoValue>
          </WizardRow>
          {availBlock && (
            <>
              <WizardRow>
                <InfoLabel>Facility</InfoLabel>
                <BlueShadeInfoValue>{packHouse?.code}</BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Pack Plan Size</InfoLabel>
                <BlueShadeInfoValue>{size?.value}</BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Market</InfoLabel>
                <BlueShadeInfoValue>
                  {availBlock?.seasonData?.evaluation?.market ||
                    adHocBlock?.seasonData?.evaluation?.market}
                </BlueShadeInfoValue>
              </WizardRow>
            </>
          )}
          {step > 1 && (
            <>
              {availBlock && <WizardDivider />}
              <WizardRow>
                <InfoLabel>Pick Date</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pickDay')
                    ? getValues('pickDay')?.toLocaleDateString()
                    : ''}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Bins Sched.</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('binsToPick')}
                </BlueShadeInfoValue>
              </WizardRow>
            </>
          )}
          {step > 2 && (
            <>
              {adHocBlock && (
                <>
                  <WizardRow>
                    <InfoLabel>Facility</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('packHouse')?.label}
                    </BlueShadeInfoValue>
                  </WizardRow>
                  <WizardRow>
                    <InfoLabel>Market</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('market')?.label || '-'}
                    </BlueShadeInfoValue>
                  </WizardRow>
                  <WizardRow>
                    <InfoLabel>Size</InfoLabel>
                    <BlueShadeInfoValue>
                      {getValues('size')?.label || '-'}
                    </BlueShadeInfoValue>
                  </WizardRow>
                </>
              )}
              <WizardRow>
                <InfoLabel>Pool</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pool')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Clean Pick</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('cleanPick')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              <WizardRow>
                <InfoLabel>Pick Type</InfoLabel>
                <BlueShadeInfoValue>
                  {getValues('pickType')?.label}
                </BlueShadeInfoValue>
              </WizardRow>
              {getValues('notes') && (
                <>
                  <InfoLabel>Notes:</InfoLabel>
                  <LightGreyNotes>{getValues('notes')}</LightGreyNotes>
                </>
              )}
            </>
          )}
        </SummarySection>
      )}
    </>
  );
};
