import { useAppDispatch } from 'app/redux';
import {
  ChangePasswordRequest,
  useChangePasswordMutation,
} from 'common/api/userApi';
import * as notificationService from 'common/services/notification';
import { authSlice } from 'features/auth/authSlice';
import { useAuth } from 'features/auth/hooks';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ChangePasswordForm, FormData } from '../components/ChangePasswordForm';
import { PageWrapper } from 'common/styles/page';
import { StyledFormWrapper, Title } from 'common/styles/form';
import { determinePathOnLogin } from 'utils/helperFunction';
import { handleError } from 'common/api/handleError';

export const ChangePasswordPage: FC = () => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [changePassword] = useChangePasswordMutation();

  const onFormSubmit = async (data: FormData) => {
    try {
      const session = await changePassword({
        id: user!.id,
        ...data,
      } as ChangePasswordRequest).unwrap(); // Force type here because
      // validation must have passed by now.
      dispatch(
        authSlice.actions.userLoggedIn({
          token: session.token,
          user: session.user,
        }),
      );
      notificationService.showSuccessMessage('Password updated.');
      history.replace(determinePathOnLogin(session.user.role.roleName));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <PageWrapper>
      <StyledFormWrapper data-testid='wrapper'>
        <Title>Change Password</Title>
        <ChangePasswordForm onSubmit={onFormSubmit} />
      </StyledFormWrapper>
    </PageWrapper>
  );
};
