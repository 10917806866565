import { yupResolver } from '@hookform/resolvers/yup';
import { handleError } from 'common/api/handleError';
import {
  HaulRateDto,
  PickRateDto,
  useGetHarvestCrewsQuery,
  useGetHaulRatesQuery,
} from 'common/api/harvestDataApi';
import { validateCoordinateInfo } from 'common/components/LocationButton/LocationButton';
import TooltipTrigger from 'common/components/WithTooltip/WithTooltip';
import { TimeDuration } from 'common/components/WizardControls/WizardDurationInput';
import { WizardNumericInput } from 'common/components/WizardControls/WizardNumericInput';
import {
  ContractorPickAssignment,
  ScheduledPick,
  pickTypeLabels,
} from 'common/models';
import { DateWithTime, TimeWithoutDate } from 'common/models/dateType';
import {
  Contractor,
  ContractorRecord,
} from 'common/models/harvestData/contractor';
import { HaulerPickRecord } from 'common/models/harvestData/hauler';
import { roundBins } from 'common/models/harvestData/utilities';
import * as notifier from 'common/services/notification';
import { darkNavy, orange, white } from 'common/styles/colors';
import usePickScheduleActions, {
  PickRecordPayload,
} from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { formatPickDetailDate } from 'features/pick-schedule-views/utils/pickDetailUtils';
import { FC, createContext, useEffect, useMemo, useState } from 'react';
import {
  FieldError,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Constants } from 'utils/constants';
import { pickDataSchema } from 'utils/schemas/pickDataSchema';
import { DataEntryRow } from '../SchedulePickWizard/styles';
import { ItemContainer } from '../WizardComponents/ItemContainer';
import { PickDataWizardContainer } from '../WizardComponents/PickDataWizardContainer';
import { PickSummary } from '../WizardComponents/PickSummary';
import { WizardHeader } from '../WizardComponents/WizardHeader';
import {
  ButtonSection,
  CancelBtn,
  InfoLabel,
  Textarea,
  WizardDivider,
  WizardRow,
} from '../WizardComponents/wizardStyles';
import { HarvestData, HarvestDataForm } from './HarvestDataForm';
import { HaulData, HaulDataForm } from './HaulDataForm';
import {
  DataInfoValue,
  MobileWrapper,
  NavyButton,
  NavyNotes,
  NavyPrompt,
  SummaryContainer,
  TotalBinsContainer,
} from './styles';

type PickDataValues = {
  harvestDataRecords: HarvestData[];
  haulDataRecords: HaulData[];
  binsLeft: string;
  fbPerTree: string;
  notes: string | null;
};

const AssignmentLabel = styled(InfoLabel)`
  min-width: 80px; // Prevent excessive wrapping.
`;

export const PickDataWizardContext = createContext<{
  contractors?: Contractor[];
  haulers?: Contractor[];
  haulRates?: HaulRateDto[];
  isLoading: boolean;
  setTotalBinsPicked: React.Dispatch<React.SetStateAction<number>>;
}>(null!);

export const PickDataWizard: FC<{
  scheduledPick: ScheduledPick;
  pickRates?: PickRateDto[];
}> = ({ scheduledPick, pickRates }) => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { savePickData } = usePickScheduleActions();
  const { data, isLoading, error: fetchCrewsError } = useGetHarvestCrewsQuery();
  const {
    data: haulRates,
    isLoading: isLoadingRates,
    error: fetchRatesError,
  } = useGetHaulRatesQuery();
  const [totalBinsPicked, setTotalBinsPicked] = useState(0);

  const formatToTimeDuration = (time: TimeWithoutDate): TimeDuration => {
    const timeArr = time.split(':');
    return {
      hours: parseInt(timeArr[0], 10),
      minutes: parseInt(timeArr[1], 10),
    };
  };

  const formatFromTimeDuration = (time: TimeDuration): TimeWithoutDate =>
    `${time.hours || '00'}:${time.minutes || '00'}:00`;

  const createDefaultHarvestRecords = (
    contractorPickAssignment: ContractorPickAssignment[],
    defaultPickRate: string | undefined,
  ): Omit<HarvestData, 'id'>[] =>
    contractorPickAssignment.map(record => ({
      startTime: null,
      contractor: {
        label: record.contractor.code,
        value: record.contractor,
      },
      headCount: null,
      timeWorked: { hours: 0, minutes: 0 },
      transferTime: { hours: 0, minutes: 0 },
      binsPicked: null,
      binsHauled: null,
      pickRate: defaultPickRate || null,
      haulRate: null,
      tarps: null,
    }));

  const createHarvestRecords = (
    contractorRecords: ContractorRecord[],
  ): HarvestData[] =>
    contractorRecords.map(record => ({
      id: record.id.toString(),
      startTime: new Date(record.startTime),
      contractor: {
        label: record.contractor.code,
        value: record.contractor,
      },
      headCount: record.headCount.toString(),
      timeWorked: formatToTimeDuration(record.hoursWorked),
      transferTime: formatToTimeDuration(record.transferTime),
      pickRate: record.pickRate.toString(),
      binsPicked: record.binsPicked.toString(),
      binsHauled: record.binsHauled ? record.binsHauled.toString() : null,
      tarps: record.tarps ? record.tarps.toString() : null,
      haulRate: record.haulRate
        ? {
            label: `${record.haulRate.haulLabel.miles} mi`,
            value: record.haulRate,
          }
        : null,
    }));

  const createDefaultHaulRecords = (
    haulers: Contractor[] | undefined,
  ): Omit<HaulData, 'id'>[] =>
    haulers?.map(record => ({
      binsHauled: null,
      tarps: null,
      haulRate: null,
      hauler: {
        label: record.code,
        value: record,
      },
    })) || [];

  const createHaulRecords = (haulerRecords: HaulerPickRecord[]): HaulData[] =>
    haulerRecords.map(record => ({
      id: record.id.toString(),
      binsHauled: record.binsHauled.toString(),
      tarps: record.tarps.toString(),
      haulRate: {
        label: `${record.haulRate.haulLabel.miles} mi`,
        value: record.haulRate,
      },
      hauler: {
        label: record.hauler.code,
        value: record.hauler,
      },
    }));

  const defaultPickRate: PickRateDto | undefined = useMemo(
    () =>
      pickRates?.find(dto => dto.varietyId === scheduledPick.block.variety?.id),
    [pickRates, scheduledPick.block.variety],
  );

  const harvestDataRecords: HarvestData[] | Omit<HarvestData, 'id'>[] = useMemo(
    () =>
      scheduledPick.pickRecord?.contractorRecords?.length > 0
        ? createHarvestRecords(scheduledPick.pickRecord.contractorRecords)
        : createDefaultHarvestRecords(
            scheduledPick.pickAssignment.contractorPickAssignment,
            defaultPickRate?.rate.toString(),
          ),
    // Util functions don't need to be passed as dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scheduledPick, defaultPickRate],
  );

  const haulDataRecords: HaulData[] | Omit<HaulData, 'id'>[] = useMemo(
    () =>
      scheduledPick.pickRecord?.contractorRecords?.length > 0
        ? createHaulRecords(scheduledPick.pickRecord.haulerRecords)
        : createDefaultHaulRecords(scheduledPick.pickAssignment.haulers),
    [scheduledPick],
  );

  const methods = useForm<PickDataValues>({
    defaultValues: {
      harvestDataRecords,
      haulDataRecords,
      binsLeft: scheduledPick.pickRecord?.binsLeft.toString(),
      fbPerTree: scheduledPick.pickRecord?.fbPerTree.toString(),
      notes: scheduledPick.pickRecord?.notes || null,
    },
    resolver: yupResolver(pickDataSchema),
    mode: 'all',
  });
  const {
    clearErrors,
    control,
    formState: { errors, isDirty, isSubmitting },
    getValues,
    setError,
    handleSubmit,
    register,
    reset,
  } = methods;
  const {
    fields: harvestDataRecordFields,
    append: appendHarvestDataRecord,
    remove: removeHarvestDataRecord,
  } = useFieldArray({
    control,
    name: 'harvestDataRecords',
  });
  const {
    fields: haulDataRecordFields,
    append: appendHaulDataRecord,
    remove: removeHaulDataRecord,
  } = useFieldArray({
    control,
    name: 'haulDataRecords',
  });

  const mapParams = validateCoordinateInfo(
    scheduledPick?.block?.latitude,
    scheduledPick?.block?.longitude,
    scheduledPick?.block?.blockId,
  );

  const canEditBinsLeft = useMemo(() => {
    if (!scheduledPick.pickRecord) {
      return true;
    }

    // Note: the server only returns one estimate in the estimates array;
    // we can safely assume that the first element is the latest estimate.
    const latestEstimate = scheduledPick.block.estimates[0];

    return (
      (latestEstimate.createdAt as DateWithTime) <=
      (scheduledPick.pickRecord.createdAt as DateWithTime)
    );
  }, [scheduledPick]);

  const onSavePickData = async ({
    harvestDataRecords,
    haulDataRecords,
    binsLeft,
    fbPerTree,
    notes,
  }: PickDataValues) => {
    if (isDirty) {
      let totalBinsPicked = 0;
      let totalBinsHauled = 0;

      const data = {
        schedulePickId: parseInt(id, 10),
        ...(scheduledPick.pickRecord && {
          pickRecordId: scheduledPick.pickRecord.id,
        }),
        contractorRecords: harvestDataRecords.map(record => {
          if (record.binsPicked) {
            totalBinsPicked += parseFloat(record.binsPicked);
          }
          if (record.binsHauled) {
            totalBinsHauled += parseFloat(record.binsHauled);
          }

          return {
            ...(record.id && {
              id: parseInt(record.id, 10),
            }),
            startTime: record.startTime,
            contractor: record.contractor?.value,
            headCount: record.headCount && parseInt(record.headCount, 10),
            hoursWorked: formatFromTimeDuration(record.timeWorked),
            transferTime: formatFromTimeDuration(record.transferTime),
            binsPicked: record.binsPicked && parseFloat(record.binsPicked),
            binsHauled: record.binsHauled && parseFloat(record.binsHauled),
            pickRate: record.pickRate && parseInt(record.pickRate, 10),
            tarps: record.tarps && parseInt(record.tarps, 10),
            haulRate: record.haulRate && record.haulRate.value,
          };
        }),
        haulerRecords: haulDataRecords.map(record => {
          if (record.binsHauled) {
            totalBinsHauled += parseFloat(record.binsHauled);
          }

          return {
            ...(record.id && {
              id: parseInt(record.id, 10),
            }),
            binsHauled: record.binsHauled && parseFloat(record.binsHauled),
            tarps: record.tarps && parseInt(record.tarps, 10),
            haulRate: record.haulRate?.value,
            hauler: record.hauler?.value,
            surcharge: record.haulRate?.value.surcharge,
          };
        }),
        binsLeft: binsLeft && parseInt(binsLeft, 10),
        fbPerTree: fbPerTree && parseInt(fbPerTree, 10),
        notes,
      } as PickRecordPayload;

      if (roundBins(totalBinsPicked) !== roundBins(totalBinsHauled)) {
        // Set a generic error to avoid useForm's 'isSubmitSuccessful' to be true
        setError('root.binsDoNotMatch', { type: 'pattern' });
        notifier.showErrorMessage(
          'The number of bins picked must match the number of bins hauled.',
        );
        return;
      }

      const isSuccessful = await savePickData(data);

      if (isSuccessful) {
        reset();
        history.push(`${Constants.routes.PICK_SCHEDULE}/${id}`);
      }
    } else {
      history.push(`${Constants.routes.PICK_SCHEDULE}/${id}`);
    }
  };

  useEffect(() => {
    if (fetchCrewsError) handleError(fetchCrewsError, 'Unable to load crews.');
    if (fetchRatesError)
      handleError(fetchRatesError, 'Unable to load haul rates.');
  }, [fetchCrewsError, fetchRatesError]);

  return (
    <FormProvider<PickDataValues> {...methods}>
      <PickDataWizardContext.Provider
        value={{
          contractors: data?.fieldLaborContractors,
          haulers: data?.fieldLaborContractors,
          haulRates,
          isLoading,
          setTotalBinsPicked,
        }}
      >
        <PickDataWizardContainer
          backgroundColor={orange}
          onSubmit={handleSubmit(onSavePickData)}
        >
          <WizardHeader
            growerId={scheduledPick.block?.grower?.growerId}
            blockId={scheduledPick.block?.blockId}
            evaluator={`${scheduledPick.block?.primaryEvaluator?.firstName} ${scheduledPick.block?.primaryEvaluator?.lastName}`}
            varietyCode={scheduledPick.block?.variety?.varietyCode}
            binsRemaining={scheduledPick.pickRecord?.binsLeft}
            size={scheduledPick.size?.value}
            mapParams={mapParams}
            locationStyles={{ backgroundColor: darkNavy, iconColor: white }}
            textColor={darkNavy}
            showSize
          />
          <PickSummary
            packHouseCode={scheduledPick.packHouse?.code}
            size={scheduledPick.size?.value}
            market={scheduledPick.market || undefined}
            pickDay={formatPickDetailDate(scheduledPick.schedule.pickDay)}
            binsToPick={scheduledPick.bins.toString()}
            pool={scheduledPick?.pool?.poolId}
            cleanPick={scheduledPick.cleanPick ? 'Yes' : 'No'}
            pickType={
              typeof scheduledPick.pickType === 'number'
                ? pickTypeLabels[scheduledPick.pickType]
                : undefined
            }
            notes={scheduledPick.notes}
            textColor={darkNavy}
          />
          <NavyPrompt>Assigned Staff/Crew</NavyPrompt>
          <MobileWrapper>
            <SummaryContainer>
              <WizardRow>
                <AssignmentLabel>HC</AssignmentLabel>
                <DataInfoValue $color={darkNavy}>
                  {`${scheduledPick.coordinator?.firstName} ${scheduledPick.coordinator?.lastName}` ||
                    '-'}
                </DataInfoValue>
              </WizardRow>
              {scheduledPick.pickAssignment?.contractorPickAssignment?.map(
                (assignment, index) => (
                  <WizardRow key={assignment.id}>
                    <AssignmentLabel>FLC {index + 1}</AssignmentLabel>
                    <DataInfoValue $color={darkNavy}>
                      {`${assignment.contractor.code} (${assignment.bins} bins)`}
                    </DataInfoValue>
                  </WizardRow>
                ),
              )}
              {scheduledPick.pickAssignment?.haulers?.map(
                (assignment, index) => (
                  <WizardRow key={assignment.id}>
                    <AssignmentLabel>Hauler {index + 1}</AssignmentLabel>
                    <DataInfoValue $color={darkNavy}>
                      {assignment.code}
                    </DataInfoValue>
                  </WizardRow>
                ),
              )}
            </SummaryContainer>
            <SummaryContainer>
              {scheduledPick.pickAssignment?.notes && (
                <>
                  <InfoLabel>Harvest Notes:</InfoLabel>
                  <NavyNotes>{scheduledPick.pickAssignment?.notes}</NavyNotes>
                </>
              )}
            </SummaryContainer>
          </MobileWrapper>
          <WizardDivider />
          <NavyPrompt>Add Harvest Data</NavyPrompt>
          <ItemContainer<HarvestData>
            columnNumber={2}
            itemName='FLC'
            items={harvestDataRecordFields}
            addItem={() => {
              if (!getValues('harvestDataRecords').length)
                clearErrors('harvestDataRecords');
              return appendHarvestDataRecord({
                id: '',
                startTime: null,
                contractor: null,
                headCount: null,
                timeWorked: { hours: 0, minutes: 0 },
                transferTime: { hours: 0, minutes: 0 },
                binsPicked: null,
                binsHauled: null,
                pickRate: defaultPickRate?.rate.toString() || null,
                tarps: null,
                haulRate: null,
              });
            }}
            removeItem={(index: number) => removeHarvestDataRecord(index)}
            error={
              (errors?.harvestDataRecords as unknown as FieldError)?.message
            }
            ItemComponent={HarvestDataForm}
            iconColor={darkNavy}
            textColor={white}
          />
          <WizardDivider />
          <NavyPrompt>Add Haul Data</NavyPrompt>
          <ItemContainer<HaulData>
            columnNumber={2}
            itemName='Hauler'
            items={haulDataRecordFields}
            addItem={() => {
              if (!getValues('haulDataRecords').length)
                clearErrors('haulDataRecords');
              return appendHaulDataRecord({
                id: '',
                hauler: null,
                binsHauled: null,
                tarps: null,
                haulRate: null,
              });
            }}
            removeItem={(index: number) => removeHaulDataRecord(index)}
            error={(errors?.haulDataRecords as unknown as FieldError)?.message}
            ItemComponent={HaulDataForm}
            isLoading={isLoadingRates}
            iconColor={darkNavy}
            textColor={white}
          />
          <WizardDivider />
          <MobileWrapper>
            <SummaryContainer>
              <NavyPrompt>Update Estimates</NavyPrompt>
              <TooltipTrigger
                title='Bins left estimate disabled.'
                tooltipText={
                  canEditBinsLeft
                    ? ''
                    : 'A more recent estimate has already been entered.'
                }
              >
                <DataEntryRow>
                  <InfoLabel>Bins Left</InfoLabel>
                  <WizardNumericInput
                    name='binsLeft'
                    isDisabled={!canEditBinsLeft}
                    disabledColor={orange}
                  />
                </DataEntryRow>
              </TooltipTrigger>
              <DataEntryRow>
                <InfoLabel>FB/Tree</InfoLabel>
                <WizardNumericInput name='fbPerTree' />
              </DataEntryRow>
            </SummaryContainer>
            <SummaryContainer>
              <TotalBinsContainer>
                <DataInfoValue $color={darkNavy}>
                  Total Bins Picked: {totalBinsPicked}
                </DataInfoValue>
              </TotalBinsContainer>
            </SummaryContainer>
          </MobileWrapper>
          <InfoLabel style={{ marginTop: '20px' }}>Notes</InfoLabel>
          <Textarea {...register('notes')} rows={2} />
          <ButtonSection>
            <CancelBtn
              type='button'
              onClick={() =>
                history.push(`${Constants.routes.PICK_SCHEDULE}/${id}`)
              }
              disabled={isSubmitting}
            >
              Cancel
            </CancelBtn>
            <NavyButton type='submit' disabled={isSubmitting}>
              CONFIRM
            </NavyButton>
          </ButtonSection>
        </PickDataWizardContainer>
      </PickDataWizardContext.Provider>
    </FormProvider>
  );
};
