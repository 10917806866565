import { FC, useEffect, useState } from 'react';
import { ModalWrapper } from '../ModalWrapper';
import { blueShadeHeader, darkNavy, white } from 'common/styles/colors';
import styled from 'styled-components';
import {
  MapsModalState,
  mapsModalSlice,
  mapsModalSliceName,
} from './GmapsModalSlice';
import { Close } from '@mui/icons-material';
import { useGoogleMaps } from './useGoogleMaps';
import { useAppDispatch, useAppSelector } from 'app/redux';

const mapHeight = '700px';
const mapElementId = 'map';
const blockTagClassName = 'block-tag';

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin: 20px 20px 0;
`;

const CloseButton = styled.button`
  display: flex;
  background: none;
  border: none;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: ${mapHeight};
  padding: 20px;

  .${blockTagClassName} {
    background-color: ${blueShadeHeader};
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
  }

  .${blockTagClassName}::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${blueShadeHeader};
  }
`;

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${mapHeight};
  color: ${white};
`;

export const GoogleMapsModal: FC = () => {
  const dispatch = useAppDispatch();
  const { tryCreateMapWithLocations } = useGoogleMaps();
  const { showModal, mapParams } = useAppSelector<MapsModalState>(
    state => state[mapsModalSliceName],
  );
  const [mapsError, setMapsError] = useState('');

  const closeModal = () => dispatch(mapsModalSlice.actions.setShowModal());

  const header = (
    <HeaderContainer>
      <CloseButton onClick={closeModal}>
        <Close sx={{ color: `${white}` }} />
      </CloseButton>
    </HeaderContainer>
  );

  const body = (
    <BodyContainer>
      {/* Entry point for google maps */}
      <div
        id={mapElementId}
        style={{ width: '100%', height: `${mapsError ? 0 : mapHeight}` }}
      />
      {mapsError && <ErrorContainer>{mapsError}</ErrorContainer>}
    </BodyContainer>
  );

  useEffect(() => {
    const handleMap = async () => {
      if (mapParams && mapParams.length > 0) {
        const isSuccessful = await tryCreateMapWithLocations(
          mapParams,
          mapElementId,
          blockTagClassName,
        );
        if (!isSuccessful)
          setMapsError('There was an issue loading Google Maps.');
      }
    };
    handleMap();
  }, [tryCreateMapWithLocations, mapParams]);

  return (
    <ModalWrapper
      isOpen={showModal}
      isLoading={false}
      backgroundColor={darkNavy}
      width='1000px'
      header={header}
      body={body}
      closeModal={() => {
        setMapsError('');
        closeModal();
      }}
    />
  );
};
