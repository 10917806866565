import { CmsDate } from 'common/models/cmsDate';
import { OrchardMonitoring } from 'common/models/growerBlock/orchardMonitoring';
import { UpdateMonitoringFormValues } from 'features/evaluation-views/types';
import { noOption, yesOption } from './commonOptions';

/** Returns default values for the monitoring form */
export const createMonitoringFormValues = (
  monitoring?: OrchardMonitoring | null,
): UpdateMonitoringFormValues => ({
  monitorDate: CmsDate.parse(monitoring?.monitorDate)?.toDate() ?? null,
  harvestDate: CmsDate.parse(monitoring?.harvestDate)?.toDate() ?? null,
  skirtPruned: {
    id: monitoring?.skirtPruned ? yesOption.id : noOption.id,
    label: monitoring?.skirtPruned ? yesOption.label : noOption.label,
  },
  copperSpray_1: CmsDate.parse(monitoring?.copperSpray_1)?.toDate() ?? null,
  copperSpray_2: CmsDate.parse(monitoring?.copperSpray_2)?.toDate() ?? null,
  frbSpray_1: CmsDate.parse(monitoring?.frbSpray_1)?.toDate() ?? null,
  frbSpray_2: CmsDate.parse(monitoring?.frbSpray_2)?.toDate() ?? null,
  brownRot: {
    id: monitoring?.brownRot ? yesOption.id : noOption.id,
    label: monitoring?.brownRot ? yesOption.label : noOption.label,
  },
  usdaList: {
    id: monitoring?.usdaList ? yesOption.id : noOption.id,
    label: monitoring?.usdaList ? yesOption.label : noOption.label,
  },
  ckApproved: {
    id: monitoring?.ckApproved ? yesOption.id : noOption.id,
    label: monitoring?.ckApproved ? yesOption.label : noOption.label,
  },
  sampleDate: CmsDate.parse(monitoring?.sampleDate)?.toDate() ?? null,
  brix: monitoring?.brix ?? null,
  acid: monitoring?.acid ?? null,
  ratio: monitoring?.ratio ?? null,
});
