import { handleError } from 'common/api/handleError';
import { useStartSyncMutation } from 'common/api/syncApi';
import { DatabaseSyncStatus } from 'common/models/databaseSync/syncStatus';
import { environment } from 'environment';
import { FC, useEffect, useState } from 'react';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useAuth } from 'features/auth/hooks';
import {
  EstimateErrorDetails,
  SyncNotification,
} from 'common/models/databaseSync/syncNotification';
import { EstimateErrorTable } from './EstimateErrorTable';
import {
  AccordionHeader,
  ErrorAccordion,
  ErrorIcon,
  SpokaneWrapper,
  StatusLabel,
  SyncButton,
} from './SpokaneSyncStyles';
import { AccordionDetails } from '@mui/material';

function feedbackMessage(status: DatabaseSyncStatus | undefined): string {
  let message: string;

  switch (status) {
    case DatabaseSyncStatus.Pending:
      message = 'Sync in progress...';
      break;
    case DatabaseSyncStatus.Complete:
      message = 'Sync completed.';
      break;
    case DatabaseSyncStatus.Failed:
      message = 'Sync failed, please try again.';
      break;
    default:
      message = '';
      break;
  }

  return message;
}

export const SpokaneSync: FC = () => {
  const [startSync, { error: startSyncError }] = useStartSyncMutation();
  const [syncStatus, setSyncStatus] = useState(DatabaseSyncStatus.None);
  const [syncStatusError, setSyncStatusError] = useState(false);
  const [syncErrorMessage, setSyncErrorMessage] = useState('');
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [syncEstimateErrors, setEstimateErrors] =
    useState<EstimateErrorDetails[]>();
  const allowSync = syncStatus !== DatabaseSyncStatus.Pending;
  const auth = useAuth();

  useEffect(() => {
    if (startSyncError) {
      handleError(startSyncError, 'Error starting sync, please try again.');
    }
  }, [startSyncError]);

  useEffect(() => {
    const controller = new AbortController();
    const initEventSource = async () => {
      await fetchEventSource(
        new URL('data-sync/status', environment.apiRoute).toString(),
        {
          headers: { authorization: `Bearer ${auth.token}` },
          signal: controller.signal,
          onopen: async res => setSyncStatusError(!res.ok),
          onerror: () => setSyncStatusError(true),
          onmessage: msg => {
            const parsedMsg = JSON.parse(msg.data) as SyncNotification;
            setSyncStatus(parsedMsg.status);
            setSyncErrorMessage(parsedMsg.error);
            setEstimateErrors(parsedMsg.estimateErrors);
          },
        },
      );
    };

    initEventSource();

    return () => controller.abort();
  }, [auth]);

  return (
    <SpokaneWrapper>
      {syncStatusError ? (
        <StatusLabel isError>Error getting sync status.</StatusLabel>
      ) : (
        <>
          <StatusLabel isError={syncStatus === DatabaseSyncStatus.Failed}>
            {feedbackMessage(syncStatus)}
          </StatusLabel>
          <SyncButton
            type='button'
            disabled={!allowSync}
            onClick={() => startSync()}
          >
            Sync Now
          </SyncButton>
          {syncStatus === DatabaseSyncStatus.Failed && syncErrorMessage && (
            <ErrorAccordion expanded={detailsExpanded}>
              <AccordionHeader
                onClick={() => setDetailsExpanded(!detailsExpanded)}
              >
                <ErrorIcon />
                Details
              </AccordionHeader>
              <AccordionDetails>
                {syncEstimateErrors?.length ? (
                  <EstimateErrorTable estimateErrors={syncEstimateErrors} />
                ) : (
                  <>
                    <p>
                      There was a problem synchronizing with Spokane. The
                      details below can help technical support resolve the
                      issue:
                    </p>
                    <p>{syncErrorMessage}</p>
                  </>
                )}
              </AccordionDetails>
            </ErrorAccordion>
          )}
        </>
      )}
    </SpokaneWrapper>
  );
};
