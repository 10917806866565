import { User } from 'common/models';
import { mobile } from 'common/styles/breakpoints';
import { darkOrange, lighterNavy } from 'common/styles/colors';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { Lock, DeleteForever, Mail } from '@mui/icons-material';
import { useAuth } from 'features/auth/hooks';
import useUserActions, { ActionResponse } from '../hooks/useUserActions';
import { LoadingButton } from 'common/components/LoadingButton';
import { boxShadow } from 'common/styles/page';

const ActionWrapper = styled.div`
  padding-bottom: 20px;
  @media (max-width: ${mobile}) {
    box-shadow: ${boxShadow};
    width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const ActionBtn = styled.button`
  margin-top: 23px;
  margin-left: 12px;
  padding: 2px;
  display: flex;
  align-items: center;
  gap: 7px;
  border: none;
  background: none;
`;

const ActionText = styled.p`
  font-family: KanitMedium;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  margin: auto 0;
  color: ${props => (props.color ? props.color : lighterNavy)};
`;

const LockIcon = styled(Lock)`
  color: ${lighterNavy};
`;

const MailIcon = styled(Mail)`
  color: ${lighterNavy};
`;

const DeleteIcon = styled(DeleteForever)`
  color: ${darkOrange};
`;

interface Props {
  user?: User;
}

interface LoadingButtons {
  [key: string]: boolean;
}

export const UserActionWrapper: FC<Props> = ({ user }) => {
  const { user: loggedInUser } = useAuth();
  const {
    handleDelete,
    handlePasswordReset,
    resendEmailChangeVerification,
    resendEmailVerification,
  } = useUserActions();
  const [loadingButtons, setLoadingButtons] = useState<LoadingButtons>({
    emailChangeVerification: false,
    emailVerification: false,
    passwordReset: false,
    delete: false,
  });
  const disableButtons = Object.values(loadingButtons).some(
    isLoading => isLoading,
  );
  const handleUserAction = (name: string, status: ActionResponse): void => {
    setLoadingButtons({ ...loadingButtons, [name]: status === 'confirm' });
  };

  return (
    <ActionWrapper>
      {user?.activatedAt && (
        <>
          {user?.newEmail && (
            <LoadingButton
              type='button'
              as={ActionBtn}
              name='emailChangeVerification'
              loading={loadingButtons.emailChangeVerification}
              disabled={disableButtons}
              onClick={event => {
                const buttonName = event.currentTarget.name;
                if (user) {
                  resendEmailChangeVerification(user, status =>
                    handleUserAction(buttonName, status),
                  );
                }
              }}
            >
              <MailIcon />
              <ActionText>Resend Email Verification</ActionText>
            </LoadingButton>
          )}
          <LoadingButton
            type='button'
            as={ActionBtn}
            name='passwordReset'
            loading={loadingButtons.passwordReset}
            disabled={disableButtons}
            onClick={event => {
              const buttonName = event.currentTarget.name;
              if (user) {
                handlePasswordReset(user, status => {
                  handleUserAction(buttonName, status);
                });
              }
            }}
          >
            <LockIcon />
            <ActionText>Send Password Reset</ActionText>
          </LoadingButton>
        </>
      )}
      {loggedInUser?.id !== user?.id && (
        <>
          {!user?.activatedAt && (
            <LoadingButton
              type='button'
              as={ActionBtn}
              name='emailVerification'
              loading={loadingButtons.emailVerification}
              disabled={disableButtons}
              onClick={event => {
                const buttonName = event.currentTarget.name;
                if (user) {
                  resendEmailVerification(user, status => {
                    handleUserAction(buttonName, status);
                  });
                }
              }}
            >
              <MailIcon />
              <ActionText>Resend Activation Email</ActionText>
            </LoadingButton>
          )}
          <LoadingButton
            type='button'
            as={ActionBtn}
            name='delete'
            loading={loadingButtons.delete}
            disabled={disableButtons}
            onClick={event => {
              const buttonName = event.currentTarget.name;
              if (user) {
                handleDelete(user, status => {
                  handleUserAction(buttonName, status);
                });
              }
            }}
          >
            <DeleteIcon />
            <ActionText color={darkOrange}>Delete Account</ActionText>
          </LoadingButton>
        </>
      )}
    </ActionWrapper>
  );
};
