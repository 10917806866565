import { FC } from 'react';
import { WizardRow, InfoLabel, SummarySection } from './wizardStyles';
import {
  MobileWrapper,
  DataInfoValue,
  SummaryContainer,
  NotesValue,
} from '../PickDataWizard/styles';

export const PickSummary: FC<{
  packHouseCode?: string;
  size?: string;
  market?: string;
  pickDay?: string;
  binsToPick?: string;
  pool?: string;
  cleanPick?: string;
  pickType?: string;
  notes?: string;
  textColor: string;
}> = ({
  packHouseCode,
  size,
  market,
  pickDay,
  binsToPick,
  pool,
  cleanPick,
  pickType,
  notes,
  textColor,
}) => (
  <SummarySection>
    <MobileWrapper>
      <SummaryContainer>
        <WizardRow>
          <InfoLabel>Pick Date</InfoLabel>
          <DataInfoValue $color={textColor}>{pickDay || '-'}</DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Facility</InfoLabel>
          <DataInfoValue $color={textColor}>
            {packHouseCode || '-'}
          </DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Pick Size</InfoLabel>
          <DataInfoValue $color={textColor}>{size || '-'}</DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Market</InfoLabel>
          <DataInfoValue $color={textColor}>{market || '-'}</DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Bins Sched.</InfoLabel>
          <DataInfoValue $color={textColor}>{binsToPick || '-'}</DataInfoValue>
        </WizardRow>
      </SummaryContainer>
      <SummaryContainer>
        <WizardRow>
          <InfoLabel>Pool</InfoLabel>
          <DataInfoValue $color={textColor}>{pool || '-'}</DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Clean Pick</InfoLabel>
          <DataInfoValue $color={textColor}>{cleanPick || '-'}</DataInfoValue>
        </WizardRow>
        <WizardRow>
          <InfoLabel>Pick Type</InfoLabel>
          <DataInfoValue $color={textColor}>{pickType || '-'}</DataInfoValue>
        </WizardRow>
        {notes && (
          <>
            <InfoLabel>FR Notes:</InfoLabel>
            <NotesValue $color={textColor}>{notes}</NotesValue>
          </>
        )}
      </SummaryContainer>
    </MobileWrapper>
  </SummarySection>
);
