import { DataTableText } from 'common/components/DataTable';
import VarietyCell from 'common/components/DataTable/VarietyCell';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { ForwardArrow } from '../components/GrowerIcons';
import { StatusIcon } from '../components/StatusIcon';
import { GrowerBlockTableItem } from './useGrowerBlockTableData';

export const useGrowerBlockTableDataMobile =
  (): Column<GrowerBlockTableItem>[] => {
    return useMemo(
      () => [
        {
          accessor: 'status',
          Cell: ({ value }) => <StatusIcon status={value} type='block' />,
        },
        {
          accessor: 'growerId',
          Header: 'Grower ID',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'blockId',
          Header: 'Block ID',
          Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
        },
        {
          accessor: 'variety',
          Header: 'VAR',
          Cell: ({ value }) => <VarietyCell variety={value} />,
        },
        {
          id: 'Arrow',
          Header: '',
          Cell: () => <ForwardArrow />,
        },
      ],
      [],
    );
  };
