import * as yup from 'yup';
import {
  dateSchemaRequired,
  dropdownOptional,
  dropdownRequired,
  nullableNumberSchema,
  nullableStringSchema,
  numberSchemaRequired,
  twoDecimalNumbersSchema,
} from './common';
import { Constants } from 'utils/constants';
import { binsToTarpRate } from 'features/harvest-payroll/utils/tableRowDefaults';

const reqErrorStr = 'At least one data record is required.';

const pickRateMin = 13;
const pickRateMax = 151;

const timeDurationSchema = yup
  .object({
    hours: nullableNumberSchema,
    minutes: nullableNumberSchema,
  })
  .test('time-required', 'Both fields cannot be 0.', fields => {
    if (!!fields.hours || !!fields.minutes) return true;
    return false;
  });

const tarpsToBinsHauledSchema = yup
  .number()
  .nullable()
  .moreThan(0, Constants.errorMessages.POSITIVE_NUMBER_REQUIRED)
  .integer(Constants.errorMessages.INTEGER_REQUIRED)
  .test(
    'more-than-bins-per-tarp',
    'Field is too high considering the number of bins hauled.',
    (val, context) => {
      if (typeof val === 'number') {
        return val - 1 < context.parent.binsHauled / binsToTarpRate;
      }
      return true;
    },
  );

const requiredIfBinsExistTest = {
  name: 'required-if-bins-exist',
  message: 'Required if bins hauled entered.',
  testField: 'binsHauled',
};

const harvestDataSchema = yup.object({
  startTime: dateSchemaRequired,
  contractor: dropdownRequired,
  headCount: numberSchemaRequired,
  timeWorked: timeDurationSchema,
  transferTime: yup
    .object({
      hours: nullableNumberSchema,
      minutes: nullableNumberSchema,
    })
    .nullable(),
  pickRate: yup
    .number()
    .nullable()
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .lessThan(pickRateMax, `Field must be less than ${pickRateMax}`)
    .moreThan(pickRateMin, `Field must be greater than ${pickRateMin}`)
    .integer(Constants.errorMessages.INTEGER_REQUIRED)
    .typeError(Constants.errorMessages.NUMBER_REQUIRED),

  binsPicked: twoDecimalNumbersSchema
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .typeError(Constants.errorMessages.NUMBER_REQUIRED),

  binsHauled: twoDecimalNumbersSchema
    .test(
      'required-if-tarps-or-haul-rate-exists',
      'Required if tarps or haul rate entered.',
      (val, context) => {
        if (!val && (context.parent.tarps || context.parent.haulRate)) {
          return false;
        }
        return true;
      },
    )
    .transform((_, val) => (!val ? null : Number(val)))
    .typeError(Constants.errorMessages.MUST_BE_A_NUMBER),

  tarps: tarpsToBinsHauledSchema
    .test(
      requiredIfBinsExistTest.name,
      requiredIfBinsExistTest.message,
      (val, context) => {
        if (!val && context.parent[`${requiredIfBinsExistTest.testField}`]) {
          return false;
        }
        return true;
      },
    )
    .transform((_, val) => (!val ? null : Number(val)))
    .typeError(Constants.errorMessages.MUST_BE_A_NUMBER),

  haulRate: dropdownOptional.test(
    requiredIfBinsExistTest.name,
    requiredIfBinsExistTest.message,
    (val, context) => {
      if (!val && context.parent[`${requiredIfBinsExistTest.testField}`]) {
        return false;
      }
      return true;
    },
  ),
});

const haulDataSchema = yup.object({
  hauler: dropdownRequired,
  binsHauled: twoDecimalNumbersSchema
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .typeError(Constants.errorMessages.NUMBER_REQUIRED),
  tarps: tarpsToBinsHauledSchema
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .typeError(Constants.errorMessages.NUMBER_REQUIRED),
  haulRate: dropdownRequired,
});

export const pickDataSchema = yup.object({
  harvestDataRecords: yup.array().of(harvestDataSchema).min(1, reqErrorStr),
  haulDataRecords: yup.array().of(haulDataSchema),
  binsLeft: yup
    .number()
    .nullable()
    .required(Constants.errorMessages.FIELD_REQUIRED)
    .moreThan(-1, Constants.errorMessages.ZERO_OR_GREATER)
    .integer(Constants.errorMessages.INTEGER_REQUIRED)
    .typeError(Constants.errorMessages.NUMBER_REQUIRED),
  fbPerTree: numberSchemaRequired,
  notes: nullableStringSchema,
});
