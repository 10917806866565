import {
  BlockDetails,
  WithPartialSeasonData,
} from 'common/api/dto/get-block.dto';
import { MakeActiveModal } from 'features/grower-views/components/BlockDetails/modals/MakeActiveModal';
import { MakeInactiveModal } from 'features/grower-views/components/BlockDetails/modals/MakeInactiveModal';
import { useState } from 'react';

export const ChangeStatusModals: React.FC<{
  isActiveOpen: boolean;
  isInactiveOpen: boolean;
  showInactiveModal: (value: boolean) => void;
  showActiveModal: (value: boolean) => void;
  block: BlockDetails<WithPartialSeasonData>;
}> = ({
  isActiveOpen,
  isInactiveOpen,
  showInactiveModal,
  showActiveModal,
  block,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { grower, blockName, primaryEvaluator } = block;

  return (
    <>
      <MakeInactiveModal
        isOpen={isInactiveOpen}
        closeModal={() => showInactiveModal(false)}
        isLoading={isLoading}
        setLoading={setIsLoading}
        contentProps={{
          growerName: grower?.name || '',
          blockName: blockName || '',
        }}
      />
      <MakeActiveModal
        isOpen={isActiveOpen}
        closeModal={() => showActiveModal(false)}
        contentProps={{
          growerName: grower?.name || '',
          blockName: blockName || '',
          evaluatorName:
            primaryEvaluator?.firstName && primaryEvaluator?.lastName
              ? `${primaryEvaluator?.firstName} ${primaryEvaluator?.lastName}`
              : '',
        }}
      />
    </>
  );
};
