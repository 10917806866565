import { FC } from 'react';
import { LocationButton } from 'common/components/LocationButton/LocationButton';
import { InfoRow } from '../SchedulePickWizard/styles';
import { Info, InfoLabel, InfoValue, WizardRow } from './wizardStyles';
import { GrowerBlock } from 'common/models';
import styled from 'styled-components';
import { MapParameters } from 'common/components/GoogleMapsModal/GmapsModalSlice';

const HeaderInfo = styled(Info)<{
  $textColor: string;
}>`
  color: ${props => props.$textColor};
`;

const StyledInfoValue = styled(InfoValue)<{
  $textColor: string;
}>`
  color: ${props => props.$textColor};
`;

export const WizardHeader: FC<{
  growerId?: string | Pick<GrowerBlock, 'growerId'>;
  blockId?: string | Pick<GrowerBlock, 'blockName'>;
  varietyCode?: string;
  evaluator?: string;
  binsRemaining?: number;
  size?: string;
  mapParams: MapParameters | null;
  locationStyles: { backgroundColor: string; iconColor: string };
  textColor: string;
  showSize: boolean;
}> = ({
  growerId,
  blockId,
  varietyCode,
  evaluator,
  binsRemaining,
  size,
  mapParams,
  locationStyles,
  textColor,
  showSize,
}) => {
  return (
    <>
      <WizardRow>
        <HeaderInfo $textColor={textColor}>
          {growerId ? growerId?.toString() : '-'}
        </HeaderInfo>
        <InfoLabel>
          Block:
          <StyledInfoValue $textColor={textColor}>
            {blockId ? blockId?.toString() : '-'}
          </StyledInfoValue>
          <LocationButton
            backgroundColor={locationStyles.backgroundColor}
            iconColor={locationStyles.iconColor}
            mapParams={mapParams}
          />
        </InfoLabel>
      </WizardRow>
      <InfoRow>
        <InfoLabel>Variety:</InfoLabel>
        <StyledInfoValue $textColor={textColor}>
          {varietyCode || '-'}
        </StyledInfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>FR:</InfoLabel>
        <StyledInfoValue $textColor={textColor}>
          {evaluator || '-'}
        </StyledInfoValue>
      </InfoRow>
      <InfoRow>
        <InfoLabel>Bins to Go:</InfoLabel>
        <StyledInfoValue $textColor={textColor}>
          {binsRemaining || '-'}
        </StyledInfoValue>
      </InfoRow>
      {showSize && (
        <InfoRow>
          <InfoLabel>Eval. Size:</InfoLabel>
          <StyledInfoValue $textColor={textColor}>
            {size || '-'}
          </StyledInfoValue>
        </InfoRow>
      )}
    </>
  );
};
