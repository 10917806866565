import styled from 'styled-components';
import { mobile } from 'common/styles/breakpoints';

export const BodyText = styled.p`
  font-family: KanitLight;
  font-size: 20px;
  line-height: 134%;
  letter-spacing: 0.0075em;
`;
export const SubText = styled(BodyText)`
  font-size: 16px;
  line-height: 138%;
`;

export const LinkText = styled.a`
  font-family: KanitLight;
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.0075em;
  text-decoration-line: underline;
  color: #0a93d0;
  cursor: pointer;
`;

export const PasswordText = styled.div`
  font-family: KanitLight;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.075em;

  li {
    font-family: KanitLight;
    font-size: 20px;
    line-height: 134%;
    letter-spacing: 0.075em;
  }

  @media (max-width: ${mobile}) {
    font-family: KanitExtraLight;
    font-size: 20px;
    line-height: 134%;
    margin-top: 10px;

    li {
      font-family: KanitSemiBold;
      font-size: 16px;
      line-height: 138%;
    }
  }
`;

export const PasswordSubtitle = styled.span`
  font-family: KanitBold;
  font-size: 28px;
  line-height: 125%;
  letter-spacing: 0.015em;

  @media (max-width: ${mobile}) {
    font-size: 22px;
    line-height: 120%;
    letter-spacing: 0.0075em;
    margin-top: 10px;
  }
`;
