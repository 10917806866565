import styled from 'styled-components';
import {
  InfoLabel,
  WizardDivider,
  WizardSection,
} from '../WizardComponents/wizardStyles';
import { DataEntryRow } from '../SchedulePickWizard/styles';
import { mobile } from 'common/styles/breakpoints';
import { lightGreyText } from 'common/styles/colors';

export const AssignSection = styled(WizardSection)`
  flex-direction: row;
  gap: 30px;

  @media (max-width: ${mobile}) {
    flex-direction: column;
  }
`;

export const AssignHaulers = styled(WizardSection)`
  @media (max-width: ${mobile}) {
    border-top: 2px solid ${lightGreyText};
  }
`;

export const DropdownRemoveContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 232px;
`;

export const AssignCrewRow = styled(DataEntryRow)`
  margin-top: 12px;
`;

export const DividerWithMargin = styled(WizardDivider)`
  margin-top: 16px;
  max-width: 232px;
`;

export const smallInputSize = '178px';

export const bigInputSize = '232px';

export const TextareaPrompt = styled(InfoLabel)`
  margin-top: 15px;
`;
