import { useAppSelector } from 'app/redux';
import { UpdateDetailsDto } from 'common/api/dto/growerBlockApiTypes';
import {
  useGetBlockWithBaseDetailsQuery,
  useUpdateBlockMutation,
} from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { GrowerBlock } from 'common/models';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { FC, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import { BlockDetailForm } from '../components/BlockDetailsForm';

export const BlockDetailView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const [updateGrowerBlock] = useUpdateBlockMutation();
  const {
    data: block,
    isLoading,
    error: getError,
  } = useGetBlockWithBaseDetailsQuery({
    blockId: parseInt(id, 10),
    // The component only renders when season exists so we safely assert the value here.
    seasonId: selectedSeason?.id as number,
  });

  const callUpdateGrowerBlock = useCallback(
    async (data: GrowerBlock) => {
      const detailsDto = {
        ...data,
      } as UpdateDetailsDto;

      await updateGrowerBlock({
        seasonId: selectedSeason?.id as number,
        blockId: parseInt(id, 10),
        ...detailsDto,
      }).unwrap();
    },
    [id, selectedSeason, updateGrowerBlock],
  );

  useEffect(() => {
    if (getError) {
      handleError(getError, 'Unable to load block. Returning to grower list.');
      history.replace(Constants.routes.GROWERS_LIST);
    }
  }, [getError, history]);

  return (
    <WithLoadingOverlay isLoading={isLoading}>
      {block && (
        <BlockDetailForm block={block} updater={callUpdateGrowerBlock} />
      )}
    </WithLoadingOverlay>
  );
};
