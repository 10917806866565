export enum EnvironmentConfiguration {
  Development = 'development',
  Testing = 'testing',
  Staging = 'staging',
  Demo = 'demo',
  Production = 'production',
}

export interface IEnvironment {
  readonly apiRoute: string;
  readonly name: EnvironmentConfiguration;
  readonly isProductionBuild: boolean;
  readonly appName: string;
  readonly version: string;
}
