import { DetailText } from 'common/components/DetailControls';
import { ResponsiveRow, StyledStack } from 'common/components/FormRow';
import { ScheduledPick, User } from 'common/models';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';

const StyledRow = styled(ResponsiveRow)`
  padding-left: 15px;
  padding-right: 20px;
  .col-lg-2 {
    min-width: 30%;
  }
  .col {
    padding-left: 20px;
  }
`;

export const DetailTextWithNoChange: React.FC<{
  name: string;
  label: string;
  text: string;
}> = ({ name, label, text }) => {
  return <DetailText name={name} label={label} text={text} largeLabel />;
};

export const StaffCrewTab: React.FC<{ pickData: ScheduledPick }> = ({
  pickData,
}) => {
  const { block, pickRecord } = pickData;
  const buildStringName = (user: User | null): string => {
    if (!user) return 'Unassigned';

    return `${user.firstName} ${user.lastName}`;
  };

  return (
    <StyledStack>
      <StyledRow>
        <Col>
          <DetailTextWithNoChange
            name='field rep'
            label='Field Rep'
            text={buildStringName(block?.primaryEvaluator ?? null)}
          />
        </Col>
        <Col>
          <DetailTextWithNoChange
            name='harvest coordinator'
            label='Coordinator'
            text={buildStringName(pickData.coordinator)}
          />
        </Col>
      </StyledRow>
      {pickRecord?.contractorRecords.map((item, index) => (
        <StyledRow key={item.id}>
          <Col>
            <DetailTextWithNoChange
              name={`flc-${index}`}
              label={`Crew ${index + 1}`}
              text={item.contractor?.code ?? ''}
            />
          </Col>
          <Col>
            <DetailTextWithNoChange
              name={`pickRate-${index}`}
              label={`Pick Rate ${index + 1}`}
              text={item.pickRate?.toString() ?? ''}
            />
          </Col>
        </StyledRow>
      ))}
      {pickRecord?.haulerRecords.map((item, index) => (
        <StyledRow key={item.id}>
          <Col>
            <DetailTextWithNoChange
              name={`hauler-${index}`}
              label={`Hauler ${index + 1}`}
              text={item?.hauler.code ?? ''}
            />
          </Col>
          <Col>
            <DetailTextWithNoChange
              name={`haul-rate-${index}`}
              label={`Haul Rate ${index + 1}`}
              text={item?.haulRate.rate.toString() ?? ''}
            />
          </Col>
        </StyledRow>
      ))}
    </StyledStack>
  );
};
