import { mobile } from 'common/styles/breakpoints';
import { PHCode } from 'features/pack-plan-views/components/PackPlanTable/styles';
import styled from 'styled-components';

export const PackHouseCode = styled(PHCode)`
  height: 48px;

  @media (max-width: ${mobile}) {
    margin: 0 5px;
  }
`;

export const StyledTableWrapper = styled.div`
  margin-bottom: 10px;

  table {
    width: max(765px, 100%);
  }

  & > div {
    box-shadow: none;
    padding: 0;
    border-radius: 0;
  }

  tfoot td {
    border-bottom: none;
  }

  td span {
    width: inherit;
  }
`;

export const TitleAndCsvBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
