import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { Rootstock } from 'common/models';

export type GetRootstockList = [Pick<Rootstock, 'id' | 'rootstockCode'>];

export const rootstockApi = createApi({
  reducerPath: 'rootstockApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['Rootstock'],

  endpoints: builder => ({
    getRootstocks: builder.query<GetRootstockList, void>({
      query: () => ({
        url: '/rootstock',
      }),
      providesTags: (_, err) => (err ? [] : ['Rootstock']),
    }),
  }),
});

export const { useGetRootstocksQuery } = rootstockApi;
