import { ModalWrapper } from 'common/components/ModalWrapper';
import { FC } from 'react';
import BlockInactiveForm from './BlockInactiveForm';
import styled from 'styled-components';
import { blueShadeHeader, darkNavy, lighterNavy } from 'common/styles/colors';
import { BodyText } from 'common/styles/texts';

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;
`;

const TitleDiv = styled.div`
  font-family: KanitLight;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.0075em;
  color: ${lighterNavy};
  margin-bottom: 10px;
`;
const InfoDiv = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const InfoText = styled(BodyText)`
  font-family: KanitMedium;
  color: ${darkNavy};
  margin: 0;
`;

interface ModalProps {
  isOpen: boolean;
  closeModal: (e: unknown) => void;
  isLoading: boolean;
  setLoading: (newState: boolean) => void;
  contentProps: {
    growerName: string;
    blockName: string;
  };
}

export const MakeInactiveModal: FC<ModalProps> = ({
  isOpen,
  closeModal,
  isLoading,
  contentProps,
  setLoading,
}) => {
  const Header: FC<Pick<ModalProps, 'contentProps'>> = ({
    contentProps: { growerName, blockName },
  }) => (
    <HeaderWrapper>
      <TitleDiv>Select a reason for making this block inactive:</TitleDiv>
      <InfoDiv>
        <InfoText>
          Grower:
          <span style={{ marginLeft: '0.5rem', color: `${blueShadeHeader}` }}>
            {growerName}
          </span>
        </InfoText>
        <InfoText>
          Block:
          <span style={{ marginLeft: '0.5rem', color: `${blueShadeHeader}` }}>
            {blockName}
          </span>
        </InfoText>
      </InfoDiv>
    </HeaderWrapper>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      closeModal={closeModal}
      isLoading={isLoading}
      header={<Header contentProps={contentProps} />}
      body={<BlockInactiveForm onClose={closeModal} setLoading={setLoading} />}
    />
  );
};
