import {
  HarvestLineType,
  HaulingLineType,
} from 'common/models/harvestData/payrollData';
import {
  PayrollTableRow,
  emptyIdKey,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { createRowKey } from 'features/harvest-payroll/utils/payrollPageUtils';
import { FC, Fragment } from 'react';
import { AddPickLineForm } from './AddCustomLineForm/AddPickLineForm';
import { PayrollTableLine } from './PayrollTableLine';
import { EmptyRow } from './EmptyRow';

export const PickRows: FC<{
  tableData: PayrollTableRow[];
  isEditable: boolean;
}> = ({ tableData, isEditable }) => {
  let defaultQuantity = '0';
  let defaultCtrRecId: number | null;
  let defaultHaulRecIds: {
    // Either 'ctrRecordId' is defined and 'hlrRecordId' is 'null' or vice-versa.
    contractorRecordId: number | null;
    haulerRecordId: number | null;
  };
  let haulRate = '0';
  let surchargeRate = '0';

  /**
   * Function to provide the default quantity and the record ID to be used
   * when creating a new line.
   */
  const determineNewLineData = (row: PayrollTableRow) => {
    const {
      metadata: { contractorRecordId, haulerRecordId, descType },
      quantity,
      rate,
    } = row;

    if (descType === HarvestLineType[HarvestLineType.Harvesting]) {
      defaultQuantity = quantity;
      defaultCtrRecId = contractorRecordId as number;
    }

    if (descType === HaulingLineType[HaulingLineType.Hauling]) {
      defaultQuantity = quantity;
      defaultHaulRecIds = { contractorRecordId, haulerRecordId };
      haulRate = rate;
    }

    if (descType === HaulingLineType[HaulingLineType.Fuel_Surcharge]) {
      surchargeRate = rate;
    }

    return { defaultQuantity, defaultCtrRecId, defaultHaulRecIds };
  };

  return tableData.map(row => {
    const isEmptyRow = row.metadata.descType === emptyIdKey;
    const changeBackground =
      row.metadata.descCategory === HaulingLineType[HaulingLineType.Hauling];

    const { defaultQuantity, defaultCtrRecId, defaultHaulRecIds } =
      determineNewLineData(row);

    return isEmptyRow ? (
      <Fragment key={createRowKey(row.metadata)}>
        {isEditable ? (
          <AddPickLineForm
            pickId={row.metadata.pickId}
            defaultCtrRecId={defaultCtrRecId}
            defaultHaulRecIds={defaultHaulRecIds}
            defaultQuantity={defaultQuantity}
            rates={{ haulRate, surchargeRate }}
          />
        ) : (
          <EmptyRow rowData={row} />
        )}
      </Fragment>
    ) : (
      <PayrollTableLine
        key={createRowKey(row.metadata)}
        row={row}
        changeBackground={changeBackground}
      />
    );
  });
};
