/* eslint @typescript-eslint/no-unused-vars: ["error", { "varsIgnorePattern": ".*_" }] */
import { ReactElement, ReactNode, ReactPortal } from 'react';
import {
  BaseTableItem,
  CustomRenderer,
  GenericTableProps,
  TableHeader,
} from './types';
import type Table_ from 'react-bootstrap/Table'; // For doc links.
import type { StyledTable as StyledTable_ } from './styles'; // For doc links.

/**
 * Retrieves a custom header renderer from the given collection.
 *
 * @param customRenderers The collection of renderers to search.
 * @param key An item property to find a renderer for.
 * @returns A function that renders a cell from the given header.
 */
function getHeaderRenderer<T extends BaseTableItem>(
  customRenderers: CustomRenderer<T>[],
  key: keyof T,
): ((hdr: TableHeader<T>) => ReactNode) | undefined {
  const renderer = customRenderers.find(cr => cr.key === key);

  return renderer?.headerRenderer;
}

/**
 * Retrieves a custom renderer from the given collection.
 *
 * @param customRenderers The collection of renderers to search.
 * @param key An item property to find a renderer for.
 * @returns A function that renders a cell from the given item.
 */
function getRenderer<T extends BaseTableItem>(
  customRenderers: CustomRenderer<T>[],
  key: keyof T,
): ((itm: T) => ReactNode) | undefined {
  const renderer = customRenderers.find(cr => cr.key === key);

  return renderer?.renderer;
}

/**
 * Retrieves a custom footer renderer from the given collection.
 *
 * @param customRenderers The collection of renderers to search.
 * @param key An item property to find a renderer for.
 * @returns A function that renders a cell from the given item.
 */
function getFooterRenderer<T extends BaseTableItem>(
  customRenderers: CustomRenderer<T>[],
  key: keyof T,
): ((itm: T) => ReactNode) | undefined {
  const renderer = customRenderers.find(cr => cr.key === key);

  return renderer?.footerRenderer;
}

/**
 * Creates table elements for items of any given type. This should be wrapped in
 * a table element, see remarks.
 *
 * @param props The properties required by the component.
 * @returns A new instance of the component.
 *
 * @remarks
 *
 * This component is not wrapped in a table element in order to make styling
 * easier. Consumers can create their own styled {@link Table_} to wrap this in,
 * or use the default, {@link StyledTable_}.
 */
export const GenericTable = <TableItem extends BaseTableItem>({
  headers,
  items,
  footer,
  customRenderers = [],
  showHeaders = true,
  emptyMessage = 'No data',
  clickHandler,
}: GenericTableProps<TableItem>): ReactElement => {
  return (
    <>
      {showHeaders && (
        <thead>
          <tr>
            {headers.map(header => {
              const renderer = getHeaderRenderer(customRenderers, header.key);
              return (
                <th key={String(header.key)}>
                  {renderer ? renderer(header) : header.label}
                </th>
              );
            })}
          </tr>
        </thead>
      )}
      <tbody>
        {items.map(item => (
          <tr
            key={item.id}
            onClick={clickHandler ? () => clickHandler(item) : undefined}
            style={clickHandler ? { cursor: 'pointer' } : {}}
          >
            {headers.map(header => {
              const renderer = getRenderer(customRenderers, header.key);
              return (
                <td key={String(header.key)}>
                  {renderer
                    ? renderer(item)
                    : (item[header.key] as ReactPortal)}
                </td>
              );
            })}
          </tr>
        ))}
        {!items.length && (
          <tr>
            <td>
              <div className='text-center'>{emptyMessage}</div>
            </td>
          </tr>
        )}
      </tbody>
      {footer && (
        <tfoot>
          <tr>
            {headers.map(hdr => {
              const renderer = getFooterRenderer(customRenderers, hdr.key);
              return (
                <td key={hdr.key.toString()}>
                  {renderer
                    ? renderer(footer)
                    : (footer[hdr.key] as ReactPortal)}
                </td>
              );
            })}
          </tr>
        </tfoot>
      )}
    </>
  );
};
