import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { usePagination, useSortBy, useTable } from 'react-table';
import { WithLoadingOverlay } from '../LoadingSpinner';
import { Paginator } from './Paginator';
import { DataTableFilter } from '../DataTableFilter/DataTableFilter';
import { DataTableProps } from './DataTable';
import { getIntParam } from 'utils/paramFunctions';
import { Constants } from 'utils/constants';
import { MobileTableContainer } from './DataTableStyles';

export const DataTableMobile = <D extends Record<string, unknown>>({
  title,
  unitToPaginate,
  isLoading,
  isFetching,
  columns,
  data,
  pagination,
  sortBy,
  filtering,
  onRowClick,
}: DataTableProps<D>): ReactElement => {
  // Evaluate these conditions once instead of re-computing in multiple places.
  const sortByEnabled = sortBy !== undefined;
  const paginationEnabled = pagination !== undefined;

  const history = useHistory();
  const currentPage = getIntParam(
    history.location.search,
    Constants.paginationParams.PAGE,
  );

  // Determine which react-table plugins should be enabled.
  const plugins = [
    // The order in which plugins are specified matters to react-table.
    // Order needs to be useFilter -> useSortBy -> usePagination.
    ...(sortByEnabled ? [useSortBy] : []),
    ...(paginationEnabled ? [usePagination] : []),
  ];

  // Generate the required initial state for the enabled plugins.
  const initialState = {
    ...(sortByEnabled ? {} : {}),
    ...(paginationEnabled
      ? {
          pageIndex: (currentPage ?? 1) - 1,
          pageSize: pagination.pageSize,
        }
      : {}),
  };

  // Generate the required table options for the enabled plugins.
  const extraTableOptions = {
    ...(sortByEnabled ? {} : {}),
    ...(paginationEnabled
      ? { manualPagination: true, pageCount: pagination.pageCount }
      : {}),
  };

  // Create the table instance.
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
      ...extraTableOptions,
    },
    ...plugins,
  );

  const {
    // Basic instance props
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    // Pagination specific props
    page,
  } = tableInstance;

  // If the 'usePagination' plugin is enabled, the 'page' instance prop contains the table rows for the current page.
  // Otherwise, the 'rows' instance prop contains the table rows.
  const tableRows = paginationEnabled ? page : rows;

  const hasTableData = tableRows.length > 0;

  return (
    <MobileTableContainer>
      <h3>{title}</h3>
      {filtering ? (
        <DataTableFilter
          filterOptions={filtering?.filterOptions}
          appliedFilters={filtering?.defaultFilters}
          setFilters={filtering?.applyFilters}
        />
      ) : null}
      <WithLoadingOverlay isLoading={isLoading || isFetching}>
        {hasTableData ? (
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      style={{ width: `${column.width}` }}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {tableRows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => {
                      if (onRowClick) {
                        const link = onRowClick(row.original);
                        if (typeof link === 'string') {
                          history.push(link);
                        }
                      }
                    }}
                    className={`${
                      index % 2 ? 'blue-row' : ''
                    } table-component-row`}
                    {...row.getRowProps()}
                    role='link'
                  >
                    {row.cells.map(cell => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className='no-data-text'>No Data Available</p>
        )}
      </WithLoadingOverlay>
      {/* If the usePagination plugin is enabled, render a paginator to allow users to page through the data. */}
      {!isLoading && paginationEnabled && hasTableData && (
        <Paginator
          {...pagination}
          unitToPaginate={unitToPaginate}
          isFetching={isFetching}
        />
      )}
    </MobileTableContainer>
  );
};
