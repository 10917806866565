import {
  CreateOrViewWrapper,
  DetailDropDown,
  DetailText,
} from 'common/components/DetailControls';
import DetailDatePicker from 'common/components/DetailControls/DetailDatePicker';
import { ResponsiveRow, StyledStack as Stack } from 'common/components/FormRow';
import { OrchardMonitoring } from 'common/models/growerBlock/orchardMonitoring';
import { mobile } from 'common/styles/breakpoints';
import { darkNavy } from 'common/styles/colors';
import { UpdateMonitoringFormValues } from 'features/evaluation-views/types';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import {
  noOption,
  yesNoOptions,
  yesOption,
} from 'utils/formValues/commonOptions';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))``;

const ColumnTitle = styled.span`
  font-family: 'KanitRegular';
  font-weight: 600;
  font-size: 20px;

  letter-spacing: 0.0075em;
  color: ${darkNavy};

  margin-left: 0.3em;

  @media (max-width: ${mobile}) {
    display: flex;
    margin-top: 20px;
  }
`;

const StyledStack = styled(Stack)`
  @media (max-width: ${mobile}) {
    .row > div {
      width: 50%;
    }
  }
`;

export const MonitoringFields: FC<{
  blockSeasonId?: number;
  monitoring?: OrchardMonitoring | null;
}> = ({ blockSeasonId, monitoring }) => {
  const { userHasPermission } = useRbac();
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext<UpdateMonitoringFormValues>();
  const today = new Date();

  const { ref: monitorDateRef, ...monitorDateProps } = register('monitorDate');
  const { ref: sampleDateRef, ...sampleDateProps } = register('sampleDate');
  const { ref: harvestDateRef, ...harvestDateProps } = register('harvestDate');
  const { ref: skirtPrunedRef, ...skirtPrunedProps } = register('skirtPruned');
  const { ref: copperSpray1Ref, ...copperSpray1Props } =
    register('copperSpray_1');
  const { ref: copperSpray2Ref, ...copperSpray2Props } =
    register('copperSpray_2');
  const { ref: frbSprayRef, ...frbSpray1Props } = register('frbSpray_1');
  const { ref: frbSpray2Ref, ...frbSpray2Props } = register('frbSpray_2');
  const { ref: brownRotRef, ...brownRotProps } = register('brownRot');
  const { ref: usdaListRef, ...usdaListProps } = register('usdaList');
  const { ref: ckApprovedRef, ...ckApprovedProps } = register('ckApproved');

  return (
    <StyledStack>
      <CreateOrViewWrapper
        formObject={monitoring && Object.keys(monitoring).length !== 0}
        message='No orchard monitoring exists for this season.'
        canCreate={
          userHasPermission('evalEstTabs:create/edit') && !!blockSeasonId
        }
      >
        <StyledRow>
          <Col>
            <ColumnTitle>Monitoring Record:</ColumnTitle>
          </Col>
          <Col>
            <ColumnTitle>Maturity:</ColumnTitle>
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='Monitor Date'
              control={control}
              maxDate={today}
              {...monitorDateProps}
            />
          </Col>
          <Col>
            <DetailDatePicker
              label='Sample Date'
              control={control}
              maxDate={today}
              {...sampleDateProps}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='Harvest Date (HSR)'
              control={control}
              maxDate={today}
              {...harvestDateProps}
            />
          </Col>
          <Col>
            <DetailText
              label='Brix %'
              name='brix'
              validation={errors.brix?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='1st Copper Spray'
              control={control}
              maxDate={today}
              {...copperSpray1Props}
            />
          </Col>
          <Col>
            <DetailText
              label='Acid %'
              name='acid'
              validation={errors.acid?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='2nd Copper Spray'
              control={control}
              maxDate={today}
              {...copperSpray2Props}
            />
          </Col>
          <Col>
            <DetailText
              label='Ratio %'
              name='ratio'
              validation={errors.ratio?.message}
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='1st FRB Spray'
              control={control}
              maxDate={today}
              {...frbSpray1Props}
            />
          </Col>
          <Col />
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDatePicker
              label='2nd FRB Spray'
              control={control}
              maxDate={today}
              {...frbSpray2Props}
            />
          </Col>
          <Col />
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='Skirt Pruned'
              defaultValue={monitoring?.skirtPruned ? yesOption : noOption}
              textValue={
                monitoring?.skirtPruned ? yesOption.label : noOption.label
              }
              {...skirtPrunedProps}
              options={yesNoOptions}
              formControl={control}
            />
          </Col>
          <Col />
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='Brown Rot'
              defaultValue={monitoring?.brownRot ? yesOption : noOption}
              textValue={
                monitoring?.brownRot ? yesOption.label : noOption.label
              }
              {...brownRotProps}
              options={yesNoOptions}
              formControl={control}
            />
          </Col>
          <Col />
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='USDA List'
              defaultValue={monitoring?.usdaList ? yesOption : noOption}
              textValue={
                monitoring?.usdaList ? yesOption.label : noOption.label
              }
              {...usdaListProps}
              options={yesNoOptions}
              formControl={control}
            />
          </Col>
          <Col />
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailDropDown
              label='CK Approved'
              defaultValue={monitoring?.ckApproved ? yesOption : noOption}
              textValue={
                monitoring?.ckApproved ? yesOption.label : noOption.label
              }
              {...ckApprovedProps}
              options={yesNoOptions}
              formControl={control}
            />
          </Col>
          <Col />
        </StyledRow>
      </CreateOrViewWrapper>
    </StyledStack>
  );
};
