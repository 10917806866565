import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { County } from 'common/models';

export type GetCountiesList = [Pick<County, 'id' | 'countyName'>];

export const countyApi = createApi({
  reducerPath: 'countyApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: ['County'],

  endpoints: builder => ({
    getCounties: builder.query<GetCountiesList, void>({
      query: () => ({
        url: '/county',
      }),
      providesTags: (_, err) => (err ? [] : ['County']),
    }),
  }),
});

export const { useGetCountiesQuery } = countyApi;
