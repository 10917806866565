import { darkNavy, lighterNavy, orange, white } from 'common/styles/colors';
import styled from 'styled-components';

export const topBarHeight = '60px';

export const FixedTopBar = styled.div`
  color: ${white};
  background-color: ${darkNavy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${topBarHeight};
  position: sticky;
  top: 0;
  z-index: 100; // keeps the mobile top bar above child content when scrolling
`;

export const MobileLinks = styled.nav`
  background-color: ${lighterNavy};
  color: ${white};
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${topBarHeight});
  width: 100vw;
`;

export const LogoBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const MenuBar = styled.div<{
  $showSeasonSelector: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  // Center logo and title when season selector is hidden.
  padding: ${props => (props.$showSeasonSelector ? '0 10px' : '0 52px 0 10px')};
`;

export const menuIconStyle = {
  cursor: 'pointer',
  height: '100%',
  width: '42px',
};

export const logoIconStyle = {
  color: orange,
  fontSize: '35px',
};

export const ContentContainer = styled.div`
  flex-grow: 1;
`;
