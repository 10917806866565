import { NotFoundView } from 'common/components/NotFound';
import { RoleType } from 'common/models';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { FC } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { HarvestPayrollView } from './pages/HarvestPayrollView';

export const HarvestPayrollRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={HarvestPayrollView}
        requiredRoles={[RoleType.Admin]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
