import { lighterNavy } from 'common/styles/colors';
import { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { DetailControl } from './DetailControl';
import { DetailFormContext } from './DetailForm';
import { EditableTextDetailProps } from './types';

export const StyledText = styled(Form.Control).attrs(() => ({
  type: 'text',
}))`
  font-family: KanitSemiBold;
  color: ${lighterNavy};

  ${({ $onViewMode }) => $onViewMode && `pointer-events: none;`}
`;

/**
 * Defines properties accepted by the {@link DetailText} control.
 */
type DetailTextProps = EditableTextDetailProps & { name?: string };

/**
 * Displays a textual value and can be toggled between editable and read-only
 * modes, for use in item detail forms.
 *
 * @param props The properties needed by the control.
 * @returns A new instance of the control.
 */
export const DetailText = (props: DetailTextProps) => {
  const {
    label,
    largeLabel,
    labelMax,
    controlId,
    withSeparator,
    text,
    editable,
    validation,
    name,
  } = props;

  const context = useContext(DetailFormContext);
  const isDisabled = !context.editable || editable === false;
  const methods = useFormContext();

  return (
    <DetailControl
      label={label}
      largeLabel={largeLabel}
      labelMax={labelMax}
      controlId={controlId}
      isViewMode={isDisabled}
      withSeparator={withSeparator}
      valueRenderer={id => {
        return (
          <>
            <StyledText
              id={id}
              readOnly={isDisabled}
              plaintext={isDisabled}
              {...(methods?.register &&
                name && { ...methods.register(`${name}`) })}
              // Override useForm value
              {...(text && { value: text })}
              isInvalid={validation}
              $onViewMode={isDisabled}
              tabIndex={isDisabled ? -1 : 0}
            />
            {isDisabled ? null : (
              <Form.Control.Feedback type='invalid'>
                {validation}
              </Form.Control.Feedback>
            )}
          </>
        );
      }}
    />
  );
};
