import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

// Type guard to check if error is a FetchBaseQueryError
export const isApiError = (
  error: FetchBaseQueryError | unknown,
): error is FetchBaseQueryError => {
  const errorValue = error as FetchBaseQueryError;

  if (typeof errorValue?.status === 'number') {
    return errorValue.data !== undefined;
  }

  switch (errorValue?.status) {
    case 'FETCH_ERROR':
    case 'CUSTOM_ERROR':
      return errorValue.error !== undefined;

    case 'PARSING_ERROR':
      return (
        errorValue.originalStatus !== undefined &&
        errorValue.data !== undefined &&
        errorValue.error !== undefined
      );

    default:
      return false;
  }
};
