import { orange, white } from 'common/styles/colors';
import { useAuth } from 'features/auth/hooks';
import styled from 'styled-components';

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

const TestAction = styled.button`
  background-color: ${orange};
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: ${white};
  border: none;
  padding: 8px;
  font-family: KanitLight;
`;

export const DeveloperTools = () => {
  const { user } = useAuth();
  return (
    <ActionContainer>
      <TestAction
        onClick={() => {
          throw new Error(
            `Test error triggered by ${user?.firstName} ${user?.lastName}.`,
          );
        }}
        title='Test how unexpected errors are handled.'
      >
        Trigger Error
      </TestAction>
    </ActionContainer>
  );
};
