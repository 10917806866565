import { FC } from 'react';
import { Spinner } from 'react-bootstrap';

export const LoadingSpinner: FC<{ size?: string }> = ({ size = '4em' }) => (
  <div className='text-center'>
    <Spinner
      animation='border'
      variant='primary'
      role='status'
      style={{ width: size, height: size }}
    >
      <span className='visually-hidden'>Loading...</span>
    </Spinner>
  </div>
);
