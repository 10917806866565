import { GrowerWithBlocks } from 'common/api/dto/get-grower.dto';
import { DataTableText } from 'common/components/DataTable';
import { GrowerStatus } from 'common/models';
import { CmsDate } from 'common/models/cmsDate';
import { DateWithoutTime } from 'common/models/dateType';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { ForwardArrow } from '../components/GrowerIcons';
import { StatusIcon } from '../components/StatusIcon';

export type GrowerTableItem = {
  id: number;
  growerId: string;
  status?: GrowerStatus;
  name: { id: number; name: string };
  gapStatus: string | null;
  certExp: DateWithoutTime | null;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string | null;
};

export type UseGrowerTableData = (agents?: GrowerWithBlocks[]) => {
  columns: Column<GrowerTableItem>[];
  data: GrowerTableItem[];
};

export const useGrowerTableData: UseGrowerTableData = (growers = []) => {
  const columns: Column<GrowerTableItem>[] = useMemo(
    () => [
      {
        accessor: 'status',
        Cell: ({ value: status }) => (
          <StatusIcon status={status} type='grower' />
        ),
      },
      {
        accessor: 'name',
        Header: 'Grower Name',
        Cell: ({ value: values }) => (
          <DataTableText>{`${values.name}`}</DataTableText>
        ),
      },
      { accessor: 'growerId', Header: 'Grower ID' },
      {
        accessor: 'gapStatus',
        Header: () => (
          <>
            Gap
            <br />
            Status
          </>
        ),
        Cell: ({ value }) => <DataTableText>{value || '-'}</DataTableText>,
      },
      {
        accessor: 'certExp',
        Header: () => (
          <>
            Cert.
            <br />
            Expiration
          </>
        ),
        Cell: ({ value }) => (
          <DataTableText>
            {CmsDate.parse(value)?.toString() || '-'}
          </DataTableText>
        ),
      },
      { accessor: 'address', Header: 'Address' },
      { accessor: 'city', Header: 'City' },
      { accessor: 'state', Header: 'State' },
      { accessor: 'zipCode', Header: 'Zip' },
      { accessor: 'phone', Header: 'Phone' },
      {
        id: 'Arrow',
        Header: '',
        Cell: () => <ForwardArrow />,
      },
    ],
    [],
  );

  const data: GrowerTableItem[] = useMemo(
    () =>
      growers.map(grower => {
        const {
          id,
          growerId,
          name,
          gapStatus,
          certExp,
          address: { addressLine1, addressLine2, city, state, zipCode },
          phone,
          seasonData,
        } = grower;
        return {
          id,
          growerId,
          status: seasonData?.status,
          name: { id, name },
          gapStatus,
          certExp,
          address: `${addressLine1}${addressLine2 ? `, ${addressLine2}` : ''}`,
          city,
          state,
          zipCode,
          phone,
        };
      }),
    [growers],
  );

  return { columns, data };
};
