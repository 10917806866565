import Header from 'common/components/Header';
import styled from 'styled-components';
import BackButton from './BackButton';

export interface props {
  title: string;
  to?: string;
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  width: 100%;
`;

const HeaderBack: React.FC<props> = ({ title, to = '/' }) => {
  return (
    <HeaderContainer>
      <Header variant='h4'>{title}</Header>
      <BackButton to={to} />
    </HeaderContainer>
  );
};

export default HeaderBack;
