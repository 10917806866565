import { FC } from 'react';
import { LoadingSpinner } from './LoadingSpinner';

export type Props = {
  isLoading: boolean;
  size?: string;
  children?: React.ReactNode;
};

export const WithLoadingOverlay: FC<Props> = ({ isLoading, size, children }) =>
  isLoading ? <LoadingSpinner size={size} /> : <>{children}</>;
