import { FC, useMemo } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import styled from 'styled-components';
import { useAppDispatch } from 'app/redux';
import {
  MapParameters,
  mapsModalSlice,
} from '../GoogleMapsModal/GmapsModalSlice';
import { borderGrey } from 'common/styles/colors';
import TooltipTrigger from '../WithTooltip/WithTooltip';

export const multipleLocationTooltip = 'Select blocks to see their locations.';

export function validateCoordinateInfo(
  latitude: number | null,
  longitude: number | null,
  blockId: string | null,
): MapParameters | null {
  return latitude && longitude && blockId
    ? {
        latLong: {
          lat: latitude,
          lng: longitude,
        },
        blockId,
      }
    : null;
}

export const IconContainer = styled.button<{
  $iconColor: string;
  $backgroundColor: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-left: 10px;
  color: ${props => props.$iconColor};
  background: ${props => props.$backgroundColor};
`;

/**
 * A component that triggers the display of coordinates in a Google map.
 * It is disabled if no coordinates are passed, handles
 * one or multiple sets of coordinates and shows a tooltip overlay on hover.
 */
export const LocationButton: FC<{
  iconColor: string;
  backgroundColor: string;
  mapParams: MapParameters | MapParameters[] | null;
  disabledTooltip?: string;
}> = ({
  iconColor,
  backgroundColor,
  mapParams,
  disabledTooltip = 'No block location(s) to view.',
}) => {
  const dispatch = useAppDispatch();
  const validatedMapParams: MapParameters[] | null = useMemo(
    () => (mapParams && !Array.isArray(mapParams) ? [mapParams] : mapParams),
    [mapParams],
  );
  const isEnabled = validatedMapParams && validatedMapParams.length > 0;

  return (
    <TooltipTrigger
      title='Block location button'
      tooltipText={
        isEnabled
          ? `See block location${validatedMapParams.length > 1 ? 's' : ''}.`
          : disabledTooltip
      }
    >
      <IconContainer
        type='button'
        $iconColor={iconColor}
        $backgroundColor={isEnabled ? backgroundColor : borderGrey}
        onClick={() => {
          if (isEnabled) {
            dispatch(mapsModalSlice.actions.setMapsParams(validatedMapParams));
            dispatch(mapsModalSlice.actions.setShowModal());
          }
        }}
        disabled={!isEnabled}
      >
        <LocationOnOutlinedIcon />
      </IconContainer>
    </TooltipTrigger>
  );
};
