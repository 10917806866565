import { useAppSelector } from 'app/redux';
import { useGetGrowerByIdQuery } from 'common/api/growerApi';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { FC, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Constants } from 'utils/constants';
import GrowerDetailForm from '../components/GrowerDetailForm';

export const GrowerDetailView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );
  const {
    data: grower,
    isLoading,
    error: getError,
  } = useGetGrowerByIdQuery({
    growerId: parseInt(id, 10),
    // The component only renders when season exists so we safely assert the value here.
    seasonId: selectedSeason?.id as number,
  });

  useEffect(() => {
    if (getError) {
      handleError(getError, 'Unable to load grower. Returning to grower list.');
      history.replace(Constants.routes.GROWERS_LIST);
    }
  }, [getError, history]);

  return (
    <WithLoadingOverlay isLoading={isLoading}>
      {grower && <GrowerDetailForm grower={grower} />}
    </WithLoadingOverlay>
  );
};
