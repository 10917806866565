import { grey, lighterNavy, tableBodyGrey } from 'common/styles/colors';
import { ReactElement } from 'react';
import styled from 'styled-components';

const FilterValueContainer = styled.div<{ separator: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: ${({ separator }) => (separator ? '8px' : '2px')};
  margin-bottom: ${({ separator }) => (separator ? '2px' : '0')};
  ${({ separator }) => separator && `border-bottom: 1px solid ${grey}`};
`;

const Checkbox = styled.input`
  box-sizing: border-box;
  width: 22px;
  height: 22px;
  background: ${lighterNavy};
  border: 2px solid ${grey};
  border-radius: 2px;
  cursor: pointer;
`;

const FilterValueComponent = styled.div`
  font-family: KanitRegular;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0075em;
  margin-left: 8px;
  color: ${tableBodyGrey};
  cursor: pointer;
`;

interface FilterCheckBoxProp {
  filterLabel: string;
  filterValue: string;
  filterCategory: string;
  isChecked: boolean;
  addCheckboxFilter: (filterValue: string, filterCategory: string) => void;
  removeFilter: (filterValue: string) => void;
  separator: boolean;
}

export const FilterCheckbox = ({
  filterLabel,
  filterValue,
  filterCategory,
  isChecked,
  addCheckboxFilter,
  removeFilter,
  separator,
}: FilterCheckBoxProp): ReactElement => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addCheckboxFilter(filterValue, filterCategory);
    } else {
      removeFilter(filterValue);
    }
  };

  return (
    <FilterValueContainer separator={separator}>
      <Checkbox
        id={filterValue}
        type='checkbox'
        name={filterValue}
        value={filterValue}
        onChange={handleCheckboxChange}
        checked={isChecked}
      />
      <label htmlFor={filterValue}>
        <FilterValueComponent className='checkboxValue'>
          {filterLabel}
        </FilterValueComponent>
      </label>
    </FilterValueContainer>
  );
};
