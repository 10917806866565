import { ContractorPickAssignment } from 'common/models';
import { ContractorRecord } from 'common/models/harvestData/contractor';
import moment from 'moment';

const formatPickDetailDate = (date: string | undefined): string =>
  moment(date).format('ddd MM/DD');

const compareContractorCodes = (
  contractor1: ContractorPickAssignment | ContractorRecord,
  contractor2: ContractorPickAssignment | ContractorRecord,
): number => {
  if (contractor1.contractor.code < contractor2.contractor.code) return -1;
  if (contractor1.contractor.code > contractor2.contractor.code) return 1;
  return 0;
};
export { compareContractorCodes, formatPickDetailDate };
