import { RowGroupKeys } from '../utils/payrollDataTypes';

/**
 * Represents the error that occurs when a collection of rows to update the
 * commission on is given, but there is no clear way to determine which rows to
 * calculate the commission for.
 */
export class AmbiguousCommissionTarget extends Error {
  constructor(first: RowGroupKeys, second: RowGroupKeys) {
    const stringifyKey = <T>(key: T | null | undefined) => key ?? `(${key})`;
    const getGroupDescription = (key: RowGroupKeys) => {
      return (
        `pick ${stringifyKey(key.pickId)}, ` +
        `contractor record ${stringifyKey(key.contractorRecordId)}, ` +
        `hauler record ${stringifyKey(key.haulerRecordId)}, ` +
        `category ${stringifyKey(key.descCategory)}`
      );
    };
    const mainMessage =
      'Cannot determine which pick rows to calculate a commission for when there ' +
      'are multiple groups and no target group is specified. Mismatch details:';
    const firstDesc = getGroupDescription(first);
    const secondDesc = getGroupDescription(second);

    super(
      `${mainMessage}\n  First group: ${firstDesc}\n  Second group: ${secondDesc}`,
    );

    this.name = AmbiguousCommissionTarget.name;
  }
}
