import { DateWithoutTime } from 'common/models/dateType';
import moment from 'moment';

export type DayInfo = {
  weekday: string;
  day: number;
  month: number;
  year: number;
};

export type Week = {
  startDate: Date;
  weekNumber: number;
  weekdays: DayInfo[];
};

/** Converts date from ISO date string to US 'MM/DD/YYYY' format */
export function getUSFormattedDate(date: Date): string {
  const [day, month, year] = new Intl.DateTimeFormat('en-US')
    .format(new Date(date))
    .split('/');

  return `${day.length === 1 ? `0${day}` : day}/${
    month.length === 1 ? `0${month}` : month
  }/${year}`;
}

/**
 * Takes a 'YYYY-MM-DD' string and returns a 'MM/DD/YY' formatted string.
 *
 * @remarks
 *
 * This function differs from {@link getUSFormattedDate} in that it avoids
 * converting the string date to a `Date` object, to avoid any of the issues
 * inherent to the `Date()` constructor.
 *
 * We'll revise either or both functions in the future to avoid confusion
 * and/or code duplication.
 */
export function toUSFormattedDate(date: DateWithoutTime): string {
  const array = date.split(/-/g);
  const arrIsNumeric = array.every(val => !Number.isNaN(Number(val)));
  const [year, month, day] = array;

  if (
    arrIsNumeric &&
    month?.length === 2 &&
    day?.length === 2 &&
    year?.length === 4
  ) {
    return `${month}/${day}/${year.substring(2)}`;
  }
  return 'Incorrect YYYY-MM-DD format.';
}

/**
 * Removes the time portion from the ISO representation of a date.
 *
 * @param date The date to format.
 *
 * @param asUtc Indicates whether to use the UTC value or the locally-formatted
 * value when converting the {@link date} to an ISO string; see remarks.
 *
 * @returns A date-only string of the format 'YYYY-MM-DD'.
 *
 * @remarks
 *
 * The {@link asUtc} parameter only matters when passing a {@link Date} or a
 * string that is not in ISO format (because ISO-formatted strings need no
 * conversion).
 *
 * When deciding what to pass for the {@link asUtc} parameter, the general
 * guideline is to consider where the date originated:
 *
 * - if the date originated in a UTC context, such as the backend, then you most
 *   likely want the UTC value, so pass `true` for the parameter.
 *
 * - if the date originated on the client side and has been shown to the user,
 *   then you most likely want the value that the user is seeing, so pass `false`.
 *
 * Otherwise, you have to determine whether it's OK to get a different result
 * than what would be displayed for the date, depending on the time and time
 * zone at which it was initialized.
 *
 * To illustrate, if a `new Date()` is initialized while in PST time (8 hours
 * behind UTC):
 *
 * - at 10AM, passing `true` or `false` makes no difference.
 *
 * - at 4PM, passing `true` returns the date for the _next_ day. Passing `false`
 *   makes no difference.
 *
 * If the user was in London (where UTC is local time), the parameter wouldn't
 * make a difference regardless of time, but if they were in Australia (11 hours
 * ahead of UTC), then the cases reverse: at 10AM, passing `true` would result
 * in a different date.
 *
 * The point is to consider cases like the above when determining what to pass
 * for the parameter, to see which ones are legitimate, and, what the effect
 * would be for whoever receives the resulting date.
 */
export function getISODateOnlyString(
  date: string | Date,
  asUtc = false,
): DateWithoutTime {
  let isoDate: string;
  const isIso = (val: string) => moment(val, moment.ISO_8601).isValid();

  if (typeof date === 'string' && isIso(date)) {
    isoDate = date;
  } else {
    const parsedDate = moment(date);

    if (parsedDate.isValid()) {
      isoDate = parsedDate.toISOString(!asUtc);
    } else {
      throw new TypeError('The specified value is not a valid date.');
    }
  }

  return isoDate.split('T')[0];
}

function isLeapYear(year: number): boolean {
  return new Date(year, 1, 29).getDate() === 29;
}

export function calculateStartDay(
  date: Date,
  startDay: 0 | 1 | 2 | 3 | 4 | 5 | 6,
): Date {
  const day = date.getDay();
  if (day === startDay) return new Date(date);
  const diff = day > startDay ? day - startDay : 7 - (startDay - day);

  const start = new Date(date);
  start.setDate(date.getDate() - diff);
  return start;
}

/** Returns the ordinal day (1-366). Also known as the day of the year. */
function getOrdinalDay(date: Date): number {
  // Convert from zero-based to one-based
  const month = date.getMonth() + 1;
  const day = date.getDate();

  function ordinalMarch(month: number, day: number): number {
    // 153 is the sum of any 5 months in March-January.
    return Math.floor((153 * (month - 3) + 2) / 5) + day;
  }

  if (month >= 3)
    return (
      ordinalMarch(month, day) + 59 + (isLeapYear(date.getFullYear()) ? 1 : 0)
    );
  return ordinalMarch(month + 12, day) - 306;
}

/** Returns the US abbreviated weekday for a given date */
export function getWeekDay(date: Date): string {
  return {
    weekday: new Intl.DateTimeFormat('en-Us', {
      weekday: 'short',
    }).format(date),
  }.weekday;
}

/** Returns the ISO 8601 defined week number */
export function getWeekNumber(date?: Date): number {
  if (!date) date = new Date();
  const tentativeWeek = Math.floor(
    (9 + getOrdinalDay(date) - date.getDay()) / 7,
  );

  if (tentativeWeek < 1)
    // final week of last year
    return 52 + (isLeapYear(date.getFullYear() - 1) ? 1 : 0);
  // first week of next year
  if (tentativeWeek > 52 + (isLeapYear(date.getFullYear()) ? 1 : 0)) return 1;
  // week of current year
  return tentativeWeek;
}

/** Returns the weekdays for a given date */
export function getWeek(
  options?: Partial<{
    /** Date to calculate week with */
    date: Date;
    /** Number of weeks to offset weekdays by. Positive values shift forward
     *  while negative values shift backwards */
    offset: number;
    /** The day to start the week, 0 is Sunday */
    startDay: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  }>,
): Week {
  const currentDay = calculateStartDay(
    options?.date ?? new Date(),
    options?.startDay ?? 1,
  );

  if (options?.offset)
    currentDay.setDate(currentDay.getDate() + options.offset * 7);

  const weekdays = [] as DayInfo[];
  const firstDay = new Date();
  for (let i = 0; i < 7; i += 1) {
    currentDay.setDate(currentDay.getDate() + (i === 0 ? 0 : 1));
    if (i === 0) {
      firstDay.setFullYear(currentDay.getFullYear());
      firstDay.setMonth(currentDay.getMonth());
      firstDay.setDate(currentDay.getDate());
    }
    const month = currentDay.getMonth();
    weekdays.push({
      weekday: getWeekDay(currentDay),
      day: currentDay.getDate(),
      // increment needed to account for 0 basing in months
      month: month + 1,
      year: currentDay.getFullYear(),
    });
  }

  return { startDate: firstDay, weekNumber: getWeekNumber(firstDay), weekdays };
}
