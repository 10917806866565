import { DataTableFilters } from 'common/components/DataTable';
import { BlockStatus } from 'common/models';
import {
  getEnumLabels,
  getKeyFromEnumLabel,
  toEnumLabel,
} from 'utils/enumFunctions';
import { SearchOps } from './helpers';

export enum BlocksFilterKeys {
  varietyKey = `variety${SearchOps.EQUALS}`,
  subVarietyKey = `subVariety${SearchOps.EQUALS}`,
  areaKey = `area${SearchOps.EQUALS}`,
  showAllKey = `showAll${SearchOps.EQUALS}`,
  statusKey = `blockStatus${SearchOps.EQUALS}`,
  blockIdkey = `blockId${SearchOps.ICONTAINS}`,
}

export const varietyFilter: DataTableFilters = {
  options: [],
  category: {
    key: BlocksFilterKeys.varietyKey,
    label: 'Variety',
  },
};

export const subVarietyFilter: DataTableFilters = {
  options: [],
  category: {
    key: BlocksFilterKeys.subVarietyKey,
    label: 'Sub-Variety',
  },
};

export const areaFilter: DataTableFilters = {
  options: [],
  category: {
    key: BlocksFilterKeys.areaKey,
    label: 'Area',
  },
};

export const showAllBlocksFilter: DataTableFilters = {
  subHeader: 'Last Two Weeks (default)',
  options: [
    {
      label: 'Show All',
      value: 'true',
    },
  ],
  category: {
    key: BlocksFilterKeys.showAllKey,
    label: 'Date Range',
  },
};

export const blockStatusFilter: DataTableFilters = {
  options: getEnumLabels(BlockStatus).map(status => ({
    label:
      status === toEnumLabel(BlockStatus[BlockStatus.No_Status])
        ? `(${status})`
        : status,
    value: getKeyFromEnumLabel(BlockStatus, status)?.toString() || '-1',
  })),
  category: {
    key: BlocksFilterKeys.statusKey,
    label: 'Block Status',
  },
};

export const blockIdFilter: DataTableFilters = {
  options: [],
  category: {
    key: BlocksFilterKeys.blockIdkey,
    label: 'Block ID',
  },
};
