import { FC, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { handleError } from 'common/api/handleError';
import {
  ResetPasswordRequest,
  useResetPasswordMutation,
} from 'common/api/userApi';
import { FormData, ResetPasswordForm } from '../components/ResetPasswordForm';
import { AuthSuccessModal } from 'features/auth-success-modal/AuthSuccessModal';
import { ReactComponent as CheckmarkIcon } from 'assets/img/success-checkmark.svg';
import { orange, white } from 'common/styles/colors';
import styled from 'styled-components';
import Header from 'common/components/Header';
import { BodyText, PasswordSubtitle } from 'common/styles/texts';
import PasswordGuidelines from '../components/PasswordGuidelines';
import { StyledContainer } from './styles';

const GoToSignInBtn = styled.button`
  border: none;
  background-color: ${orange};
  font-family: KanitRegular;
  font-weight: 500;
  font-size: 18px;
  line-height: 148%;
  letter-spacing: 0.12em;
  color: ${white};
  text-transform: uppercase;
  padding: 6.5px 20px;
  border-radius: 4px;
  margin-top: 20px;
`;

export const ResetPasswordPage: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const { firstName, token } = useParams<{
    firstName: string;
    token: string;
  }>();
  const [resetPassword] = useResetPasswordMutation();
  const history = useHistory();

  const onSubmit = async (formData: FormData) => {
    const data = { ...formData, token };

    try {
      // Force type here because validation must have passed by now.
      await resetPassword(data as ResetPasswordRequest).unwrap();
      setIsOpen(!isOpen);
      setIsSuccessful(!isSuccessful);
    } catch (error) {
      handleError(error);
    }
  };

  const closeModal = () => {
    setIsOpen(!isOpen);
  };

  const goToSignIn = () => {
    history.push('/auth/login');
  };

  const ResetSuccessIcon = (
    <CheckmarkIcon title='success checkmark' style={{ marginBottom: 35 }} />
  );

  const ResetSuccessTitle = <span className='modal-title'>Success!</span>;

  const ResetSuccessBody = (
    <>
      <BodyText>
        Your password has been reset and you can now use it to sign into your
        account.
      </BodyText>
      <GoToSignInBtn type='button' onClick={goToSignIn}>
        Sign In
      </GoToSignInBtn>
    </>
  );

  return (
    <StyledContainer>
      <Header variant='h4'>Welcome Back {firstName}!</Header>
      <PasswordSubtitle>Let’s create a new password</PasswordSubtitle>
      <PasswordGuidelines />
      <ResetPasswordForm onSubmit={onSubmit} isSuccessful={isSuccessful} />
      <AuthSuccessModal
        isOpen={isOpen}
        closeModal={closeModal}
        icon={ResetSuccessIcon}
        title={ResetSuccessTitle}
        body={ResetSuccessBody}
      />
    </StyledContainer>
  );
};
