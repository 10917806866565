import { FC } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import styled from 'styled-components';
import { darkNavy } from 'common/styles/colors';

const BootstrapModal: FC<ModalProps> = ({ children, ...rest }) => (
  <Modal {...rest}>{children}</Modal>
);

const StyledModal = styled(BootstrapModal)`
  & .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    @media (min-width: 576px) {
      max-width: unset;
      margin: 0;
    }
  }

  & .modal-content {
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    max-height: 450px;
    max-width: 560px;
  }

  & .modal-header {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 28px 48px 0;
  }

  & .modal-title {
    font-family: KanitBold;
    font-size: 28px;
    line-height: 125%;
    letter-spacing: 0.015em;
    color: ${darkNavy};
  }

  & .modal-body {
    padding-bottom: 0;
    flex: none;
    padding: 0 85px;

    p {
      margin: 20px 0 0;
    }

    @media (max-width: 767px) {
      padding: 0 10px;
    }
  }
`;

interface Props {
  icon?: React.ReactNode;
  title?: React.ReactNode;
  body?: React.ReactNode;
  isOpen: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AuthSuccessModal: FC<Props> = ({
  icon,
  title,
  body,
  isOpen,
  closeModal,
}) => {
  return (
    <StyledModal show={isOpen} onHide={closeModal}>
      <Modal.Header closeButton />
      {icon ?? icon}
      <Modal.Title>{title}</Modal.Title>
      <Modal.Body>{body}</Modal.Body>
    </StyledModal>
  );
};
