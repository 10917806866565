import { DataTable, DataTableProps } from 'common/components/DataTable';
import { FC, useEffect } from 'react';
import {
  PackHousePickScheduleTableData,
  usePackHousePickScheduleTableData,
} from '../hooks/usePackHousePickScheduleTableData';
import { PackHouse } from 'common/models/packHouse';
import WithTooltip from 'common/components/WithTooltip/WithTooltip';
import { PackHouseCode, StyledTableWrapper } from '../pages/styles';
import { useGetPickScheduleQuery } from 'common/api/pickSchedulingApi';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { useRbac } from 'features/rbac';
import { PackHousePickSchedule } from '../pages/PickScheduleView';
import styled from 'styled-components';
import { tableHeaderGrey } from 'common/styles/colors';

const HouseCodeDateWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;

  h6 {
    font-family: KanitSemiBold;
    margin: 0;
    color: ${tableHeaderGrey};
  }
`;

function buildDetailsLink(id: number): string {
  return `${Constants.routes.PICK_SCHEDULE}/${id}`;
}

export const PackHousePickScheduleTable: FC<{
  packHouse: PackHouse;
  selectedDate: {
    withYear: string;
    noYear: string;
    scheduleDay: string;
  };
  setDataLoadingCount: React.Dispatch<React.SetStateAction<number>>;
  setPickSchedules: React.Dispatch<
    React.SetStateAction<PackHousePickSchedule[]>
  >;
}> = ({ packHouse, selectedDate, setDataLoadingCount, setPickSchedules }) => {
  const { userHasPermission } = useRbac();
  const { data, isLoading, isFetching, error } = useGetPickScheduleQuery(
    {
      date: selectedDate.withYear,
      packHouseId: packHouse.id,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { columns, data: tableData } = usePackHousePickScheduleTableData(
    isLoading || isFetching,
    data?.scheduledTotal,
    data?.pickedTotal,
    data?.binsLeftTotal,
    data?.scheduledPicks ?? [],
  );

  const tableProps: DataTableProps<PackHousePickScheduleTableData> = {
    isLoading,
    isFetching,
    columns,
    data: tableData,
    ...(userHasPermission('pick:read') && {
      onRowClick: pick => buildDetailsLink(pick.id),
    }),
  };

  const updatePickScheduleState = (previousData: PackHousePickSchedule[]) => {
    const newRows = [...previousData];
    const totals = {
      scheduledTotal: data?.scheduledTotal || 0,
      pickedTotal: data?.pickedTotal || 0,
      binsLeftTotal: data?.binsLeftTotal || 0,
    };
    const index = previousData.findIndex(
      item => item.packHouseCode === packHouse.code,
    );

    if (index > -1) {
      newRows[index].rows = tableData;
      newRows[index].totals = totals;
    } else {
      newRows.push({
        packHouseCode: packHouse.code,
        rows: tableData,
        totals,
      });
    }
    return newRows;
  };

  // When querying the schedule, inform parent of loading state.
  // Once the query completes, update parent state data.
  // Only subscribe to query boolean and date changes to avoid infinite loops.
  useEffect(() => {
    if (isLoading || isFetching) {
      setDataLoadingCount(prev => prev + 1);
    } else {
      setDataLoadingCount(prev => (prev > 0 ? prev - 1 : prev));
      setPickSchedules(prev => updatePickScheduleState(prev));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, selectedDate]);

  useEffect(() => {
    if (error) {
      handleError(error, 'Unable to load pick schedules.');
    }
  }, [error]);

  return (
    <>
      <HouseCodeDateWrapper>
        <PackHouseCode>
          <WithTooltip title='Pack House' tooltipText={packHouse.name}>
            <span>{packHouse.code}</span>
          </WithTooltip>
        </PackHouseCode>
        <h6>{`${selectedDate.scheduleDay.toUpperCase()} ${
          selectedDate.noYear
        }`}</h6>
      </HouseCodeDateWrapper>
      <StyledTableWrapper>
        <DataTable<PackHousePickScheduleTableData> {...tableProps} />
      </StyledTableWrapper>
    </>
  );
};
