import * as yup from 'yup';
import { dateSchema, dateSchemaRequired, percentageSchema } from './common';

export const monitoringSchema = yup.object({
  monitorDate: dateSchemaRequired,
  harvestDate: dateSchema,
  copperSpray_1: dateSchema,
  copperSpray_2: dateSchema,
  frbSpray_1: dateSchema,
  frbSpray_2: dateSchema,
  sampleDate: dateSchema,
  brix: percentageSchema,
  acid: percentageSchema,
  ratio: percentageSchema,
});
