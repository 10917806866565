/**
 * Enum to track the reason a grower or block is inactive.
 */
export enum ReasonEnum {
  None,
  No_Harvest,
  Moved_Packer,
  PCI_Release,
  Ranch_Sold,
  Redeveloped,
  Other,
}

export type InactiveReason = {
  id: number;
  reason: ReasonEnum;
  note: string;
};
