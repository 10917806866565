import { mobile } from 'common/styles/breakpoints';
import { lightOrange, white } from 'common/styles/colors';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ForgotPassword = styled.div`
  font-size: 1.2em;
  margin-top: 18px;

  @media (max-width: ${mobile}) {
    margin-top: 80px;
  }
`;

const StyledLink = styled(Link)`
  color: ${white};

  &:hover {
    color: ${lightOrange};
  }
`;

export const ForgotPasswordLink: FC = () => (
  <ForgotPassword>
    <div>
      <span>Forgot your&nbsp;</span>
      <StyledLink data-testid='forgotPasswordLink' to='/auth/forgot-password'>
        password
      </StyledLink>
      <span>?</span>
    </div>
  </ForgotPassword>
);
