import { PasswordText } from 'common/styles/texts';

const PasswordGuidelines: React.FC = () => {
  return (
    <PasswordText>
      <span>Follow these rules to create a safe password.</span>
      <ul>
        <li>Use at least 8 characters</li>
        <li>No more than two consecutive identical characters</li>
        <li>A mixture of letters and numbers</li>
        <li>A mixture of both upper- and lower-case letters</li>
        <li>Include at least one special character, e.g., ! @ # ? ].</li>
      </ul>
    </PasswordText>
  );
};

export default PasswordGuidelines;
