import { EstimateWithUser } from 'common/api/dto/get-block.dto';
import {
  CreateOrViewWrapper,
  DetailText,
} from 'common/components/DetailControls';
import { ResponsiveRow, StyledStack as Stack } from 'common/components/FormRow';
import { CmsDate } from 'common/models/cmsDate';
import { HarvestEstimate } from 'common/models/growerBlock/harvestEstimate';
import { mobile } from 'common/styles/breakpoints';
import { useRbac } from 'features/rbac';
import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { EstimatesHistoryTable } from './EstimatesHistoryTable';

const StyledRow = styled(ResponsiveRow).attrs(() => ({
  className: 'styled-row',
}))`
  div.col > div:first-of-type {
    div:first-of-type {
      width: 50%;
    }
  }
`;

const StyledStack = styled(Stack)`
  @media screen and (max-width: ${mobile}) {
    .row > div:first-child {
      width: 50%;
    }
  }
`;

export const EstimatesFields: FC<{
  blockSeasonId?: number;
  conversionRate?: number | null;
  latestHarvEst?: HarvestEstimate | null;
  estimates?: EstimateWithUser[];
}> = ({ blockSeasonId, conversionRate, latestHarvEst, estimates }) => {
  const { userHasPermission } = useRbac();
  const {
    formState: { errors },
  } = useFormContext<HarvestEstimate>();

  const formatEstValues = (value?: number | null, isPercent = false) =>
    `${typeof value === 'number' ? value : '-'}${isPercent ? '%' : ''}`;

  return (
    <StyledStack>
      <CreateOrViewWrapper
        formObject={latestHarvEst && Object.keys(latestHarvEst).length !== 0}
        message='No harvest estimates exist for this season.'
        canCreate={
          userHasPermission('evalEstTabs:create/edit') && !!blockSeasonId
        }
      >
        <StyledRow>
          <Col>
            <DetailText
              label='Latest Estimate Bins'
              name='estimatedBins'
              validation={errors.estimatedBins?.message}
            />
          </Col>
          <Col>
            <DetailText
              label='Latest Estimate Date'
              editable={false}
              text={
                CmsDate.parse(latestHarvEst?.estimateDate)?.toDateString() ??
                '-'
              }
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailText
              label='Bins Picked'
              editable={false}
              text={formatEstValues(latestHarvEst?.binsPicked)}
            />
          </Col>
          <Col>
            <DetailText
              label='Latest Harvest Date'
              editable={false}
              text={
                CmsDate.parse(latestHarvEst?.harvestDate)?.toDateString() ?? '-'
              }
            />
          </Col>
        </StyledRow>
        <StyledRow>
          <Col>
            <DetailText
              label='Bins to Harvest'
              editable={false}
              text={formatEstValues(latestHarvEst?.binsToHarvest)}
            />
          </Col>
          <Col>
            <DetailText
              label='Harvest Phase'
              editable={false}
              text={formatEstValues(latestHarvEst?.harvestPhase, true)}
            />
          </Col>
        </StyledRow>
        <EstimatesHistoryTable
          conversionRate={conversionRate}
          estimates={estimates}
        />
      </CreateOrViewWrapper>
    </StyledStack>
  );
};
