import { ReactElement } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';
import { grey, tableHeaderGrey, white } from 'common/styles/colors';

const FilterBubbleContainer = styled.div`
  box-sizing: border-box;
  color: ${tableHeaderGrey};
  font-family: KanitSemiBold;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.0075em;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 6px 6px 10px;
  height: 32px;
  background: ${white};
  border: 1px solid ${grey};
  border-radius: 30px;
  margin: 0 1px 3px;

  & .bubble-prop {
    margin: 0 3px 0 0;
    font-family: KanitLight;
    font-size: 16px;
  }

  & .bubble-label {
    margin: 0;
    font-family: KanitSemiBold;
    white-space: nowrap;
  }

  & button {
    color: ${tableHeaderGrey};
    border: none;
    background-color: transparent;
  }
`;

interface FilterBubbleProp {
  categoryLabel: string;
  filterLabel: string;
  filterValue: string;
  filterCategory: string;
  removeBubble: (filterValue: string, filterCategory: string) => void;
}

export const FilterBubble = ({
  categoryLabel,
  filterLabel,
  filterValue,
  filterCategory,
  removeBubble,
}: FilterBubbleProp): ReactElement => {
  return (
    <FilterBubbleContainer>
      <p className='bubble-prop'>{categoryLabel}: </p>
      <p className='bubble-label'>{filterLabel}</p>
      <button
        type='button'
        onClick={() => removeBubble(filterValue, filterCategory)}
      >
        <CancelIcon />
      </button>
    </FilterBubbleContainer>
  );
};
