import { useAppSelector } from 'app/redux';
import { useGetBlockWithAllDetailsQuery } from 'common/api/growerBlockApi';
import { handleError } from 'common/api/handleError';
import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import {
  seasonSliceName,
  SeasonState,
} from 'features/navbar/components/SeasonSelector/seasonSlice';
import { FC, useEffect } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { Constants } from 'utils/constants';
import { BlockEvalForm } from '../components/BlockEvalForm';

export const BlockEvalView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const { selectedSeason } = useAppSelector<SeasonState>(
    state => state[seasonSliceName],
  );

  const {
    data: block,
    isLoading,
    error,
  } = useGetBlockWithAllDetailsQuery({
    id: parseInt(id, 10),
    // The component only renders when season exists so we safely assert the value here.
    seasonId: selectedSeason?.id as number,
  });

  useEffect(() => {
    const tabPath = pathname.substring(url.length);

    // if user tries to access page without a tab, redirect to details tab
    if (tabPath === '') {
      history.replace(`${url}${Constants.routes.DETAILS}`);
    }
  }, [history, pathname, url]);

  useEffect(() => {
    if (error) {
      handleError(error, 'Unable to load block.');
      history.replace(Constants.routes.EVAL_EST_MAIN);
    }
  }, [error, history]);

  return (
    <WithLoadingOverlay isLoading={isLoading}>
      {block && <BlockEvalForm block={block} />}
    </WithLoadingOverlay>
  );
};
