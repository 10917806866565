import { mobile } from 'common/styles/breakpoints';
import { lighterNavy } from 'common/styles/colors';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import Header from '../Header';
import { ExtendedDetailSeparatorStyle } from './styles';

const StyledHeader = styled(Header)<{ $withSeparator: boolean }>`
  color: ${lighterNavy};
  padding-bottom: 5px;

  ${props =>
    props.$withSeparator &&
    css`
      ${ExtendedDetailSeparatorStyle}
    `}

  @media (max-width: ${mobile}) {
    margin: 0;
    padding: 5px 1rem;
    font-family: 'KanitBold';
    font-weight: 700;
    font-size: 28px;
    line-height: 125%;
    letter-spacing: 0.015em;
    color: ${lighterNavy};
  }
`;

interface DetailHeadingProps {
  /**
   * Indicates whether to show a separator under the control. Defaults to true.
   */
  withSeparator?: boolean;
  children?: React.ReactNode;
}

/** Renders a heading in the style of detail forms. */
export const DetailHeading: FC<DetailHeadingProps> = ({
  children,
  withSeparator = true,
}) => {
  return (
    <StyledHeader variant='h5' $withSeparator={withSeparator}>
      {children}
    </StyledHeader>
  );
};
