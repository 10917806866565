/** The character to represent a space in an enum key. */
const enumSpaceChar = '_';
/** The character to represent a dash in an enum key. */
const enumDashChar = '$';

/**
 * Function that replaces an enum key's special characters to make the
 * string more user-friendly:
 * - All `_` are replaced by spaces.
 * - All `$` are replaced by `-`.
 * */
export function toEnumLabel(key: string): string {
  return key.replace(/[_]/g, ' ').replace(/[$]/g, '-');
}

/**
 * Function that replaces a possible enum string's special characters:
 * - All spaces are replaced by `_`.
 * - All `-` are replaced by `$`.
 * */
export function toEnumKey(str: string): string {
  return str.replace(/[ ]/g, enumSpaceChar).replace(/[-]/g, enumDashChar);
}

/**
 * Function that takes an enum object and returns an ordered array of its labels.
 * */
export function getEnumLabels(
  appEnum: Record<string, string | number>,
): string[] {
  const enumKeys = Object.keys(appEnum);

  // Because number-valued enums contain bidirectional mappings,
  // only retain the first half of the array.
  return enumKeys.splice(enumKeys.length / 2).map(key => toEnumLabel(key));
}

/**
 * Function that compares a string to a given enum's labels to try to find
 * a match. Returns a numbered key or `null` if no match.
 * */
export function getKeyFromEnumLabel(
  appEnum: Record<string, string | number>,
  label: string,
): number | null {
  const enumLabels = getEnumLabels(appEnum);
  const key = enumLabels.findIndex(enumlabel => enumlabel === label);

  return key > -1 ? key : null;
}
