import * as yup from 'yup';
import { Constants } from 'utils/constants';
import {
  dateSchema,
  nullableNumberSchema,
  nullableStringSchema,
} from './common';

const toOptionalNumber = (unused: unknown, value: unknown) => {
  value = typeof value === 'string' ? value.trim() : value;

  return value ? Number(value) : null;
};

const optionalNumberSchema = yup
  .number()
  .nullable()
  .positive(Constants.errorMessages.POSITIVE_NUMBER_REQUIRED)
  .integer(Constants.errorMessages.INTEGER_REQUIRED)
  .typeError(Constants.errorMessages.INTEGER_REQUIRED)
  .transform(toOptionalNumber);

export const growerCertFieldsSchema = yup.object({
  ggnNumber: optionalNumberSchema,
  certExp: dateSchema,
  gapStatus: nullableStringSchema.required(
    Constants.errorMessages.FIELD_REQUIRED,
  ),
  gapCertNotes: nullableStringSchema,
});

export const growerAllFieldsSchema = yup.object({
  parentGrowerName: nullableStringSchema.required(
    Constants.errorMessages.FIELD_REQUIRED,
  ),
  managerName: nullableStringSchema,
  grpNumber: nullableNumberSchema,
  permitNumber: nullableNumberSchema,
  ggnNumber: optionalNumberSchema,
  certExp: dateSchema,
  gapStatus: nullableStringSchema,
  gapCertNotes: nullableStringSchema,
  managerCell: nullableStringSchema.matches(Constants.patterns.US_PHONE_REGEX, {
    message: Constants.errorMessages.INVALID_PHONE,
    excludeEmptyString: true,
  }),
  notes: nullableStringSchema,
  secondGrower: nullableStringSchema,
});
