import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'common/api/customBaseQuery';
import { withFilters } from 'common/api/utils/apiFunctions';
import { FilterCategoryOptions } from 'common/hooks';
import { Grower, PaginatedResult, PaginationQueryParams } from 'common/models';
import { GrowerWithBlocks, GrowerWithoutBlocks } from './dto/get-grower.dto';
import { GrowerUpdateRequest } from './dto/put-grower.dto';

type GrowerFilterQueryParams = {
  filters: FilterCategoryOptions[];
};

const growersBaseUrl = '/growers';

const growerTag = 'Grower';
const growersTag = 'Growers';

export const growerApi = createApi({
  reducerPath: 'growerApi',
  baseQuery: customBaseQuery,
  refetchOnReconnect: true,
  tagTypes: [growerTag, growersTag],

  endpoints: builder => ({
    getGrowers: builder.query<
      PaginatedResult<GrowerWithBlocks>,
      PaginationQueryParams & GrowerFilterQueryParams & { seasonId: number }
    >({
      query: ({ seasonId, ...prm }) =>
        withFilters(prm, `${growersBaseUrl}/list/${seasonId}`),
      providesTags: (_, err, { seasonId }) =>
        err ? [] : [{ type: growersTag, id: seasonId }],
    }),

    getGrowerDataBySeason: builder.query<
      GrowerWithoutBlocks[],
      { seasonId: number }
    >({
      query: ({ seasonId }) => ({
        url: `${growersBaseUrl}/grower-data/${seasonId}`,
        method: 'GET',
      }),
      forceRefetch: () => true,
    }),

    getGrowerById: builder.query<
      GrowerWithBlocks,
      { seasonId: number; growerId: number }
    >({
      query: ({ seasonId, growerId }) =>
        `${growersBaseUrl}/${seasonId}/${growerId}`,
      providesTags: (_, err, { seasonId, growerId }) =>
        err ? [] : [{ type: growerTag, id: `/${seasonId}/${growerId}` }],
    }),

    updateGrower: builder.mutation<
      Grower,
      GrowerUpdateRequest & { seasonId: number; growerId: number }
    >({
      query: ({ seasonId, growerId, ...payload }) => ({
        url: `${growersBaseUrl}/${seasonId}/${growerId}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (_, err, { seasonId, growerId }) =>
        err
          ? []
          : [growersTag, { type: growerTag, id: `/${seasonId}/${growerId}` }],
    }),
  }),
});

export const {
  useGetGrowersQuery,
  useGetGrowerDataBySeasonQuery,
  useGetGrowerByIdQuery,
  useUpdateGrowerMutation,
} = growerApi;
