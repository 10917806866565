import {
  lightGreyBackground,
  lighterNavy,
  red,
  tableHeaderGrey,
  white,
} from 'common/styles/colors';
import { boxShadow } from 'common/styles/page';
import { NoBackgroundBtn } from 'features/pick-schedule-views/components/WizardComponents/wizardStyles';
import styled from 'styled-components';

export const SubmitNewLineBtn = styled(NoBackgroundBtn)`
  grid-column-start: 3;
  grid-column-end: 6;
  font-family: KanitSemiBold;
  font-size: 16px;
  color: ${lighterNavy};
`;

export const AddNewLineBtn = styled(SubmitNewLineBtn)`
  grid-column-start: 3;
  grid-column-end: 8;
  justify-self: flex-end;
  padding: 5px;
`;

export const NewLineContainer = styled.form`
  margin-top: 5px;
  grid-column-start: 4;
  grid-column-end: 7;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  width: 100%;
  padding: 10px 0;
  margin-bottom: 10px;
  background: ${lightGreyBackground};
  border-radius: 8px;
  box-shadow: ${boxShadow};
`;

export const NewLineBtnsContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 6;
  justify-self: center;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0;
  width: 100%;
`;

export const CancelBtn = styled.button`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0.2rem;
  border: none;
  background-color: transparent;
  font-family: KanitRegular;
  font-size: 16px;
  color: ${lighterNavy};

  :focus {
    outline: ${tableHeaderGrey} dotted 1px;
  }
`;

export const QuantityInput = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  padding: 0 5px;

  input {
    height: 37px;
  }
`;

export const DescriptionInput = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  padding: 0 5px;
  border-right: 1px solid ${tableHeaderGrey};
  border-left: 1px solid ${tableHeaderGrey};
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 85px;
`;

export const RateInput = styled.div`
  grid-column-start: 3;
  grid-column-end: 4;
  padding: 0 5px;

  input {
    height: 37px;
  }
`;

export const TransferLineContainer = styled(NewLineContainer)`
  grid-column-start: 1;
  grid-column-end: 7;
  grid-template-columns: 1fr 2fr 1.5fr 1fr 4fr 1fr;
`;

export const TransfersBtnsContainer = styled(NewLineBtnsContainer)`
  grid-column-start: 1;
  grid-column-end: 7;
`;

export const TransferDate = styled.div`
  grid-column-start: 1;
  grid-column-end: 2;
  border-right: 1px solid ${tableHeaderGrey};
  padding: 0px 5px;

  input {
    height: 37px;
  }
`;

export const TransferInfoContainer = styled.div`
  padding: 0px 5px;
  border-right: 1px solid ${tableHeaderGrey};
  grid-column-start: 2;
  grid-column-end: 3;
  width: 100%;
`;

export const TransferEntryContainer = styled(TransferInfoContainer)`
  grid-column-start: 3;
  grid-column-end: 4;
`;

export const LineItemTextarea = styled.textarea<{
  $isError: boolean;
}>`
  width: 100%;
  background: ${white};
  color: ${lighterNavy};
  font-family: KanitRegular;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  padding: 6.5px;
  resize: none;
  ${props => (props.$isError ? `border: 2px solid ${red};` : {})}

  ::placeholder {
    font-family: KanitSemiBold;
    font-size: 16px;
    color: ${lighterNavy};
  }
`;

export const TransferQuantityInput = styled(QuantityInput)`
  grid-column-start: 4;
  grid-column-end: 5;
  padding: 0px 5px;
`;

export const TransferDescriptionInput = styled(DescriptionInput)`
  grid-column-start: 5;
  grid-column-end: 6;
`;

export const TransferRateInput = styled(RateInput)`
  grid-column-start: 6;
  grid-column-end: 7;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 85px;

  input {
    height: 37px;
  }
`;
