import { ReactElement, useState } from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  darkerBlueShade,
  lighterBlueShade,
  lighterNavy,
  tableBodyGrey,
  tableHeaderGrey,
  white,
} from 'common/styles/colors';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { FilterBubble } from './FilterBubble';
import { DataTableFilters } from '../DataTable/DataTable';
import { FilterCategoryOptions } from 'common/hooks';
import { FilterCategory } from './FilterCategory';

interface Props {
  isMobile: boolean;
  isExtraSmall: boolean;
}

const FilterWrapper = styled.div<Props>`
  display: flex;
  position: relative;
  flex-direction: ${props => (props.isMobile ? 'column-reverse' : 'row')};
  justify-content: ${props => (props.isMobile ? 'flex-end' : '')};
  align-items: ${props => (props.isMobile ? 'flex-end' : '')};
  // This -40px margin prevents the "Add Filter" button from moving down on mobile,
  // but only up to a certain point (otherwise we want that to prevent overlap).
  margin-top: ${props =>
    props.isMobile && !props.isExtraSmall ? '-40px' : '0'};
  padding-bottom: ${props => (props.isMobile ? '20px' : '0')};
`;

const BubblesContainer = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  align-items: ${props => (props.isMobile ? 'baseline' : 'center')};
  flex-wrap: wrap;
  margin: ${props => (props.isMobile ? '0 5px' : '0')};

  & .filters-applied {
    font-family: KanitSemiBold;
    font-size: 16px;
    line-height: 138%;
    letter-spacing: 0.0075em;
    color: ${tableHeaderGrey};
    margin: 0;
    padding: 0 4px 4px;
    white-space: nowrap;
  }
`;

const AddFilterBtn = styled.button<Props>`
  font-family: KanitSemiBold;
  font-size: 16px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: ${darkerBlueShade};
  border: none;
  background: none;
  padding-left: 20px;
  white-space: nowrap;
  margin: ${props =>
    props.isMobile && !props.isExtraSmall ? '25px 5px 5px 0' : '0'};
`;

const FilterContainer = styled.div`
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  padding: 4px 10px 14px;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 273px;
  background: ${lighterBlueShade};
  border: 1px solid ${tableHeaderGrey};
  border-radius: 8px;
  z-index: 1;
`;

const Title = styled.div`
  font-family: KanitSemiBold;
  font-size: 24px;
  line-height: 134%;
  letter-spacing: 0.0075em;
  color: ${tableBodyGrey};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;

  & .close-filter {
    background-color: transparent;
    border: none;
  }
`;

const ApplyBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 28px;
  color: ${white};
  border: none;
  width: 253px;
  height: 40px;
  background: ${lighterNavy};
  border-radius: 20px;
`;

type TableFilterProps = {
  filterOptions: DataTableFilters[];
  setFilters: (filters: FilterCategoryOptions[]) => void;
  appliedFilters?: FilterCategoryOptions[];
};

type Filter = {
  value: string;
  category: string;
};

function toFilters(filters?: FilterCategoryOptions[]): Filter[] {
  return (filters ?? []).reduce((all, current) => {
    return all.concat(
      current.options.map(option => {
        return { value: option, category: current.category };
      }),
    );
  }, [] as Filter[]);
}

/**
 * Component that handles all filtering on tables. It renders a pop-up list of
 * passed filters when 'Add Filter' is clicked. When a filter is applied, a
 * 'bubble' with the filter's label will render.
 *
 * @param filterOptions array containing filter options grouped by categories.
 * @param setFilters method that add/removes filters in state.
 * @param appliedFilters the currently applied filters.
 */
export const DataTableFilter = ({
  filterOptions,
  setFilters,
  appliedFilters,
}: TableFilterProps): ReactElement => {
  const filters = toFilters(appliedFilters ?? []);

  // Array that tracks user input concerning checkboxes and text input field
  const [userInput, setUserInput] = useState(filters);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);
  // Check if screen size is extra small, text elements start to overlap at this point
  const isExtraSmall = width < 375;

  const addCheckboxFilter = (filterValue: string, filterCategory: string) => {
    let newFilters = [...userInput];

    newFilters = [
      ...newFilters,
      { value: filterValue, category: filterCategory },
    ];
    setUserInput(newFilters);
  };

  const addTextFilter = (filterValue: string, filterCategory: string) => {
    let newFilters = [...userInput];

    const filterOptionIdx = userInput.findIndex(
      option => option.category === filterCategory,
    );

    if (filterValue && filterOptionIdx === -1) {
      newFilters = [
        ...newFilters,
        { value: filterValue, category: filterCategory },
      ];
    } else if (filterValue && filterOptionIdx > -1) {
      newFilters[filterOptionIdx] = {
        value: filterValue,
        category: filterCategory,
      };
    } else {
      newFilters = newFilters.filter((el, index) => index !== filterOptionIdx);
    }
    setUserInput(newFilters);
  };

  const removeFilter = (filterValue: string) => {
    let newFilters = [...userInput];

    newFilters = newFilters.filter(option => option.value !== filterValue);
    setUserInput(newFilters);
    return newFilters;
  };

  // Convert Filters to FilterCategoryOptions used by usePSFQuery
  const toCategoryOptions = (
    existingFilters: Filter[],
  ): FilterCategoryOptions[] => {
    const filtersArr = [] as FilterCategoryOptions[];

    existingFilters.forEach(element => {
      const index = filtersArr.findIndex(
        filter => filter.category === element.category,
      );

      if (index > -1) {
        filtersArr[index].options = [
          ...filtersArr[index].options,
          element.value,
        ];
      } else {
        filtersArr.push({
          options: [element.value],
          category: element.category,
        });
      }
    });
    return filtersArr;
  };

  const onApplyFilters = () => {
    setFilterModalOpen(false);
    setFilters(toCategoryOptions([...userInput]));
  };

  const removeBubble = (filterValue: string) => {
    setFilters(toCategoryOptions(removeFilter(filterValue)));
  };

  return (
    <FilterWrapper isMobile={isMobile} isExtraSmall={isExtraSmall}>
      <BubblesContainer isMobile={isMobile} isExtraSmall={isExtraSmall}>
        {filters.length > 0 && (
          <p className='filters-applied'>Filters Applied:</p>
        )}
        {/* Match selected filters in state to given filter options in order
          to display labels rather than values in the bubbles; values are
          used as a backup */}
        {filters.map(filterOption => {
          const filter = filterOptions.find(
            filter => filter.category.key === filterOption.category,
          );
          const options = filter?.options?.flat();
          return (
            <FilterBubble
              key={filterOption.value}
              categoryLabel={filter?.category?.label ?? filterOption.category}
              filterLabel={
                options?.find(option => option?.value === filterOption.value)
                  ?.label ?? filterOption.value
              }
              filterValue={filterOption.value}
              filterCategory={filterOption.category}
              removeBubble={removeBubble}
            />
          );
        })}
      </BubblesContainer>
      <AddFilterBtn
        isMobile={isMobile}
        isExtraSmall={isExtraSmall}
        type='button'
        onClick={() => setFilterModalOpen(!filterModalOpen)}
      >
        <AddIcon />
        Add Filter
      </AddFilterBtn>
      <FilterContainer style={{ display: filterModalOpen ? 'flex' : 'none' }}>
        <Header>
          <Title>Filter</Title>
          <button
            type='button'
            onClick={() => setFilterModalOpen(false)}
            className='close-filter'
          >
            <CloseIcon />
          </button>
        </Header>
        {filterOptions.map(filter => (
          <FilterCategory
            key={filter.category.key}
            filterCategory={filter}
            userInput={userInput}
            addCheckboxFilter={addCheckboxFilter}
            addTextFilter={addTextFilter}
            removeFilter={removeFilter}
            subHeader={filter?.subHeader}
          />
        ))}
        <ApplyBtn type='button' onClick={onApplyFilters}>
          APPLY
        </ApplyBtn>
      </FilterContainer>
    </FilterWrapper>
  );
};
