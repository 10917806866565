import { mobile } from 'common/styles/breakpoints';
import { lighterNavy } from 'common/styles/colors';
import styled from 'styled-components';

export const HeaderForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  padding: 0 20px;

  @media (max-width: ${mobile}) {
    flex-direction: column;
  }
`;

export const TitleAndCsvBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

export const DropdownAndWeekSwitcher = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  max-width: 400px;
  width: 70%;

  @media (max-width: ${mobile}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80px;
  }
`;

export const DateRange = styled.span`
  font-family: KanitMedium;
  font-size: 20px;
  color: ${lighterNavy};
  width: fit-content;
  text-align: center;
  margin-left: 20px;

  @media (max-width: ${mobile}) {
    width: 100%;
    margin: 5px 0 0 0;
  }
`;

export const ExportBtnWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 270px;
`;
