function getParamValue(searchParams: string, param: string): string | null {
  const searchParam = new URLSearchParams(searchParams);
  return searchParam.get(param);
}

function toInt(param: string | null): number | null {
  const int = parseInt(param ?? '', 10);
  return Number.isNaN(int) ? null : int;
}

export function getIntParam(
  searchParams: string,
  param: string,
): number | null {
  const rawParam = getParamValue(searchParams, param);
  return toInt(rawParam);
}
