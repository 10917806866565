import { ModalWrapper } from 'common/components/ModalWrapper';
import {
  blueShade,
  darkNavy,
  lightGreyText,
  orange,
} from 'common/styles/colors';
import { FC } from 'react';
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-family: KanitExtraLight;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: 0.0075em;
  color: ${blueShade};
  padding: 20px 20px 0;
`;

const InfoText = styled.section`
  font-family: KanitRegular;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.0075em;
  color: ${lightGreyText};
  margin: 0;
  padding: 10px 20px 0;

  span {
    font-family: KanitRegular;
    margin-left: 10px;
    color: ${blueShade};
  }
`;

const MainText = styled.main`
  display: flex;
  flex-direction: column;
  font-family: KanitRegular;
  font-size: 20px;
  line-height: 110%;
  letter-spacing: 0.0075em;
  color: ${lightGreyText};
  padding: 10px 20px 0;

  span {
    font-family: KanitRegular;
    color: ${lightGreyText};
  }

  .evaluator-name {
    padding-top: 10px;
    color: ${blueShade};
  }
`;

const FooterButton = styled.button`
  background-color: ${orange};
  width: 100%;
  max-width: 93px;
  height: 40px;
  border-radius: 4px;
  border: none;
  font-family: 'KanitRegular';
  font-size: 18px;
  line-height: 148%;
  letter-spacing: 0.12em;
  color: ${lightGreyText};
  margin: 30px 20px 20px;
`;

interface ModalProps {
  isOpen: boolean;
  closeModal: (e: React.MouseEvent<HTMLButtonElement>) => void;
  contentProps: {
    growerName: string;
    blockName: string;
    evaluatorName: string;
  };
}

export const MakeActiveModal: FC<ModalProps> = ({
  isOpen,
  closeModal,
  contentProps,
}) => {
  const Header: FC<Pick<ModalProps, 'contentProps'>> = ({
    contentProps: { growerName, blockName },
  }) => (
    <HeaderWrapper>
      <Title>New Estimate Required</Title>
      <InfoText>
        Grower:
        <span>{growerName}</span>
      </InfoText>
      <InfoText>
        Block:
        <span>{blockName}</span>
      </InfoText>
    </HeaderWrapper>
  );

  const Body: FC<Pick<ModalProps, 'contentProps'>> = ({
    contentProps: { evaluatorName },
  }) => (
    <MainText>
      <span>
        A new estimate is required to make this block ready for picking. The
        block will be queued for:
      </span>
      <span className='evaluator-name'>{evaluatorName}</span>
    </MainText>
  );

  const Footer: FC<Pick<ModalProps, 'closeModal'>> = ({ closeModal }) => (
    <FooterButton type='button' aria-label='close modal' onClick={closeModal}>
      <span>DONE</span>
    </FooterButton>
  );

  return (
    <ModalWrapper
      isOpen={isOpen}
      closeModal={closeModal}
      width='600px'
      backgroundColor={`${darkNavy}`}
      header={<Header contentProps={contentProps} />}
      body={<Body contentProps={contentProps} />}
      footer={<Footer closeModal={closeModal} />}
    />
  );
};
