import {
  darkGrey,
  darkNavy,
  darkOrange,
  grey,
  lighterNavy,
  red,
} from 'common/styles/colors';
import styled from 'styled-components';
import {
  Button,
  InfoContainer,
  InfoLabel,
  InfoValue,
  NoBackgroundBtn,
  Row,
} from '../WizardComponents/wizardStyles';
import CloseIcon from '@mui/icons-material/Close';
import { mobile } from 'common/styles/breakpoints';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const DisableMobileStacking = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 30px;

  @media (max-width: ${mobile}) {
    margin-left 0;
  }
`;

export const NoLaborData = styled.div`
  margin: 30px 0 0;
  text-align: center;
`;

export const BackBtn = styled(NoBackgroundBtn)`
  font-family: KanitSemiBold;
  font-size: 16px;
  letter-spacing: 0.0075em;
  color: ${darkGrey};
  margin: 0 0 25px 10px;
`;

export const ChevronLeft = styled(ChevronRightIcon)`
  margin-right: 10px;
`;

export const ChevronRight = styled(ChevronRightIcon)`
  margin-left: 10px;
`;

export const HeaderSection = styled.form<{
  $backgroundColor: string;
}>`
  margin 0 5px 5px;
  padding: 25px 15px 10px;
  border-radius: 8px;
  background: ${props => props.$backgroundColor};

  @media (max-width: ${mobile}) {
    padding: 0 5px 15px;
  }
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 25px;

  @media (max-width: ${mobile}) {
    margin-bottom: 10px;
  }
`;

export const HeaderInfoLabel = styled(InfoLabel)`
  font-family: KanitLight;
  color: ${darkNavy};
`;

export const NavyInfoContainer = styled(InfoContainer)`
  color: ${lighterNavy};
`;

export const NavyInfoValue = styled(InfoValue)`
  color: ${lighterNavy};
`;

export const NestedContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HarvestIcon = styled.img`
  margin-right: 5px;
`;

export const PickDetailInput = styled.div`
  min-height: 30px;
  max-width: 130px;
  margin-left: 10px;
  font-size: 18px;
`;

export const ButtonSection = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;

  @media (max-width: ${mobile}) {
    padding-top: 0;
    flex-direction: column;
  }
`;

export const AssignBtn = styled(Button)`
  margin-right: 10px;
  background: ${lighterNavy};

  :disabled {
    background: ${grey};
  }

  @media (max-width: ${mobile}) {
    margin: 0 0 15px;
    padding: 15px 20px;
  }
`;

export const InputBtn = styled(Button)`
  background: ${darkOrange};

  :disabled {
    background: ${grey};
  }

  @media (max-width: ${mobile}) {
    padding: 15px 20px;
  }
`;

export const RemoveBtn = styled(NoBackgroundBtn)`
  font-family: KanitMedium;
  font-size: 18px;
  letter-spacing: 0.12em;
  margin-left: auto;
  padding: 6px 20px;
  color: ${red};

  @media (max-width: ${mobile}) {
    margin: 15px 0 0;
  }
`;

export const RemoveIcon = styled(CloseIcon)`
  margin-right: 5px;
`;
