import { FC } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { PrivateRoute } from 'features/auth/components/privateRoute.tsx';
import { RoleType } from 'common/models';
import { NotFoundView } from 'common/components/NotFound';
import { PackPlanView } from './pages/PackPlanView/PackPlanView';

export const PackPlanRoutes: FC = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <PrivateRoute
        exact
        path={path}
        component={PackPlanView}
        requiredRoles={[
          RoleType.Admin,
          RoleType.Analyst,
          RoleType.FieldRep,
          RoleType.HarvestManager,
        ]}
      />
      <Route path={path} component={NotFoundView} />
    </Switch>
  );
};
