import styled from 'styled-components';
import Header from '../../../common/components/Header';
import logo from '../../../assets/img/Transp_logo.png';
import { Constants } from '../../../utils/constants';
import { mobile } from 'common/styles/breakpoints';

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  margin-right: 30px;
  width: 100%;
  max-width: 150px;

  @media (max-width: ${mobile}) {
    max-width: 70px;
    margin-right: 7px;
  }
`;

const StyledHeader1 = styled(Header)`
  @media (max-width: ${mobile}) {
    font-size: 21px;
    line-height: 104.5%;
    letter-spacing: -0.015em;
  }
`;

const StyledHeader2 = styled(Header)`
  @media (max-width: ${mobile}) {
    font-size: 16px;
    line-height: 120%;
    letter-spacing: 0.0075em;
  }
`;

const LogoHeader = (): JSX.Element => {
  return (
    <LogoContainer>
      <Logo src={logo} alt='logo' />
      <div>
        <StyledHeader1 variant='h3'>{Constants.legalCompanyName}</StyledHeader1>
        <StyledHeader2 variant='h5'>{Constants.appName}</StyledHeader2>
      </div>
    </LogoContainer>
  );
};

export default LogoHeader;
