import { ChevronRight } from '@mui/icons-material';
import { white } from 'common/styles/colors';
import { FC } from 'react';
import { Constants } from 'utils/constants';
import {
  ButtonSection,
  AssignBtn,
  InputBtn,
  RemoveBtn,
  RemoveIcon,
} from './styles';
import { useRbac } from 'features/rbac';
import { useHistory, useParams } from 'react-router-dom';
import { useDeletePickScheduleMutation } from 'common/api/pickSchedulingApi';
import * as notifier from 'common/services/notification';
import { useConfirmationModal } from 'features/confirmation-modal';
import { handleError } from 'common/api/handleError';

export const ScheduledPickButtons: FC<{
  isAssigned: boolean;
  isPicked: boolean;
  isEdit: boolean;
}> = ({ isAssigned, isPicked, isEdit }) => {
  const { id } = useParams<{ id: string }>();
  const [deletePickSchedule] = useDeletePickScheduleMutation();
  const { openModal } = useConfirmationModal();
  const { userHasPermission } = useRbac();
  const history = useHistory();

  async function tryDeletePickSchedule(id: number) {
    try {
      await deletePickSchedule(id).unwrap();
      history.replace(Constants.routes.PICK_SCHEDULE);
      notifier.showSuccessMessage('Pick schedule successfully deleted.');
    } catch (error) {
      handleError(error, 'Error deleting this pick schedule.');
    }
  }

  function openDeleteModal() {
    openModal({
      message: 'This pick record will be deleted.',
      confirmButtonLabel: 'DELETE',
      declineButtonLabel: 'CANCEL',
      onConfirm: () => tryDeletePickSchedule(parseInt(id, 10)),
    });
  }

  return isEdit ? (
    <></>
  ) : (
    <ButtonSection>
      {userHasPermission('pick:assign-crew') && (
        <AssignBtn
          type='button'
          $textColor={white}
          onClick={() =>
            history.push(
              `${Constants.routes.PICK_SCHEDULE}/${id}${Constants.routes.CREW_ASSIGNMENT}`,
            )
          }
          disabled={isPicked}
        >
          ASSIGN HC & CREW
          <ChevronRight />
        </AssignBtn>
      )}
      {userHasPermission('pick:input-data') && isAssigned && (
        <InputBtn
          type='button'
          $textColor={white}
          onClick={() =>
            history.push(
              `${Constants.routes.PICK_SCHEDULE}/${id}${Constants.routes.PICK_DATA}`,
            )
          }
        >
          INPUT PICK DATA
          <ChevronRight />
        </InputBtn>
      )}
      {userHasPermission('pick:remove') && (
        <RemoveBtn type='button' onClick={() => openDeleteModal()}>
          <RemoveIcon /> REMOVE PICK
        </RemoveBtn>
      )}
    </ButtonSection>
  );
};
