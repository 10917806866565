import MenuIcon from '@mui/icons-material/Menu';
import UserCard from 'common/components/UserCard';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Constants } from 'utils/constants';
import logo from '../../../../assets/img/Transp_logo.png';
import Header from '../../../../common/components/Header';
import { useNavLinks } from '../../hooks/useNavLinks';
import { SeasonSelector } from '../SeasonSelector/SeasonSelector';
import { SettingsButton } from '../SettingsButton';
import MobileNavMenu from './MobileNavMenu';
import {
  ContentContainer,
  FixedTopBar,
  LogoBar,
  logoIconStyle,
  MenuBar,
  menuIconStyle,
  MobileLinks,
} from './styles';

const MobileNav = ({
  isLoading,
  showSeasonSelector,
  children,
}: {
  isLoading: boolean;
  showSeasonSelector: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  const navLinks = useNavLinks();
  const { pathname } = useLocation();
  const link =
    navLinks[navLinks.findIndex(link => pathname.startsWith(link.path))];

  const isSettings = pathname.includes(Constants.routes.SETTINGS);

  const [expanded, setExpanded] = useState(true);
  const closeMenu = () => setExpanded(false);
  const toggleMenu = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <FixedTopBar>
        <MenuBar $showSeasonSelector={showSeasonSelector}>
          <MenuIcon style={menuIconStyle} onClick={toggleMenu} />
          <LogoBar>
            {expanded ? (
              <>
                <img src={logo} alt='logo' width='50px' />
                <Header variant='h4'>{Constants.legalCompanyName}</Header>
              </>
            ) : (
              <>
                {link?.icon && <link.icon style={logoIconStyle} />}
                {isSettings && <SettingsButton style={logoIconStyle} />}
                <Header variant='h4'>
                  {isSettings ? 'Settings' : link?.label}
                </Header>
                {showSeasonSelector && <SeasonSelector isLoading={isLoading} />}
              </>
            )}
          </LogoBar>
        </MenuBar>
      </FixedTopBar>
      {expanded ? (
        <MobileLinks>
          <UserCard />
          <MobileNavMenu callback={closeMenu} />
        </MobileLinks>
      ) : (
        <ContentContainer>{children}</ContentContainer>
      )}
    </>
  );
};

export default MobileNav;
