import {
  CsvHeaders,
  emptyCellValue,
} from 'common/components/CsvButton/CsvButton';
import {
  PackHousePickScheduleTableData,
  dailyTotalStr,
} from '../hooks/usePackHousePickScheduleTableData';
import {
  pciPickedTotalStr,
  pciSchedTotalStr,
} from '../components/PickSchedule/DailyTotalRow';

export const enum PickSchedKeys {
  packHouse = 'packHouse',
  growerId = 'growerId',
  block = 'block',
  variety = 'variety',
  pool = 'pool',
  binsSched = 'binsSched',
  binsPicked = 'binsPicked',
  market = 'market',
  binsLeft = 'binsLeft',
  fieldRep = 'fieldRep',
  harvestCoor = 'harvestCoor',
  assignedPickCrews = 'assignedPickCrews',
  assignedHaulers = 'assignedHaulers',
  gapStatus = 'gapStatus',
  lotNumber = 'lotNumber',
}

const pickSchedLabels: Record<PickSchedKeys, string> = {
  packHouse: 'Schedule for',
  growerId: 'Grower',
  block: 'Block',
  variety: 'Variety',
  pool: 'Pool',
  binsSched: 'Bins Scheduled',
  binsPicked: 'Bins Picked',
  binsLeft: 'Bins Left',
  fieldRep: 'Field Rep.',
  market: 'Market',
  harvestCoor: 'Harvest Co.',
  assignedPickCrews: 'Pick Crews',
  assignedHaulers: 'Haulers',
  gapStatus: 'Gap Status',
  lotNumber: 'Lot #',
};

export const pickSchedHeaders = (date: string): CsvHeaders<PickSchedKeys>[] => [
  {
    label: `${pickSchedLabels.packHouse} ${date}`,
    key: PickSchedKeys.packHouse,
  },
  { label: pickSchedLabels.growerId, key: PickSchedKeys.growerId },
  { label: pickSchedLabels.block, key: PickSchedKeys.block },
  { label: pickSchedLabels.variety, key: PickSchedKeys.variety },
  { label: pickSchedLabels.pool, key: PickSchedKeys.pool },
  { label: pickSchedLabels.binsSched, key: PickSchedKeys.binsSched },
  { label: pickSchedLabels.binsPicked, key: PickSchedKeys.binsPicked },
  { label: pickSchedLabels.binsLeft, key: PickSchedKeys.binsLeft },
  { label: pickSchedLabels.market, key: PickSchedKeys.market },
  { label: pickSchedLabels.fieldRep, key: PickSchedKeys.fieldRep },
  { label: pickSchedLabels.harvestCoor, key: PickSchedKeys.harvestCoor },
  {
    label: pickSchedLabels.assignedPickCrews,
    key: PickSchedKeys.assignedPickCrews,
  },
  {
    label: pickSchedLabels.assignedHaulers,
    key: PickSchedKeys.assignedHaulers,
  },
  { label: pickSchedLabels.gapStatus, key: PickSchedKeys.gapStatus },
  { label: pickSchedLabels.lotNumber, key: PickSchedKeys.lotNumber },
];

export const emptyPickSchedRow = {
  [`${PickSchedKeys.packHouse}`]: emptyCellValue,
  [`${PickSchedKeys.growerId}`]: emptyCellValue,
  [`${PickSchedKeys.block}`]: emptyCellValue,
  [`${PickSchedKeys.variety}`]: emptyCellValue,
  [`${PickSchedKeys.pool}`]: emptyCellValue,
  [`${PickSchedKeys.binsSched}`]: emptyCellValue,
  [`${PickSchedKeys.binsPicked}`]: emptyCellValue,
  [`${PickSchedKeys.binsLeft}`]: emptyCellValue,
  [`${PickSchedKeys.market}`]: emptyCellValue,
  [`${PickSchedKeys.fieldRep}`]: emptyCellValue,
  [`${PickSchedKeys.harvestCoor}`]: emptyCellValue,
  [`${PickSchedKeys.assignedPickCrews}`]: emptyCellValue,
  [`${PickSchedKeys.assignedHaulers}`]: emptyCellValue,
  [`${PickSchedKeys.gapStatus}`]: emptyCellValue,
  [`${PickSchedKeys.lotNumber}`]: emptyCellValue,
};

export const formatCsvData = (
  allSchedulesTotals: { scheduledTotal: number; pickedTotal: number },
  packHouseSchedules: {
    packHouseCode: string;
    rows: PackHousePickScheduleTableData[];
    totals: {
      scheduledTotal: number;
      pickedTotal: number;
      binsLeftTotal: number;
    };
  }[],
): Record<PickSchedKeys, string>[] => {
  const csvData: Record<PickSchedKeys, string>[] = [
    {
      ...emptyPickSchedRow,
      packHouse: `${pciSchedTotalStr}${allSchedulesTotals.scheduledTotal}`,
    },
    {
      ...emptyPickSchedRow,
      packHouse: `${pciPickedTotalStr}${allSchedulesTotals.pickedTotal}`,
    },
    { ...emptyPickSchedRow },
  ];

  packHouseSchedules
    .sort((a, b) => a.packHouseCode.localeCompare(b.packHouseCode))
    .forEach(({ packHouseCode, rows, totals }) => {
      if (!rows.length) {
        csvData.push(
          {
            ...emptyPickSchedRow,
            packHouse: `${packHouseCode} - No blocks scheduled.`,
          },
          { ...emptyPickSchedRow },
        );
        return;
      }

      rows.forEach(row => {
        csvData.push({
          packHouse: packHouseCode,
          ...row,
          block: row.blockId,
          binsSched: row.binsScheduled.toString(),
          binsPicked: row.binsPicked.toString(),
          binsLeft: row.binsLeft.toString(),
          harvestCoor: row.harvestCoordinator,
          variety: row.varietyName,
        });
      });

      csvData.push(
        {
          ...emptyPickSchedRow,
          variety: dailyTotalStr,
          binsSched: totals.scheduledTotal.toString(),
          binsPicked: totals.pickedTotal.toString(),
          binsLeft: totals.binsLeftTotal.toString(),
        },
        emptyPickSchedRow,
      );
    });
  return csvData;
};
