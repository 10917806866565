import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { mobile } from 'common/styles/breakpoints';
import { lighterNavy } from 'common/styles/colors';
import { BodyText } from 'common/styles/texts';
import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  isFetching: boolean;
  fetchingError: unknown | undefined;
  scheduledTotal: number | undefined;
  pickedTotal: number | undefined;
}

const Wrapper = styled.div`
  display: flex;
  margin: 0.5rem 0;

  @media (max-width: ${mobile}) {
    margin: 0.5rem 5px;
  }
`;

const TotalText = styled(BodyText)`
  font-family: KanitSemiBold;
  color: ${lighterNavy};
  margin-bottom: 5px;
`;

export const pciSchedTotalStr = 'Day Schedule Total: ';
export const pciPickedTotalStr = 'Day Pick Total: ';

export const DailyTotalRow: FC<Props> = ({
  isFetching,
  fetchingError,
  scheduledTotal,
  pickedTotal,
}) => {
  return (
    <Wrapper>
      <WithLoadingOverlay isLoading={isFetching} size='2rem'>
        {fetchingError ? (
          <p className='error'>Error loading daily totals.</p>
        ) : (
          <div>
            <TotalText>{`${pciSchedTotalStr}${scheduledTotal ?? 0}`}</TotalText>
            <TotalText>{`${pciPickedTotalStr}${pickedTotal ?? 0}`}</TotalText>
          </div>
        )}
      </WithLoadingOverlay>
    </Wrapper>
  );
};
