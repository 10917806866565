import {
  WeekSwitcherIds,
  useWeekSwitcherState,
} from 'common/components/WeekSwitcher/useWeekSwitcherState';
import {
  WizardDropdownOption,
  WizardDropdown,
} from 'common/components/WizardControls/WizardDropdown';
import { Contractor } from 'common/models';
import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  DateRange,
  DropdownAndWeekSwitcher,
  HeaderForm,
  TitleAndCsvBtn,
} from './styles';
import { WeekSwitcher } from 'common/components/WeekSwitcher';
import { CsvButtonWrapper } from './CsvButtonWrapper';
import { createContractorOptions } from 'utils/formValues/dropdownOptions';
import { useAppDispatch, useAppSelector } from 'app/redux';
import {
  PayrollPageState,
  payrollSlice,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import { PayrollData } from 'common/models/harvestData/payrollData';

type SelectedContractor = {
  contractor: WizardDropdownOption<Contractor> | null;
};

export const PayrollHeader: FC<{
  payrollData: PayrollData | undefined;
  contractors: Contractor[] | undefined;
  isLoadingContractors: boolean;
  isLoadingPayrollData: boolean;
}> = ({
  payrollData,
  contractors,
  isLoadingContractors,
  isLoadingPayrollData,
}) => {
  const dispatch = useAppDispatch();
  const { state: week } = useWeekSwitcherState(WeekSwitcherIds.HarvPayroll);
  const { isEditable, selectedContractor } = useAppSelector<PayrollPageState>(
    state => state[payrollSliceName],
  );
  const { control, watch } = useForm<SelectedContractor>({
    defaultValues: {
      contractor: selectedContractor
        ? { label: selectedContractor.code, value: selectedContractor }
        : null,
    },
  });

  const contractorOptions: WizardDropdownOption<Contractor>[] = useMemo(
    () => createContractorOptions(contractors),
    [contractors],
  );

  const dateRange = useMemo(() => {
    const firstDay = week.weekdays[0];
    const lastDay = week.weekdays[6];
    return `${firstDay.weekday} ${firstDay.month}/${firstDay.day} - ${lastDay.weekday} ${lastDay.month}/${lastDay.day}`;
  }, [week]);

  useEffect(() => {
    const subscription = watch(data => {
      if (data.contractor?.value) {
        // Enforce type as useForm is causing type issue.
        dispatch(
          payrollSlice.actions.setContractor(
            data.contractor.value as Contractor,
          ),
        );
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch, watch]);

  return (
    <>
      <TitleAndCsvBtn>
        <h1>Harvest Payroll</h1>
        {selectedContractor &&
          !!payrollData &&
          !!payrollData?.picks.length &&
          !isLoadingPayrollData && (
            <CsvButtonWrapper
              week={week}
              payrollData={payrollData}
              selectedContractor={selectedContractor}
            />
          )}
      </TitleAndCsvBtn>
      <HeaderForm>
        <DropdownAndWeekSwitcher>
          <WizardDropdown<Contractor>
            name='contractor'
            formControl={control}
            options={contractorOptions}
            isLoading={isLoadingContractors}
            isDisabled={isLoadingPayrollData || isEditable}
          />
          <WeekSwitcher
            identifier={WeekSwitcherIds.HarvPayroll}
            disableButtons={isLoadingPayrollData || isEditable}
          />
        </DropdownAndWeekSwitcher>
        <DateRange>{dateRange}</DateRange>
      </HeaderForm>
    </>
  );
};
