import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import { DataTableText } from 'common/components/DataTable';
import { useCheckboxColumn } from 'common/components/DataTable/hooks/useCheckboxColumn';
import { CmsDate } from 'common/models/cmsDate';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { Column } from 'react-table';
import styled from 'styled-components';

const CenteredText = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: KanitLight;
  font-size: 16px;
`;

const CenteredHeaderText = styled(CenteredText)`
  font-family: 'KanitSemiBold';
`;

export type AvailBlocksTableItem = {
  block: BlockDetails<WithAllSeasonData>;
  binsRemaining: string;
  growerId: string;
  blockId: string;
  market: string;
  lotNumber: string;
  latestEval: CmsDate | string;
  harvestPhase: number | string;
};

export type UseAvailBlocksTableData = (
  isFetching: boolean,
  setBlocks: Dispatch<SetStateAction<BlockDetails<WithAllSeasonData>[]>>,
  blocks?: BlockDetails<WithAllSeasonData>[],
) => {
  columns: Column<AvailBlocksTableItem>[];
  data: AvailBlocksTableItem[];
};

export const useAvailBlocksTableData: UseAvailBlocksTableData = (
  isFetching,
  setBlocks,
  blocks = [],
) => {
  const selectBlockColumn: Column<AvailBlocksTableItem> = useCheckboxColumn(
    'block',
    '5%',
    blocks,
    setBlocks,
    () => <></>,
    isFetching, // Needed to deselect boxes if switching seasons.
  );
  const columns: Column<AvailBlocksTableItem>[] = useMemo(
    () => [
      selectBlockColumn,
      {
        accessor: 'binsRemaining',
        Header: () => <CenteredHeaderText>Bins to go</CenteredHeaderText>,
        width: '10%',
        Cell: ({ value }) => <CenteredText>{value}</CenteredText>,
      },
      {
        accessor: 'growerId',
        Header: 'Grower ID',
        width: '20%',
        Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
      },
      {
        accessor: 'blockId',
        Header: 'Block',
        width: '15%',
        Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
      },
      {
        accessor: 'lotNumber',
        Header: () => <CenteredHeaderText>Lot</CenteredHeaderText>,
        width: '20%',
        Cell: ({ value }) => <CenteredText>{value}</CenteredText>,
      },
      {
        accessor: 'market',
        Header: 'Market',
        width: '15%',
        Cell: ({ value }) => <DataTableText>{value}</DataTableText>,
      },
      {
        accessor: 'latestEval',
        Header: 'Latest Eval.',
        width: '15%',
        Cell: ({ value }) => <DataTableText>{value.toString()}</DataTableText>,
      },
      {
        accessor: 'harvestPhase',
        Header: () => <CenteredHeaderText>Harv. Phase</CenteredHeaderText>,
        width: '10%',
        Cell: ({ value }) => <CenteredText>{`${value}%`}</CenteredText>,
      },
    ],
    [selectBlockColumn],
  );

  const data: AvailBlocksTableItem[] = useMemo(
    () =>
      blocks.map(block => {
        const { grower, seasonData } = block;
        return {
          block,
          binsRemaining:
            seasonData?.latestHarvestEstimate?.binsToHarvest?.toString() || '-',
          growerId: grower.growerId,
          blockId: block.blockId,
          lotNumber: block.lotNumber || '-',
          market: seasonData?.evaluation?.market || '-',
          latestEval:
            CmsDate.parse(seasonData?.evaluation?.evaluationDate) || '-',
          harvestPhase: seasonData?.latestHarvestEstimate?.harvestPhase || 0,
        };
      }),
    [blocks],
  );

  return { columns, data };
};
