import {
  blueShade,
  white,
  darkNavy,
  darkOrange,
  orange,
  lighterNavy,
} from 'common/styles/colors';
import styled from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px 20px 0;
  justify-content: space-between;
`;

export const TitleDiv = styled.div`
  font-family: KanitLight;
  font-size: 32px;
  letter-spacing: 0.0075em;
  color: ${blueShade};
`;
export const CancelButton = styled.button`
  background: ${darkNavy};
  font-size: 16px;
  color: ${white};
  border: 0px;
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 20px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;

  & button {
    padding: 7.5px 20px;
    gap: 10px;
    width: 230px;
    height: 40px;
    margin-bottom: 1rem;
    color: ${lighterNavy};
    justify-content: center;
    background-color: ${white};
    border: none;
    border-radius: 4px;
    align-items: center;

    &:hover {
      background-color: ${darkOrange};
      color: ${white};
    }

    & span {
      font-family: KanitRegular;
      font-size: 18px;
      line-height: 148%;
      font-style: normal;
      font-weight: 500;
      text-align: center;
      letter-spacing: 0.12em;
    }
  }

  & button:last-of-type {
    background-color: ${orange};
    color: ${white};
  }

  & button:last-of-type:hover {
    background-color: ${darkOrange};
  }
`;
