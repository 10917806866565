import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { authApi } from 'common/api/authApi';
import { roleApi } from 'common/api/roleApi';
import { userApi } from 'common/api/userApi';
import { growerApi } from 'common/api/growerApi';
import { authSlice } from 'features/auth/authSlice';
import { confirmationModalSlice } from 'features/confirmation-modal/slice';
import { growerBlockApi } from 'common/api/growerBlockApi';
import { varietyApi } from 'common/api/varietyApi';
import { subVarietyApi } from 'common/api/subVarietyApi';
import { rootstockApi } from 'common/api/rootstockApi';
import { countyApi } from 'common/api/countyApi';
import { districtApi } from 'common/api/districtApi';
import { areaApi } from 'common/api/areaApi';
import { syncApi } from 'common/api/syncApi';
import { psfQuerySlice } from './psfQuerySlice';
import { packHouseApi } from 'common/api/packHouse.api';
import { harvestDataApi } from 'common/api/harvestDataApi';
import { pickScheduleApi } from 'common/api/pickSchedulingApi';
import { weekSwitcherSlice } from 'common/components/WeekSwitcher/weekSwitcherSlice';
import { daySelectorSlice } from 'features/pick-schedule-views/components/PickSchedule/DaySelectorSlice';
import { payrollSlice } from 'features/harvest-payroll/PayrollSlice';
import { mapsModalSlice } from 'common/components/GoogleMapsModal/GmapsModalSlice';
import { healthCheckApi } from 'common/api/healthCheckApi';
import { seasonSlice } from 'features/navbar/components/SeasonSelector/seasonSlice';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createAppStore = (
  options?: ConfigureStoreOptions['preloadedState'] | undefined,
) =>
  configureStore({
    reducer: {
      auth: authSlice.reducer,
      confirmationModal: confirmationModalSlice.reducer,
      [authApi.reducerPath]: authApi.reducer,
      [roleApi.reducerPath]: roleApi.reducer,
      [userApi.reducerPath]: userApi.reducer,
      [growerApi.reducerPath]: growerApi.reducer,
      [growerBlockApi.reducerPath]: growerBlockApi.reducer,
      [varietyApi.reducerPath]: varietyApi.reducer,
      [subVarietyApi.reducerPath]: subVarietyApi.reducer,
      [rootstockApi.reducerPath]: rootstockApi.reducer,
      [countyApi.reducerPath]: countyApi.reducer,
      [districtApi.reducerPath]: districtApi.reducer,
      [areaApi.reducerPath]: areaApi.reducer,
      [syncApi.reducerPath]: syncApi.reducer,
      [psfQuerySlice.name]: psfQuerySlice.reducer,
      [packHouseApi.reducerPath]: packHouseApi.reducer,
      [weekSwitcherSlice.name]: weekSwitcherSlice.reducer,
      [harvestDataApi.reducerPath]: harvestDataApi.reducer,
      [pickScheduleApi.reducerPath]: pickScheduleApi.reducer,
      [daySelectorSlice.name]: daySelectorSlice.reducer,
      [payrollSlice.name]: payrollSlice.reducer,
      [mapsModalSlice.name]: mapsModalSlice.reducer,
      [healthCheckApi.reducerPath]: healthCheckApi.reducer,
      [seasonSlice.name]: seasonSlice.reducer,
    },

    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat(
        authApi.middleware,
        roleApi.middleware,
        userApi.middleware,
        growerApi.middleware,
        growerBlockApi.middleware,
        varietyApi.middleware,
        subVarietyApi.middleware,
        rootstockApi.middleware,
        countyApi.middleware,
        districtApi.middleware,
        areaApi.middleware,
        syncApi.middleware,
        packHouseApi.middleware,
        harvestDataApi.middleware,
        pickScheduleApi.middleware,
        pickScheduleApi.middleware,
        healthCheckApi.middleware,
      ),

    ...options,
  });

export const store = createAppStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
