import {
  useDeleteUserMutation,
  useForgotPasswordMutation,
  useResendActivationEmailMutation,
  useResendChangeEmailVerificationEmailMutation,
} from 'common/api/userApi';
import { User } from 'common/models';
import { useConfirmationModal } from 'features/confirmation-modal';
import { getUserFullName } from 'utils/helperFunction';
import * as notificationService from 'common/services/notification';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { handleError } from 'common/api/handleError';

export type ActionResponse = 'confirm' | 'success' | 'error';

type ActionResponseHandler = (response: ActionResponse) => void;
interface action {
  (user: User, onActionResponse?: ActionResponseHandler): void;
}

type ActionsType = {
  handleDelete: action;
  handlePasswordReset: action;
  resendEmailChangeVerification: action;
  resendEmailVerification: action;
};

const useUserActions = (): ActionsType => {
  const history = useHistory();
  const [sendForgotPasswordEmail] = useForgotPasswordMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [resendActivationEmail] = useResendActivationEmailMutation();
  const [resendChangeEmailVerificationEmail] =
    useResendChangeEmailVerificationEmailMutation();
  const { openModal } = useConfirmationModal();

  const handlePasswordReset: action = useCallback(
    (user: User, onActionResponse?: ActionResponseHandler) => {
      const message = `Send Reset Password Email to ${getUserFullName(user)}`;
      const onConfirm = async () => {
        try {
          if (onActionResponse) {
            onActionResponse('confirm');
          }
          await sendForgotPasswordEmail({ email: user.email }).unwrap();
          notificationService.showSuccessMessage(
            `Password reset email has been sent to ${user.email}`,
          );
          if (onActionResponse) {
            onActionResponse('success');
          }
        } catch (error) {
          handleError(
            error,
            `There was an issue sending an email to ${user.email}`,
          );
          if (onActionResponse) {
            onActionResponse('error');
          }
        }
      };

      openModal({ message, confirmButtonLabel: 'SEND', onConfirm });
    },
    [openModal, sendForgotPasswordEmail],
  );

  const handleDelete: action = useCallback(
    (user: User, onActionResponse?: ActionResponseHandler) => {
      const message = `Delete ${getUserFullName(user)}?`;

      const onConfirm = async () => {
        try {
          if (onActionResponse) {
            onActionResponse('confirm');
          }
          await deleteUser(user.id).unwrap();
          history.push('/users');
          notificationService.showSuccessMessage('User deleted.');
        } catch (error) {
          handleError(
            error,
            `There was an issue deleting ${getUserFullName(user)}`,
          );
          if (onActionResponse) {
            onActionResponse('error');
          }
        }
      };

      openModal({ message, confirmButtonLabel: 'DELETE', onConfirm });
    },
    [openModal, deleteUser, history],
  );

  const resendEmailVerification: action = useCallback(
    (user: User, onActionResponse?: ActionResponseHandler) => {
      const message = `Resend Email Verification to ${user.email}?`;

      const onConfirm = async () => {
        try {
          if (onActionResponse) {
            onActionResponse('confirm');
          }
          await resendActivationEmail({ id: user.id }).unwrap();
          notificationService.showSuccessMessage(
            `Sent Verification email to ${user.email}.`,
          );
          if (onActionResponse) {
            onActionResponse('success');
          }
        } catch (error) {
          handleError(error, `There was an issue sending an email.`);
          if (onActionResponse) {
            onActionResponse('error');
          }
        }
      };

      openModal({ message, confirmButtonLabel: 'SEND', onConfirm });
    },
    [openModal, resendActivationEmail],
  );

  const resendEmailChangeVerification: action = useCallback(
    (user: User, onActionResponse?: ActionResponseHandler) => {
      const message = `Resend Email Verification to ${user.newEmail}?`;

      const onConfirm = async () => {
        try {
          if (onActionResponse) {
            onActionResponse('confirm');
          }
          await resendChangeEmailVerificationEmail({ id: user.id }).unwrap();
          notificationService.showSuccessMessage(
            `Sent Verification email to ${user.newEmail}.`,
          );
          if (onActionResponse) {
            onActionResponse('success');
          }
        } catch (error) {
          handleError(error, `There was an issue sending an email.`);
          if (onActionResponse) {
            onActionResponse('error');
          }
        }
      };

      openModal({ message, confirmButtonLabel: 'SEND', onConfirm });
    },
    [openModal, resendChangeEmailVerificationEmail],
  );

  return {
    handlePasswordReset,
    handleDelete,
    resendEmailVerification,
    resendEmailChangeVerification,
  };
};

export default useUserActions;
