import { Row, Stack } from 'react-bootstrap';
import styled from 'styled-components';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';

interface ResponsiveRowProps {
  className?: string;
  children?: React.ReactNode;
}

/** A container that wraps form fields with a Row only in desktop view.
 *  In mobile view, form fields are not wrapped with a Row for reordering
 *  to be possible via {@link StyledStack}.
 */
export const ResponsiveRow: React.FC<ResponsiveRowProps> = ({
  children,
  className,
}) => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  return isMobile ? (
    <>{children}</>
  ) : (
    <Row className={className}>{children}</Row>
  );
};

/** A flex container that allows for reordering form fields via order property */
export const StyledStack = styled(Stack)`
  @media (max-width: ${mobile}) {
    & .col:nth-child(odd) {
      order: -1;
    }
  }
`;
