import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'react-bootstrap';
import * as yup from 'yup';
import { Constants } from 'utils/constants';
import ForgotPasswordFooter from './ForgotPasswordFooter';

export type FormData = {
  email: string | null;
};

type Props = {
  onSubmit: (data: FormData) => void;
};

const schema: yup.SchemaOf<FormData> = yup.object().shape({
  email: yup
    .string()
    .required(Constants.errorMessages.EMAIL_REQUIRED)
    .email(Constants.errorMessages.INVALID_EMAIL),
});

export const ForgotPasswordForm: FC<Props> = ({ onSubmit }) => {
  const {
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      email: null,
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label htmlFor='email'>Email Address</Form.Label>
        <span>&nbsp;(required)</span>
        <Form.Control
          id='email'
          type='email'
          {...register('email')}
          placeholder='Enter Email Address'
          isInvalid={!!errors.email}
        />
        <Form.Control.Feedback type='invalid' role='alert'>
          <>{errors.email?.message}</>
        </Form.Control.Feedback>
      </Form.Group>
      <ForgotPasswordFooter isValid={isValid} isSubmitting={isSubmitting} />
    </Form>
  );
};
