import { useAppSelector } from 'app/redux';
import { CsvButton } from 'common/components/CsvButton/CsvButton';
import { Contractor } from 'common/models';
import {
  PayrollPageState,
  payrollSliceName,
} from 'features/harvest-payroll/PayrollSlice';
import {
  AccountsPayableHeaders,
  AccountsPayableKeys,
  PayrollKeys,
  picksHeaders,
} from 'features/harvest-payroll/utils/payrollDataTypes';
import { FC, useState } from 'react';
import { Week } from 'utils/dateTimeHelpers';
import { ExportBtnWrapper } from './styles';
import { PayrollData } from 'common/models/harvestData/payrollData';
import {
  generateApCsv,
  generateFilename,
  generatePayrollCsv,
} from 'features/harvest-payroll/utils/payrollPageUtils';

type PayrollCsvRow = Record<PayrollKeys, string>;

type ApCsvRow = Record<AccountsPayableKeys, string>;

export const CsvButtonWrapper: FC<{
  week: Week;
  payrollData: PayrollData;
  selectedContractor: Contractor;
}> = ({ week, payrollData, selectedContractor }) => {
  const [payrollCsvData, setPayrollCsvData] = useState<PayrollCsvRow[]>([]);
  const [apCsvData, setApCsvData] = useState<ApCsvRow[]>([]);
  const {
    picks,
    transfers,
    picksTotal,
    transfersTotal,
    invoiceTotal,
    isEditable,
  } = useAppSelector<PayrollPageState>(state => state[payrollSliceName]);

  /**
   * Handler to generate and set csv rows.
   *
   * @param dataType - Indicates whether to generate a payroll csv or an
   * accounts payable csv.
   *
   * @remark - Using this click handler over calling a util function on the
   * button's `data` prop avoids component re-rendering on slice state changes.
   */
  const handleCsvData = (dataType: 'payroll' | 'ap') => {
    if (dataType === 'payroll') {
      setPayrollCsvData(
        generatePayrollCsv(
          picks,
          transfers,
          picksTotal,
          transfersTotal,
          invoiceTotal,
        ),
      );
    } else {
      setApCsvData(
        generateApCsv(payrollData, selectedContractor, picks, transfers, week),
      );
    }
  };

  return (
    <ExportBtnWrapper>
      <CsvButton
        label='Export Payroll'
        headers={picksHeaders}
        data={payrollCsvData}
        filename={generateFilename(week, selectedContractor)}
        // Don't allow download when in edit mode
        isDisabled={isEditable}
        onClick={() => handleCsvData('payroll')}
      />
      <CsvButton
        label='Export Summary'
        headers={AccountsPayableHeaders}
        data={apCsvData}
        filename={generateFilename(week, selectedContractor, true)}
        // Don't allow download when in edit mode
        isDisabled={isEditable}
        onClick={() => handleCsvData('ap')}
      />
    </ExportBtnWrapper>
  );
};
