import * as Sentry from '@sentry/react';
import { store } from 'app/redux';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { sentryErrorHandledProperty } from 'common/api/handleError';
import { environment } from 'environment';
import { Constants } from 'utils/constants';

Sentry.init({
  dsn: 'https://eb5d615d9b3e3d5a1262b21844d2fcd7@o4506621406412800.ingest.sentry.io/4506625051000832',
  environment: environment.name,
  release: Constants.release,
  autoSessionTracking: false,
  beforeSend(event) {
    if (process.env.NODE_ENV === 'production') {
      // Determine if our error handler dealt with this
      const handled =
        event.contexts && event.contexts[sentryErrorHandledProperty];

      if (!handled && event.event_id) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    }
    return null;
  },
});

ReactDOM.render(
  <StrictMode>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
