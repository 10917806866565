import { Close } from '@mui/icons-material';
import { tableHeaderGrey } from 'common/styles/colors';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const CloseBtn = styled(Button).attrs(() => ({
  variant: 'transparent',
  type: 'button',
  'aria-label': 'close',
}))`
  padding: 0.2rem;
  :focus {
    outline: ${tableHeaderGrey} dotted 1px;
  }
  border-color: transparent;
`;

const CloseIcon = styled(Close)`
  color: ${tableHeaderGrey};
`;

interface CloseButtonProps {
  /**
   * The function that will take action when the form is closed. If not provided,
   * the default is to return to the previous page.
   */
  closeHandler?: () => void;
}

export const CloseButton: FC<CloseButtonProps> = ({ closeHandler }) => {
  const history = useHistory();
  return (
    <CloseBtn onClick={closeHandler ?? history.goBack}>
      <CloseIcon />
    </CloseBtn>
  );
};
