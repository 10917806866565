import { WithLoadingOverlay } from 'common/components/LoadingSpinner';
import { FC, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useGetRolesQuery } from 'common/api/roleApi';
import {
  useGetUserDetailsQuery,
  useUpdateUserMutation,
} from 'common/api/userApi';
import { Title } from 'common/styles/form';
import { UserDetailForm, UserDetails } from '../components/UserDetailForm';
import useWindowSize from 'common/hooks/useWindowSize';
import { mobile } from 'common/styles/breakpoints';
import { handleError } from 'common/api/handleError';
import { Constants } from 'utils/constants';
import { getIntParam } from 'utils/paramFunctions';
import { useAppDispatch } from 'app/redux';
import { useAuth } from 'features/auth/hooks';
import { authSlice } from 'features/auth/authSlice';
import * as authLocalStorage from 'features/auth/authLocalStorage';

interface RouteParams {
  id: string;
}

export const UserDetailView: FC = () => {
  const { width } = useWindowSize();
  const isMobile = width < parseInt(mobile, 10);

  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [updateUser] = useUpdateUserMutation();
  const {
    data: user,
    isLoading: isLoadingUser,
    error: getUserError,
  } = useGetUserDetailsQuery({ id });
  const { data: roles = [], isLoading: isLoadingRoles } = useGetRolesQuery();
  const page = getIntParam(
    history.location.search,
    Constants.paginationParams.FROM_PAGE,
  );
  const dispatch = useAppDispatch();
  const { token, user: authedUser } = useAuth();

  useEffect(() => {
    if (getUserError) {
      handleError(getUserError, 'Unable to load user. Returning to user list.');
      history.replace('/users');
    }
  }, [getUserError, history]);

  const updateWithId = useCallback(
    async (dat: UserDetails) => {
      const userWithId = {
        id: Number(user?.id),
        profilePicture: null,
        ...dat,
      };
      const newUser = await updateUser(userWithId).unwrap();
      // We need to do the following in order to update the UserCard component,
      // but we could refactor to use the RTK 'useGetUserByIdQuery' hook later
      if (newUser.id === authedUser?.id) {
        const newAuth = { token, user: newUser };
        dispatch(authSlice.actions.userLoggedIn(newAuth));
        authLocalStorage.saveAuthState(newAuth);
      }
    },
    [user?.id, updateUser, authedUser?.id, dispatch, token],
  );

  const closeHandler = () => {
    history.push(
      `${Constants.routes.USERS_LIST}?${Constants.paginationParams.PAGE}=${page}`,
    );
  };

  return (
    <WithLoadingOverlay isLoading={isLoadingUser || isLoadingRoles}>
      {!isMobile && <Title>User Detail</Title>}
      <UserDetailForm
        user={user}
        availableRoles={roles}
        updater={updateWithId}
        closeHandler={closeHandler}
      />
    </WithLoadingOverlay>
  );
};
