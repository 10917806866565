export const lightGreyText = '#FAFAFA';
export const lightGreyBackground = '#ECECEC';
export const grey = '#ACACAC';
export const borderGrey = '#ced4da';
export const tableHeaderGrey = '#707070';
export const tableBodyGrey = '#404040';
export const secondaryWhite = '#FAFAFA';
export const white = '#FFFFFF';
export const lightOrange = '#EFD0B2';
export const orange = '#D97517';
export const brightOrange = '#FE5000';
export const darkOrange = '#B85900';
export const red = '#EA1A1A';
export const lighterNavy = '#36487A';
export const darkNavy = '#112457';
export const shadowGrey = '#00000033';
export const darkGrey = '#2d4072';
export const lighterBlueShade = '#E6ECFF';
export const blueShade = '#C7D4F9';
export const darkerBlueShade = '#0A93D0';
export const blueShadeHeader = '#3A559F';
export const navHoverBlue = '#8699D0';
export const green = '#2DA44F';
export const darkBlue = '#3C65D7';
export const lightBlue = '#E5ECFF';
