import { FC } from 'react';
import { useStatus } from '../hooks/useStatus';

export const StatusIcon: FC<{
  status?: number; // Grower or block status enum key.
  type: 'grower' | 'block';
}> = ({ status, type }) => {
  const { createStatusProps } = useStatus();

  const { icon } = createStatusProps(type, true, status);

  return icon;
};
