import { yupResolver } from '@hookform/resolvers/yup';
import { BlockDetails, WithAllSeasonData } from 'common/api/dto/get-block.dto';
import FormPrompt from 'common/components/FormPrompt';
import { WizardDateTimePicker } from 'common/components/WizardControls/WizardDateTimePicker';
import {
  WizardDropdown,
  WizardDropdownOption,
  wizardYesNoOptions,
} from 'common/components/WizardControls/WizardDropdown';
import { WizardNumericInput } from 'common/components/WizardControls/WizardNumericInput';
import { Variety } from 'common/models';
import { Size } from 'common/models/growerBlock/size';
import { Pool } from 'common/models/harvestData/pool';
import { Market } from 'common/models/market';
import { PackHouse } from 'common/models/packHouse';
import { pickTypeLabels } from 'common/models/pickType';
import { darkNavy } from 'common/styles/colors';
import { useConfirmationModal } from 'features/confirmation-modal';
import usePickScheduleActions, {
  ScheduledPickPayload,
} from 'features/pick-schedule-views/hooks/usePickScheduleActions';
import { ModifiedAllSeasonData } from 'features/pick-schedule-views/pages/SchedulePickWizardView';
import { FC, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { scheduledPickSchema } from 'utils/schemas/scheduledPickSchema';
import {
  ButtonSection,
  CancelBtn,
  InfoLabel,
  Textarea,
  WizardContainer,
  WizardSection,
  selectBaseStyling,
} from '../WizardComponents/wizardStyles';
import { DataEntryRow, OrangeButton, OrangePrompt } from './styles';
import { WizardInfoSection } from './WizardInfoSection';

type PickToSchedule = {
  packHouse: WizardDropdownOption<PackHouse> | null;
  market: WizardDropdownOption<string> | null;
  size: WizardDropdownOption<Size> | null;
  pickDay: Date | null;
  binsToPick: number | null;
  pool: WizardDropdownOption<Pool> | null;
  /**
   * Options are numbers as strings.
   *
   * @remark - Falsy number `0` causes issue with `react-select` so
   * we use strings instead. The actual value is determined
   * after form validation.
   * */
  cleanPick: WizardDropdownOption<string> | null;
  /**
   * Options are numbers as strings.
   *
   * @remark - Falsy number `0` causes issue with `react-select` so
   * we use strings instead. The actual value is determined
   * after form validation.
   * */
  pickType: WizardDropdownOption<string> | null;
  notes: string | null;
};

type KeyOfPickToSchedule = keyof PickToSchedule;

export const SchedulePickWizard: FC<{
  packHouses: PackHouse[];
  pools: Pool[];
  availBlock?: BlockDetails<WithAllSeasonData>;
  adHocBlock?: BlockDetails<ModifiedAllSeasonData>;
  packHouse: PackHouse | undefined;
  variety: Variety | undefined;
  size: Size | undefined;
  requestId: number | undefined;
  handleNextBlock: () => void;
}> = ({
  packHouses,
  pools,
  availBlock,
  adHocBlock,
  packHouse,
  variety,
  size,
  requestId,
  handleNextBlock,
}) => {
  const history = useHistory();
  const { openCancelSchedulingModal, savePickToSchedule } =
    usePickScheduleActions();
  const { openModal } = useConfirmationModal();
  const [showPrompt, setShowPrompt] = useState(true);
  const [step, setStep] = useState(1);
  const [isScheduling, setIsScheduling] = useState(false);
  const pickTypeOptions = pickTypeLabels.map((label, index) => ({
    label,
    value: `${index}`,
  }));
  const packhouseOptions: WizardDropdownOption<PackHouse>[] = useMemo(
    () =>
      packHouses?.map(packHouse => ({
        label: packHouse.code,
        value: packHouse,
      })),
    [packHouses],
  );
  const marketOptions: WizardDropdownOption<string>[] = Object.values(
    Market,
  ).map(market => ({
    label: market,
    value: market,
  }));
  const sizeOptions: WizardDropdownOption<Size>[] = useMemo(
    () =>
      adHocBlock
        ? adHocBlock?.seasonData?.sizeEstimates.map(est => ({
            label: est.size.value,
            value: est.size,
          })) ?? []
        : [],
    [adHocBlock],
  );
  const poolsOptions: WizardDropdownOption<Pool>[] = useMemo(
    () =>
      pools?.map(pool => ({
        label: pool.poolId,
        value: pool,
      })),
    [pools],
  );
  const getBinsToGo = (
    adHocBlk: BlockDetails<ModifiedAllSeasonData> | undefined,
    availBlk: BlockDetails<WithAllSeasonData> | undefined,
  ) => {
    return (
      (availBlk ?? adHocBlk)?.seasonData?.latestHarvestEstimate
        ?.binsToHarvest ?? 0
    );
  };
  const binsToGo: number = useMemo(
    () => getBinsToGo(adHocBlock, availBlock),
    [adHocBlock, availBlock],
  );

  const methods = useForm<PickToSchedule>({
    defaultValues: {
      packHouse: packHouse ? { label: packHouse.code, value: packHouse } : null,
      pickDay: null,
      binsToPick: null,
      pool: null,
      market: null,
      size: null,
      cleanPick: null,
      pickType: null,
      notes: null,
    },
    resolver: yupResolver(scheduledPickSchema),
    mode: 'all',
    context: { binsToGo },
  });
  const {
    control,
    formState: { isSubmitting, isDirty },
    register,
    handleSubmit,
    trigger: validate,
    reset,
  } = methods;

  const handleNext = async () => {
    const fieldArr: KeyOfPickToSchedule[] = [];
    if (step === 1) fieldArr.push('pickDay', 'binsToPick');
    if (step === 2) {
      if (adHocBlock) fieldArr.push('packHouse');
      fieldArr.push('pool', 'cleanPick', 'pickType');
    }
    const isValid = await validate(fieldArr);
    if (isValid) setStep(prevStep => prevStep + 1);
  };

  const schedulePick = async (data: PickToSchedule, force = false) => {
    const {
      binsToPick,
      pool,
      cleanPick,
      pickType,
      notes,
      market,
      size,
      packHouse,
      pickDay,
    } = data;

    // Force type because validation must have passed by now.
    const pick = {
      bins: binsToPick,
      pickDay,
      blockId: adHocBlock ? adHocBlock?.id : availBlock?.id,
      packHouseId: packHouse?.value?.id,
      poolId: pool?.value?.id,
      cleanPick: cleanPick?.value && !!parseInt(cleanPick.value, 10),
      pickType: pickType?.value && parseInt(pickType.value, 10),
      ...(requestId && { requestId }),
      ...(market && { market: market?.value }),
      ...(size && { sizeId: size.value?.id }),
      ...(notes && { notes }),
    } as ScheduledPickPayload;

    setIsScheduling(true);

    const status = await savePickToSchedule(pick, force);

    switch (status) {
      case 'OK':
        reset();
        setStep(1);
        setIsScheduling(false);
        handleNextBlock();
        break;

      case 'PENDING':
        openModal({
          message: 'This block has already been scheduled elsewhere.',
          onConfirm: async () => schedulePick(data, true),
          onDecline: () => setIsScheduling(false),
        });
        break;

      default:
        setIsScheduling(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <WizardContainer
        $backgroundColor={darkNavy}
        $width='450px'
        onSubmit={handleSubmit(data => schedulePick(data))}
      >
        <WizardInfoSection
          step={step}
          availBlock={availBlock}
          adHocBlock={adHocBlock}
          binsToGo={binsToGo}
          packHouse={packHouse}
          variety={variety}
          size={size}
        />

        {/* To avoid a memory leak warning and because form values must be registered,
            sections are hidden with css instead of conditionally rendered */}
        <WizardSection style={{ display: `${step === 1 ? 'flex' : 'none'}` }}>
          <OrangePrompt>Add this block to the schedule for:</OrangePrompt>
          <DataEntryRow>
            <InfoLabel>Pick Date</InfoLabel>
            <WizardDateTimePicker
              name='pickDay'
              formControl={control}
              minDate={new Date()}
            />
          </DataEntryRow>
          <DataEntryRow>
            <InfoLabel>Bins</InfoLabel>
            <WizardNumericInput name='binsToPick' />
          </DataEntryRow>
        </WizardSection>

        <WizardSection style={{ display: `${step === 2 ? 'flex' : 'none'}` }}>
          <OrangePrompt>Pick Details:</OrangePrompt>
          <DataEntryRow style={{ display: `${adHocBlock ? 'flex' : 'none'}` }}>
            <InfoLabel>Facility</InfoLabel>
            <WizardDropdown<PackHouse>
              name='packHouse'
              formControl={control}
              options={packhouseOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow style={{ display: `${adHocBlock ? 'flex' : 'none'}` }}>
            <InfoLabel>Market</InfoLabel>
            <WizardDropdown<string>
              name='market'
              formControl={control}
              options={marketOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow style={{ display: `${adHocBlock ? 'flex' : 'none'}` }}>
            <InfoLabel>Size</InfoLabel>
            <WizardDropdown<Size>
              name='size'
              formControl={control}
              options={sizeOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow>
            <InfoLabel>Pool</InfoLabel>
            <WizardDropdown<Pool>
              name='pool'
              formControl={control}
              options={poolsOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow>
            <InfoLabel>Clean Pick</InfoLabel>
            <WizardDropdown<string>
              name='cleanPick'
              formControl={control}
              options={wizardYesNoOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow>
            <InfoLabel>Pick Type</InfoLabel>
            <WizardDropdown<string>
              name='pickType'
              formControl={control}
              options={pickTypeOptions}
              styles={selectBaseStyling}
            />
          </DataEntryRow>
          <DataEntryRow>
            <InfoLabel>Add Notes:</InfoLabel>
          </DataEntryRow>
          <Textarea {...register('notes')} />
        </WizardSection>

        <WizardSection>
          {step === 3 && <OrangePrompt>Add to Pick Schedule?</OrangePrompt>}
          <ButtonSection>
            <CancelBtn
              type='button'
              onClick={() => {
                if (!isDirty) history.goBack();
                else {
                  setShowPrompt(false);
                  openCancelSchedulingModal();
                }
              }}
              disabled={isScheduling}
            >
              Cancel
            </CancelBtn>
            {/* The 'Next' and 'Confirm' buttons are rendered using two seprate statements
                to avoid a bubbling effect that submits the form on step 2 */}
            {step !== 3 && (
              <OrangeButton type='button' onClick={handleNext}>
                NEXT
              </OrangeButton>
            )}
            {step === 3 && (
              <OrangeButton type='submit' disabled={isScheduling}>
                CONFIRM
              </OrangeButton>
            )}
          </ButtonSection>
        </WizardSection>
      </WizardContainer>
      {showPrompt && (
        <FormPrompt isDirty={isDirty} isSubmitting={isSubmitting} />
      )}
    </FormProvider>
  );
};
